import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Role.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import RoleSearchForm from './RoleSearchForm';
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' },
    { id: 'SAQACode', numeric: false, disablePadding: true, label: 'SAQA Code' },


];

const searchParameters = [
    { Name: "Description", Value: "" },
    { Name: "SAQACode", Value: "" }

]

class RoleSearch extends Component {

    state = {
        model: {
            Description: "",
            SAQACode: ""
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: 'delete', label: '', tooltip: 'Delete selected role from records', icon: 'DeleteIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }

            ]
        },
        keyUpSwitch: false
    };

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("add" === buttonId) {
            this.props.history.push('/roleMaintenance');
        } else if ("edit" === buttonId) {
            this.props.history.push({
                pathname: "/roleMaintenance",
                state: {
                    selection: this.state.selectedRoleID,
                    RoleName: this.state.selectedRoleName,
                    RoleSAQACode: this.state.selectedSAQACode
                }
            })
        }
    }

    postLinkHandler(linked) {
        this.setState({ linkDialogOpen: false, selectedLinkType: null, selectedLinkStandards: null });
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedRoleID: selection[0].Id,
                selectedRoleName: selection[0].Description,
                selectedSAQACode: selection[0].SAQACode
            });
        } else {
            this.setState({
                selectedRoleID: null,
                selectedRoleName: null,
                selectedSAQACode: null
            });
        }
    }

    render() {
        return (
            <ViewContainer title={""}>
                <p className="breadcrumbs">
                    {"Role Maintenance > Roles & Permissions"}
                </p>
                <SearchView headCells={headCells}
                    dataUrl="api/sims/role/getsearch"
                    entityViewPath='/roleMaintenance'
                    model={this.state.model}
                    toolbarConfig={this.state.toolbarConfig}
                    searchParameters={searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="Surname" mode={this.props.mode}
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch} deleteUrl={"api/sims/role/RemoveRole"} useDeletePost={true}
                    promptTitle={"Remove Role"} promptContent={"Are you sure you want to remove this Role?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <RoleSearchForm model={this.state.model} />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(RoleSearch));
