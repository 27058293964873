const requestType = 'REQUEST';
const requestUserDetails = "REQUEST_USER_DETAILS";
const receiveUserDetailsType = 'RECEIVE_USER_DETAILS';
const receiveRoleDetailsType = 'RECEIVE_ROLE_DETAILS';
const selectRoleResponseType = 'SELECT_ROLE_RESPONSE';
const requestPasswordReset = 'REQUEST_PASSWORD_RESET';
const receivePasswordReset = 'RECEIVE_PASSWORD_RESET';
const requestValidateReset = "REQUEST_PASSWORD_VALIDATE";
const receiveValidateReset = "RECEIVE_PASSWORD_VALIDATE";

const initialState = {
    userDetails: {},
    hashdetails: {},
    validdetails: {},
    isLoading: false, 
    roleSet: false, 
    error: false, 
    userRoles: [], 
    selectedRole: {}, 
    currentMenuJson: ""
};

let currentUserDetails = {};
let currenUserRoles = [];
let currentHashDetails = {};
let currentValidDetails = {};

export const actionCreators = {
    //login: (username, password) => async (dispatch) => {
    //    dispatch({ type: requestUserDetails });
    //    let domain = "SG";
    //    var arr = username.split("\\");
    //    if (arr.length > 1) {
    //        domain = arr[0];
    //        username = arr[1];
    //    }
    //    const url = `${process.env.PUBLIC_URL}/api/indicium/security/login/v1/s1/${domain}/${username}/${password}`;
    //    const response = await fetch(url);
    //    const userDetails = await response.json();
    //    dispatch({ type: receiveUserDetailsType, userDetails });
    //},
    //confirmOTP: (id) => async (dispatch) => {
    //    const url = `${process.env.PUBLIC_URL}/api/indicium/security/confirmotp/${id}`;
    //    const response = await fetch(url);
    //    const userDetails = await response.json();
    //    dispatch({ type: receiveUserDetailsType, userDetails });
    //},
    //getRoles: () => async (dispatch) => {
    //    dispatch({ type: requestType });
    //    const url = `${process.env.PUBLIC_URL}/api/indicium/security/roles`;
    //    const response = await fetch(url);
    //    const roleDetails = await response.json();
    //    dispatch({ type: receiveRoleDetailsType, roleDetails });
    //},
    //selectRole: (selectedRole, username) => async (dispatch) => {
    //    dispatch({ type: requestType });
    //    const url = `${process.env.PUBLIC_URL}/api/indicium/security/setRole?id=${selectedRole}&username=${username}`;
    //    const response = await fetch(url);
    //    const tokenDetails = await response.json();
    //    localStorage.setItem("token", tokenDetails);
    //    if (response.ok) {
    //        dispatch({ type: selectRoleResponseType, error: false });
    //    } else {
    //        dispatch({ type: selectRoleResponseType, error: true });
    //    }
    //},

    reset: (email) => async (dispatch) => {
        dispatch({ type: requestPasswordReset });
        const url = `${process.env.PUBLIC_URL}/api/indicium/security/reset/${email}`;
        const response = await fetch(url);
        const hashdetails = await response.json();
        dispatch({ type: receivePasswordReset, hashdetails });
    },

    TokenValid: (tknid, servername, IP) => async (dispatch) => {
        dispatch({ type: requestValidateReset });
        const url = `${process.env.PUBLIC_URL}/api/indicium/security/TokenValid/${tknid}/${servername}/${IP}`;
        const response = await fetch(url);
        const validdetails = await response.json();
        dispatch({ type: receiveValidateReset, validdetails });
    },

    sendPasswordResetData: (ConfirmPassword, urlElelement,  addon  , add ) => async (dispatch) => {
        dispatch({ type: requestValidateReset });
        const url = `${process.env.PUBLIC_URL}/api/indicium/security/sendPasswordResetData/${ConfirmPassword}/${urlElelement}/${addon}/${add}`;
        const response = await fetch(url);
        const validdetails = await response.json();
        dispatch({ type: receiveValidateReset, validdetails });
    }
}

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === requestUserDetails) {
        return {
            ...state,
            isLoading: true,
            error: false,
            userDetails: {},
            userRoles: [],
            roleSet: false
        };
    }

    if (action.type === requestType) {
        return { ...state, isLoading: true }
    }

    if (action.type === selectRoleResponseType) {
        return {
            ...state,
            isLoading: false,
            roleSet: !action.error,
            error: action.error
        };
    }

    if (action.type === receiveUserDetailsType) {
        currentUserDetails = JSON.parse(action.userDetails);
        return {
            ...state,
            userDetails: currentUserDetails,
            isLoading: false
        };
    }

    if (action.type === receiveRoleDetailsType) {
        currenUserRoles = JSON.parse(action.roleDetails);
        return {
            ...state,
            userRoles: currenUserRoles,
            isLoading: false
        };
    }

    if (action.type === receivePasswordReset) {
        currentHashDetails = JSON.parse(action.hashdetails);
        return {
            ...state,
            hashdetails: currentHashDetails,
            isLoading: false
        };
    }

    if (action.type === receiveValidateReset) {
        currentValidDetails = JSON.parse(action.validdetails);
        return {
            ...state,
            validdetails: currentValidDetails,
            isLoading: false
        };
    }

    return state;
}
