import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import AlertItem from "../../widgets/AlertItem";
import ViewUtils from '../../ViewUtils';
import { AssessmentHeaderForm } from './AssessmentHeaderForm';

const headCells = [
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'SAQA Unit Standard Code' },
    { id: "ProgrammeTitle", numeric: false, disablePadding: true, label: "SAQA Unit Standard Title" },
    { id: "Credits", numeric: false, disablePadding: true, label: "Credits" },
    { id: "UnitStandardType", numeric: false, disablePadding: true, label: "Unit Standard Type" },
    { id: "RPL", numeric: false, disablePadding: true, label: "RPL" },
    { id: "Assessor", numeric: false, disablePadding: true, label: "Assessor" },
    { id: "AssessmentDate", numeric: false, disablePadding: true, label: "Assessment Date" },
    { id: "Moderator", numeric: false, disablePadding: true, label: "Moderator" },
    { id: "ModerationDate", numeric: false, disablePadding: true, label: "Moderation Date" },
    { id: "Status", numeric: false, disablePadding: true, label: "Status" },
]


const utils = new ViewUtils();
const toolbarRef = React.createRef();
const learnerRef = React.createRef();

class ViewBursaryAssessment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                AssessmentID: null
            },
            selectedUnitStandards: null,
            keyUpSwitch: false,
            updateUSd: false,
            messsageAlert: null,
            messageStyle: null,
            showTable: true,
            searchParameters: [{ Name: "AssessmentID", Value: this.props.location.state.BursaryID  }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'cancel', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'updateUS', label: '', tooltip: 'UPDATE UNIT STANDARDS', icon: 'UpdateIcon' },
                        { id: 'remove', label: '', tooltip: 'REMOVE SELECTED ASSESSMENTS', icon: "DeleteIcon" },
                        { id: 'closeoff', label: 'CLOSE OFF', tooltip: 'CLOSE OFF', disabled: true },
                        { id: 'qaapproval', label: 'QA APPROVAL', tooltip: 'QA APPROVAL', disabled: true }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("updateUS" === buttonId) {
            this.updateUSDocuments();

        } else if ("remove" === buttonId) {
            this.removeAssessments();

        } else if ("cancel" === buttonId) {
            this.props.history.push({
                pathname: '/LearnerBursaryAssessment',
                state: {
                    learnerID: this.props.location.state.LearnerID,
                    learnerNames: this.props.location.state.learnerNames
                }
            });
        } else if ("closeoff" === buttonId) {
            this.closeOff();

        } else if ("qaapproval" === buttonId) {
            this.qaApproval();

        }
    }

    handleToolbarButtonClickClose = (buttonId) => {
        if ("cancel" === buttonId) {
           
        }
    }

    successCallback = (response) => {
        this.setState({
            showTable: false,
            message: response.Message, messageStyle: response.MessageType
        }, () => {
            this.setState({ showTable: true, message: response.Message, messageStyle: response.MessageType })
        });
    }

    successCloseOffandQA = (response) => {
        this.setState({
            message: response.Message,
            messageStyle: response.MessageType,
            showTable: false
        }, () => {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType,
                showTable: true
            })
        });
    }

    closeOff() {
        let url = "api/sims/LearnerBursaryAssessment/CloseOffAssessment?AssessmentID=" + this.props.location.state.BursaryID;
        utils.invokeUrl(url, (response) => {
            this.successCloseOffandQA(response)
            toolbarRef.current.setButtonDisabled("closeoff", true);
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    qaApproval() {
        let url = "api/sims/LearnerBursaryAssessment/ApproveAssessment?AssessmentID=" + this.props.location.state.BursaryID;
        utils.invokeUrl(url, (response) => {
            this.successCloseOffandQA(response)
            toolbarRef.current.setButtonDisabled("qaapproval", true);
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    removeAssessments() {
        const UnitStandardIDs = this.state.selectedUnitStandards.map(e => e.Id);
        let data = {
            UnitStandardIDs: UnitStandardIDs,
            ...this.state.model
        }
        let input = JSON.stringify(data);
        utils.invokeUrl("api/sims/LearnerBursaryAssessment/RemoveLearnerBursaryAssessment", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: input
        });
    }

    componentDidMount() {
        this.GetClosePermission();
        this.GetQAPermission();
    }

    GetQAPermission() {
        let url = "api/sims/LearnerBursaryAssessment/GetQAPermission?AssessmentID=" + this.props.location.state.BursaryID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "1") {
                toolbarRef.current.setButtonDisabled("qaapproval", true);
            }
            else {
                toolbarRef.current.setButtonDisabled("qaapproval", false);
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    GetClosePermission() {
        let url = "api/sims/LearnerBursaryAssessment/GetClosePermission?AssessmentID=" + this.props.location.state.BursaryID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "1") {
                toolbarRef.current.setButtonDisabled("closeoff", true);
            }
            else {
                toolbarRef.current.setButtonDisabled("closeoff", false);
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    updateUSDocuments() {
            const UnitStandardIDs = this.state.selectedUnitStandards.map(e => e.Id);
            let data = {
                UnitStandardIDs: UnitStandardIDs,
                ...this.state.model
            }
            let input = JSON.stringify(data);
        utils.invokeUrl("api/sims/LearnerBursaryAssessment/UpdateLearnerBursaryAssessment", (response) => {
            this.successCallback(response);
        }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedUnitStandards: selection, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("updateUS", selection.length === 0);
                    toolbarRef.current.setButtonDisabled("remove", selection.length === 0);
                }
            )
        } else {
            this.setState({ selectedUnitStandards: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("updateUS", selection.length === 0);
                    toolbarRef.current.setButtonDisabled("remove", selection.length === 0);
                }
            )
        }
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                this.setState({
                    model: newValues,
                    Message: ""
                });
                toolbarRef.current.setButtonDisabled("updateUS", true);
                toolbarRef.current.setButtonDisabled("Cancel", true);
                toolbarRef.current.setButtonDisabled("remove", true);
            }
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="legend">
                    {`Learner > Update Bursary Assessment > ${this.props.location.state.learnerNames}`}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                {this.state.showTable === true && (
                    <AssessmentHeaderForm
                    ref={learnerRef}
                    viewRef={this}
                    id={this.props.location.state.BursaryID}
                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                    controllerName={"LearnerBursaryAssessment"}
                    dataURL='api/sims/LearnerBursaryAssessment/GetLearnerBursaryAssessmentHeaderDetails'   />
                )}
                <td>
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                </td>
               
                <div>
                    {this.state.showTable === true && (
                    <EnhancedTable
                        multiSelect={true}
                        headCells={headCells}
                        dataUrl="api/sims/LearnerBursaryAssessment/getLearnerBursaryAssessmentDetails"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                    )}
                    </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ViewBursaryAssessment)
);