import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Skills.css";
import { ViewContainer } from "./../../View.jsx";
import { SkillsQueryReasonForm } from './SkillsQueryReasonForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        },
    ]
};

const userRef = React.createRef();

class SkillsQueryReason extends Component {

    state = {
        model: {
            Name: "",
            Description: "",
            LetterType: ""

        },
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
    };

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/SkillsQueryReasonSearch',

            })
        } else if ("save" === buttonId) {
            this.save()
        }
    }
    handleFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            this.setState({
                Name: values.Name
            });
        }
    }

    save() {
        let formValue = userRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            //if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
            //    formValue.id = this.props.location.state.selection;
            //    isAdd = false;
            //}
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }
            //let data = JSON.stringify(formValue);

            if (isAdd) {
                const url = `api/sims/skillsqueryreasons/insertqueryreasons`;
                utils.saveForm(this, userRef, url, (response) => {
                    this.setState({ message: "Query Reason added successfully", messageStyle: "SUCCESS", id: response.EntityId });
                }, this.error);
            } else {
                const url = `api/sims/skillsqueryreasons/updatequeryreasons`;
                utils.updateForm(this, userRef, url, (response) => {
                    this.setState({ message: "Query Reason updated successfully", messageStyle: "SUCCESS" });
                }, this.error);
            }
        }
    }

    error(response) {
        this.setState({ message: "A system error has accured. Please contact your system administrator", messageStyle: "ERROR" });

    }
    close() {
        this.props.history.push('/SkillsQueryReasonSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={utils.getEntityViewTitle(this.props.location.state, "Query Reasons", this.state.Name)}>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Skills Query Reason " buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true} config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <SkillsQueryReasonForm ref={userRef}
                                    id={this.state.id}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                // editPermission={this.props.menuPermissionItem.Edit }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>

        );
    }
}

export default withRouter(SkillsQueryReason);
