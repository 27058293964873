import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import SearchView from "../../../widgets/SearchView";
import InstitutionSearchForm from './InstitutionSearchForm';
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "ProviderCode", numeric: false, disablePadding: true, label: "Provider Code" },
    { id: "InstitutionName", numeric: false, disablePadding: true, label: "Institution Name" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select Provider from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

class InstitutionSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters =
            //For search on the provider search screen
            [
            { Name: "ProviderCode", Value: "" },
            { Name: "InstitutionName", Value: "" }
            ]

        let model =
        {
            ProviderCode: "",
            InstitutionName: ""
        }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedProvider: null,
            linkDialogOpen: false
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Institution' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/DGApplicationForms2022/GetDGFormInstitutionSearch"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="ProviderCode"
                    SecondDescriptionProp="InstitutionName"
                    model={this.state.model}
                    searchParameters={this.searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <InstitutionSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} />
                </SearchView>
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(InstitutionSearch)
);