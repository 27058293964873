import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./SkillsProgramSetup.css";
import { SkillsProgrammeSetup } from './SkillsProgramSetupForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const skillsRef = React.createRef();
const toolbarRef = React.createRef();
const utils = new ViewUtils();

class SkillsProgramme extends Component {

    constructor(props) {
        super(props);

        this.state = {

            skillsProgrammeSetupDetails: {
                SkillsProgrammeCode: "",
                SkillsProgrammeTitle: "",

            },
            model: {
                OFOOccupationID: null,
                QualificationID: null,
                QualityAssuranceBodyID: { Id: 11, Description: "FOODBEV" }
            },
            leadsToQualification: false,
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit},
                        { id: 'unitStandards', label: 'UNIT STANDARDS', tooltip: 'Link unit standards to quilification', disabled: utils.isNull(this.state.id) },
                        { id: 'crm', label: 'CRM', tooltip: 'Client Relations Management', disabled: utils.isNull(this.state.id) },
                    ]
                }
            ]
        }
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            let typeId = values.SkillsProgrammeTypeID;

            if (!utils.isNull(typeId) && typeId.Description === "Leads to a Qualification") {
                this.setState({ leadsToQualification: true });
            } else if(!utils.isNull(typeId) && typeId.Description === "Standalone") {
                this.setState({ leadsToQualification: false });
            }

            this.setState({ skillsProgrammeSetupDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();

        } else if ("close" === buttonId) {
            this.closeView();
           
        } else if ("unitStandards" === buttonId) {
            this.props.history.push({
                pathname: '/skillsProgramestandardsearch',
                state: {
                    skillsID: !utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection) ? this.props.location.state.selection : this.state.selection,
                    SkillsTitle: this.state.skillsProgrammeSetupDetails.SkillsProgrammeTitle
                }
            });
        } else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: '/SkillsProgrammeSetupCRM',
                state: {
                    SkillsProgrammeID: !utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection) ? this.props.location.state.selection : this.state.selection,
                    SkillsProgrammeName: this.state.skillsProgrammeSetupDetails.SkillsProgrammeTitle
                }
            })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId !== 0) {
            this.setState({ id: response.EntityId }, () => {
                this.props.history.push({
                    pathname: "/SkillsProgrammeSetup",
                    state: { selection: this.state.id }
                })
                toolbarRef.current.setButtonDisabled("unitStandards", this.state.id === null || response.MessageType === "ERROR")
            })
            skillsRef.current.setValue("SkillsProgrammeCode", response.SkillsProgrammeCode);
        }
    }

    save() {
        //utils.saveForm(this, skillsRef, `api/indicium/SkillsProgrammeSetup/updateSkillsSetup`, this.successCallback, null);

        let formValue = skillsRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let isAdd = true;
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            formValue.Document = this.state.Document;
            const data = JSON.stringify(formValue);
            let url = "api/sims/skillsprogrammesetupdetails/updateskillsprogramme";
            if (!isAdd) {
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            } else {
                url = "api/sims/skillsprogrammesetupdetails/insertskillsprogramme";
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            }
        }
    }

    closeView() {
        this.props.history.push('/skillsProgrammeSetupSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="legend">
                    {"Skills Programmes Details"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    ref={toolbarRef}
                                    title="Skills Programme"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true} config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <SkillsProgrammeSetup ref={skillsRef}
                                        id={this.state.id !== null ? this.state.id : typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} data={this.state.model}
                                        leadsToQualification={this.state.leadsToQualification}
                                        editPermission={this.props.menuPermissionItem.Edit}
                                    />
                                    </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(SkillsProgramme));

