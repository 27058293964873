import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { SelectItem } from '../../widgets/SelectItem';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import DocumentUpload from '../../widgets/DocumentUpload';
import ViewUtils from "./../../ViewUtils";

const utils = new ViewUtils();
export default function LearnerDocumentDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [filename, setFilename] = React.useState(props.fileName);
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [entityId, setEntityId] = React.useState(props.EntityID);
    const [highestEducation, setHighestEducation] = React.useState('');
    const [educationTitle, setEeducationTitle] = React.useState('');
    const [institute, setInstitute] = React.useState('');

    React.useEffect(() => {
        if (!utils.isNull(props.EntityID)) {
            setEntityId(props.EntityID);
        }
        if (!utils.isNull(props.HighestEducation)) {
            setHighestEducation(props.HighestEducation);
        }
        if (!utils.isNull(props.EducationTitle)) {
            setEeducationTitle(props.EducationTitle);
        }
        if (!utils.isNull(props.Institute)) {
            setInstitute(props.Institute);
        }
    }, [props.EntityID]);

    const handleClose = (save, cancel) => {
        clearValues();
        setOpen(false);
        props.closeHandler(save, cancel)
    };

    const clearValues = () => {
        if (!utils.isNull(props.form)) {
            props.form.values["EducationTitle"] = null;
            props.form.values["HighestEducationLevelID"] = null;
            props.form.values["InstitutionID"] = { Id: null, Description: '' };
            setFilename(null);
            props._setFilename(null);
            setSize(null);
            setBase64(null);
            setType(null);
        }
    }


    const cancel = () => {
        clearValues();
        handleClose(false, true);
    };

    const save = () => {

        let data = {};
        data.OriginalFileName = filename;
        data.EntityID = entityId;
        data.EducationTitle = props.EducationTitle;
        data.HighestEducation = props.HighestEducation.Id;
        data.Institute = props.Institute.Id;
        data.Document = {};
        data.Document.name = filename;
        data.Document.size = size;
        data.Document.type = type;
        data.Document.base64 = base64;

        utils.invokeUrl(props.postUrl, (response) => {
            handleClose(true, false);
        }, () => { handleClose(false, false); },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        );
    }

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            props._setFilename(data.name);
            console.log("learnerDOC");
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <div>
                <div style={{ height: "80%" }}>
                    <DocumentUpload showViewer={false} onUploadHandler={onUploadHandler} filename={filename} buttonLabel={props.buttonLabel}
                    />
                    {!utils.isNull(props.HighestEducation) && (
                        <Button onClick={save} color="primary">
                            Save Record
                        </Button>
                    )}
                    <Button onClick={cancel} color="primary">
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}
