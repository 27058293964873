import React from 'react';
import Loader from 'react-loader-spinner';
import "./loader.css"

export default function LoadingIndicatorLogin(props) {

    return (
        props.show === true &&
        <div id="myModal" className="modal">
            <div style={{
                margin: "25% auto",
                width: "100%", height: "100", display: "flex",
                justifyContent: "center", alignItems: "center",
            }}>
                <Loader type="ThreeDots" color="#114084" height={100} width={100} />
            </div>
        </div>
    )
};