import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../View.css";
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import SearchView from "../widgets/SearchView";
import { ViewContainer } from "./../View.jsx";
import ViewUtils from '../ViewUtils';
import STATSSAAreaCodeSearchForm from './STATSSAAreaCodeSearchForm';

const utils = new ViewUtils();

const headCells = [
    { id: "Description", numeric: false, disablePadding: true, label: "Description" },
    { id: "Code", numeric: false, disablePadding: true, label: "Stats SA Area Code" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select OFO Occupation from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

class STATSSAAreaCodeSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: {
                AreaCode: "",
                Description: ""
            },
            keyUpSwitch: false,
        }

        this.searchParameters = [
            { Name: "AreaCode", Value: "" },
            { Name: "Description", Value: "" }
        ]
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }
    
    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'STATS SA Area Codes' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/Assessor/GetAreaCode"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="Description"
                    model={this.state.model}
                    searchParameters={this.searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <STATSSAAreaCodeSearchForm
                        model={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}

                    />
                </SearchView>
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(STATSSAAreaCodeSearch)
);