import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Backspace';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import PrintIcon from '@material-ui/icons/Print';
import UploadIcon from '@material-ui/icons/CloudUpload';
import AddCommentIcon from '@material-ui/icons/AddComment';
import TimelineIcon from '@material-ui/icons/Timeline';
import UpdateIcon from '@material-ui/icons/Update';
import SmsIcon from '@material-ui/icons/Sms';
import CreateIcon from '@material-ui/icons/Create';
import ApproveIcon  from '@material-ui/icons/VerifiedUser';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PasswordIcon from '@material-ui/icons/VpnKey';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#fff"
    },
    '&:hover': {
      backgroundColor: 'red !important',
      color: 'blue'
    }
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  MuiButtonDefault: {
    color: 'rgb(0, 0, 0, .38)'
  },
  MuiButtonWhite: {
    color: '#fff'
  }
}));

export default function MenuButton(props) {
  const classes = useStyles();

  const width = props.width !== null ? props.width : "60px";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root} style={{ width: width }}>
      <div className="menuButtonWrapper">
        <Button
          className={props.defaultStyle === true ? classes.MuiButtonDefault : classes.MuiButtonWhite}
          aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} disabled={props.disabled}
        >
          <Tooltip title={props.tooltip} arrow>
          {props.contextIcon !== null && typeof props.contextIcon !== 'undefined' ?
          props.contextIcon === "AddIcon" ? < AddIcon /> : 
          (props.contextIcon === "SaveIcon" ? < SaveIcon  /> :
          (props.contextIcon === "EditIcon" ? < EditIcon  /> : 
          (props.contextIcon === "InfoIcon") ? <InfoIcon  /> : 
          (props.contextIcon === "ViewIcon") ? <VisibilityIcon  /> : 
          (props.contextIcon === "EmailIcon") ? <EmailIcon  /> :
          (props.contextIcon === "DownloadIcon") ? <DownloadIcon  /> :
          (props.contextIcon === "CloseIcon") ? <CloseIcon /> :
          (props.contextIcon === "LinkIcon") ? <LinkIcon /> :
          (props.contextIcon === "LinkOffIcon") ? <LinkOffIcon /> :
          (props.contextIcon === "PrintIcon") ? <PrintIcon /> :
          (props.contextIcon === "UploadIcon") ? <UploadIcon  /> :
          (props.contextIcon === "AddCommentIcon") ? <AddCommentIcon  /> :
          (props.contextIcon === "AuditIcon") ? <TimelineIcon  /> :
          (props.contextIcon === "UpdateIcon") ? <UpdateIcon  /> :
          (props.contextIcon === "SmsIcon") ? <SmsIcon  /> :
          (props.contextIcon === "CreateIcon") ? <CreateIcon  /> :
          (props.contextIcon === "ApproveIcon") ? <ApproveIcon  /> :
          (props.contextIcon === "LockOpenIcon") ? <LockOpenIcon  /> :
          (props.contextIcon === "PasswordIcon") ? <PasswordIcon  /> :
          <DeleteIcon  />))
          :
          <div>{props.label}</div>
        }
          </Tooltip>
        </Button>
        <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        >
        <MenuItem onClick={handleClose}>{props.children}</MenuItem>
      
      </Menu>

      </div>
    </div>
  );
}
