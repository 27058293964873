import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import SkillsProgramSetupSearch from '../program/SkillsProgramSetupSearch';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import FacilitatorInterventionAudit from '../facilitator/FacilitatorInterventionAudit';
import FormDialog from "../FormDialog";
import { OutComeStatusForm } from "../common/OutcomeStatusForm";

export const skillsProgrammeHeadCells = [
    { id: 'SkillsProgrammeCode', numeric: false, disablePadding: true, label: 'Skills Programme ID' },
    { id: 'SkillsProgrammeTitle', numeric: false, disablePadding: true, label: 'Skills Programme Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'Outcome', numeric: false, disablePadding: true, label: 'Outcome' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
];

const toolbarRef = React.createRef();
const addFormRef = React.createRef();

const utils = new ViewUtils();

class FacilitatorSkillsProgrammeSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                SkillsProgrammeCode: "",
                SkillsProgrammeTitle: "",
                FacilitatorID: this.props.location.state.FacilitatorID
            },
            showTable: true,
            linkDialogOpen: false,
            updateDialogOpen: false,
            formDialogOpen: false,
            auditDialogOpen: false,
            selectedSkillsProgramme: null,
            propmtOpen: false,
            promptTitle: "Unlink Skills Programme From Facilitator",
            promptContent: "Are you sure you want to unlink this skills programme from the Facilitator?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "FacilitatorID", Value: this.props.location.state.FacilitatorID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Skills Programme/s to the Facilitator', icon: 'LinkIcon' },
                        { id: 'update', label: '', tooltip: 'Update the status and outcome of the selected skills programme', disabled: utils.isNull(this.state.selectedSkillsProgramme), icon: 'UpdateIcon' },
                        { id: 'audit', label: '', tooltip: 'View Record Audit Trail', disabled: utils.isNull(this.state.selectedSkillsProgramme) , icon: 'AuditIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected skills programme from the Facilitator', disabled: utils.isNull(this.state.selectedSkillsProgramme), icon: 'LinkOffIcon' }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/facilitator',
                state: { selection: this.props.location.state.FacilitatorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("update" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedSkillsProgramme: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkUnitstandard(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedSkillsProgramme}&facilitatorId=${this.props.location.state.FacilitatorID}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedSkillsProgramme: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedSkillsProgramme: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        } else {
            this.setState({
                selectedSkillsProgramme: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, selectedSiteVisit: null, auditDialogOpen: false, linkDialogOpen: false, selection: this.props.location.state.selection, selectedSkillsProgramme: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Facilitator > Skills Programmes" + (this.props.location.state !== null ? " > " + this.props.location.state.FacilitatorName + " - " + this.props.location.state.FacilitatorIDNo : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={skillsProgrammeHeadCells}
                            dataUrl={`api/sims/facilitatorskillsprogramme/GetFacilitatorSkillsProgrammes`}
                            height="50vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Update Outcome and Status"}
                    viewRef={this}
                    entityId={this.state.selectedUnitStandard}
                    formRef={addFormRef}
                    usePut={true}
                    saveUrl={"api/sims/facilitatorskillsprogramme/UpdateSkillsProgrammeOutcomeStatus"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeStatusForm ref={addFormRef} id={this.state.selectedSkillsProgramme} dataURL='api/sims/facilitatorskillsprogramme/GetFindSkillsProgrammeOutcomeStatus' controllerName='facilitatorskillsprogramme' />
                </FormDialog>

                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedQualification}
                >

                    <FacilitatorInterventionAudit dataUrl={"api/sims/facilitatorskillsprogramme/GetFacilitatorSkillsProgrammeAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedSkillsProgramme}
                    />
                </LinkDialog>


                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.FacilitatorName}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Skills Programme to Facilitator: ${this.props.location.state.FacilitatorName}`}
                    linkName={"SkillsProgrammes"} postUrl={"api/sims/facilitatorskillsprogramme/LinkFacilitatorSkillsProgramme"} usePost={true}
                    selectionId={this.props.location.state.FacilitatorID}
                >
                    <SkillsProgramSetupSearch
                        dataUrl={"api/sims/facilitatorskillsprogramme/GetSearchLinkableSkillsProgrammes"}
                        multiSelect={true} showTitle={false} customMessage={"No Skills Programmes with this search criteria have been found."}
                        programmeId={this.props.location.state.FacilitatorID} programmeType="Q"
                        mode="lookup" allowClear={true}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkUnitstandard(`api/sims/facilitatorskillsprogramme/RemoveFacilitatorSkillsProgramme`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(FacilitatorSkillsProgrammeSearch)
);
