import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';


export default function StakeholderSearchForm(props) {
    return (
        <EntityForm ref={React.createRef()} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="OrganisationSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <CustomTextField
                                id="FirstName"
                                label="First Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="Surname"
                                label="Surname"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="IDNumber"
                                label="ID Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                        <SelectItem
                                    dataUrl="api/sims/DGStakeholder/GetLookupList?listId=Province"
                                    id="ProvinceID"
                                    label="Province"
                                    form={form}
                                    allowEmptyValue={true}
                                />
                        </div>
                        <div className="col form-col">
                        <CustomTextField
                                id="SDLNumber"
                                label=" SDL Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                        <CustomTextField
                                id="SDLNumber"
                                label=" SDL Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        
                    </div>
                </form>
            }
        </EntityForm >
    );
}
