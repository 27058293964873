import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { Col, Button } from "react-bootstrap";
import { SelectItem } from '../../widgets/SelectItem';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { CustomTextField } from '../../widgets/CustomTextField';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import ProviderSearch from './ProviderSearch';
import EmployeeSearch from './EmployerSearch';
import { LookupField } from '../../widgets/LookupField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const MasterFileForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="provider" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="providerForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Report Detail" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id=""
                                                key={tabValue}
                                                required
                                                label="From Date"
                                                className='{classes.textField} w-100 '
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id=""
                                                key={tabValue}
                                                required
                                                label="To Date"
                                                className='{classes.textField} w-100 '
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="provider"
                                                label="Provider"
                                                className="w-100"
                                                form={form}
                                            >
                                                <ProviderSearch showTitle={true} mode='lookup'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupation", value.Id)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="provider"
                                                label="Employer"
                                                className="w-100"
                                                form={form}
                                            >
                                                <EmployeeSearch showTitle={true} mode='lookup'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupation", value.Id)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id=""
                                                key={tabValue}
                                                required
                                                label="From Date Created"
                                                className='{classes.textField} w-100 '
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id=""
                                                key={tabValue}
                                                required
                                                label="To Date Created"
                                                className='{classes.textField} w-100 '
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Col sm="6" style={{ marginTop: '50px' }}>
                                <Button
                                    block
                                    type="button"
                                    style={{ backgroundColor: 'black !important' }}
                                    className="btn btn-success"
                                >
                                    Execute Report
                             </Button>
                            </Col>
                        </TabPanel>
                    </FormTabContainer>
                </form >
            }
        
        </EntityForm >
    );
})