import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Skills.css";
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';
import SkillsQueryReasonSearchForm from './SkillsQueryReasonSearchForm';
import withMenuPermission from "../../widgets/withMenuPermission";
import ViewUtils from '../../ViewUtils';
const utils = new ViewUtils();

const headCells = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' },
    { id: 'LetterType', numeric: false, disablePadding: true, label: 'LetterType' },
    { id: 'Update', numeric: false, disablePadding: true, label: 'Update' }



];
const filterParameters = []



class SkillsQueryReasonSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                Name: "",
                Description: ""
            },
            parameters: {},
            keyUpSwitch: false,
            addDialogOpen: false,
            selectedQueryReason: null
        };


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    // buttons: [
                    //     { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                    //     { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
                    // ],
                    buttons: [
                        {
                            id: 'add',
                            label: '',
                            tooltip: 'Add',
                            icon: 'AddIcon',
                            visible: this.props.menuPermissionItem.Add
                        },
                        {
                            id: 'edit',
                            label: '',
                            tooltip: 'Edit',
                            icon: 'EditIcon',
                            disabled: true,
                            visible: this.props.menuPermissionItem.Edit
                        },
                        // {
                        //     id: 'delete',
                        //     label: '',
                        //     tooltip: 'Delete selected query reason from records',
                        //     icon: 'DeleteIcon',
                        //     disabled: true,
                        //     visible: true//this.props.menuPermissionItem.Delete
                        // }
                    ]
                }
            ]
        };
        this.searchParameters = this.searchParameters = !utils.isNull(this.props.programmeId) ?

            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "Name", Value: "" },
                { Name: "Description", Value: "" }
            ]
            :

            [
                { Name: "Name", Value: "" },
                { Name: "Description", Value: "" }
            ]
    }
    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }
    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedQueryReason: selection })
        } else {
            this.setState({ selectedQueryReason: null })
        }
    }
    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });
        }
    }


    render() {
        return (
            <CardLayout title='Skills Maintenance'>
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/skillsqueryreasons/getsearch"}
                    entityViewPath='/SkillsQueryReason'
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    filterParameters={filterParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="Name"
                    mode={this.props.mode}
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    deleteUrl={"api/sims/skillsqueryreasons/removequeryreasons"}
                    useDeletePost={true}
                    promptTitle={"Remove Query Reason"}
                    promptContent={"Are you sure you want to remove this Query Reason?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <SkillsQueryReasonSearchForm model={this.state.model} />

                </SearchView>
            </CardLayout >
        );
    }
}
export default withRouter(withMenuPermission(SkillsQueryReasonSearch));
