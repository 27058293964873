import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import OFOSearchForm from './OFOSearchForm';
import { ViewContainer } from "./../../View.jsx";
import CardLayout from '../../widgets/Card';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "OFOCode", numeric: false, disablePadding: true, label: "OFO Code" },
    { id: "OFODescription", numeric: false, disablePadding: true, label: "OFO Description" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select OFO Occupation from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

const searchParameters = [
    { Name: "OFOCode", Value: "" },
    { Name: "OFODescription", Value: "" }
]

class OFOSearch extends Component {
    state = {
        model: {
            OFOCode: "",
            OFODescription: ""
        },
        keyUpSwitch: false
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'OFO Occupations' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/OFOOccupation/search"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="OFOCode" 
                    SecondDescriptionProp="OFODescription"
                    model={this.state.model}
                    searchParameters={searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <OFOSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} />
                </SearchView>
            </ViewContainer>
            )
    }
}

export default withRouter(
    connect()(OFOSearch)
);