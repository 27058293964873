import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import Checkbox from '@material-ui/core/Checkbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const WSPandATREditForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL='api/sims/wspatr/getfind'
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="organisationWSPandATRForms">

                    <div className="row w-100">
                        <div className="col w-100">
                                <table cellPadding="2" className="w-100">
                                    <tbody>
                                        <tr>
                                            <td>
                                            <SelectItem
                                                dataUrl={'api/sims/wspatr/GetWSPStatusByRole?parameter=[{"Name":"WSPATRID","Value":' + props.id + '}]'}
                                                    id="WSPStatusID"
                                                    key={tabValue}
                                                    label="WSP Status"
                                                    className="w-100"
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/wspatr/GetLookupList?listId=FormType"
                                                    id="formTypeID"
                                                    key={tabValue}
                                                    label="Form Type"
                                                    className="w-100"
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomDatePicker
                                                    id="DueDate"
                                                    key={tabValue}
                                                    label="Due Date"
                                                    className='{classes.textField} w-100'
                                                    required
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomDatePicker
                                                    id="ManualSubmissionDate"
                                                    key={tabValue}
                                                    label="Manual Submission Date"
                                                    className='{classes.textField} w-100'
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})

export default WSPandATREditForm;