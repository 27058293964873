import React from "react";
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import Save from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import ViewUtils from '../ViewUtils';
import { makeStyles } from "@material-ui/core";
import { Row } from "antd";

const utils = new ViewUtils();

const actionIcons = {
    save: () => <Save />,
    delete: () => <Delete />,
    add: () => <Add />,
    edit: () => <Edit />
};

const useStyles = makeStyles({
    tableContainer: {
        overflowY: "auto",
        maxHeight: "73vh"
    }
});

const CustomMaterialTable = function (props) {
    const [data, setData] = React.useState(!utils.isNull(props.data) ? props.data : []);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 10);
    const classes = useStyles();

    //Data coming from a remote source
    React.useEffect(() => {
        if (!utils.isNull(props.dataUrl)) {
            utils.invokeUrl(props.dataUrl + "?request=" + utils.doGetRequestJson(page, rowsPerPage, props.searchParameters, props.paged), response => {
                let result;
                if (typeof response === "string") {
                    result = JSON.parse(response);
                }
                else {
                    result = response;
                }

                let remoteData = result.Data;
                setData(remoteData);
            })
        }
    }, [props.searchParameters]);

    return (
        <div className={classes.tableContainer}>
            <MaterialTable
                icons={actionIcons}
                title={props.tableTitle}
                columns={props.columns}
                data={data}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: rowsPerPage,
                    paging: props.paged,
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF',
                        fontSize: "14px",
                        padding: "0px",
                        paddingLeft: "8px",
                    },
                    search: false,
                    draggable: false,
                    showTitle: true,
                    toolbar: false,
                    padding: "dense"
                }}
            />
        </div>
    );
};

CustomMaterialTable.propTyles = {
    columns: PropTypes.array.isRequired,
}

export default CustomMaterialTable;
