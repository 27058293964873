import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';


export default function GrantClaimFormReversalSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="DownloadClaimSearchForm" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="DownloadClaimSearchForm">
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/DownloadClaim/GetLookupList?listId=DGType"}
                            id="DiscretionaryGrantTypeID"
                            label="Intervention Type"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <LookupField
                            id="EmployerID"
                            label="Employer"
                            className="w-100"
                            form={form}
                        >
                            <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/DownloadClaim/EmployerSearch"
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("EmployerID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <td>
                        <CustomTextField
                            id="ClaimNumber"
                            label="Claim Number"
                            className="w-100"
                            form={form}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/DownloadClaim/GetLookupList?listId=YesNo"}
                            id="ApprovedID"
                            label="Approved"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <br>
                    </br>
                </form>
            }
        </EntityForm>
    );
}
