import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { LinkTrancheForm } from "./LinkTrancheForm";
import FormDialog from "../../FormDialog";

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
const updateFormRef = React.createRef();

const headCells = [
    { id: 'TrancheType', numeric: false, disablePadding: false, label: 'Tranche Type' },
    { id: 'TranchePercentage', numeric: false, disablePadding: false, label: 'Tranche Percentage' },
    { id: 'DueDate', numeric: false, disablePadding: false, label: 'Due Date' },
    { id: 'TrancheAmount', numeric: false, disablePadding: false, label: 'Tranche Amount' }
];


class TrancheDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            keyUpSwitch: false,
            showTable: true,
            linkDialogOpen: false,
            updateDialogOpen: false,
            TrancheHistoryID: this.props.location.state.GrantID,
            listedTranches: 0,
            propmtOpen: false,
            message: null,
            messageStyle: null,
            totalAmount: 0,
            selectedTrancheRec: null,
            searchParameters: [{ Name: "TrancheSetupID", Value: this.props.location.state.GrantID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'close', label: 'close', tooltip: 'Go back to setup', icon: 'CloseIcon'}
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'linktranche', label: 'Link Tranche', icon: 'LinkIcon', tooltip: 'Link Tranche'},
                        {id: 'edittranche', label: 'Edit Tranche', icon: 'EditIcon', tooltip: 'Edit Tranche Record', disabled: utils.isNull(this.state.selectedTrancheRec)},
                        {id: 'delete', label: 'Remove Tranche', icon: 'DeleteIcon', tooltip: 'Remove Tranche', disabled: utils.isNull(this.state.selectedTrancheRec)}
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("linktranche" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })
        } else if ("edittranche" === buttonId) {
            this.setState({ updateDialogOpen: true, removeMessage: "" })
        } 
        else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: {
                    TrancheID: this.state.TrancheHistoryID,
                    hideFields: false
                }
            });
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedTrancheRec: selection[0].Id })
        } else {
            this.setState({ selectedTrancheRec: null })
        }
        toolbarRef.current.setButtonDisabled("edittranche", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("delete", selection === null || selection.length === 0);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedTrancheRec: null, updateDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
                this.GetTotalAmounts();
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false,
                updateDialogOpen: false
            })
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }
    

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleFormValueChange = (values) => {
      
    }

    componentDidMount() {
        this.GetTotalAmounts();
    }

    GetTotalAmounts() {
        let url = "api/sims/TrancheDetail/TrancheSetupGetTotalValues?TrancheID=" + this.state.TrancheHistoryID;
        utils.invokeUrl(url, (response) => {
            if (response.currentAmount != null && response.currentTotalAmount != null) {
                if (response.currentAmount == response.currentTotalAmount) {
                    this.setState({ listedTranches: response.currentAmount, totalAmount: response.currentTotalAmount, messageStyle: null, message: null})
                } else {
                    this.setState({
                        listedTranches: response.currentAmount, totalAmount: response.currentTotalAmount, messageStyle: "WARNING",
                        message: "Please note that the total amount from setup details tab does not match the amount for listed tranches below, and therefore any associations to learners will not be successful."
                    })
                }
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }


    render() {
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"Grant Setup > Tranche Details"}
                </p>
                {this.state.showTable === true && (
                <SearchView headCells={headCells} dataUrl="api/sims/TrancheDetail/GetTrancheDetailSearch" 
                    toolbarConfig={this.toolbarConfig} searchParameters={this.state.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange} deleteUrl={"api/sims/TrancheDetail/deleteTrancheSetupPeriod"}
                    useDeletePost={true}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    autoFetchData={true}
                    search={false}
                    keyUpSwitch={this.state.keyUpSwitch}
                    promptTitle={"Remove Tranche Link"}
                    promptContent={"Are you sure you want to unlink this record?"}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >

                        <FormDialog open={this.state.linkDialogOpen}
                            title={'Tranche Record'}
                            formRef={addFormRef}
                            viewRef={this}
                            closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                            dialogTitle={'Link Tranche Record'}
                            linkName={"Tranches"} saveUrl={"api/sims/TrancheDetail/TrancheSetupPeriodInsert"}
                            usePost={true} entityId={this.props.location.state.GrantID}
                    >

                            <LinkTrancheForm ref={addFormRef} id={this.state.TrancheHistoryID} />
                        </FormDialog>

                        <FormDialog open={this.state.updateDialogOpen}
                            title={'Update Tranche Record'}
                            formRef={updateFormRef}
                            viewRef={this}
                            closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                            dialogTitle={'Update Tranche Record'}
                            linkName={"Tranches"} saveUrl={"api/sims/TrancheDetail/TrancheSetupPeriodUpdate"}
                            usePost={true} entityId={this.props.location.state.GrantID}
                        >

                            <LinkTrancheForm ref={updateFormRef} dataURL='api/sims/TrancheDetail/GetTrancheSetupPeriodDetails' identity={this.state.selectedTrancheRec} />
                        </FormDialog>
                </SearchView>
                )}
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div> {'Total amount for listed tranches: R' + this.state.listedTranches} </div>
                <div> {'Total amount from setup details: R' + this.state.totalAmount} </div>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(TrancheDetails));

