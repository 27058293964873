import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import SearchView from "../../../widgets/SearchView";
import OFOSearchForm from './OFOSearchForm';
import { ViewContainer } from "./../../../View.jsx";
import CardLayout from '../../../widgets/Card';
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "Code", numeric: false, disablePadding: true, label: "OFO Code" },
    { id: "Description", numeric: false, disablePadding: true, label: "OFO Description" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select OFO Occupation from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

class OFOSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.WSPATRID) ?
            //For search of providers that can be linked to a particular entity
            [
                { Name: "WSPATRID", Value: this.props.WSPATRID },
                { Name: "OFOCode", Value: "" },
                { Name: "OFODescription", Value: "" }
            ]
            :
            //For search on the provider search screen
            [
                { Name: "OFOCode", Value: "" },
                { Name: "OFODescription", Value: "" }
            ]

        let model = !utils.isNull(this.props.WSPATRID) ?
            {
                OFOCode: "",
                OFODescription: "",
                WSPATRID: this.props.WSPATRID
            }
            :
            {
                OFOCode: "",
                OFODescription: ""
            }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedOFO: null,
            linkDialogOpen: false
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'OFO Occupations' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/OFOOccupation/search"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="Description" 
                    SecondDescriptionProp="Code"
                    model={this.state.model}
                    searchParameters={this.searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <OFOSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} />
                </SearchView>
            </ViewContainer>
            )
    }
}

export default withRouter(
    connect()(OFOSearch)
);