import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import EnhancedToolbar from "../../../widgets/Toolbar";

const utils = new ViewUtils();


class ProcessingNav extends Component {

    constructor(props) { 
        super(props) 
        this.state = {
            IDs: null,
            keyUpSwitch: false,
            messsageAlert: null,
            messageStyle: null
        };
        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'generateClaim', label: 'Generate Claim', tooltip: 'Generate Claim' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'approveClaim', label: 'Approve Claim', tooltip: 'Approve Claim' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'grantClaimFormReversal', label: 'Grant Claim Form Reversal', tooltip: 'Grant Claim Form Reversal' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'downloadClaim', label: 'Download Claim', tooltip: 'Download Claim' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'generateBatch', label: 'Generate Batch', tooltip: 'Generate Batch' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'downloadBatch', label: 'Download Batch', tooltip: 'Download Batch' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'axProcessor', label: 'AX-Processor', tooltip: 'AX-Processor' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'grantReversals', label: 'Grant Reversals', tooltip: 'Grant Reversals' },
                    ]
                },
            ]
        }  
    }

    handleToolbarButtonClick = (buttonId) => {
        console.log(buttonId)
        if ("generateClaim" === buttonId) {
            this.props.history.push({
                pathname: '/GenerateClaim'
            })
        } else if ("approveClaim" === buttonId) {
            this.props.history.push({
                pathname: '/ApproveClaim'
            })
        } else if ("grantClaimFormReversal" === buttonId) {
            this.props.history.push({
                pathname: '/GrantClaimFormReversal'
            })
        } else if ("downloadClaim" === buttonId) {
            this.props.history.push({
                pathname: '/DownloadClaim'
            })
        } else if ("generateBatch" === buttonId) {
            this.props.history.push({
                pathname: '/GenerateBatch'
            })
        } else if ("downloadBatch" === buttonId) {
            this.props.history.push({
                pathname: '/DownloadBatch'
            })
        } else if ("axProcessor" === buttonId) {
            this.props.history.push({
                pathname: '/AXProcessor'
            })
        } else if ("grantReversals" === buttonId) {
            this.props.history.push({
                pathname: '/GrantReversals'
            })
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {

        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Grant Processing"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar
                                    highlight={false}
                                    title="Grant Processing"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(ProcessingNav));

