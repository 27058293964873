import React from 'react';
import TextField from '@material-ui/core/TextField';
import { BaseField } from "./BaseField";
import ViewUtils from '../ViewUtils';
const utils = new ViewUtils();

const CustomTextFieldComponent = React.forwardRef((props, ref) => {
    const [multiline] = React.useState(props.multiline);
    const base = props.base;

    const onHandleChange = () => event => {
        props.form.handleChange(props.id)(event);
        if (!utils.isNull(props.onChange)) {
            props.onChange(props.form, props.id, event.target.value);
        }
    };

    return (
        <TextField
            id={props.id}
            InputProps={props.InputProps}
            inputProps={props.inputProps}
            ref={base.reference}
            required={base.required}
            label={props.label}
            value={String(!utils.isNull(base.valueObject[base.valueProperty]) ? base.valueObject[base.valueProperty] : (typeof props.defaultHander !== 'undefined' ? props.value : ''))}
            className={props.multiline ? props.styles.TextArea : props.className}
            //onChange={props.form.handleChange(props.id)}
            onChange={onHandleChange()}
            onKeyPress={props.onKeyPress}
            error={base.hasError}
            helperText={base.errorMessage}
            margin="normal"
            multiline={multiline}
            disabled={props.disabled}
            size="small"
            hidden={props.hidden}
            style={props.styles ? props.styles : { minWidth: "200px" }}
            //style={{ minWidth: "200px" }}
            variant="outlined">
        </TextField>
    );
})

export function CustomTextField(props) {
    return (
        <BaseField ref={React.createRef()} form={props.form} required={props.required} id={props.id} className={props.className}
            validator={props.validator} validationRegex={props.validationRegex} validationGroup={props.validationGroup}
            validationMessage={props.validationMessage} parentId={props.parentId} >
            {base =>
                <CustomTextFieldComponent
                    id={props.id}
                    base={base}
                    defaultHander={props.defaultHander}
                    InputProps={props.InputProps}
                    inputProps={props.inputProps}
                    required={props.required}
                    label={props.label}
                    className={props.className}
                    onKeyPress={props.onKeyPress}
                    readOnly={props.readOnly}
                    form={props.form}
                    multiline={props.multiline}
                    rowsMax={props.rowsMax}
                    styles={props.styles}
                    disabled={props.disabled}
                    hidden={props.hidden}
                    onChange={props.onChange}
                    value={props.value}
                >
                </CustomTextFieldComponent>
            }
        </BaseField>
    );
}