import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { UnionForm } from './UnionForm';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";

export default function UnionEditDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const formRef = React.createRef();

    const handleClose = () => {
        setOpen(false);
    };

    const cancel = () => {
        handleClose(true);
    };

    const add = () => {
        let formValue = formRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {
            let data = JSON.stringify(formValue);
            const url = `api/indicium/organisation/save?data=${data}`;
            //Todo: fetch(url).then blah blah blah
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
        console.log(props.addTrainingCommittee)
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}>
                <div style={{ padding: "32px" }}>
                    <p className="legend">
                        Edit
                    </p>
                    <div style={{ height: "80%" }}>
                        <UnionForm ref={formRef} addTrainingCommittee={props.addTrainingCommittee} className="h-100" />
                    </div>
                    <DialogActions>
                        <Button onClick={add} color="primary">
                            Save
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
