import React, { Component } from "react";
import "./Login.css";
//import { MsalProvider } from "@azure/msal-react";
//import { PublicClientApplication } from "@azure/msal-browser";
import AdLoginHandler from './ADLoginHandler';
/*import { msalConfig } from './ADAuthConfig';*/

/*const pca = new PublicClientApplication(msalConfig);*/

class ADLogin extends Component {

    render() {
        return (
           /* <MsalProvider instance={pca}>*/
                <AdLoginHandler />
           /* </ MsalProvider>*/
        );
    }
}

export default ADLogin;

