import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LinearFlowHeading from '../../widgets/LinearFlowHeading';
import AddLearnerSearch from './AddLearnerSearch';
import AddLearnerDetails from './AddLearnerDetails';
import AddLearnerPrograms from './AddLearnerPrograms';
import AddLearnerAccreditationHistory from './AddLearnerAccreditationHistory';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";

const steps = [
    { name: "Identity Details", tooltip: "Search the system for an existing person. If one does not extist, click proceed to create a learner with a new person" },
    { name: "Learner Details", tooltip: "Capture learner details" },
    { name: "Enrolment History", tooltip: "Add the qualifications the learner has previously archived" },
    { name: "Programme Enrolment", tooltip: "Capture the program the learner is enrolling for" }
];

const utils = new ViewUtils();
class AddLearnerFlow extends Component {
    state = {
        currentStepIndex: 0,
        model: {},
        personDetails: {},
        documents: {},
        learnerDetails: {},
        learnerProgrammeRegistration: {},
        program: {},
        saveMessage: "",
        hasSaveError: false
    }

    stepHandler(index, forward, data) {
        if (index === 0) {
            this.setState({ personDetails: data });
        } else if (index === 1) {
            this.setState({ learnerDetails: data });
        } else if (index === 3) {
            this.setState({ program: data });

            if (forward) {
                let dataObject = {};
                dataObject.Person = this.state.learnerDetails;

                if (!utils.isNull(this.state.personDetails)) {
                    dataObject.Person.PersonID = this.state.personDetails.ID;
                }

                //dataObject.documents = this.state.documents;
                if (1 == data.programmeType) {
                    dataObject.LearnerLearnershipDto = data;
                    dataObject.LearnerLearnershipDto.LearnershipID = data.LearnershipID;
                    dataObject.LearnerLearnershipDto.LeadProviderID = data.LeadProviderID;

                    if (!utils.isNull(data.SecondaryProvider)) {
                        dataObject.Scope.LearnerLearnershipDto.SecondaryProviderID = data.SecondaryProviderID;
                    }

                    dataObject.LearnerLearnershipDto.LeadEmployerID = data.LeadEmployerID;

                    if (!utils.isNull(data.SecondaryEmployer)) {
                        dataObject.Scope.LearnerLearnershipDto.SecondaryEmployerID = data.SecondaryEmployerID;
                    }
                } else if (2 == data.programmeType) {
                    dataObject.LearnerSkillsProgrammeDto = data;
                    dataObject.LearnerSkillsProgrammeDto.ProviderID = data.ProviderID;
                    dataObject.LearnerSkillsProgrammeDto.SkillsProgrammeID = data.SkillsProgrammeID;
                    dataObject.LearnerSkillsProgrammeDto.EmployerID = data.EmployerID;
                } else if (3 == data.programmeType) {
                    dataObject.LearnerUnitStandardDto = data;
                    dataObject.LearnerUnitStandardDto.ProviderID = data.ProviderID;
                    dataObject.LearnerUnitStandardDto.UnitStandardID = data.UnitStandardID;
                    dataObject.LearnerUnitStandardDto.EmployerID = data.EmployerID;
                } else if (4 == data.programmeType) {
                    dataObject.LearnerQualificationDto = data;
                    dataObject.LearnerQualificationDto.ProviderID = data.ProviderID;
                    dataObject.LearnerQualificationDto.QualificationID = data.QualificationID;
                    dataObject.LearnerQualificationDto.EmployerID = data.EmployerID;
                } else if (5 == data.programmeType) {
                    dataObject.LearnerTradeDto = data;
                    dataObject.LearnerTradeDto.ProviderID = data.ProviderID;
                    dataObject.LearnerTradeDto.QualificationID = data.TradeID;
                    dataObject.LearnerTradeDto.EmployerID = data.EmployerID;
                } else if (6 == data.programmeType) {
                    dataObject.LearnerInternshipDto = data;
                    dataObject.LearnerInternshipDto.ProviderID = data.ProviderID;
                    dataObject.LearnerInternshipDto.QualificationID = data.QualificationID;
                    dataObject.LearnerInternshipDto.EmployerID = data.EmployerID;
                } else if (7 == data.programmeType) {
                    dataObject.LearnerBusaryDto = data;
                    dataObject.LearnerBusaryDto.ProviderID = data.ProviderID;
                    dataObject.LearnerBusaryDto.QualificationID = data.BursaryID;
                    dataObject.LearnerBusaryDto.EmployerID = data.EmployerID;
                } else if (8 == data.programmeType) {
                    dataObject.LearnerAETDto = data;
                    dataObject.LearnerAETDto.ProviderID = data.ProviderID;
                    dataObject.LearnerAETDto.QualificationID = data.AETID;
                    dataObject.LearnerAETDto.EmployerID = data.EmployerID;
                } else if (9 == data.programmeType) {
                    dataObject.LearnerFETDto = data;
                    dataObject.LearnerFETDto.ProviderID = data.ProviderID;
                    dataObject.LearnerFETDto.QualificationID = data.FETID;
                    dataObject.LearnerFETDto.EmployerID = data.EmployerID;
                } else if (10 == data.programmeType) {
                    dataObject.LearnerCandidacyDto = data;
                    dataObject.LearnerCandidacyDto.ProviderID = data.ProviderID;
                    dataObject.LearnerCandidacyDto.QualificationID = data.CandidacyID;
                    dataObject.LearnerCandidacyDto.EmployerID = data.EmployerID;
                }

                let input = JSON.stringify(dataObject);
              
                let learnerData = {};
                learnerData.data = input;

                utils.invokeUrl("api/sims/learner/saveLearnerFlow", (data) => {
                    this.props.history.push({
                        pathname: '/learners'
                    })
                }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                        body: JSON.stringify(learnerData)
                    });
            }
        }

        if (index !== 3) {
            var increament = forward === true ? 1 : -1;
            this.setState({ currentStepIndex: index + increament });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            //save();
        } else {
            //close();
        }
    }

    componentDidMount() {
        utils.setLegend("Add Learner");
    }

    render() {
        return (
            <div>
                <LinearFlowHeading steps={steps} currentIndex={this.state.currentStepIndex} />
                {this.state.currentStepIndex === 0 ? <AddLearnerSearch stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                {this.state.currentStepIndex === 1 ? <AddLearnerDetails permissionItem={this.props.onMenuSpecificPermissionItem('/learner')} data={this.state.personDetails} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                {this.state.currentStepIndex === 2 ? <AddLearnerAccreditationHistory data={this.state.personDetails} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                {this.state.currentStepIndex === 3 ? <AddLearnerPrograms  data={this.state.learnerProgrammeRegistration} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} saveMessage={this.state.saveMessage} hasSaveError={this.state.hasSaveError} /> : ""}
            </div>
        );
    }
}

export default withRouter(withMenuPermission(AddLearnerFlow));