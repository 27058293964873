import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Person.css";
import { PersonForm } from './PersonForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";

const utils = new ViewUtils();
const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        },
        {
            type: "devider"
        }
    ]
};

const personRef = React.createRef();
class Person extends Component {

    state = {
        personDetails: {
            FirstName: "",
            Surname: ""
        },
        title: ""
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ PersonDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        }
    }

    handleToolbarButtonMenuClick(menu) {
       
    }

    successCallback = () => {

        this.setState({ message: "Person updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = personRef.current.getValue();
        let input = JSON.stringify(formValue);
        let personData = {};
        personData.data = input;


        utils.invokeUrl("api/indicium/person/updatePersonRecord", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(personData)
        });
    }

    closeView() {
        this.props.history.push('/persons');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={utils.getEntityViewTitle(this.props.location.state, "Person", this.state.personDetails.FirstName, this.state.personDetails.Surname)}>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title="Person"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true}
                                    config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <PersonForm ref={personRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(Person);
