import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Skills.css";
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';
import SkillsTNumberConversionForm from './SkillsTNumberConversionForm';

const headCells = [
    { id: 'TemporaryNumber', numeric: false, disablePadding: true, label: 'Temporary Number' },
    { id: 'PossibleSDLNumber', numeric: false, disablePadding: true, label: 'Possible SDL Number' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
            ]
        }
    ]
};

const searchParameters = [
    { Name: "TemporaryNumberme", Value: "" },
    { Name: "PossibleSDLNumber", Value: "" }


]

class TNumberConversionSearch extends Component {

    state = {
        model: {
            TemporaryNumberme: "",
            PossibleSDLNumber: ""


        }
    };

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/SkillsLetterCreation'
            })
        }
    } 

    postLinkHandler(linked) {
        this.setState({ linkDialogOpen: false, selectedLinkType: null, selectedLinkStandards: null });
    }

    render() {
        return (
            <CardLayout title='Skills Maintenance'>
                <SearchView headCells={headCells} dataUrl="api/indicium/roles/search" entityViewPath='/SkillsTNumberConversion'
                title='T Number Conversion' model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                onLookupValueChange={this.props.onLookupValueChange}
                descriptionProp="Skills" mode={this.props.mode}
                history={this.props.history}
            >
                  <SkillsTNumberConversionForm model={this.state.model} />
                </SearchView>
                </CardLayout>
        );
    }
}

export default withRouter(
    connect()(TNumberConversionSearch)
);
