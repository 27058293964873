import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import FormDialog from "../FormDialog";
import { LearnershipOutcomeForm } from "../program/LearnershipOutcomeForm";
import LearnershipSetupSearch from "../program/LearnershipSetupSearch";
import LinkDialog from "../LinkDialog";
import ProviderInterventionAudit from '../provider/ProviderInterventionAudit';

const headCells = [
    { id: 'LearnershipCode', numeric: false, disablePadding: false, label: 'Learnership Code' },
    { id: 'LearnershipTitle', numeric: false, disablePadding: false, label: 'Leanership Title' },
    { id: 'QualificationTitle', numeric: false, disablePadding: false, label: 'Learnership Qualification Title' },
    { id: 'QualificationID', numeric: false, disablePadding: false, label: 'Learnership Qualification ID' },
    { id: 'ProgramOutcome', numeric: false, disablePadding: false, label: 'Program Outcome' },
    { id: 'Specialisation', numeric: false, disablePadding: false, label: 'Specialisation' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class ProviderLearnershipSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showTable: true,
            selectedLearnership: null,
            linkDialogOpen: false,
            selectedLearnershipTitle: null,
            auditDialogOpen: false,
            formDialogOpen: false,
            searchParameters: [{ Name: "ProviderID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove Leanership from Provider",
            promptContent: "Are you sure you want to remove this leanership from the provider?",
            removeMessage: "",
            removeMessageStyle: "",

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link existing Learnership to Provider" },
                        {
                            id: 'view', label: '', icon: 'UpdateIcon', tooltip: 'Update Outcome and Specialisation',
                            disabled: utils.isNull(this.state.selectedLearnership)
                        },
                        {
                            id: 'audit', label: '', icon: 'AuditIcon', tooltip: 'View Record Audit Trail',
                            disabled: utils.isNull(this.state.selectedLearnership)
                        },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove Learnership from Provider',
                            disabled: utils.isNull(this.state.selectedLearnership)
                        }
                    ]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/provider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("view" === buttonId) {
            this.setState({ removeMessage: "" }, this.viewRecords());
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })

        }
        else if ("unitStandards" === buttonId) {
            this.props.history.push({
                pathname: '/providerLearnershipUSSearch',
                state: {
                    ProviderID: this.props.location.state.selection,
                    ProviderLearnershipID: this.state.selectedLearnership,
                    LearnershipTitle: this.state.selectedLearnershipTitle,
                    providerName: this.props.location.state.providerName,
                    providerSDLNumber: this.props.location.state.providerSDLNumber
                }
            });
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, auditDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false
            }, () => this.setState({ showTable: true }));
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnership: selection[0].Id, removeMessage: "", selectedLearnershipTitle: selection[0].LearnershipTitle });
        } else {
            this.setState({ selectedLearnership: null, removeMessage: "", selectedLearnershipTitle: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("unitStandards", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedLearnership: null,
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeLearnerships(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("view", true);
            toolbarRef.current.setButtonDisabled("audit", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedLearnership}&providerId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    viewRecords = () => {
        this.setState({ formDialogOpen: true })
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Provider > Learnerships " + (this.props.location.state.providerName !== null ? "> " + this.props.location.state.providerName + (this.props.location.state.providerSDLNumber !== null ? " - " + this.props.location.state.providerSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />
                    {this.state.showTable === true && (


                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/providerlearnership/GetProviderLearnerships`}
                            height="60vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}

                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Update Outcome and Mode of Delivery"}
                    viewRef={this}
                    entityId={this.state.selectedLearnership}
                    formRef={addFormRef}
                    isView={false}
                    saveUrl={"api/sims/providerlearnership/UpdateLearnershipOutcomeAndSpecialisation"} usePut={true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >

                    <LearnershipOutcomeForm ref={addFormRef} id={this.state.selectedLearnership}
                        dataURL='api/sims/providerlearnership/GetFindLearnershipOutcome'
                    />
                </FormDialog>


                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedLearnership}
                >

                    <ProviderInterventionAudit dataUrl={"api/sims/providerlearnership/GetProviderLearnershipAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedLearnership}
                    />
                </LinkDialog>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link Learnerships to Provider > ${this.props.location.state.providerName} - ${this.props.location.state.providerSDLNumber}`}
                    linkName={"Learnerships"} postUrl={"api/sims/providerlearnership/LinkProviderLearnership"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <LearnershipSetupSearch
                        dataUrl="api/sims/providerlearnership/GetSearchLinkableLearnerships"
                        multiSelect={true} showTitle={false}
                        mode="lookup"
                        programmeId={this.props.location.state.selection}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeLearnerships("api/sims/providerlearnership/RemoveProviderLearnership") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ProviderLearnershipSearch)
);
