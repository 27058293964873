import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from "../../ViewUtils";
import AssessorSearch from "../assessor/AssessorSearch";
import ModeratorSearch from "../moderator/ModeratorSearch";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const utils = new ViewUtils();
export const AssessmentHeaderForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
   
    return (
        <EntityForm ref={ref} values={props.data} viewId="Assessment" dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LearnershipAssessmentForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Assessment Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="LearnerName"
                                                label="Learner Name"
                                                required
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="LearnerID"
                                                label="Learner ID Number"
                                                required
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ProgramDescription"
                                                label="Programme Title"
                                                required
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="RequiredCredits"
                                                label="Required Credits"
                                                required
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="AchievedCredits"
                                                label="Achieved Credits"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <hr />
                                    <h5 className="w-100">Update Assessment Below:</h5>
                                    <br/>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="Assessor"
                                                label="Assessor"
                                                className="w-100"
                                                required
                                                form={form}
                                            >
                                                <AssessorSearch showTitle={true} mode='lookup' dataUrl={"api/sims/" + props.controllerName + "/GetAssessorSearch"} programmeId={props.id}
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("Assessor", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="AssessmentDate"
                                                parentId={0}
                                                className="w-100"
                                                key={tabValue}
                                                label="Assessment Date"
                                                required
                                                form={form}
                                                validator={{
                                                    script: (formValues) => {
                                                        let currentDate = new Date();
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.AssessmentDate)
                                                            && formValues.AssessmentDate.getTime() < currentDate.getTime()
                                                    }, message: "Assessment date must be prior to the current date"
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                            <td>
                                                <LookupField
                                                    id="Moderator"
                                                label="Moderator"
                                                className="w-100"
                                                    form={form}
                                                >
                                                <ModeratorSearch showTitle={true} mode='lookup'
                                                    dataUrl={"api/sims/" + props.controllerName + "/GetModeratorSearch"} programmeId={props.id}
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("Moderator", value)
                                                        }} autoFetchData={false} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                        <td>
                                            <CustomDatePicker
                                                id="ModerationDate"
                                                className="w-100"
                                                key={tabValue}
                                                label="Moderation Date"
                                                form={form}
                                                validator={{
                                                    script: (formValues) => {
                                                        let currentDate = new Date();
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.ModerationDate)
                                                            && formValues.ModerationDate.getTime() < currentDate.getTime()
                                                    }, message: "Moderation date must be prior to the current date"
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomCheckbox 
                                                id="Competent"
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Competent"
                                            />
                                            <CustomCheckbox
                                                id="RPL"
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="RPL"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
