import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import DocumentUpload from "../../widgets/DocumentUpload";
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();
export const MonitoringAndEvaluationForm = React.forwardRef((props, ref) => {

    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [filename, setFilename] = React.useState("");
    const [file, setFile] = React.useState(null)

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name)
            setFile(data.base64)
        }
    }

    return (
        <EntityForm ref={ref} viewId="monitoringAndEvaluation" values={props.data} dataURL={props.dataURL}
            entityLookupController={props.entityLookupController} org="saseta"
            searchParameters={props.searchParameters}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}
            findParameters={props.findParameters}
        >
            {form =>
                <form className={form.classes.container} id="monitoringAndEvaluation">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            <tr>

                                <CustomDatePicker
                                    parentId={0}
                                    id="MonitorEvaluationDate"
                                    key={tabValue}
                                    label="Monitoring Evaluation Date"
                                    className='{classes.textField} w-100'
                                    required={true}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    form={form}
                                />
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="MentorName"
                                        key={tabValue}
                                        label="Mentor Name"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=ReasonForMonitorEvaluation"
                                        parentId={0}
                                        id="ReasonForMonitorEvaluationID"
                                        required={true}
                                        key={tabValue}
                                        label="Reason For Monitor Evaluation"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=Outcomes"
                                        parentId={0}
                                        id="OutcomesID"
                                        required={true}
                                        key={tabValue}
                                        label="Outcomes"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>

                            <tr>
                                        <DocumentUpload
                                            id="document"
                                            form={form}
                                            showViewer={false}
                                            filename={filename}
                                            onUploadHandler={onUploadHandler}
                                        />
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
