import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from '../../../ViewUtils';
import EnhancedToolbar from '../../../widgets/Toolbar';
import EnhancedTable from '../../../widgets/ResponsiveTable';
import AddDialog from './TrancheSetupEAPTargetAddDialog';
import AlertItem from "../../../widgets/AlertItem";
import EditDialog from './TrancheSetupEAPTargetEditDialog';
import Prompt from '../../../widgets/Prompt';
import withMenuPermission from "../../../widgets/withMenuPermission";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'Equity', numeric: false, disablePadding: true, label: 'Race' },
    { id: 'Gender', numeric: false, disablePadding: true, label: 'Gender' },
    { id: 'Number', numeric: false, disablePadding: true, label: 'Number' }
];

class TrancheSetupEAPTarget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                Id: this.props.location.state.selection
            },
            addDialogOpen: false,
            editDialogOpen: false,
            selectedId: null,
            searchParameters: [{ Name: "TrancheSetupID", Value: this.props.location.state.selection }],
            Letterhead: null,
            AuthorisedSignatory: null,
            Reasons: null,
            clearSelectedRow: false,
            promptOpen: false,
            promptTitle: "Remove EAP Target",
            promptContent: "Are you sure you want to remove this EAP Target?"
        }

        this.toolbarConfig = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add EAP Target', disabled: utils.isNull(this.state.FinancialYearID), icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit EAP Target', disabled: utils.isNull(this.state.selectedId), icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'remove', label: '', tooltip: 'Remove EAP Target', disabled: utils.isNull(this.state.selectedId), icon: 'DeleteIcon', visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });

        }
        else if (buttonId === "edit") {
            this.setState({ editDialogOpen: true });
        }
        else if (buttonId === "close") {
            this.props.history.push({
                pathname: "/AddEditTranche",
                state: { TrancheID: this.props.location.state.selection }
            })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        } else {
            // this.setState({ editDialogOpen: true });
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedId: selection[0].Id, Message: "", clearSelectedRow: false });
        } else {
            this.setState({ selectedId: null, clearSelectedRow: false });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "success",
                    clearSelectedRow: true
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "warning"
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }

    remove(url) {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedId}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null })
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedId: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase(),
                clearSelectedRow: true
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="legend">
                    {"Project Setup > EAP Targets " + (this.props.location.state !== null ? " > " + this.props.location.state.ProjectNumber : "> Add")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">

                    <EnhancedToolbar highlight={false}
                        title="EAP Targets"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />

                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/eaptarget/GetSearch"
                        searchParameters={this.state.searchParameters}
                        clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                        paged={true} />
                </div>

                <AddDialog
                    open={this.state.addDialogOpen}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                    TrancheSetupID={this.props.location.state.selection}
                />

                <EditDialog
                    open={this.state.editDialogOpen}
                    id={this.state.selectedId}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                />

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.remove("api/sims/eaptarget/removeEAPTarget") }}
                    closePrompt={this.closePrompt}
                />

            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(TrancheSetupEAPTarget));