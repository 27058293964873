import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ViewUtils from "../ViewUtils";
import AlertItem from "../widgets/AlertItem";

export default function LinkDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const [title, setTitle] = React.useState(props.dialogTitle)
    const [selection, setSelection] = React.useState(null)
    const [Message, setMessage] = React.useState("")
    const [MessageStyle, setMessageStyle] = React.useState("")
    const [buttonName, setButtonName] = React.useState(props.buttonName);

    const utils = new ViewUtils();

    const handleClose = (isCancel, linked) => {
        setOpen(false);
        setMessage("");
        setSelection(null);
        props.closeHandler(isCancel, linked);
    };

    const cancel = () => {
        handleClose(true, false);
    };

    React.useEffect(() => {
        setOpen(props.open);
        setTitle(props.dialogTitle)
        setButtonName(props.buttonName)
    }, [props.open]);

    const link = () => {
        if (!utils.isNull(props.postUrl)) {
            let data = {};
            if (!utils.isNull(props.type)) {
                data.Type = props.type;
            }

            data.ProgrammeId = !utils.isNull(props.programmeId) ? props.programmeId : props.selectionId
            data[props.linkName] = selection;
            if (props.usePost) {
                utils.invokeUrl(props.postUrl, (response) => {
                    if (!utils.isNull(response) && !utils.isNull(response.Message)) {
                        if (response.MessageType === "SUCCESS") {
                            handleClose(false, true);
                        }
                        else {
                            setMessage(response.Message);
                            setMessageStyle(response.MessageType.toLowerCase());
                        }
                    }
                }, () => {
                    setMessage(utils.SYSTEM_ERROR_MESSAGE);
                    setMessageStyle("ERROR");
                    handleClose(false, false)
                }, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(data)
                });
            }
            else {
                utils.invokeUrl(props.postUrl + "?data=" + JSON.stringify(data), (response) => {
                    if (!utils.isNull(response) && !utils.isNull(response.Message)) {
                        if (response.MessageType === "SUCCESS") {
                            handleClose(false, true);
                        }
                        else {
                            setMessage(response.Message);
                            setMessageStyle(response.MessageType.toLowerCase());
                        }
                    }
                }, () => {
                    setMessage(utils.SYSTEM_ERROR_MESSAGE);
                    setMessageStyle("ERROR");
                    handleClose(false, false)
                });
            }
        } else if (!utils.isNull(props.linkHandler)) {
            props.linkHandler(selection);
            handleClose(false, true);
        }
    };

    const handleSelectionChange = (selection) => {
        setSelection(selection)
    }

    return (
        <div className="w-100 lookup" >
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="responsive-dialog-title"
                disableBackdropClick={true}
            >
                <DialogTitle disableTypography className="breadcrumbs" id="responsive-dialog-title">{title}</DialogTitle>
                <div style={{ paddingLeft: "24px", paddingRight: "44px" }}>
                    <AlertItem message={Message} alertStyle={MessageStyle} />
                </div>
                <DialogContent>
                    {React.cloneElement(props.children, { onLookupValueChange: (selection) => { handleSelectionChange(selection) } })}
                </DialogContent>

                <DialogActions>
                    <Button onClick={link} color="primary" disabled={utils.isNull(selection) || selection.length === 0}>
                        {utils.isNull(buttonName)? "Link" : buttonName}
                    </Button>
                    <Button onClick={cancel} color="primary">
                        Cancel
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}
