import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { LookupField } from '../../widgets/LookupField';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import ProviderSearch from "../provider/ProviderSearch";
import EmployerSearch from "../employer/EmployerSearch";
import LearnerSearch from "../learner/LearnerSearch";
import { Row, Col } from 'react-bootstrap';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const ProofOfRegistrationSearchForm = React.forwardRef((props, ref) => {
    const [dependency, setDependency] = React.useState(props.dependency);

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    const onCheckFieldValitationArray = (fields, values) => {
        if (values.FinancialYearID !== null) {
            fields = fields.filter(e => e.id !== "PrintTypeID");
        }
        console.log(fields);
        return fields;
    }

    return (
        <EntityForm ref={ref} viewId="learner" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}
            onCheckFieldValitationArray={onCheckFieldValitationArray}
        >
            {form =>
                <form className={form.classes.container} id="SearchForm">
                    <div className="row search-form-container">

                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/ProofOfRegistration/GetLookupList?listId=FinancialYear"
                                id="FinancialYearID"
                                required={true}
                                label="Financial Year"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/ProofOfRegistration/GetLookupList?listId=LearnerPORLearningProgramme"
                                id="LearningProgrammeID"
                                required={true}
                                label="Learning Programme"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/ProofOfRegistration/GetLookupList?listId=SocioEconomicStatus"
                                id="SocioEconomicStatusID"
                                required={true}
                                label="Socio Economic Status"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomDatePicker
                                id="RegistrationDateFrom"
                                label="Registration Date From"
                                className='{classes.textField} w-100 '
                                form={form}
                            />
                        </div>

                        <div className="col form-col">
                            <CustomDatePicker
                                id="RegistrationDateTo"
                                label="Registration Date To"
                                className='{classes.textField} w-100 '
                                form={form}
                            />
                        </div>

                        <div className="col form-col">
                            <LookupField
                                id="ProviderID"
                                label="Provider"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <ProviderSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/bulklearnercertificate/ProviderSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("ProviderID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>

                        <div className="col form-col">
                            <LookupField
                                id="EmployerID"
                                label="Employer"
                                className="w-100"
                                required={true}
                                form={form}
                            >
                                <EmployerSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/bulklearnercertificate/EmployerSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("EmployerID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>

                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/ProofOfRegistration/GetLookupList?listId=Sponsorship"
                                id="SponsorshipID"
                                required={false}
                                label="Sponsorship"
                                className="w-100"
                                form={form}
                            />
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    );
})

export default ProofOfRegistrationSearchForm;