import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Header from "./header";
import SideBar from "./sidebar";
import Routes from "../Routes";
import Footer from '../widgets/Footer';
import LoadingIndicator from '../LoadingIndicator';
import { Scrollbars } from 'react-custom-scrollbars';
import simsDashboard from '../../content/images/sims_dashboardsidenav_background.png';
import ViewUtils from '../ViewUtils';
import React, { Fragment, useEffect } from 'react';

const utils = new ViewUtils();
const sideBarWidth = 320;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: "100%"
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    appBarShift: {
        marginLeft: sideBarWidth,
        width: `calc(100% - ${sideBarWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    menuButton: {
        marginRight: 36,
    },

    hide: {
        display: 'none',
    },

    drawer: {
        width: sideBarWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        marginTop: '48px'
    },

    fullHeightDrawer: {
        height: "100%",
        //backgroundColor: "#fff !important",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${simsDashboard})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    },

    drawerOpen: {
        width: sideBarWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginTop: '48px'
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,/* theme.spacing(7) + 1,*/
        //[theme.breakpoints.up('sm')]: {
        //    width: theme.spacing(9) + 1,
        //},
    },

    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '48px',
    },

    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        marginTop: "48px",

        height: "100%"
    },
    legend: {
    },
    colorScrollBar: {
        color: theme.palette.action.main,
        fill: theme.palette.action.main
    }
}));

export default function MainLayout(props) {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const theme = useTheme();
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: false
    });
    //const [dashboardURL, setDashboardURL] = React.useState("");
    //const [dashboardTitle, setDashboardTitle] = React.useState("");

    const handleOpenMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    //useEffect(() => {
    //    getdashboardlink();
    //}, [])

    //const getdashboardlink = () => {
    //    let url = "api/sims/Dashboard/GetDashboardURL";
    //    utils.invokeUrl(url, (response) => {
    //        setDashboardURL(response.DashboardURL);
    //        setDashboardTitle(response.DashboardTitle);
    //    },
    //        null,
    //        {
    //            method: 'GET',
    //            headers: {
    //                'Accept': 'application/json',
    //                'Content-Type': 'application/json',
    //                'Authorization': "Bearer " + localStorage.getItem("token")
    //            }
    //        }
    //    )
    //}

    return (
        <Fragment>
            <div className={classes.root}>
                <Header classes={classes} handleOpenMenu={handleOpenMenu} open={isMenuOpen} />
                <SideBar
                    classes={classes}
                    open={isMenuOpen}
                    closeMenu={handleOpenMenu}
                    handleOpenMenu={handleOpenMenu}
                    variant={isDesktop ? 'persistent' : 'temporary'}
                />
                <Scrollbars>
                    <main className={classes.content} role="main">
                        <Typography className={classes.legend} variant="h6" component="h2" id="globalLegend">
                            Dashboard
                        </Typography>
                        <Routes />
                        <Footer className={classes.footer} color="#FFDD00"></Footer>
                    </main>
                    <LoadingIndicator />
                </Scrollbars>
            </div>
        </Fragment >
    );
}