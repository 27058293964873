import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();
export const EmployerAddContactForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="employerAddContact" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="employerAddContact">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            {/*<tr>*/}
                            {/*    <td>*/}
                            {/*        <CustomTextField*/}
                            {/*            id="IDNo"*/}
                            {/*            key={tabValue}*/}
                            {/*            label="Contact ID Number"*/}
                            {/*            className="w-100"*/}
                            {/*            required*/}
                            {/*            InputProps={{*/}
                            {/*                readOnly: false,*/}
                            {/*            }}*/}
                            {/*            form={form}*/}
                            {/*        />*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            <tr>

                                <td>
                                    <CustomTextField id="FirstName"
                                        key={tabValue}
                                        label="Contact First Name"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="Surname"
                                        key={tabValue}
                                        label="Contact Surname"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/employercontact/GetLookupList?listId=Designation"
                                        id="DesignationID"
                                        required
                                        key={tabValue}
                                        label="Contact Position"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomTextField id="TelephoneNumber"
                                        key={tabValue}
                                        label="Contact Telephone Number"
                                        className="w-100"
                                        required
                                        validationRegex={"^0\\d{9}$|^2\\d{10}$"}
                                        validationMessage="Starting with 0 then number should be 10 digits long else starting with 2 it should be 11"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        validationGroup="contactNumbers"
                                        validator={{
                                            script: (formValues) => {

                                                if (!utils.isNull(formValues)) {
                                                    return !utils.isNull(formValues.CellphoneNumber) && !utils.isStringEmpty(formValues.CellphoneNumber) ||
                                                        !utils.isNull(formValues.FaxNumber) && !utils.isStringEmpty(formValues.FaxNumber) ||
                                                        !utils.isNull(formValues.TelephoneNumber) && !utils.isStringEmpty(formValues.TelephoneNumber)
                                                }
                                            },
                                            message: "Atleast one contact number needs to be filled in"
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="CellphoneNumber"
                                        key={tabValue}
                                        validationGroup="contactNumbers"
                                        label="Contact Cellphone Number"
                                        className="w-100"
                                        required
                                        validationRegex={"^0\\d{9}$|^2\\d{10}$"}
                                        validationMessage="Starting with 0 then number should be 10 digits long else starting with 2 it should be 11"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="FaxNumber"
                                        key={tabValue}
                                        validationGroup="contactNumbers"
                                        label="Contact Fax Number"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="Email"
                                        key={tabValue}
                                        label="Email"
                                        className="w-100"
                                        required
                                        validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/employercontact/GetLookupList?listId=OrganisationContactType"
                                        id="OrganisationContactTypeID"
                                        required
                                        key={tabValue}
                                        label="Contact Type"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
