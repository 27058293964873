import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
/*import { LookupField } from '../../../widgets/LookupField';*/
/*import EmployerSearch from '../../employer/EmployerSearch';*/
import { CustomTextField } from '../../../widgets/CustomTextField';


export default function GrantSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="GrantSetup" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="GrantSearchForm">
                    <td>
                        {/*<LookupField*/}
                        {/*    id="ClientID"*/}
                        {/*    label="Client"*/}
                        {/*    className="w-100"*/}
                        {/*    form={form}*/}
                        {/*>*/}
                        {/*    <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/GrantSetup/ClientSearch"*/}
                        {/*        onLookupValueChange={(value) => {*/}
                        {/*            form.setLookupSelection("ClientID", value)*/}
                        {/*        }} autoFetchData={false} selection={props.selection} />*/}
                        {/*</LookupField>*/}
                        <CustomTextField
                            id="Client"
                            label="Client"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/GrantSetup/GetLookupList?listId=FinancialYear"}
                            id="FinancialYearID"
                            label="Time Period"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        {props.mode != 'lookup' ?
                            <SelectItem
                                dataUrl={"api/sims/GrantSetup/GetLookupList?listId=DGType"}
                                id="DiscretionaryGrantTypeID"
                                label="Intervention Type"
                                className="w-150"
                                form={form}
                                InputProps={{
                                    readOnly: props.disableFields
                                }}
                            /> : null
                        }
                    </td>
                    <br>
                    </br>
                </form>
            }
        </EntityForm>
    );
}
