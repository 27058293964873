import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

export default function QualificationsSearch(props) {
    const [labelWidth, setLabelWidth] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (labelRef.current !== null) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, []);

    return (
        <div className={classes.container}>
            <table cellPadding="2">
                <tbody>
                    <tr>
                        <td>
                       
                            <FormControl className={classes.formControl} variant="outlined">
                                <InputLabel ref={labelRef} htmlFor="SAQAQualificationTitle">
                                  SAQAQualificationTitle
                                </InputLabel>
                                <OutlinedInput
                                    id="SAQAQualificationTitle"
                                    onChange={(ev) => props.model.SAQAQualificationTitle = ev.target.value}
                                    labelWidth={labelWidth}
                                />
                            </FormControl>
                        </td>
                        <td>
                            <FormControl className={classes.formControl} variant="outlined">
                                <InputLabel ref={labelRef} htmlFor="SAQAQualificationID">
                                SAQA Code
                                </InputLabel>
                                <OutlinedInput
                                    id="SAQAQualificationID"
                                    onChange={(ev) => props.model.SAQAQualificationID = ev.target.value}
                                    labelWidth={labelWidth}
                                />
                            </FormControl>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
