import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import { SelectItem } from '../../widgets/SelectItem';
import ProviderSearch from "../provider/ProviderSearch";
import EmployerSearch from "../employer/EmployerSearch";
import LearnerSearch from "../learner/LearnerSearch";
import { Row, Col } from 'react-bootstrap';
import ViewUtils from '../../ViewUtils';
import QualificationSetupSearch from '../program/QualificationSetupSearch';
import LearnershipSetupSearch from '../program/LearnershipSetupSearch';
import SkillsProgramSetupSearch from '../program/SkillsProgramSetupSearch';
import UnitStandardSetupSearch from '../program/UnitStandardSetupSearch';

const utils = new ViewUtils();

const LearnerAttendanceSearchForm = React.forwardRef((props, ref) => {

    return (
        <EntityForm ref={ref} viewId="learner" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}
        >
            {form =>
                <form className={form.classes.container} id="LearnerSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <LookupField
                                id="LearnerID"
                                label="Learner"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <LearnerSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/learnerattendancecertificate/LearnerSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("LearnerID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>
                        <div className="col form-col">
                            <LookupField
                                id="ProgrammeID"
                                label="Learnerships"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <LearnershipSetupSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/learnerattendancecertificate/getlearnershipsearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("ProgrammeID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    );
})

export default LearnerAttendanceSearchForm;