import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { withAlert } from 'react-alert'
//import { actionCreators } from '../../store/SecurityStore';
import * as actions from '../../store/actions/auth';
import "./Login.css";
import simslogoLoading from '../../content/images/logos/sims_logo_loading.png'
import LoadingIndicatorLogin from '../LoadingIndicatorLogin';
import ViewUtils from "../ViewUtils";

//const handler = (state) => {
//    let isLoading = state.security.isLoading;
//    let hashdetails = state.security.hashdetails;
//    return {
//        isLoading: isLoading,
//        hashdetails: hashdetails,
//        hashmailsent: hashdetails.HashMailSent,
//        message: hashdetails.Message,
//    };
//};

const utils = new ViewUtils();

export class Forgot extends Component {

    state = {
        Username: "",
        message: "",
        display: "none",
        loading: true
    };

    validateUsername() {
        return this.state.Username.length > 0;
    }

    componentDidUpdate(prevProps) {

        if (prevProps.loginErrorMessage !== this.props.loginErrorMessage && this.props.loginErrorMessage) {
            this.showAlert(this.props.loginErrorMessage, 'error');
        }

        if (prevProps.loginMessage !== this.props.loginMessage && this.props.loginMessage) {
            this.showAlert(this.props.loginMessage, 'error');
        }

        //if (prevProps.message !== this.props.message && this.props.message) {
        //    if (this.props.hashmailsent === true) {
        //        this.setState({ message: this.props.message });
        //        this.showAlert(this.props.message, "success")
        //    }
        //    else {
        //        this.setState({ message: this.props.message });
        //        this.showAlert(this.props.message, 'error');
        //    }
        //}
    }

    reset = () => {

        if (!this.validateUsername()) {
            this.setState({ message: "Enter Username", display: "" });
            this.showAlert('Enter Username', 'error');
            return;
        }

        this.setState({ message: "" });
        //this.props.reset(this.state.Username);
        this.props.onAuthForgotPassword(this.state.Username);
    }

    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 3000, // custom timeout just for this one alert
            type: msgType,
            onOpen: () => {
            },
            onClose: () => {
            }
        })
    }

    //componentDidMount() {
    //    demoAsyncCall().then(() => this.setState({ loading: false }));
    //}

    render() {

        const successMessage = (
            <div style={{
                color: '#66FF00',
                fontWeight: 'bold',
                marginBottom: '16px'
            }}>An email has been sent with your new password. Please note the email may take several minutes to receive. Please also remember to check your email blocking and spam settings if applicable.</div>
        );

        return (
            <div className="login-container">
                <div className="login-container-logo">
                </div>
                <div className="card-container sec-card">
                    <img src={simslogoLoading} className="img-responsive" />
                    <form onSubmit={this.handleSubmit}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon">
                                    <i className="fa fa-user prefix"></i>
                                </span>
                            </div>
                            <input
                                type="text"
                                value={this.state.Username}
                                onChange={(ev) => this.setState({ Username: ev.target.value })}
                                className="form-control"
                                placeholder="Please enter your Username"
                                aria-label="Username"
                                aria-describedby="basic-addon" />
                        </div>
                        <br />
                        {this.props.forgotPasswordSuccess && successMessage}
                        <Button
                            block
                            onClick={this.reset}
                            className="loginButton"
                            disabled={this.props.forgotPasswordSuccess}
                        >
                            Reset Password
                        </Button>
                        <Link className="forgotlink" to='/login'>Back to Login</Link>
                    </form>
                </div>
                <div className="loginCopyright">
                    @ {new Date().getFullYear()} Copyright SIMS. All rights reserved. Developed By SoluGrowth
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>
        )
    }
}

//function demoAsyncCall() {
//    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
//}

//export default withRouter(
//    connect(handler,
//        dispatch => bindActionCreators(actionCreators, dispatch)
//    )(withAlert()(Forgot)))

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        loginMessage: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Message : null,
        loginErrorMessage: state.auth.errorMessage,
        forgotPasswordSuccess: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.ForgotPasswordSuccess : false
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthForgotPassword: (Username) => dispatch(actions.authForgotPassword(Username))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(Forgot)));