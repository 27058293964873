import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import Checkbox from '@material-ui/core/Checkbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const OrganisationCFODetailsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL='api/sims/organisationcfodetail/GetFind'
            entityLookupId={props.id} useCustomSettings={false} 
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>

            {form =>
                <form className={form.classes.container} id="organisationCFODetails">

                    <div className="row w-100">
                        <div className="col w-100">
                            <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                    <Tab label="Organisation CFO Details" {...a11yProps(0)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={tabValue} index={0} className='organisationFormTabPanel'>
                                <table cellPadding="2" className="w-100">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="FirstName"
                                                    key={tabValue}
                                                    label="First Name"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="Surname"
                                                    key={tabValue}
                                                    label="Surname"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="CellPhoneNumber"
                                                    key={tabValue}
                                                    label="Cellphone Number"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="FaxNumber"
                                                    key={tabValue}
                                                    label="Fax Number"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="EMail"
                                                    key={tabValue}
                                                    label="Email"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})