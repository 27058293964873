import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';

const headCells = [
    { id: 'UnitStandardCode', numeric: false, disablePadding: true, label: 'Unit Standard Code' },
    { id: 'UnitStandardDescription', numeric: false, disablePadding: true, label: 'Unit Standard Description' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'AssessmentDate', numeric: false, disablePadding: true, label: 'Assessment Date' },
    { id: 'Assessor', numeric: false, disablePadding: true, label: 'Assessor' },
    { id: 'ModerationDate', numeric: false, disablePadding: true, label: 'Moderation Date' },
    { id: 'Moderator', numeric: false, disablePadding: true, label: 'Moderator' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
];



const utils = new ViewUtils();
const toolbarRef = React.createRef();

class ViewLearnershipAchievementUS extends Component {

    state = {
        LearnershipDetails: {
            LearnershipProgrammeDescription: "",
            LearnershipProgrammeCode: ""
        },
        messageStyle: "message",
        id: null,
        showTable: true,
        searchParameters: [{ Name: "LearnerLearnershipID", Value: this.props.location.state.LearnerLearnershipID }],
        Learner: this.props.location.state.LearnerID
    };

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            }
        ]
    };


    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.closeView();
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/LearnerLearnershipAchievements',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Achieved Learnership Unit Standards > ${this.props.location.state.learnerNames}`}
                </p>
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/LearnerLearnershipAchievement/GetLearnerAchievedLearnershipsUSSearch`}
                            height="60vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />)}

                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(ViewLearnershipAchievementUS);
