import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./ArtisansSetup.css";
import SearchView from "../../widgets/SearchView";
import ArtisansSetupSearchForm from './ArtisansSetupSearchForm';
import CardLayout from '../../widgets/Card';


const headCells = [
    { id: 'ArtisanCode', numeric: false, disablePadding: true, label: 'Artisan Code' },
    { id: 'ArtisanTitle', numeric: false, disablePadding: true, label: 'Artisan Title' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
            ]
        }
    ]
};

const searchParameters = [
   { Name: "ArtisanCode", Value: "" },
    { Name: "ArtisanTitle", Value: "" }
];

class  ArtisansSetupSearch extends Component {

    state = {
        model: {
            ArtisanCode: "",
            ArtisanTitle: ""
        }
    };

    render() {
        return (
            <CardLayout title='Artisans'>
            <SearchView headCells={headCells} dataUrl="api/indicium/ArtisansSetup/search" entityViewPath='/ArtisanSetup'
                 model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                onLookupValueChange={this.props.onLookupValueChange}
                descriptionProp="ArtisanTitle" mode={this.props.mode}
                history={this.props.history}
            >
                <ArtisansSetupSearchForm model={this.state.model} />
                </SearchView>
            </CardLayout>
        );
    }
}

export default withRouter(
    connect()(ArtisansSetupSearch)
);
