import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import { LearnerForm } from './LearnerForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";

const utils = new ViewUtils();
const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Documents', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to Programs', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class AddLearnerDetails extends Component {

    state = {
        model: {}
    };

    handleToolbarButtonClick(buttonId) {
        if ("back" === buttonId) {
            this.props.stepHandler(1, false, this.state.model);
        } else if ("next" === buttonId) {
            let formValue = formRef.current.getValue();
            if (!utils.isNull(formValue)) {
                this.props.stepHandler(1, true, formValue);
            }
        }
    }

    handleFormValueChange(values) {
        this.setState({model: values})
    }

    componentDidMount() {
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <p className="breadcrumbs">
                    Learner Details
                </p>
                <div style={{height: "65%", maxHeight: "55vh"}}>
                    <LearnerForm ref={formRef} data={this.props.data} editPermission={this.props.permissionItem.Edit} valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AddLearnerDetails)
);
