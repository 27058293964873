import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const SkillsTNumberConversionCreationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="user" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="SkillsTNumberConversionCreationForm">
                    <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                            <Tab label="T Number Conversion" {...a11yProps(0)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                            <tbody>
                             <tr>
                                        <td>
                                        <CustomTextField
                                            id="TemporaryNumber"
                                            key={tabValue}
                                            label="Temporary Number"
                                            className="w-100"

                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />  
                                        </td>
                                </tr>    
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="PossibleSDLNumber"
                                            key={tabValue}
                                            label="Possible SDL Number"
                                            className="w-100"

                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        /> 
                                    </td>
                                </tr>              
                                </tbody>
                            </table>
                        </TabPanel>
                       
                </form>
            }
        </EntityForm>
    );
})

