import React from 'react';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { LookupField } from '../../widgets/LookupField';
import { EntityForm } from '../../widgets/Forms';
import NavigationSearch from './Controls/NavigationSearch';
import { CustomTextField } from '../../widgets/CustomTextField';


export const RolePermissionForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref} viewId="rolepermission"
            dataURL={props.dataUrl}
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id}
            useCustomSettings={false}
            valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <table cellPadding="2" width="80%">
                    <tbody>
                        <tr>
                            <td>
                                { props.id === null ?
                                    <LookupField
                                        id="NavigationID"
                                        label="Menu Item Name"
                                        className="w-100"
                                        form={form}
                                        required={true}
                                    >
                                        <NavigationSearch showTitle={true} mode='lookup'
                                            dataUrl='api/sims/PermissionDetails/GetSearchNavigationSearch'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("NavigationID", value)
                                            }} autoFetchData={true} selection={props.selection}
                                            RoleID={props.RoleID}
                                        />
                                    </LookupField>
                                    :
                                    <CustomTextField
                                        id="MenuItemName"
                                        key={tabValue}
                                        label="Menu Item Name"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        form={form}
                                    />
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <td xs lg="6">
                                    <CustomCheckbox
                                        id="View"
                                        key={tabValue}
                                        form={form}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Viewable"
                                    />
                                </td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <td xs lg="6">
                                    <CustomCheckbox
                                        id="Add"
                                        key={tabValue}
                                        form={form}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Add"
                                    />
                                </td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <td xs lg="6">
                                    <CustomCheckbox
                                        id="Edit"
                                        key={tabValue}
                                        form={form}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Edit"
                                    />
                                </td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <td xs lg="6">
                                    <CustomCheckbox
                                        id="Delete"
                                        key={tabValue}
                                        form={form}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Delete"
                                    />
                                </td>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </EntityForm >
    );
})