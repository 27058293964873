import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { SelectItem } from '../../widgets/SelectItem';

export default function SDFSearchForm(props) {
    return (
        <EntityForm ref={React.createRef()} viewId="sdf" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="SDFSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <CustomTextField
                                id="FirstName"
                                label="First Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="Surname"
                                label="Surname"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="IDNo"
                                label="ID Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                                <div className="col form-col">
                                    <SelectItem
                                        dataUrl="api/sims/sdf/GetLookupList?listId=Province"
                                        id="ProvinceID"
                                        label="Province"
                                        className="w-100"
                                        form={form}
                                    />
                                </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="SDLNumber"
                                label="SDL Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    );
}