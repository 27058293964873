import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';
import AlertItem from "../../../widgets/AlertItem";
import Prompt from '../../../widgets/Prompt';
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "./../../../View.jsx";
import GrantSetupElectiveUSSearchModule from "../../program/GrantSetupElectiveUSSearchModule";
import LinkDialog from "../../LinkDialog";

const headCells = [
    { id: 'SAQAUnitStandardID', numeric: false, disablePadding: false, label: 'SAQA UnitStandard ID' },
    { id: 'SAQAUnitStandardTitle', numeric: false, disablePadding: false, label: "SAQA UnitStandard Title" },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: false, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: false, label: 'Registration End Date' },
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();

class GrantSetupElectiveUSSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showTable: true,
            selectedUnitStandard: null,
            selectedUnitStandardTitle: null,
            linkDialogOpen: false,
            searchParameters: [{ Name: "TrancheSetupID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove Elective Unit Standard from Project Setup",
            promptContent: "Are you sure you want to remove this elective unit standard from the project setup?",
            removeMessage: "",
            removeMessageStyle: "",

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link elective unit standard to project setup" },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove elective unit standard from project setup',
                            disabled: utils.isNull(this.state.selectedUnitStandard)
                        }]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: { TrancheID: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false
            }, () => { this.setState({ showTable: true }) });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedUnitStandard: selection[0].Id, removeMessage: "", selectedUnitStandardTitle: selection[0].SkillsProgrammeTitle });
        } else {
            this.setState({ selectedUnitStandard: null, removeMessage: "", selectedUnitStandardTitle: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedUnitStandard: null,
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeSkillsProgramme(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedUnitStandard}&tranchesetupId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Project Setup Details > Elective Unit Standards " + (this.props.location.state.ProjectNumber !== null ? "> " + this.props.location.state.ProjectNumber : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (

                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/electiveunitstandard/GetElectiveUnitStandards`}
                            height="60vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link Elective Unit Standards to Project Setup > ${this.props.location.state.ProjectNumber}`}
                    linkName={"unitstandards"} postUrl={"api/sims/electiveunitstandard/LinkElectiveUnitStandards"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <GrantSetupElectiveUSSearchModule
                        programmeId={this.props.location.state.selection}
                        dataUrl="api/sims/electiveunitstandard/GetSearchLinkableElectiveUnitStandards"
                        mode="lookup"
                        multiSelect={true} showTitle={false}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeSkillsProgramme("api/sims/electiveunitstandard/RemoveProviderSkillsProgramme") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(GrantSetupElectiveUSSearch);
