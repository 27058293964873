import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { SelectItem } from '../../widgets/SelectItem';
import { Row, Col } from 'react-bootstrap';



export default function SponsorshipSetupSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="sponsorshipsetup" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form => <form className={form.classes.container} id="sponsorship">
                <div className="row search-form-container">
                    <div className="col form-col">
                        <CustomTextField
                            id="Client"
                            label="Client"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                    <div className="col form-col">
                        <SelectItem
                            dataUrl="api/sims/learnersponsorship/GetLookupList?listId=TrancheSetupSponsorshipType"
                            id="TrancheSetupSponsorshipTypeID"
                            label="Sponsorship Type"
                            className="{classes.select} w-100"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </div>
                </div>
            </form>
            }
        </EntityForm>
    );
}
