import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EnhancedToolbar from "../../widgets/Toolbar";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./../organisation/DGOrganisation.css";
import { DGApplicationCreationForm } from "./DGApplicationCreationForm";

import ViewUtils from "../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { ViewContainer } from "../../View";
import withMenuPermission from "../../widgets/withMenuPermission";
const utils = new ViewUtils();

const formRef = React.createRef();

const userRef = React.createRef();

class DGApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        Name: "",
        Description: "",
        LetterType: "",
      },
      title: "",
      id:
        !utils.isNull(this.props.location.state) &&
        !utils.isNull(this.props.location.state.selection)
          ? this.props.location.state.selection
          : null,
    };

    this.toolbarConfig = {
      items: [
        {
          type: "buttonGroup",
          buttons: [
            { id: "close", label: "CLOSE", tooltip: "Close" },
            { id: "save", label: "SAVE", tooltip: "Save", icon: "SaveIcon" },
          ],
        },
      ],
    };
  }

  handleToolbarButtonClick(buttonId) {
    if ("close" === buttonId) {
      this.close();
    } else if ("save" === buttonId) {
      this.save();
    }
  }

  handleFormValueChange = (values) => {
    console.log(values);
    if (!utils.isNull(values)) {
      this.setState({
        Name: values.Name,
      });
    }
  };

  save() {
    let formValue = userRef.current.getValue();

    //Organisation
    // formValue.Organisation = {Description: "OrganisationID", Id: this.props.location.state.preSelectedProp}
    // formValue.OrganisationID = {Description: "OrganisationID", Id: this.props.location.state.preSelectedProp}
    formValue.OrganisationID = this.props.location.state.preSelectedProp;
    console.log(formValue);
    console.log(this.props.location.state);

    if (formValue !== null && typeof formValue !== "undefined") {
      let isAdd = true;
      if (
        !utils.isNull(this.props.location.state) &&
        !utils.isNull(this.props.location.state.selection)
      ) {
        formValue.id = this.props.location.state.selection;
        isAdd = false;
      }
      let data = JSON.stringify(formValue);

      if (isAdd) {
        const url = `api/sims/dgapplication/insertdgapplication`;
        utils.saveForm(
          this,
          userRef,
          url,
          (response) => {
            this.setState({
              message: "DG Application  added successfully",
              messageStyle: "SUCCESS",
            });
          },
          this.error
        );
      } else {
        const url = `api/sims/dgapplication/updateledgapplication`;
        utils.updateForm(
          this,
          userRef,
          url,
          (response) => {
            this.setState({
              message: "DG Application updated successfully",
              messageStyle: "SUCCESS",
            });
          },
          this.error
        );
      }
    }
  }

  successCallback(response) {
    this.setState({
      message: response.Message,
      messageStyle: response.MessageType,
    });
  }
  error(response) {
    this.setState({
      message: response.Message,
      messageStyle: response.MessageType,
    });
  }
  close() {
    this.props.history.push({
      pathname: "/DGApplicationSearch",
      state: { selection: this.state.id },
    });
  }

  render() {
    return (
      <ViewContainer
        topPadding="0"
        title={utils.getEntityViewTitle(
          this.props.location.state,
          "DG Application",
          this.state.Name
        )}
      >
        <AlertItem
          message={this.state.message}
          alertStyle={this.state.messageStyle}
        />

        <table width="100%">
          <tbody>
            <tr>
              <td>
                <EnhancedToolbar
                  highlight={false}
                  title="DG Application"
                  buttonClickHandler={(buttonId) =>
                    this.handleToolbarButtonClick(buttonId)
                  }
                  enabled={true}
                  config={this.toolbarConfig}
                />
              </td>
            </tr>
            <tr>
              <td className="formContainer">
                <DGApplicationCreationForm
                  ref={userRef}
                  valueChangeHandler={(values) =>
                    this.handleFormValueChange(values)
                  }
                  id={
                    typeof this.props.location.state !== "undefined"
                      ? this.props.location.state.selection
                      : null
                  }
                  editPermission={this.props.menuPermissionItem.Edit}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </ViewContainer>
    );
  }
}
export default withRouter(withMenuPermission(DGApplication));
