import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Moderator.css"
import ViewUtils from '../../ViewUtils';
import { EntityForm } from '../../widgets/Forms';
import { SelectItem } from '../../widgets/SelectItem';
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';
import UnitStandardSetupSearch from '../program/UnitStandardSetupSearch';
import QualificationSetupSearch from '../program/QualificationSetupSearch';
import LearnershipSetupSearch from '../program/LearnershipSetupSearch';
import SkillsProgrammeSetupSearch from '../program/SkillsProgramSetupSearch';
import LinkDialog from "../LinkDialog";
import { unitStandardHeadCells } from "./AssessorUnitStandards";
import { skillsProgrammeHeadCells } from "./AssessorSkillsProgramme";
import { qualificationHeadCells } from "./AssessorQualifications";
import { learnershipHeadCells } from "./AssessorLearnerships";
import { ViewContainer } from "../../View";
import { format } from "date-fns";

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
 
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#01579B',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#01579B',
    },
  },
});

const linkToolbarRef = React.createRef();
const toolbarRef = React.createRef();
const formRef = React.createRef();
const utils = new ViewUtils();

function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

class AddAssessorProgrammeDetails extends Component {
    constructor(props) {
        super(props);

        let nextButtonDisabled = true;

        if (!utils.isNull(this.props.data) && !utils.isNull(this.props.data.scopeDetails)) {
            nextButtonDisabled = this.props.data.scopeDetails.qualifications.length === 0 &&
                this.props.data.scopeDetails.skillsProgrammes.length === 0 && this.props.data.scopeDetails.unitStandards.length === 0 && this.props.data.scopeDetails.learnerships.length === 0;
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'back', label: 'BACK', tooltip: 'Back to Search', theme: true },
                        { id: 'finish', label: 'FINISH', tooltip: 'Save Moderator', theme: true, disabled: nextButtonDisabled }
                    ]
                }
            ]
        };

        let qualificationIds = "";
        let skillsProgrammeIds = "";
        let unitStandardIds = "";
        let learnershipIds = "";
        let scopeSelected = "";

        if (!utils.isNull(props.data) && !utils.isNull(props.data.scopeDetails)) {
            this.setState({ qualificationIds: this.getIdList(props.data.scopeDetails.qualifications) });
            this.setState({ skillsProgrammeIds: this.getIdList(props.data.scopeDetails.skillsProgrammes) });
            this.setState({ unitStandardIds: this.getIdList(props.data.scopeDetails.unitStandards) });
            this.setState({ learnershipIds: this.getIdList(props.data.scopeDetails.learnerships) });
        }

        this.state = {
            model: !utils.isNull(props.data) && !utils.isNull(props.data.scopeDetails) ? props.data.scopeDetails : { unitStandards: [], skillsProgrammes: [], qualifications: [], learnerships: [], scopeSelected: null },
            searchParameters: null,
            message: "",
            scopeDescription: "",
            unitStandardIds: unitStandardIds,
            skillsProgrammeIds: skillsProgrammeIds,
            qualificationIds: qualificationIds,
            learnershipIds: learnershipIds,
            linkDialogOpen: false,
            propmtOpen: false,
            selectedUnitStandard: null,
            formValue: { Intervention: !utils.isNull(props.data) && !utils.isNull(props.data.scopeDetails) ? props.data.scopeDetails.selectedIntervention : null },
            assessorName: this.props.data.FirstName + " " + this.props.data.Surname,
            personId: !utils.isNull(props.data) && !utils.isNull(props.data.personRoleDetails) ? props.data.personRoleDetails.ID: null,
            scopeSelected: '1'
        };

        this.linkToolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: 'Link', tooltip: 'link the selected intervention record to the moderator' },
                        { id: 'unlink', label: 'Unlink', tooltip: 'Un-link the selected intervention record from the moderator', disabled: utils.isNull(this.state.selectedUnitStandard) }
                    ]
                }
            ]
        };
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ model: { ...this.state.model, ["selectedIntervention"]: values.Intervention } });
            if (!utils.isNull(linkToolbarRef.current)) {
                linkToolbarRef.current.setButtonDisabled("unlink", true);
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true });
        }
        else if ("unlink" === buttonId) {
            if (!utils.isNull(this.state.selectedProgrammeId)) {
                let programmeName =
                    this.state.model.selectedIntervention.Id === "3" ? "learnerships" :
                    this.state.model.selectedIntervention.Id === "1" ? "unitStandards" :
                    this.state.model.selectedIntervention.Id === "4" ? "skillsProgrammes" : "qualifications";

                let programmes = this.state.model[programmeName];
                let newProgrammes = [];

                for (let i = 0; i < programmes.length; i++) {
                    if (programmes[i].Id !== this.state.selectedProgrammeId) {
                        newProgrammes.push(programmes[i]);
                    }
                }

                let newIds = this.getIdList(newProgrammes);
                this.state.model.selectedIntervention.Id === "3" ? this.setState({ learnershipIds: newIds }) :
                this.state.model.selectedIntervention.Id === "1" ? this.setState({ unitStandardIds: newIds }) :
                this.state.model.selectedIntervention.Id === "4" ? this.setState({ skillsProgrammeIds: newIds }) :
                this.setState({ qualificationIds: newIds });

                this.setState({ model: { ...this.state.model, [programmeName]: newProgrammes } }, () => {
                    toolbarRef.current.setButtonDisabled("finish", this.state.model.qualifications.length === 0 &&
                        this.state.model.skillsProgrammes.length === 0 && this.state.model.unitStandards.length === 0 && this.state.model.learnerships.length === 0);
                });
            }
        } else if ("back" === buttonId) {
            this.props.stepHandler(4, false, this.state.model);
        } else if ("finish" === buttonId) {
            this.props.stepHandler(4, true, this.state.model);
        }
    }

    postLinkHandler(isCancel, linked) {
        this.setState({ linkDialogOpen: !isCancel && !linked})
    }

    getIdList(programmes) {
        let exclusions = "";

        for (let i = 0; i < programmes.length; i++) {
            exclusions += programmes[i].Id;
            if (i < programmes.length - 1) {
                exclusions += ","
            }
        }

        return exclusions;
    }

    saveProgramme(selection, programmeName) {
        for (let i = 0; i < selection.length; i++) {
            selection[i].AssessorAssessmentEndDate = format(this.props.data.registrationDetails.RegistrationEndDate, "yyyy/MM/dd HH:mm:ss");
            this.state.model[programmeName].push(selection[i]);
        }

        toolbarRef.current.setButtonDisabled("finish", this.state.model.qualifications.length === 0 &&
            this.state.model.skillsProgrammes.length === 0 && this.state.model.unitStandards.length === 0 && this.state.model.learnerships.length === 0);

        
    }

    saveQualifications(selection) {
        this.saveProgramme(selection, "qualifications");
        this.setState({ qualificationIds: this.getIdList(selection) });
    }
    
    saveSkillsProgrammes(selection) {
        this.saveProgramme(selection, "skillsProgrammes");
        this.setState({ skillsProgrammeIds: this.getIdList(selection) });
    }

    saveUnitStandards(selection) {
        this.saveProgramme(selection, "unitStandards");
        this.setState({ unitStandardIds: this.getIdList(selection) });
    }

    saveLearnerships(selection) {
        this.saveProgramme(selection, "learnerships");
        this.setState({ learnershipIds: this.getIdList(selection) });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedProgrammeId: selection[0].Id });
        } else {
            this.setState({ selectedProgrammeId: null });
        }
        linkToolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    handleScopeSelected = (event) => {

        if (event.target.value === '1') {
            this.setState({ scopeDescription: "Manually choose scope items existing under the Assessor profile for this Moderator." })
        }

        if (event.target.value === '2') {
            this.AutoGrabAssessorScopes();
        }

        if (event.target.value === '3') {
            this.setState({ scopeDescription: "No scope will be added to the moderator." })
        }
        
        if (event.target.value != this.state.scopeSelected) { 
            this.setState({ scopeSelected: event.target.value });
            this.state.model.scopeSelected = event.target.value;
            toolbarRef.current.setButtonDisabled("finish", event.target.value === '1');
        }   
    }

    AutoGrabAssessorScopes() {
        let url = "api/sims/Moderator/GetPrefillAssessorScope?PersonID=" + this.state.personId;
        utils.invokeUrl(url, (response) => {
            this.setState({
                model: {
                    ...this.state.model,
                    ["learnerships"]: response[0].Data,
                    ["skillsProgrammes"]: response[1].Data,
                    ["unitStandards"]: response[2].Data,
                    ["qualifications"]: response[3].Data
                }, scopeDescription: "The system will populate the scope from the Moderators’ Assessor profile to the Moderators’ scope."
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                {
                    !utils.isStringEmpty(this.props.saveMessage) ?
                        <p className={this.props.hasSaveError ? "errorMessage" : "message"}>
                            {this.props.saveMessage}
                        </p>
                        :
                        null
                }

                <div topPadding="0">
                    <p>
                        {this.state.scopeDescription}
                    </p>
                </div>
                <div>
                    <FormControl style={{ paddingLeft: 5 }}> 
                        
                        <RadioGroup onChange={(event)=>{this.handleScopeSelected(event)}} defaultValue="1" aria-label="linkscope" name="scope module"  row>  
                            <FormControlLabel value="3" control={<StyledRadio />} label="No Scope" />
                            <FormControlLabel value="2" control={<StyledRadio />} label="Read Scope from Assesor Profile " />
                            <FormControlLabel value="1" control={<StyledRadio />} label="Link Scope" />
                        </RadioGroup>
                    </FormControl>
                </div>
                
                <EntityForm ref={formRef} viewId="assessorProgrammeScope" values={this.state.formValue} useCustomSettings={false}
                    valueChangeHandler={(values) => { this.handleFormValueChange(values) }}>
                    {form =>
                        <form  id="assessorProgrammeForm" >
                            <div className="row w-50">
                                <div className="col w-100">
                                    <SelectItem
                                        disabled={(this.state.scopeSelected === "3" ) ? true : false }
                                        dataUrl="api/sims/moderator/GetLookupList?listId=ProgrammeType"
                                        id="Intervention"
                                        label="Programme Type"
                                        className="{classes.select} w-100"
                                        form={form} />
                                </div>
                            </div>
                        </form>
                    }
                </EntityForm>
                {
                    !utils.isNull(this.state.model.selectedIntervention) ?
                        <div style={{ margin: "8px 0 16px 8px" }}>
                            <EnhancedToolbar
                                highlight={false}
                                buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                ref={linkToolbarRef} config={this.linkToolbarConfig} />
                        </div> :
                        null
                }
                {
                    !utils.isNull(this.state.model.selectedIntervention) && this.state.model.selectedIntervention.Id === "4" ?
                        <div style={{ margin: "8px 0 16px 8px", height: "35vh" }}>
                            <p className="legend">
                                Skills Programmes
                            </p>
                            <div style={{ height: "90%", overflow: "auto", width: "99%", paddingLeft: "8px", paddingRight: "8px" }}>
                                <EnhancedTable
                                    headCells={skillsProgrammeHeadCells} autoFetchData={false}
                                    rows={this.state.model.skillsProgrammes}
                                    onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                                    searchParameters={this.state.searchParameters} paged={false} />
                            </div>
                            <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => { this.saveSkillsProgrammes(selection) }}
                                closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                                dialogTitle={`Link Skills Programmes to Moderator: ${this.state.assessorName}`}
                            >
                                <SkillsProgrammeSetupSearch dataUrl="api/sims/ModeratorSkillsProgramme/GetModeratorLinkableSkillsProgrammesWithExclusions"
                                    multiSelect={true} showTitle={false} selectionCallbackMode="full"
                                    programmeType="Q" exclusions={this.state.skillsProgrammeIds}
                                    personId={this.state.personId}
                                />
                            </LinkDialog>
                        </div>
                        :
                        null 
                }
                {
                    !utils.isNull(this.state.model.selectedIntervention) && this.state.model.selectedIntervention.Id === "1" ?
                        <div style={{ margin: "8px 0 16px 8px", height: "35vh"}}>
                            <p className="legend">
                                Unit Standards
                            </p>
                            <div style={{ height: "90%", overflow: "auto", width: "99%", paddingLeft: "8px", paddingRight: "8px" }}>
                                <EnhancedTable
                                    headCells={unitStandardHeadCells} autoFetchData={false}
                                    rows={this.state.model.unitStandards}
                                    onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                                    searchParameters={this.state.searchParameters} paged={false} />
                            </div>
                            <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => {this.saveUnitStandards(selection)}}
                                closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                                dialogTitle={`Link Unit Standards to Moderator: ${this.state.assessorName}`}
                            >
                                <UnitStandardSetupSearch dataUrl="api/sims/ModeratorUnitStandard/GetModeratorLinkableUnitStandardsWithExclusions"
                                    multiSelect={true} showTitle={false} selectionCallbackMode="full"
                                    programmeType="Q" exclusions={this.state.unitStandardIds}
                                    personId={this.state.personId}
                                />
                            </LinkDialog>
                        </div>
                        :
                        null
                }
                {
                    !utils.isNull(this.state.model.selectedIntervention) && this.state.model.selectedIntervention.Id === "2" ?
                        <div style={{ margin: "8px 0 16px 8px", height: "35vh" }}>
                            <p className="legend">
                                Qualifications
                            </p>
                            <div style={{ height: "90%", overflow: "auto", width: "99%", paddingLeft: "8px", paddingRight: "8px" }}>
                                <EnhancedTable
                                    headCells={qualificationHeadCells} autoFetchData={false}
                                    rows={this.state.model.qualifications}
                                    onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                                    searchParameters={this.state.searchParameters} paged={false} />
                            </div>
                            <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => { this.saveQualifications(selection) }}
                                closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                                dialogTitle={`Link Qualification to Moderator: ${this.state.assessorName}`}
                            >
                                <QualificationSetupSearch dataUrl="api/sims/ModeratorQualification/GetModeratorLinkableQualificationsWithExclusions"
                                    multiSelect={true} showTitle={false} selectionCallbackMode="full"
                                    programmeType="Q" exclusions={this.state.qualificationIds}
                                    personId={this.state.personId}
                                />
                            </LinkDialog>
                        </div>
                        :
                        null
                }
                {
                    !utils.isNull(this.state.model.selectedIntervention) && this.state.model.selectedIntervention.Id === "3" ?
                        <div style={{ margin: "8px 0 16px 8px", height: "35vh" }}>
                            <p className="legend">
                                Learnership
                            </p>
                            <div style={{ height: "90%", overflow: "auto", width: "99%", paddingLeft: "8px", paddingRight: "8px" }}>
                                <EnhancedTable
                                    headCells={learnershipHeadCells} autoFetchData={false}
                                    rows={this.state.model.learnerships}
                                    onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                                    searchParameters={this.state.searchParameters} paged={false} />
                            </div>
                            <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => { this.saveLearnerships(selection) }}
                                closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                                dialogTitle={`Link Learnership to Moderator: ${this.state.assessorName}`}
                            >
                                <LearnershipSetupSearch dataUrl="api/sims/ModeratorLearnership/GetModeratorLinkableLearnershipsWithExclusions"
                                    multiSelect={true} showTitle={false} selectionCallbackMode="full"
                                    programmeType="L" exclusions={this.state.learnershipIds}
                                    personId={this.state.personId}
                                />
                            </LinkDialog>
                        </div>
                        :
                        null
                }
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar disabled={false} ref={toolbarRef} highlight={true} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={this.toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AddAssessorProgrammeDetails)
);
