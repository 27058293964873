import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Provider.css";
import { ProviderAccreditationForm } from './ProviderAccreditationForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        }
    ]
};

const providerRef = React.createRef();
const utils = new ViewUtils();

class ProviderAccreditation extends Component {

    state = {
        providerDetails: {
            LegalName: "",
        }
    };

    handleFormValueChange(values) {
        this.setState({ providerDetails: values });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else {
            this.close();
        }
    }

    save() {
        //TODO: IMPLEMENT THE SAVE LOGIC
    }

    close() {
        this.props.history.push({
            pathname: '/provider',
            state: { selection: this.props.location.state.selection }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={`Providers > ${this.props.location.state.providerName} > Accreditation Details`}>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Provider"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true}
                                    config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <ProviderAccreditationForm ref={providerRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(ProviderAccreditation);
