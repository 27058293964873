import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function createData(number, checkList, confirmation, submited, comments) {
  return { number, checkList, confirmation, submited, comments };
}

const utils = new ViewUtils();
const useStyles = makeStyles({
  root: {
    //   width: '90%',
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  container: {
    maxHeight: 600,
    width: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 10,
  },
  pos: {
    marginBottom: 12,
  },
  icon: {
    borderRadius: '50%',
    width: 12,
    height: 12,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',

    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  table: {
    minWidth: 700,
    width: "100%"
  },
  checkedIcon: {
    backgroundColor: '#01579B',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#01579B',
    },
  },
});



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#01579B',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 10,

  },
}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    fontWeight: 400
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      height: theme.palette.action.main
    },
  },
}))(TableRow);

const CommentInput = (props) => {
  return (
    <FormControl>
      <TextField
        style={{ width: 500, padding: '10px' }}
        variant="outlined"
        onChange={(e) => props.handleComment(e)}
        multiline
        rows={1}
        rowsMax={3}
      />
    </FormControl>
  );
}



const EvaluationForm = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const [proofOfBankingDetailsComment, setproofOfBankingDetailsComment] = React.useState("")
  const [workplaceSkillsPlanComment, setworkplaceSkillsPlanComment] = React.useState("")
  const [plannedPivotalTrainingComment, setplannedPivotalTrainingComment] = React.useState("")
  const [plannedBeneficiariesofTrainingComment, setplannedBeneficiariesofTrainingComment] = React.useState("")
  const [annualTrainingReportComment, setannualTrainingReportComment] = React.useState("")
  const [actualPivotalTrainingComment, setactualPivotalTrainingComment] = React.useState("")
  const [actualBeneficiariesofTrainingComment, setactualBeneficiariesofTrainingComment] = React.useState("")
  const [proofofTrainingUploadedComment, setproofofTrainingUploadedComment] = React.useState("")
  const [authorisationPageUploadedComment, setauthorisationPageUploadedComment] = React.useState("")
  const [trainingImplementedComment, settrainingImplementedComment] = React.useState("")
  const [verificationComment, setVerificationComment] = React.useState("")
  const [evaluationComment, setEvaluationComment] = React.useState("")



  const handleComment = (event) => {
    if (event.target.value) {
      setproofOfBankingDetailsComment(event.target.value)
    }
  }
  const handleComment1 = (event) => {
    if (event.target.value) {
      setworkplaceSkillsPlanComment(event.target.value)
    }
  }

  const handleComment2 = (event) => {
    if (event.target.value) {
      setplannedPivotalTrainingComment(event.target.value)
    }
  }

  const handleComment3 = (event) => {
    if (event.target.value) {
      setplannedBeneficiariesofTrainingComment(event.target.value)
    }
  }

  const handleComment4 = (event) => {
    if (event.target.value) {
      setannualTrainingReportComment(event.target.value)
    }
  }
  const handleComment5 = (event) => {
    if (event.target.value) {
      setactualPivotalTrainingComment(event.target.value)
    }
  }

  const handleComment6 = (event) => {
    if (event.target.value) {
      setactualBeneficiariesofTrainingComment(event.target.value)
      alert("6")
    }
  }

  const handleComment7 = (event) => {
    if (event.target.value) {
      setproofofTrainingUploadedComment(event.target.value)
    }
  }

  const handleComment8 = (event) => {
    if (event.target.value) {
      setauthorisationPageUploadedComment(event.target.value)
    }
  }

  const handleComment9 = (event) => {
    if (event.target.value) {
      settrainingImplementedComment(event.target.value)
    }
  }

  const handleVerificationComment = (event) => {
    setVerificationComment(event.target.value)
  }

  const handleEvaluationComment = (event) => {
    setEvaluationComment(event.target.value)
  }





  return (
    <EntityForm ref={ref}
      viewId="organisation"
      // dataURL='api/sims/lettercreationdetails/getfind' 
      values={props.data}
      entityLookupId={props.id}
      entityLookupController={props.entityLookupController} org="saseta"
      useCustomSettings={false}
      dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
      {form =>
        <form className={form.classes.container} id="organisationDetailsForm">


          {/* {props.location.state.FinancialYearID === null ? <p align="center">PLEASE SELECT FINANCIAL YEAR</p> : */}
          <div className="col w-100">
            <TableContainer className={classes.container}>
              <Table className={classes.table} size="medium" >
                <TableHead>
                  <StyledTableRow >
                    <StyledTableCell align="left"># </StyledTableCell>
                    <StyledTableCell align="left">CheckList</StyledTableCell>
                    <StyledTableCell align="center" > Please check and confirm that the information has been completed by the organisation (Y/N)
                    </StyledTableCell>
                    <StyledTableCell align="center">Information Submitted (Y/N) </StyledTableCell>
                    <StyledTableCell align="center" >Comments</StyledTableCell>
                    <StyledTableCell align="center" >Links</StyledTableCell>
                  </StyledTableRow >
                </TableHead>
                <TableBody>

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >a. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Proof of Banking Details</StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >b. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Workplace Skills Plan</StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment1(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >c. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Planned Pivotal Training – Employees</StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment2(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >d. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  > Planned Beneficiaries of Training – Employees</StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment3(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >e. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Annual Training Report</StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment4(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >f. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Actual Pivotal Training </StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment5(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >g. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Actual Beneficiaries of Training</StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment6(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >h. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Proof of Training Uploaded</StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment7(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >i. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Authorisation Page Uploaded</StyledTableTextCell>
                    <StyledTableCell align="center" >    <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} />
                    </StyledTableCell>
                    <StyledTableCell align="center"> <SelectItem
                      dataUrl="api/sims/employer"
                      id="Do"
                      label="Yes/No"
                      required={true}
                      className="w-100"
                      form={form} /> </StyledTableCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment8(values)} /></StyledTableCell>
                  </StyledTableRow >

                  <StyledTableRow >
                    <StyledTableTextCell align="left" >j. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Training Implemented</StyledTableTextCell>
                    <StyledTableTextCell align="center" >  Training Planned (20/21): 0 </StyledTableTextCell>
                    <StyledTableTextCell align="center" >  Training Planned (20/22): 1 </StyledTableTextCell>
                    <StyledTableCell align="center" ><CommentInput handleComment={(values) => handleComment9(values)} /></StyledTableCell>
                  </StyledTableRow >

                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer className={classes.container}>
              <Table >
                <TableHead>
                  <StyledTableRow >
                    <StyledTableCell align="left"># </StyledTableCell>
                    <StyledTableCell align="left" colSpan={3}></StyledTableCell>
                    <StyledTableCell align="center" colSpan={3}>
                    </StyledTableCell>
                  </StyledTableRow >
                </TableHead>
                <TableBody>


                  <StyledTableRow >
                    <StyledTableTextCell align="left" >1. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >
                      Evaluation Status</StyledTableTextCell>
                    <StyledTableCell align="center" >
                      <SelectItem
                        dataUrl="api/sims/employer"
                        id="Do"
                        label="Yes/No"
                        required={true}
                        className="w-100"
                        form={form} />
                    </StyledTableCell>
                  </StyledTableRow >
                  <StyledTableRow >
                    <StyledTableTextCell align="left" >2. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Verification Comment</StyledTableTextCell>
                    <StyledTableCell align="center" >
                      <CommentInput handleComment={(values) => handleVerificationComment(values)} />
                    </StyledTableCell>
                  </StyledTableRow >
                  <StyledTableRow >
                    <StyledTableTextCell align="left" >3. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Evaluation Comment</StyledTableTextCell>
                    <StyledTableCell align="center" >
                      <CommentInput handleComment={(values) => handleEvaluationComment(values)} />
                    </StyledTableCell>
                  </StyledTableRow >
                  <StyledTableRow >
                    <StyledTableTextCell align="left" >4. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Evaluator</StyledTableTextCell>
                    <StyledTableTextCell align="center" >
                      Evaluator's Name
                    </StyledTableTextCell >
                  </StyledTableRow >
                  <StyledTableRow >
                    <StyledTableTextCell align="left" >5. </StyledTableTextCell>
                    <StyledTableTextCell align="left"  >Evaluation Date</StyledTableTextCell>
                    <StyledTableTextCell align="center" >
                      7/7/21
                    </StyledTableTextCell >
                  </StyledTableRow >
                  <StyledTableRow >
                    <StyledTableTextCell align="left" > </StyledTableTextCell>
                    <StyledTableTextCell align="left"  ></StyledTableTextCell>
                    <StyledTableCell align="center" >
                      <Button variant="contained" disabled={true}>
                        Save Evaluation
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow >
                </TableBody>
              </Table>
            </TableContainer>


          </div>

        </form >
      }
    </EntityForm >
  );
})

export default EvaluationForm;