import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dot: {
        alignSelf: 'stretch',
        height: '17px',
        width: '16px',
        margin: theme.spacing(1, 0),
        backgroundColor: 'grey',
        borderRadius: '50%',
        display: 'inline-block'
    },
    line: {
        height: '1px',
        width: 'calc(50% - 8px)',
        margin: "16px 0",
        backgroundColor: 'grey',
        display: 'inline-block'
    },
    spacer: {
        width: 'calc(50% - 8px)',
        display: 'inline-block'
    },
    vDot: {
        alignSelf: 'stretch',
        height: '17px',
        width: '16px',
        margin: theme.spacing(1, 0),
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '48%',
        display: 'inline-block'
    },
    visitedLine: {
        height: '1px',
        width: 'calc(50% - 8px)',
        margin: "16px 0",
        backgroundColor: theme.palette.secondary.main,
        display: 'inline-block'
    },
    text: {
        color: 'black'
    },
    vText: {
        color: theme.palette.secondary.main
    }
}));

export default function LinearFlowHeading(props) {
    const { steps } = props;
    let segmentPercentage = 100 / props.steps.length;
    const { currentIndex } = props;
    let counter = -1;
    let graphicsCounter = -1;

    const classes = useStyles();
    return (
        <div className="w-100" style={{ width: "100%" }}>
            <table cellPadding='0' border="0" width="100%">
                <tbody>
                    <tr>
                        {steps.map(step => (
                            <td key={counter} width={segmentPercentage + '%'} align='center'
                                className={++counter <= currentIndex ? classes.vText : classes. text}>
                                <Tooltip title={step.tooltip}>
                                    <span style={{ display: 'inline-block', width: '100%' }}>{step.name}</span>
                                </Tooltip>
                            </td>
                        ))}
                    </tr>
                    <tr>
                        {steps.map(step => (
                            ++graphicsCounter == 0 ?
                                <td key={graphicsCounter} width={segmentPercentage + '%'} align='center' style={{ whiteSpace: "nowrap" }}>
                                    <span className={classes.spacer}></span>
                                    <span className={classes.vDot}></span>
                                    <span className={graphicsCounter + 1 <= currentIndex ? classes.visitedLine : classes.line}></span>
                                </td>
                                :
                                <td key={graphicsCounter} width={segmentPercentage + '%'} style={{ whiteSpace: "nowrap" }}>
                                    <span className={graphicsCounter <= currentIndex ? classes.visitedLine : classes.line}></span>
                                    <span className={graphicsCounter <= currentIndex ? classes.vDot : classes.dot}></span>
                                    {graphicsCounter === steps.length - 1 ?
                                        <span className={classes.spacer}></span>
                                        :
                                        <span className={graphicsCounter + 1 <= currentIndex ? classes.visitedLine : classes.line}></span>
                                    }
                                </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
