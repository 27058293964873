import React, {Fragment} from 'react';

// Alert optional cofiguration

export const AlertStyle = {
    backgroundColor: '#dc3545',
    color: 'white',
    padding: '10px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.03) 0px 2px 2px 2px',
    fontFamily: 'Arial',
    width: 'auto',
    boxSizing: 'border-box',
    margin: '30px'
};

const alertButtonStyle = {
    marginLeft: '20px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: 'rgb(255, 255, 255)'
}

export const options = {
    position: 'top center',
    timeout: 0,
    offset: '30px',
    transition: 'scale',
    containerStyle: {
        marginTop: '260px'
    }
}
function NewlineText(msg) {
    const text = msg.text;
    console.log(text);
    return text.split('\n').map(str => <Fragment><div>{str}</div></Fragment>);
  }

export const AlertTemplate = ({ options, message, close }) => (
    <div style={AlertStyle}>
        <span className="fa fa-exclamation-circle fa-2x"></span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
            fill="none" stroke="#FF0040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            style={{ marginRight: '20px', minWidth: '24px' }}>
        </svg>

        {options.type === 'info' && ''}
        {options.type === 'success' && ''}
        {options.type === 'error' && ''}
        <div style={{display : 'block'}}>
            <NewlineText text={message}/>
        </div>
        
        <button style={alertButtonStyle} onClick={close}>X</button>
    </div>
)

export default AlertTemplate;