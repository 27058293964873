import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import FormDialog from "../FormDialog";
import { OutComeForm } from "../program/OutcomeForm";
import UnitStandardSetupSearch from "../program/UnitStandardSetupSearch";
import LinkDialog from "../LinkDialog";
import ProviderInterventionAudit from '../provider/ProviderInterventionAudit';

const headCells = [
    { id: 'SAQAUnitStandardID', numeric: false, disablePadding: false, label: 'Unit Standard ID' },
    { id: 'SAQAUnitStandardTitle', numeric: false, disablePadding: false, label: 'Unit Standard Title' },
    { id: 'Credits', numeric: false, disablePadding: false, label: 'Credits', widthRation: .05 },
    { id: 'UnitStandardRegistrationStartDate', numeric: false, disablePadding: false, label: 'Registration Start Date' },
    { id: 'UnitStandardRegistrationEndDate', numeric: false, disablePadding: false, label: 'Registration End Date' },
    { id: 'UnitStandardLastDateForEnrolment', numeric: false, disablePadding: false, label: 'Last Enrolment Date' },
    { id: 'UnitStandardLastDateForAchievement', numeric: false, disablePadding: false, label: 'Last Achievement Date' },
    { id: 'ProgramOutcome', numeric: false, disablePadding: false, label: 'Program Outcome' },
    { id: 'ModeOfDelivery', numeric: false, disablePadding: false, label: 'Mode Of Delivery' },
    { id: 'Specialisation', numeric: false, disablePadding: false, label: 'Specialisation' }
    

];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class ProviderUnitStandardSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedUnitStandard: null,
            linkDialogOpen: false,
            formDialogOpen: false,
            auditDialogOpen: false,
            searchParameters: [{ Name: "ProviderID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove Unit Standard from Provider",
            promptContent: "Are you sure you want to remove this unit standard from the provider?",
            removeMessage: "",
            removeMessageStyle: "",
            Message: "",
            showTable: true

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link Unit Standard to provider" },
                        {
                            id: 'view', label: '', icon: 'UpdateIcon', tooltip: 'Update Outcome and Mode of Delivery',
                            disabled: utils.isNull(this.state.selectedUnitStandard)
                        },
                        {
                            id: 'audit', label: '', icon: 'Auditcon', tooltip: 'View Record Audit Trail',
                            disabled: utils.isNull(this.state.selectedUnitStandard)
                        },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove Unit Standard from Provider',
                            disabled: utils.isNull(this.state.selectedUnitStandard)
                        }
                    ]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/provider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("view" === buttonId) {
            this.setState({ removeMessage: "" }, this.viewLearner());
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })

        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, selectedSiteVisit: null, auditDialogOpen: false, linkDialogOpen: false, selection: this.props.location.state.selection, selectedUnitStandard: null
            }, ()=>{
                this.setState({showTable: true})
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedUnitStandard: selection[0].Id, removeMessage: "" });
        } else {
            this.setState({ selectedUnitStandard: null, removeMessage: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedUnitStandard: null,
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeUnitStandards(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("view", true);
        })


        let fullUrl = `${url}?id=${this.state.selectedUnitStandard}&providerId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }
    viewLearner = () => {
        this.setState({ formDialogOpen: true })
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Provider > Unit Standards " + (this.props.location.state.providerName !== null ? "> " + this.props.location.state.providerName + (this.props.location.state.providerSDLNumber !== null ? " - " + this.props.location.state.providerSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable===true && (
                   
                    <EnhancedTable
                        headCells={headCells}
                            dataUrl={`api/sims/providerunitstandard/GetProviderUnitStandards`}
                        height="60vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                        )}
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Update Outcome and Mode of Delivery"}
                    viewRef={this}
                    entityId={this.state.selectedUnitStandard}
                    formRef={addFormRef}
                    saveUrl={"api/sims/providerunitstandard/UpdateUSModeAndDelivery"} usePut={true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeForm ref={addFormRef} id={this.state.selectedUnitStandard} dataURL='api/sims/providerunitstandard/GetFindUSMode' controllerName='providerunitstandard'/>
                </FormDialog>


                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedUnitStandard}
                >

                    <ProviderInterventionAudit dataUrl={"api/sims/providerunitstandard/GetProviderUnitStandardAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedUnitStandard}
                    />
                </LinkDialog>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link Unit Standards to Provider > ${this.props.location.state.providerName} - ${this.props.location.state.providerSDLNumber}`}
                    linkName={"unitStandards"} postUrl={"api/sims/providerunitstandard/LinkProviderUnitStandard"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <UnitStandardSetupSearch
                        dataUrl="api/sims/providerunitstandard/GetSearchLinkableUnitStandards"
                        mode="lookup"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.selection} programmeType="Q"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeUnitStandards("api/sims/providerunitstandard/RemoveProviderUnitStandard") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ProviderUnitStandardSearch)
);
