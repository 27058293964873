import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import ProviderSearchForm from './ProviderSearchForm';
import { ViewContainer } from "./../../View.jsx";
import CardLayout from '../../widgets/Card';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "OFOCode", numeric: false, disablePadding: true, label: "OFO Code" },
    { id: "OFODescription", numeric: false, disablePadding: true, label: "OFO Description" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select OFO Occupation from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

const searchParameters = [
    { Name: "LegalName", value: "" },
    { Name: "SDLNumber", value: "" },
    { Name: "TradeName", value: "" },
    { Name: "ProviderAccreditationNumber", value: "" },
    { Name: "AccreditationStatus", value: "" },
    { Name: "Province", value: "" }
]

class ProviderSearch extends Component {
    state = {
        model: {
            LegalName: "",
            SDLNumber: "",
            TradeName: "",
            ProviderAccreditationNumber: "",
            AccreditationStatus: "",
            Province: ""
        }
    }

    render() {
        return (
            <CardLayout title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'ETQAReport' : null}>
                
                    <ProviderSearchForm model={this.state.model} />
               
            </CardLayout>
            )
    }
}

export default withRouter(
    connect()(ProviderSearch)
);