import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { CustomTextField } from '../../../widgets/CustomTextField';

export default function ProjectSearchForm(props) {
        return (
            <EntityForm ref={React.createRef()} viewId="grant" values={props.data}
                entityLookupController={props.entityLookupController} org="saseta"
                entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
                {form =>
                    <form className={form.classes.container} id="ProjectSearchForm">
                        <div className="row search-form-container">
                            <div className="col form-col">
                                <CustomTextField
                                    id="Id"
                                    label="Id"
                                    className="w-100"
                                    onKeyPress={props.onKeyUp}
                                    InputProps={{
                                        readOnly: false,
                                    }}
                                    form={form}
                                />
                            </div>
                            <div className="col form-col">
                                <CustomTextField
                                    id="Description"
                                    label="Description"
                                    className="w-100"
                                    onKeyPress={props.onKeyUp}
                                    InputProps={{
                                        readOnly: false,
                                    }}
                                    form={form}
                                />
                            </div>
                        </div>
                    </form>
                }
            </EntityForm >
        );
}
