import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import TradeSetupSearch from "../program/Trade/TradeSetupSearch";
import LinkDialog from "../LinkDialog";
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { EditableTable } from "../EditableTable";

const toolbarRef = React.createRef();
const tableRef = React.createRef();
const utils = new ViewUtils();

const headCells = [
    { id: 'TradeTitle', numeric: false, disablePadding: false, label: 'Trade Title' },
    {
        id: 'RegistrationEndDate', numeric: false, disablePadding: false, label: 'Provider Training End Date',
        editor: {
            type: "DATE"
        }
    }
];

class ProviderTradeSearch extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedTrade: null,
            propmtOpen: false,
            promptTitle: "Remove trade from provider",
            promptContent: "Are you sure you want to remove this trade from the provider?",
            Message: "",
            MessageStyle: "",
            searchParameters: [{ Name: "ProviderID", Value: this.props.location.state.selection }],
            linkDialogOpen: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: 'Link', tooltip: 'link the selected trade to the provider', width: "60px" },
                        { id: 'remove', label: 'Remove', tooltip: 'Un-link the selected trade from the provider', disabled: utils.isNull(this.state.selectedTrades) },
                        { id: "update", label: "Update", tooltip: "Update the trade assessment end date", disabled: utils.isNull(this.state.selectedTrades) }
                    ]
                }
            ]
        };
    }


    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/Provider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true })
        }
        else if ("update" === buttonId) {
            if (!utils.isNull(this.state.selectedTrades) && this.state.TrainingEndDate !== "") {

                this.update();
                toolbarRef.current.setButtonDisabled("remove", true);
                toolbarRef.current.setButtonDisabled("update", true);
            }
        }
    }

    update() {

        let trades = this.state.selectedTrades.map((trade) => {
            return { Id: trade.Id, EditableContent: trade.RegistrationEndDate };
        })

        let data = {
            Objects: trades,
            Id: this.props.location.state.selection
        }

        let url = `api/indicium/provider/updateProviderTrades?data=${JSON.stringify(data)}`;
        utils.invokeUrl(url, this.successCallback, this.errorCallback)
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false,
                Message: ""
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false,
                Message: ""
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedTrades: selection, Message: "" },
                function () {
                    const datesToUpdate = this.state.selectedTrades.filter(trade => { return trade.TrainingEndDate !== "" });
                    toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
                    toolbarRef.current.setButtonDisabled("update", selection == null || selection.length === 0 || datesToUpdate.length === 0);
                }
            )
        } else {
            this.setState({ selectedTrades: null, Message: "" },
                function () {
                    const datesToUpdate = this.state.selectedTrades.filter(trade => { return trade.TrainingEndDate !== "" });
                    toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
                    toolbarRef.current.setButtonDisabled("update", selection == null || selection.length === 0 || datesToUpdate.length === 0);
                }
            )
        }
    }

    successCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedTrade: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase(), TrainingEndDate: "" });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    errorCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeTrades(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("update", true);
        })

        let data = {
            Trades: this.state.selectedTrades,
            Id: this.props.location.selection
        }

        let fullUrl = `${url}?data=${JSON.stringify(data)}`;

        utils.invokeUrl(fullUrl, this.successCallback, this.errorCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={`Providers > ${this.props.location.state.providerName} > Trades`}>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig}
                        ref={toolbarRef}
                    />

                    <EditableTable
                        handleSelectionChange={selection => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters}
                        id={"ProviderTrades"}
                        headCells={headCells}
                        dataUrl={"api/indicium/provider/searchTrades"}
                        ref={tableRef}
                        multiSelect={true}
                    />

                </div>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Trades to Provider: ${this.props.location.state.providerName}`}
                    linkName={"trades"} postUrl={"api/indicium/TradeSetup/linkProviderTrades"}
                    selectionId={this.props.location.state.selection}
                >
                    <TradeSetupSearch
                        dataUrl="api/indicium/provider/searchLinkableTrades"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.selection} programmeType="Q"
                        //mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeTrades("api/indicium/provider/removeProviderTrade") }}
                    closePrompt={this.closePrompt}
                />

            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ProviderTradeSearch)
);
