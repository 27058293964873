import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Barchart from '../barchart/barchart';
import Piechart from '../piechart/piechart';
import { Row } from 'react-bootstrap';
import ViewUtils from '../ViewUtils';
import CardLayout from '../widgets/Card';

const utils = new ViewUtils();
class Dashboard extends Component {
    componentDidMount() {
        utils.setLegend("Dashboard");
    }

    render() {
        return (
            <div className="container-fluid">      
              <Row>
              <Barchart />
              <Piechart />
              v1.0.3      
              </Row>
            </div>                     
        );
    }
}

export default withRouter(
    connect()(Dashboard)
);
