import React from "react";
import {useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import Menu from "./menu";
//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';
//import ListItemText from '@material-ui/core/ListItemText';
import { Scrollbars } from 'react-custom-scrollbars';
import { ListItem, ListItemText } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
//import { Route, Link } from "react-router-dom";

export default function SideBar(props) {
    const theme = useTheme();
    const classes = props.classes;
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const renderThumb = ({ style, ...props }) => {
        const thumbStyle = {
            backgroundColor: 'white'
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props} />
        );
    }

    //const wspColor = {color: '#fffff'}

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={props.handleOpenMenu}
            variant={props.variant}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx(classes.fullHeightDrawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <Scrollbars autoHide
                renderThumbVertical={renderThumb}
            >
                <Menu open={open} closeMenu={props.closeMenu} />


            </Scrollbars>

            <Divider />

            <div className={classes.toolbar}>
                <IconButton>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
        </Drawer>
    );
}
