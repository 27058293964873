import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Col, Row, Button } from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    'MuiOutlinedInput-input': {
        padding: '18.5px 100px',
    }
}));


export default function ETQSearchForm(props) {
    const [labelWidth, setLabelWidth] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (labelRef.current !== null) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, []);

    return (
        <div>
            <Row>  
                <Col md="6">
                    <FormControl fullWidth className={classes.formControl} variant="outlined">
                                <InputLabel ref={labelRef} htmlFor="LegalName">
                                    Legal Name
								</InputLabel>
                                <OutlinedInput
                                    id="LegalName"
                                    labelWidth={labelWidth}
                                />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel ref={labelRef} htmlFor="TradeName">
                            Trade Name
					    </InputLabel>
                        <OutlinedInput
                            id="SDLNumber"
                            labelWidth={labelWidth}
                            className={classes.MuiOutlinedInputInput}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel ref={labelRef} htmlFor="SDLNumber">
                            SDL Number
								</InputLabel>
                        <OutlinedInput
                            id="SDLNumber"
                            labelWidth={labelWidth}
                        />
                    </FormControl>
                </Col>
            
                <Col md="6">
                    <FormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel ref={labelRef} htmlFor="ProviderAccreditationNumber">
                            Provider Accreditation Number
								</InputLabel>
                        <OutlinedInput
                            id="ProviderAccreditationNumber"
                            labelWidth={labelWidth}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel ref={labelRef} htmlFor="AccreditationStatus">
                           Accreditation Status
								</InputLabel>
                        <OutlinedInput
                            id="AccreditationStatus"
                            labelWidth={labelWidth}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl} variant="outlined">
                        <InputLabel ref={labelRef} htmlFor="Province">
                            Province
					    </InputLabel>
                        <OutlinedInput
                            id="Province"
                            labelWidth={labelWidth}
                        />
                    </FormControl>          
                </Col>
            </Row>    
        </div>
    );
}
