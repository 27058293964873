import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import SearchView from "../../../widgets/SearchView";
import QualificationsSearchForm from './QualificationsSearchForm';
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "SAQAQualificationID", numeric: false, disablePadding: true, label: "Qualification ID" },
    { id: "SAQAQualificationTitle", numeric: false, disablePadding: true, label: "Qualification Title" }
]


class QualificationsSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters =
            [
                { Name: "SAQAQualificationID", Value: "" },
                { Name: "SAQAQualificationTitle", Value: "" }
            ];


        let model =
        {

            SAQAQualificationTitle: "",
            SAQAQualificationID: ""

        }


        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedQual: null,
            linkDialogOpen: false
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Qualifications Search' : null}>
                <SearchView
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={this.props.dataUrl}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="SAQAQualificationID"
                    SecondDescriptionProp="SAQAQualificationTitle"
                    model={this.state.model}
                    searchParameters={this.searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                    mode="lookup"
                    showToolBar={true}
                >
                    <QualificationsSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} />
                </SearchView>
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(QualificationsSearch)
);