import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms'
import { makeStyles } from '@material-ui/core/styles';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const DGRejectionReasonForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "150px !important",
                width: "600px !important"
            }
        }
    })

    return (

        <EntityForm ref={ref}
            viewId="dgrejectionreason"
            dataURL='api/sims/dgrejectionreasons/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="DGRejectionReasonForm">
                    <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                            <Tab label="Rejection Reason" {...a11yProps(0)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index={0}>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>

                                        <SelectItem
                                            dataUrl="api/sims/dgrejectionreasons/GetLookupList?listId=DGFinancialYearID"
                                            id="DGFinancialYearID"
                                            key={tabValue}
                                            label="DG Financial Year"
                                            // className={classes.MuiSelectSelectMenu}
                                            className="w-100"
                                            required={true}
                                            form={form}
                                        />

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="Name"
                                            key={tabValue}
                                            required={true}
                                            label="Name"
                                            className="w-100"
                                            form={form}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="Description"
                                            key={tabValue}
                                            required={true}
                                            label="Description"
                                            className="w-100"
                                            multiline
                                            styles={styles()}
                                            form={form}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </TabPanel>

                </form>
            }
        </EntityForm>

    );
})

