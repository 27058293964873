import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import { SelectItem } from '../../widgets/SelectItem';
import ProviderSearch from "../provider/ProviderSearch";
import { Row, Col } from 'react-bootstrap';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const ProviderDocumentSearchForm = React.forwardRef((props, ref) => {
    const [dependency, setDependency] = React.useState(props.dependency);

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    return (
        <EntityForm ref={ref} viewId="provider" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ProviderSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/providerletters/GetLookupList?listId=ProviderPrintType"
                                id="PrintTypeID"
                                required={true}
                                label="Print Type"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <LookupField
                                id="ProviderID"
                                label="Provider"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <ProviderSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/providerletters/ProviderSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("ProviderID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>

                        {/*<div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/indicium/employer/GetLookupList?listId=DocumentType"
                                id="DocumentType"
                                required={true}
                                label="Document Type"
                                className="w-100"
                                form={form}
                            />
                        </div>

                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/indicium/employer/GetLookupList?listId=ProviderAccreditationStatus"
                                id="ProviderAccreditationStatus"
                                required={true}
                                label="Accreditation Status"
                                className="w-100"
                                form={form}
                            />
                        </div>*/}

                    </div>
                </form>
            }
        </EntityForm >
    );
})

export default ProviderDocumentSearchForm;