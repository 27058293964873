import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./AETSetup.css";
import SearchView from "../../widgets/SearchView";
import AETSetupSearchForm from './AETSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import CardLayout from '../../widgets/Card';
import { ViewContainer } from "./../../View.jsx";

const utils = new ViewUtils();
const headCells = [
    { id: 'AETProgrammeCode', numeric: false, disablePadding: true, label: 'AET Programme Code' },
    { id: 'AETProgrammeDescription', numeric: false, disablePadding: true, label: 'AET Programme Description' }
];

class AETSetupSearch extends Component {

    constructor(props) {
        super(props)

        let model = {
            AETProgrammeCode: "",
            AETProgrammeDescription: ""
        }

        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "AETProgrammeCode", Value: "" },
                { Name: "AETProgrammeDescription", Value: "" }
            ]
            :
            [
                { Name: "AETProgrammeCode", Value: "" },
                { Name: "AETProgrammeDescription", Value: "" },
            ]

        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        if (!utils.isNull(props.personId)) {
            model.PersonID = props.personId;
            this.searchParameters.push({ Name: "PersonID", Value: props.personId })
        }

        this.state = {
            model: model,
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                        { id: "delete", label: '', tooltip: 'Remove AET', icon: 'DeleteIcon', disabled: true}
                    ]
                }
            ]
        }
    }

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }


    render() {
        return (
            <ViewContainer title={utils.isNull(this.props.showTitle) || this.props.showTitle === true ? 'AETs' : null}>
                <SearchView headCells={headCells} 
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/AETSetup/GetSearch"}
                    entityViewPath='/AETSetup'
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="AETProgrammeDescription" mode={this.props.mode}
                    keyUpSwitch={this.state.keyUpSwitch} history={this.props.history}
                    multiSelect={this.props.multiSelect} selectionCallbackMode={this.props.selectionCallbackMode}
                    deleteUrl={"api/sims/AETSetup/unlinkAET"}
                    promptTitle={"Remove AET"}
                    promptContent={"Removing the AET is a permanent action and the AET will not be available going forward. Are you sure you want to remove this AET?"}
                >
                    <AETSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView> 
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AETSetupSearch)
);
