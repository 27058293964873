import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import SearchView from "../../../widgets/SearchView";
import TradeSearchForm from './TradeSearchForm';
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "TradeID", numeric: false, disablePadding: true, label: "Trade ID" },
    { id: "TradeName", numeric: false, disablePadding: true, label: "Trade Name" },
    { id: "TradeCode", numeric: false, disablePadding: true, label: "Trade Code" },
]


class TradeSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters =
            [
                { Name: "TradeID", Value: "" },
                { Name: "TradeCode", Value: "" },
                { Name: "TradeName", Value: "" }

            ];


        let model =
        {
            
            TradeID: "", 
            TradeName: "",
            TradeCode: ""
        }


        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedQual: null,
            linkDialogOpen: false
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={'Trade Search'}>
                <SearchView
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/Trades/search"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    model={this.state.model}
                    descriptionProp="TradeName" 
                    SecondDescriptionProp="TradeCode"
                    searchParameters={this.searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <TradeSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} />
                </SearchView>
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(TradeSearch)
);