import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import "../View.css";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import CustomMaterialTable from "./Table.js";

const columns = [];

class TestTableDemo extends Component {

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={`Test Table Demo`}>
                <CustomMaterialTable
                    columns={columns}
                    paged={true}
                    rowsPerPage={5}
                />
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(TestTableDemo)
);
