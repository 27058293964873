import React from 'react';
import ViewUtils from '../ViewUtils';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { BaseField } from "./BaseField";

export const LookupFieldComponent = React.forwardRef((props, ref) => {
    const [currentSelection, setCurrentSelection] = React.useState(null);
    const [value, setValue] = React.useState(props.base.valueObject[props.base.valueProperty]);
    const utils = new ViewUtils();
    const [open, setOpen] = React.useState(false);
    const base = props.base;

    React.useEffect(() => {
        if (props.base.valueObject[props.base.valueProperty] != null && props.valueMode !== "ID") {
            props.base.valueObject[props.base.valueProperty].toString = () => { return props.base.valueObject[props.base.valueProperty].Description };
        }

        setValue(props.base.valueObject[props.base.valueProperty]);
    }, [props.base.valueObject[props.base.valueProperty]]);

    const handleClose = () => {
        setOpen(false);
    };
    
    const setSelectionAsValue = () => {
        props.form.doHandleChange(props.id, props.valueMode === "ID" ? currentSelection.Id : currentSelection);
        handleClose();
    };

    const openSearch = () => {
        setOpen(true);
    }

    React.useImperativeHandle(base.reference, () => ({
        validate: (values, currentValue) => {
            return base.doValidation(values, currentValue);
        },
        setSelection: (selection) => {
            setCurrentSelection(selection);
            setValue(selection);
        },
        parseValue: (value) => {
            return value;
        },
        getId: () => {
            return props.id;
        },
        getValidationGroup: () => {
            return props.validationGroup;
        }
    }));

    return (
        <div className="w-100 lookup">
            <table className="w-100">
                <tbody>
                    <tr>
                        <td width="90%">
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                id={props.id}
                                style={{ minWidth: "200px" }}
                                ref={base.reference}
                                required={base.required}
                                disabled={props.disabled}
                                label={props.label}
                                value={value || ''}
                                className={props.className}
                                error={base.hasError}
                                helperText={base.errorMessage}
                                size="small"
                                margin="normal"
                                variant="outlined">
                            </TextField>
                        </td>
                        <td valign="middle" align="left">
                            <IconButton
                                onClick={openSearch}
                                disabled={props.disabled}
                                aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}>
                <div >
                    <div style={{ height: "auto", overflow: "auto" }}>
                        {props.children}
                    </div>
                    <div style={{ paddingTop: "4px" }}>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={setSelectionAsValue} color="primary" disabled={utils.isNull(currentSelection)}>
                                Ok
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
  );
})

export function LookupField(props) {
    return (
        <BaseField ref={React.createRef()} form={props.form} required={props.required} id={props.id} className={props.className}
            validator={props.validator} validationRegex={props.validationRegex} validationGroup={props.validationGroup}
            validationMessage={props.validationMessage} parentId={props.parentId}>
            {base =>
                <LookupFieldComponent
                    id={props.id}
                    base={base}
                    InputProps={props.InputProps}
                    valueMode={props.valueMode}
                    required={props.required || false}
                    disabled={props.disabled}
                    validationGroup={props.validationGroup}
                    label={props.label}
                    className={props.className}
                    readOnly={props.readOnly}
                    parentId={props.parentId}
                    children={props.children}
                    form={props.form}>
                </LookupFieldComponent>
            }
        </BaseField>
    );
}