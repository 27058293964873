import React, { useState, useEffect, useRef } from 'react';
import { BaseField } from "./BaseField"
import TextField from '@material-ui/core/TextField';
import { min } from 'date-fns';

let autoComplete;
var componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    sublocality_level_1: 'long_name',
    locality: 'long_name',
    administrative_area_level_2: 'short_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
};

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    }
    else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}

function isMyScriptLoaded(url) {
    if (!url) url = "missingURL";
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
        if (scripts[i].src == url) return true;
    }
    return false;
}

function handleScriptLoad(updateQuery, controlID, updateGPSAddress, form) {
    const input = document.getElementById(controlID);
    autoComplete = new window.google.maps.places.Autocomplete(
        input,
        { types: ["geocode"] }
    );

    autoComplete.setFields(['address_component', 'geometry.location']);

    autoComplete.addListener("place_changed", () => {
        handlePlaceSelect(updateQuery, controlID, updateGPSAddress, form);
    });

    geolocate();
}

function handlePlaceSelect(updateQuery, controlID, updateGPSAddress, form) {
    const input = document.getElementById(controlID);
    updateQuery(input.value);

    const place = autoComplete.getPlace();
    let address = {}
    for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
            if (addressType == "street_number") {
                address.streetnumberpar = place.address_components[i][componentForm[addressType]];
            }
            else if (addressType == "route") {
                address.routepar = place.address_components[i][componentForm[addressType]];
            }
            else if (addressType == "sublocality_level_1") {
                address.sublocalitylevel1par = place.address_components[i][componentForm[addressType]];
            }
            else if (addressType == "locality") {
                address.localitypar = place.address_components[i][componentForm[addressType]];
            }
            else if (addressType == "administrative_area_level_2") {
                address.adminarealevel2par = place.address_components[i][componentForm[addressType]];
            }
            else if (addressType == "administrative_area_level_1") {
                address.adminarealevel1par = place.address_components[i][componentForm[addressType]];
            }
            else if (addressType == "country") {
                address.countrypar = place.address_components[i][componentForm[addressType]];
            }
            else if (addressType == "postal_code") {
                address.postalcodepar = place.address_components[i][componentForm[addressType]];
            }
        }
    }

    var latitude = place.geometry.location.lat();
    var longitude = place.geometry.location.lng();

    address.gpscoordinates = convertDMS(latitude, longitude);

    updateGPSAddress(address, form);
}

function toDegreesMinutesAndSeconds(coordinate) {

    const dms = {};

    var degrees = Math.trunc(coordinate);
    var minutes = Math.trunc((coordinate - degrees) * 60);
    var seconds = (((coordinate - degrees) * 60) - minutes) * 60;
    if (degrees < 0) {
        dms.degrees = degrees * -1;
        dms.minutes = minutes * -1;
        dms.seconds = Math.trunc(seconds * -1);
        dms.milliseconds = Number(String((seconds * -1).toFixed(3)).split('.')[1] || 0);
    }
    else {
        dms.degrees = degrees;
        dms.minutes = minutes;
        dms.seconds = Math.trunc(seconds);
        dms.milliseconds = Number(String((seconds).toFixed(3)).split('.')[1] || 0);
    }

    return dms;

    //var absolute = Math.abs(coordinate);
    //var degrees = Math.floor(absolute);
    //var minutesNotTruncated = (absolute - degrees) * 60;
    //var minutes = Math.floor(minutesNotTruncated);
    //var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    //return degrees + " " + minutes + " " + seconds;
}

function convertDMS(lat, lng) {
    var latitude = toDegreesMinutesAndSeconds(lat);
    //var latitudeCardinal = lat >= 0 ? "N" : "S";

    var longitude = toDegreesMinutesAndSeconds(lng);
    //var longitudeCardinal = lng >= 0 ? "E" : "W";

    //return latitude + " " + latitudeCardinal + "\n" + longitude + " " + longitudeCardinal;
    const gpscoordinates = {};
    gpscoordinates.latitude = latitude;
    gpscoordinates.longitude = longitude;
    gpscoordinates.coordinate = "-" + pad(latitude.degrees,2) + "," + pad(latitude.minutes,2) + ","
        + pad(latitude.seconds, 2) + "." + pad(latitude.milliseconds, 3) + " " + pad(longitude.degrees, 2) + ","
        + pad(longitude.minutes, 2) + "," + pad(longitude.seconds, 2) + "." + pad(longitude.milliseconds, 3);

    return gpscoordinates;
}

function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

function geolocate() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            var geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            // var circle = new window.google.maps.Circle({
            //     center: geolocation,
            //     radius: position.coords.accuracy
            // });
            autoComplete.setBounds(geolocation);
        });
    }
}

const GPSCoordinatesComponent = React.forwardRef((props, ref) => {
    const [query, setQuery] = useState("");

    useEffect(() => {
        const url = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCrq4Fws6p-Tye07zFZh2_Nz34YbMaFE1c&libraries=places`;
        if (!isMyScriptLoaded(url)) {
            loadScript(url,
                () => handleScriptLoad(setQuery, props.id, props.updateGPSAddress, props.form)
            );
        } else {
            handleScriptLoad(setQuery, props.id, props.updateGPSAddress, props.form);
        }
    }, []);

    const handleChange = (id) => event => {
        let value = event.target.value;
        setQuery(value);
    };

    return (
        //<div className="search-location-input">
        //    <input
        //        ref={autoCompleteRef}
        //        onChange={event => setQuery(event.target.value)}
        //        placeholder="Enter Address"
        //        value={query}
        //    />
        //</div>
        <div>
            <TextField
                id={props.id}
                InputProps={props.InputProps}
                ref={props.base.reference}
                required={props.base.required}
                label={props.label}
                value={query}
                className={props.multiline ? props.styles.TextArea : props.className}
                onChange={handleChange(props.id)}
                error={props.base.hasError}
                helperText={props.base.errorMessage}
                margin="normal"
                disabled={props.disabled}
                size="small"
                variant="outlined">
            </TextField>
        </div>
    );
})

export function GPSCoordinatesAPI(props) {

    return (
        <BaseField ref={React.createRef()} form={props.form} required={props.required} id={props.id} className={props.className}
            validator={props.validator} validationRegex={props.validationRegex} validationGroup={props.validationGroup}
            validationMessage={props.validationMessage} parentId={props.parentId}>
            {base =>
                <GPSCoordinatesComponent
                    id={props.id}
                    base={base}
                    InputProps={props.InputProps}
                    required={props.required}
                    label={props.label}
                    className={props.className}
                    readOnly={props.readOnly}
                    form={props.form}
                    styles={props.styles}
                    disabled={props.disabled}
                    value={props.value}
                    updateGPSAddress={props.updateGPSAddress}
                    form={props.form}
                >
                </GPSCoordinatesComponent>
            }
        </BaseField>
    );
}