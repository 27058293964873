import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import LearnershipSetupSearch from '../program/LearnershipSetupSearch';
import AlertItem from "../../widgets/AlertItem";
import AssessorInterventionAudit from '../assessor/AssessorInterventionAudit';
import FormDialog from "../FormDialog";
import { OutComeStatusForm } from "../common/OutcomeStatusForm";

export const learnershipHeadCells = [
    { id: 'LearnershipCode', numeric: false, disablePadding: true, label: 'Learnership ID' },
    { id: 'LearnershipTitle', numeric: false, disablePadding: true, label: 'Learnership Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'LearnershipRegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'LearnershipRegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' },
    { id: 'Outcome', numeric: false, disablePadding: true, label: 'Outcome' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
   
]

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class AssessorLearnershipSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                LearnershipCode: "",
                LearnershipTitle: "",
                AssessorID: this.props.location.state.AssessorID
            },
            showTable: true,
            linkDialogOpen: false,
            updateDialogOpen: false,
            formDialogOpen: false,
            auditDialogOpen: false,
            selectedLearnership: null,
            propmtOpen: false,
            promptTitle: "Unlink Learnership From assessor",
            promptContent: "Are you sure you want to unlink this learnership from the assessor?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "AssessorID", Value: this.props.location.state.AssessorID}]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Learnership/s to the assessor', icon: 'LinkIcon' },
                        { id: 'update', label: '', tooltip: 'Update the status and outcome of the selected learnership', disabled: utils.isNull(this.state.selectedLearnership), icon: 'UpdateIcon' },
                        { id: 'audit', label: '', tooltip: 'View Record Audit Trail', disabled: utils.isNull(this.state.selectedLearnership), icon: 'AuditIcon' },
                        { id: 'unlink', label: 'Unlink learnership', tooltip: 'Un-link selected learnership from the assessor', disabled: utils.isNull(this.state.selectedLearnership), icon: 'LinkOffIcon' },
                        { id: 'unitstandards', label: 'Unit Standards', tooltip: 'link selected unit standard(s) to the currently linked learnership', disabled: utils.isNull(this.state.selectedLearnership) }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/assessor',
                state: { selection: this.props.location.state.AssessorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("update" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("unitstandards" === buttonId) {
            this.props.history.push({
                pathname: '/assessorLearnershipUnitStandards',
                state: {
                    AssessorLearnershipID: this.state.selectedLearnership,
                    AssessorID: this.props.location.state.AssessorID,
                    AssessorName: this.props.location.state.AssessorName,
                    AssessorIDNo: this.props.location.state.AssessorIDNo
                }
            })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedLearnership: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkLearnership(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedLearnership}&assessorId=${this.props.location.state.AssessorID}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                linkDialogOpen: false, selectedLearnership: null,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedLearnership: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        } else {
            this.setState({
                selectedLearnership: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("unitstandards", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, selectedSiteVisit: null, auditDialogOpen: false, linkDialogOpen: false, selection: this.props.location.state.selection, selectedSkillsProgramme: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    render() {
        return(
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Assessor > Learnerships" + (this.props.location.state !== null ? " > " + this.props.location.state.AssessorName + " - " + this.props.location.state.AssessorIDNo : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={learnershipHeadCells}
                            dataUrl={`api/sims/assessorlearnership/GetAssessorLearnerships`}
                            height="50vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Update Outcome and Status"}
                    viewRef={this}
                    entityId={this.state.selectedUnitStandard}
                    formRef={addFormRef}
                    usePut={true}
                    saveUrl={"api/sims/assessorlearnership/UpdateLearnershipOutcomeStatus"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeStatusForm ref={addFormRef} id={this.state.selectedLearnership} dataURL='api/sims/assessorlearnership/GetFindLearnershipOutcomeStatus' controllerName='assessorlearnership' />
                </FormDialog>

                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedLearnership}
                >

                    <AssessorInterventionAudit dataUrl={"api/sims/assessorlearnership/GetAssessorLearnershipAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedLearnership}
                    />
                </LinkDialog>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.LearnershipTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Learnership to Assessor: ${this.props.location.state.AssessorName}`}
                    linkName={"Learnerships"} postUrl={"api/sims/assessorlearnership/LinkAssessorLearnership"} usePost={true}
                    selectionId={this.props.location.state.AssessorID}
                >
                    <LearnershipSetupSearch //TODO: Enter dataurl prop for linkable Learnerships
                        dataUrl={"api/sims/assessorlearnership/GetSearchLinkableLearnerships"} allowClear={true}
                        multiSelect={true} showTitle={false} customMessage={"No Learnerships with this search criteria have been found."}
                        programmeId={this.props.location.state.AssessorID} programmeType="Q"
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkLearnership(`api/sims/assessorlearnership/RemoveAssessorLearnership`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}


export default withRouter(
    connect()(AssessorLearnershipSearch)
);