import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

export default function RequestExtensionForm(props) {
    const [labelWidth, setLabelWidth, tabValue] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (labelRef.current !== null) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, []);

    return (
        <EntityForm viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={classes.container} >
                    <table cellPadding="2">
                        <tbody>
                            <tr>
                                <td>
                                    <FormControl className={classes.formControl} variant="outlined">
                                        <SelectItem
                                            dataUrl="api/sims/RequestExtension/GetLookupList?listid=FinancialYear"
                                            id="FinancialYear"
                                            key={tabValue}
                                            label="Financial Year"
                                            className={classes.MuiSelectSelectMenu}
                                            form={form}
                                        />
                                    </FormControl>
                                </td>
                                <td>

                                </td>
                            </tr>

                        </tbody>
                    </table>
                </form >
            }
        </EntityForm>
    );
}
