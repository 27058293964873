import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Organisation.css";
import { OrganisationBankingDetailsForm } from './OrganisationBankDetailsForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();

const formRef = React.createRef();

class OrganisationBankingDetails extends Component {

    state = {
        organisationDetails: {
            LegalName: "",
        },
        message: "",
        messageStyle: "",
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
        counter: 0
    };

    toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            }
            ,
            {
                type: "devider"
            },
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add }
                ]
            }
        ]
    };

    save = () => {
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }
            formValue.OrganisationID = this.state.organisationID;

            if (isAdd) {
                const url = `api/sims/organisationbankdetail/addbankdetail`;
                utils.saveForm(this, formRef, url, (response) => {
                    this.setState({ id: response.EntityId });
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.errorCallback);
            } else {
                const url = `api/sims/organisationbankdetail/updatebankdetail`;
                utils.updateForm(this, formRef, url, (response) => {
                    this.setState({ id: response.EntityId});
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.errorCallback);
            }
        }
    }

    successCallback(response) {
        this.setState({
            message: response.Message, messageStyle: response.MessageType, counter: this.state.counter + 1
        });

    }
    errorCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    handleFormValueChange(values) {
        this.setState({
            organisationDetails: { 
                values
            },
        });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else {
            this.close();
        }
    }


    close() {
        this.props.history.push({
            pathname: '/skillsOrganisation',
            state: { selection: this.props.location.state.organisationID }
        });
    }

    dataArrivedHandler = (values) => {
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] });
        }
    }


    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
            <div className="view w-100 h-100">
                <p className="breadcrumbs">
                    {"Organisation > Banking Details " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="formContainer">
                                <OrganisationBankingDetailsForm key={this.state.counter}
                                        ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.state.organisationID : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} dataArrivedHandler={(values) => this.dataArrivedHandler(values)} refreshId={this.state.refreshId} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(OrganisationBankingDetails));