import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LinearFlowHeading from '../../widgets/LinearFlowHeading';
import AddPersonSearch from './AddPersonSearch';
import AddPersonDetails from './AddPersonDetails';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';

const steps = [
    { name: "Identity Details", tooltip: "Search the system for an existing person. If one does not extist, click proceed to create a new person" },
    { name: "Person Details", tooltip: "Capture person details" },
];

const utils = new ViewUtils();
class AddPersonFlow extends Component {
    state = {
        currentStepIndex: 0,
        model: {},
        personDetails: {},
        documents: {},
        program: {},
        saveMessage: "",
        hasSaveError: false
    }

    stepHandler(index, forward, data) {
        if (index === 0) {
            this.setState({ personDetails: data });
        }

        if (forward) {
            let dataObject = {};
            dataObject.PersonDto = this.state.personDetails;

            if (!utils.isNull(this.state.personDetails)) {
                dataObject.PersonDto.PersonID = this.state.personDetails.ID;
            }

            let input = JSON.stringify(dataObject);

            const url = `api/indicium/learner/addPerson?data=${input}`;
            let learnerData = {};
            learnerData.data = input;

            utils.invokeUrl("api/indicium/learner/addLearner", (data) => {
                this.props.history.push({
                    pathname: '/learners'
                })
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(learnerData)
                });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            //save();
        } else {
            //close();
        }
    }

    componentDidMount() {
        utils.setLegend("Add Person");
    }

    render() {
        return (
            <div>
                <LinearFlowHeading steps={steps} currentIndex={this.state.currentStepIndex} />
                {this.state.currentStepIndex === 0 ? <AddPersonSearch stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                {this.state.currentStepIndex === 1 ? <AddPersonDetails data={this.state.personDetails} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
            </div>
        );
    }
}

export default withRouter(
    connect()(AddPersonFlow)
);
