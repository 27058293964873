import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { withAlert } from 'react-alert'
//import { actionCreators } from '../../store/SecurityStore';
import * as actions from '../../store/actions/auth';
import "./Login.css";
import simslogoLoading from '../../content/images/logos/sims_logo_loading.png'
import LoadingIndicatorLogin from '../LoadingIndicatorLogin';
import ViewUtils from "../ViewUtils"; import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";


//const handler = (state) => {
//    let isLoading = state.security.isLoading;
//    let validdetails = state.security.validdetails;
//    return {
//        isLoading: isLoading,
//        validdetails: validdetails,
//        Valid: validdetails.Valid,
//        message: validdetails.Message,
//    };
//};

const utils = new ViewUtils();

export class Reset extends Component {

    state = {
        Currentpassword: "",
        Newpassword: "",
        Confirmpassword: "",
        Valid: false,
        message: "",
        display: "none",
        loading: true,
        passwordShown1: false,
        passwordShown2: false,
        passwordShown3: false
    };

    validateCurrentPassword() {
        if (this.state.Currentpassword !== undefined) {
            return this.state.Currentpassword.length > 0;
        }
    }

    validateNewPassword() {
        if (this.state.Newpassword !== undefined) {
            return this.state.Newpassword.length > 0;
        }
    }

    validateConfirmPassword() {
        if (this.state.Confirmpassword !== undefined) {
            return this.state.Confirmpassword.length > 0;
        }
    }

    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 20000, // custom timeout just for this one alert
            type: msgType,
            onOpen: () => {
            },
            onClose: () => {
            }
        })
    }

    submit = () => {

        if (!this.validateCurrentPassword()) {
            this.setState({ message: "Enter current password", display: "" });
            this.showAlert('Enter current password', 'error');
            return;
        }

        if (!this.validateNewPassword()) {
            this.setState({ message: "Enter new password", display: "" });
            this.showAlert('Enter new password', 'error');
            return;
        }

        if (!this.validateConfirmPassword()) {
            this.setState({ message: "Enter password confirmation", display: "" });
            this.showAlert('Enter password confirmation', 'error');
            return;
        }

        if (this.state.Newpassword !== this.state.Confirmpassword) {
            this.setState({ message: "Passwords do not match", display: "" });
            this.showAlert('Passwords do not match', 'error');
            return;
        }

        //let urlElements = window.location.pathname.split('/');
        //let urlElelement = (urlElements[2]);
        //this.props.sendPasswordResetData(this.state.ConfirmPassword, urlElelement, 't', 't');
        //this.showAlert('Password Updated Successfully', 'success');
        //this.props.history.push('/login');
        this.props.onAuthResetPassword(this.props.userDetails.Username, this.state.Currentpassword, this.state.Newpassword, this.state.Confirmpassword);
    }

    componentDidUpdate(prevProps) {

        //if (this.props.Valid === true) {
        //    }
        //    else {
        //    this.props.history.push('/login');
        //    }
        if (prevProps.passwordReset !== this.props.passwordReset && this.props.passwordReset) {
            clearTimeout(this.props.userLoginTimer);
            this.props.history.push('/login');
        }

        if (prevProps.loginRedirectToLogin !== this.props.loginRedirectToLogin && this.props.loginRedirectToLogin) {
            this.props.history.push('/login');
        }

        if (prevProps.loginErrorMessage !== this.props.loginErrorMessage && this.props.loginErrorMessage) {
            this.showAlert(this.props.loginErrorMessage, 'error');
        }

        if (prevProps.loginMessage !== this.props.loginMessage && this.props.loginMessage) {
            this.showAlert(this.props.loginMessage, 'error');
        }
    }

    // Password toggle handler
    togglePassword1 = () => {
        // chnage inverse the boolean state passwordShown
        if (this.state.passwordShown1 === false) {
            this.setState({ passwordShown1: true })
        }
        else {
            this.setState({ passwordShown1: false })
        }
    };
    togglePassword2 = () => {
        // chnage inverse the boolean state passwordShown
        if (this.state.passwordShown2 === false) {
            this.setState({ passwordShown2: true })
        }
        else {
            this.setState({ passwordShown2: false })
        }
    };
    togglePassword3 = () => {
        // chnage inverse the boolean state passwordShown
        if (this.state.passwordShown3 === false) {
            this.setState({ passwordShown3: true })
        }
        else {
            this.setState({ passwordShown3: false })
        }
    };

    //componentDidMount() {
    //    demoAsyncCall().then(() => this.setState({ loading: false }));
    //    let urlElements = window.location.pathname.split('/');
    //    let urlElelement = (urlElements[2])
    //    this.props.TokenValid(urlElelement,'local','192.168.2.32');
    //}

    render() {

        return (
            <div className="login-container">
                <div style={{ marginTop: '-300px' }} className="card-container sec-card">
                    <img src={simslogoLoading} className="img-responsive" />
                    <p>Your Password has expired.</p><p>Please enter new password</p>
                    <form onSubmit={this.handleSubmit}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon">
                                    <i className="fa fa-lock prefix"></i>
                                </span>
                            </div>
                            <Input
                                value={this.state.Currentpassword}
                                onChange={(ev) => this.setState({ Currentpassword: ev.target.value })}
                                type={this.state.passwordShown1 ? "text" : "password"} className="form-control"
                                placeholder="Current Password" aria-label="Password"
                                aria-describedby="basic-addon"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.togglePassword1}
                                            edge="end"
                                        >
                                            {this.state.passwordShown1 ? <VisibilityIcon /> : <VisibilityOff />}
                                        </IconButton>

                                    </InputAdornment>
                                }
                            />
                        </div>
                        <br />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon">
                                    <i className="fa fa-lock prefix"></i>
                                </span>
                            </div>
                            <Input
                                value={this.state.Newpassword}
                                onChange={(ev) => this.setState({ Newpassword: ev.target.value })}
                                type={this.state.passwordShown2 ? "text" : "password"} className="form-control"
                                placeholder="New Password" aria-label="Password"
                                aria-describedby="basic-addon"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.togglePassword2}
                                            edge="end"
                                        >
                                            {this.state.passwordShown2 ? <VisibilityIcon /> : <VisibilityOff />}
                                        </IconButton>

                                    </InputAdornment>
                                }
                            />
                        </div>
                        <br />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon">
                                    <i className="fa fa-lock prefix"></i>
                                </span>
                            </div>
                            <Input
                                value={this.state.Confirmpassword}
                                onChange={(ev) => this.setState({ Confirmpassword: ev.target.value })}
                                type={this.state.passwordShown3 ? "text" : "password"} className="form-control"
                                placeholder="Confirm Password" aria-label="Password"
                                aria-describedby="basic-addon"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.togglePassword3}
                                            edge="end"
                                        >
                                            {this.state.passwordShown3 ? <VisibilityIcon /> : <VisibilityOff />}
                                        </IconButton>

                                    </InputAdornment>
                                }
                            />
                        </div>
                        <br />
                        <Button
                            block
                            onClick={this.submit}
                            className="loginButton"
                        >
                            Submit
                    </Button>
                    </form>
                </div>
                <div className="loginCopyright">
                    @ {new Date().getFullYear()} Copyright SIMS. All rights reserved. Developed By SoluGrowth
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>
        )
    }
}

//function demoAsyncCall() {
//    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
//}

//export default withRouter(
//    connect(handler,
//        dispatch => bindActionCreators(actionCreators, dispatch)
//    )(withAlert()(Reset)))

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        userDetails: state.auth.userDetails,
        loginMessage: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Message : null,
        loginErrorMessage: state.auth.errorMessage,
        loginRedirectToLogin: state.auth.redirectToLogin,
        userLoginTimer: state.auth.userLoginTimer,
        passwordReset: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.PasswordResetDone : false
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthResetPassword: (Username, CurrentPassword, NewPassword, ConfirmPassword) => dispatch(actions.authResetPassword(Username, CurrentPassword, NewPassword, ConfirmPassword))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(Reset)));