import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();
export const ProviderSiteVisitForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
<EntityForm ref={ref} viewId="providerAddVisit" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
    {form =>
    <form className={form.classes.container} id="providerSiteVisit">
        <table cellPadding="2" className="w-100">
            <tbody>
                            <tr>

                                <CustomDatePicker
                                    parentId={0}
                                    id="SiteVisitDate"
                                    key={tabValue}
                                    label="Site Visit Date"
                                    className='{classes.textField} w-100'
                                    required={true}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    form={form}
                                />
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="EvaluatorName"
                                        key={tabValue}
                                        label="Evaluator Name"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                </tr>
                <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=ReasonForSiteVisit"
                                        parentId={0}
                                        id="ReasonForSiteVisitID"
                                        required={true}
                                        key={tabValue}
                                        label="Reason For Site Visit"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=Outcomes"
                                        parentId={0}
                                        id="OutcomesID"
                                        required={true}
                                        key={tabValue}
                                        label="Outcomes"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                </tr>
            </tbody>
        </table>
    </form>
    }
</EntityForm>
    );
})
