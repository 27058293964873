import React from 'react';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./LearnerDocuments.css"
import EnhancedTable from '../../widgets/ResponsiveTable';

const headCells = [
    { id: 'HighestEducationLevel', numeric: false, disablePadding: true, label: 'Highest Education Level' },
    { id: 'InstitutionName', numeric: false, disablePadding: true, label: 'Institution Name' },
    { id: 'EducationTitle', numeric: false, disablePadding: true, label: 'Education Title' },
    { id: 'UploadedBy', numeric: false, disablePadding: true, label: 'Uploaded By' },
    { id: 'UploadDate', numeric: false, disablePadding: true, label: 'Upload Date' },
    { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' }
]

export default function EducationalHistoryDocuments(props, ref) {
    return (
        <EnhancedTable headCells={headCells} onCellSelectionChange={(rowid, columnid) => { props.handleCell(rowid, columnid) }} searchParameters={[{ Name: `${props.moduleName}ID`, Value: props.selection }]} paged={false}
            dataUrl={props.documentsDataUrl !== null ? props.documentsDataUrl : null} 
        />
    );
}
