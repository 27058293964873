import React from 'react';
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./CRM.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';

const headCells = [
    { id: 'EmailSentBy', numeric: false, disablePadding: true, label: 'Email Sent By' },
    { id: 'ToAddress', numeric: false, disablePadding: true, label: 'To Address' },
    { id: 'FromAddress', numeric: false, disablePadding: true, label: 'From Address' },
    { id: 'Subject', numeric: false, disablePadding: true, label: "Subject" },
    { id: "Body", numeric: false, disablePadding: true, label: "Body" },
    { id: "MessageStatus", numeric: false, disablePadding: true, label: "Message Status" },
    { id: "DateSent", numeric: false, disablePadding: true, label: "Date Sent" },
    { id: "DocumentName", numeric: false, disablePadding: true, label: "Document Name", contentType: "document"}
]

export default function Emails(props, ref) {
    return (
        
        <EnhancedTable headCells={headCells} onCellSelectionChange={(rowid, columnid) => { props.handleCell(rowid, columnid) }}
                    searchParameters={
                        [
                            {Name: `${props.moduleName}ID`, Value: props.selection }, 
                            {Name: 'EmailAddress', Value: props.Email}, 
                            {Name: 'Subject', Value: props.Subject}
                        ]
                    }
                    paged={true}
                    dataUrl={props.emailsDataUrl}
            />
    
    );
}
