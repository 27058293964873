import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import ViewUtils from "../ViewUtils";

const utils = new ViewUtils();

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function AlertItem(props) {
    const classes = useStyles();
    const [message, setMessage] = React.useState(props.message);
    const [alertStyle, setAlertStyle] = React.useState(props.alertStyle);
    const [title, setTitle] = React.useState(props.alertTitle)

    React.useEffect(() => {
        setMessage(props.message);
        setAlertStyle(props.alertStyle)
        setTitle(props.alertTitle)
    }, [props.message, props.alertStyle, props.alertTitle]);

    function NewlineText(msg) {
        const text = msg.text;
        return text.split('\n').map(str => <p>{str}</p>);
      }

    return (
        <div className={classes.root}>
            {!utils.isNull(message) && message.trim().length > 0 ?
                <Alert severity={alertStyle.toLowerCase()}>
                    <AlertTitle> {title} </AlertTitle>
                    <NewlineText text={message}/>
                </Alert>
                :
                ""
            }
        </div>
    );
}