import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from "./../../ViewUtils";
import { SelectItem } from '../../widgets/SelectItem';

const utils = new ViewUtils();

export const DGReportForm = React.forwardRef((props, ref) => {

    const paramArray = !utils.isNull(props.dgReportDetails) && !utils.isNull(props.dgReportDetails.ReportParameters) ? props.dgReportDetails.ReportParameters.split(",") : [];

    const getName = (name) => {
        let text = name.slice(0, -2)
        let newText = [];
        newText.push(text[0]);

        for (var i = 1; i < text.length; i++) {
            if (checkUppercase(text[i]) && !checkUppercase(text[i - 1]) && !checkPuctuation(text[i - 1])) {
                newText.push(' ');
            }
            else if (checkDigit(text[i]) && checkLetter(text[i - 1])) {
                newText.push(' ');
            }
            else if (i < text.length - 1) {
                if (checkUppercase(text[i]) && (!checkUppercase(text[i + 1]) && text[i + 1] !== " " && text[i + 1] !== ")" && text[i + 1] !== "'") && !checkPuctuation(text[i - 1])) {
                    newText.push(' ');
                }
            }

            newText.push(text[i]);
        }
        return newText.join("").replace("  ", " ");
    }

    const checkUppercase = (value) => {
        if (value === value.toUpperCase()) {
            return true;
        } else {
            return false;
        }
    }

    const checkPuctuation = (value) => {
        if (value === ".") {
            return true;
        }
        else {
            return false;
        }
    }

    const checkDigit = (value) => {
        if (Number.isInteger(value)) {
            return true;
        }
        else {
            return false;
        }
    }

    const checkLetter = (str) => {
        return str.length === 1 && str.match(/[a-z]/i);
    }

    return (
        <EntityForm ref={ref} viewId="DGGrantReports" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="DGGrantReportForm">
                    <div className="row search-form-container">
                        {
                            paramArray.map(parameter => {

                                let field;

                                if (parameter.slice(-2).includes("ID")) {
                                    field = (
                                        <div key={parameter} className="col form-col">
                                            <SelectItem
                                                dataUrl={"api/sims/DGGrantReportsDetails/GetLookupList?listId=" + parameter}
                                                id={parameter}
                                                label={getName(parameter)}
                                                className="w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                            />
                                        </div>
                                    );
                                }

                                return field;
                            })
                        }
                    </div>
                </form >
            }
        </EntityForm >
    );
})