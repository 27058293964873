import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerInternshipForm } from '../../etqa/program/registration/LearnerInternshipForm';

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
            ]
        }
        ,
        {
            type: "devider"
        },
        {
            type: "buttonGroup",
            buttons: [
                { id: 'keep', label: '', tooltip: 'Save', icon: 'SaveIcon'}
            ]
        }
    ]
};

const InternshipRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerInternship extends Component {

    state = {
        InternshipDetails: {
            SAQAQualificationTitle: "",
            SAQAQualificationID: ""
        },
        messageStyle: "message",
        id: null,
        showTable: true,
        CurrentProgramme: this.props.location.state.InternshipID,
        CreateNewFlag: this.props.location.state.hideFields,
        Learner: this.props.location.state.LearnerID
    };


    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            if (!utils.isNull(values.InternshipStartDate)) {
                utils.autoSetDate(this, InternshipRef, values.InternshipStartDate, 1, 0, 0, "InternshipEndDate", "InternshipStartDate")
            }


            this.setState({
                InternshipSetupDetails: {
                    SAQAQualificationID: values.SAQAQualificationID,
                    SAQAQualificationTitle: values.SAQAQualificationTitle
                },
                Learner: this.props.location.state.LearnerID
            });
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("keep" === buttonId) {
            this.keep();
        } else if ("close" === buttonId) {
            this.closeView();
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    keep() {
        let formValue = InternshipRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerinternship/LinkLearnerInternship", (response) => {
                this.successCallback(response);
            },() => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {

        this.props.history.push({
            pathname: '/LearnerInternshipsSearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New Internship > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update Internship > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                    <LearnerInternshipForm ref={InternshipRef}
                                        viewRef={this}
                                            id={this.state.CurrentProgramme}
                                            editPermission={this.props.location.state.permissions}
                                            viewOnly={this.props.location.state.viewOnly}
                                        hideFields={this.state.CreateNewFlag}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddLearnerInternship);
