import React from "react";
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import Save from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import ViewUtils from '../ViewUtils';
import { makeStyles } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const utils = new ViewUtils();

const actionIcons = {
    save: () => <Save />,
    delete: () => <Delete />,
    add: () => <Add />,
    edit: () => <Edit />
};

const useStyles = makeStyles({
    tableContainer: {
        overflowY: "auto",
        maxHeight: "73vh"
    }
});

const TableCellContent = (props) => {
    const valueType = props.valueType;
    const value = props.value;

    return (
        valueType === "file" ?
            <a href={`${props.dataUrl}fileId=${props.id}`}>
                {value}
            </a>
            :
        valueType === "date" ?
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id={"dateEditor" + props.id}
                            format="dd/MM/yyyy"
                            value={value || null}
                            InputProps={{
                                readOnly: true
                            }}
                            size="small"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                </MuiPickersUtilsProvider>
                :
                <p>{value}</p>
    );
};

const testColumn = [
    {
        field: "testFile",
        title: "Test file column",
        valueType: "file",
        get valueProperty() {
            return this.field;
        }
    },

    {
        field: "testDate",
        title: "Test date column",
        valueType: "date",
        get valueProperty() {
            return this.field;
        }
    }
];

const testData = [
    { testFile: "test data", testDate: "05/02/1995" },
    { testFile: "test data2", testDate: "06/02/1995" },
    { testFile: "test data3", testDate: "07/02/1995" }
];

const CustomMaterialTable = function (props) {
    const [data, setData] = React.useState(!utils.isNull(props.data) ? props.data : []);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 10);
    const [columns, setColumns] = React.useState(testColumn);
    const classes = useStyles();

    const customCellRender = valueProperty => valueType => rowData => {
        const rowId = !utils.isNull(rowData.Id) ? rowData.Id : rowData.tableData["id"];
        return (
            <TableCellContent
                valueType={valueType}
                value={rowData[valueProperty]}
                id={rowId}
            />
        );
    };

    const applyCustomCellRender = () => {
        let newColumns = testColumn.map((column) => {
            return { ...column, render: (rowData) => customCellRender(column.valueProperty)(column.valueType)(rowData) }
        });
        setColumns(newColumns);
    };

    React.useEffect(() => {
        applyCustomCellRender();
    }, [testColumn]);

    //Data coming from a remote source
    React.useEffect(() => {
        if (!utils.isNull(props.dataUrl)) {
            utils.invokeUrl(props.dataUrl + "?request=" + utils.doGetRequestJson(page, rowsPerPage, props.searchParameters, props.paged),
                response => {
                    let result;
                    if (typeof response === "string") {
                        result = JSON.parse(response);
                    }
                    else {
                        result = response;
                    }
                    let remoteData = result.Data;
                    setData(remoteData);
                })
        }
    }, [props.searchParameters]);

    return (
        <div className={classes.tableContainer}>
            <MaterialTable
                icons={actionIcons}
                title={props.tableTitle}
                columns={columns}
                data={testData}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: rowsPerPage,
                    paging: props.paged,
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF',
                        fontSize: "14px",
                        padding: "0px",
                        paddingLeft: "8px",
                    },
                    search: false,
                    draggable: false,
                    showTitle: true,
                    toolbar: false,
                    padding: "dense"
                }}
            />
        </div>
    );
};

CustomMaterialTable.propTyles = {
    columns: PropTypes.array.isRequired,
}

export default CustomMaterialTable;
