import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';

/*THIS FORM IS UNDER A SHARED STATUS BETWEEN GENERATE BATCH, DOWNLOAD BATCH AND AX-PROCESSOR, SAME SEARCH PARAMS. Please note before changing anything. replicate and alter if need be.*/
export default function ProcessSearchFormShared(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="GenerateBatchSearchForm" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="GenerateBatchSearchForm">
                    <tr>
                    <td>
                        <SelectItem
                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=DGType"}
                            id="DiscretionaryGrantTypeID"
                            label="Intervention Type"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=SocioEconomicStatus"}
                            id="SocioEconomicStatusID"
                            label="Socio Economic Status"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                        </td>
                    </tr>
                    <tr>
                    <td>
                        <LookupField
                            id="EmployerID"
                            label="Employer"
                            className="w-100"
                            form={form}
                        >
                                <EmployerSearch showTitle={false} mode='lookup' dataUrl={"api/sims/" + props.controllerName + "/EmployerSearch"}
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("EmployerID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    </tr>
                    <br>
                    </br>
                </form>
            }
        </EntityForm>
    );
}
