import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import AlertItem from "../../widgets/AlertItem";
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import "./DGOrganisation.css";
import { DGOrganisationDetailsForm } from './DGOrganistaionDetailsForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const formRef = React.createRef();

class DGOrganisation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            organisationDetails: {
                LegalName: "",
            },
            message: "",
            messageStyle: "",
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        };
        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: "CloseIcon" },
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'contact', label: 'Contact', tooltip: '', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgorganisationcontacts').View },
                        { id: 'stakeholder', label: 'Stakeholder', tooltip: '', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgorganisationstakeholder').View },
                        { id: 'upload', label: 'Document Uploads', tooltip: '', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgdocumentuploads').View },
                        { id: 'dgapplication', label: 'DG Application', tooltip: '', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgapplicationdashboard').View },
                        { id: 'dgverification', label: 'DG Verification', tooltip: '', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgverificationsearch').View },
                        { id: 'dgevaluation', label: 'DG Evaluation', tooltip: '', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgevaluationsearch').View },
                        { id: 'dgapproval', label: 'DG Approval', tooltip: '', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgapprovalsearch').View },
                        { id: 'dgcontracts', label: 'DG Contracts', tooltip: '', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgcontracts').View },
                        { id: 'crm', label: 'CRM', tooltip: 'Client Relationship Management', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/dgorganisationcrm').View },
                    ]
                }
            ]
        }
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            this.setState({
                organisationDetails: {
                    LegalName: values.LegalName,
                    SDLNumber: values.SDLNumber
                }
            });
            if (this.props.location.state.isDashboard) {
                this.props.history.push({
                    pathname: '/DGOrganisationStakeholder',
                    state: {
                        organisationID: this.state.id,
                        organisationName: this.state.organisationDetails.LegalName,
                        selection: this.props.location.state.selection,
                        sdlnumber: this.state.organisationDetails.SDLNumber
                    }
                });
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("contact" === buttonId) {
            this.props.history.push({
                pathname: '/DGOrganisationContacts',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    selection: this.props.location.state.selection,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        }
        else if ("stakeholder" === buttonId) {
            this.props.history.push({
                pathname: '/DGOrganisationStakeholder',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    selection: this.props.location.state.selection,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        }
        else if ("upload" === buttonId) {
            this.props.history.push({
                pathname: '/DGDocumentUploads',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    selection: this.props.location.state.selection,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        } 
         else if ("dgapplication" === buttonId) {
            this.props.history.push({
                pathname: '/DGApplicationDashboard',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        } else if ("dgevaluation" === buttonId) {
            this.props.history.push({
                pathname: '/DGEvaluationSearch',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    selection: this.props.location.state.selection,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        } else if ("dgverification" === buttonId) {
            this.props.history.push({
                pathname: '/DGVerificationSearch',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    selection: this.props.location.state.selection,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        } else if ("dgcontracts" === buttonId) {
            this.props.history.push({
                pathname: '/DGContracts',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        }
        else if ("dgapproval" === buttonId) {
            this.props.history.push({
                pathname: '/DGApprovalSearch',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        } else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: "/DGOrganisationCRM",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
    }

 
    save() {
        let formValue = formRef.current.getValue();
        
        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            console.log(formValue);
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            let data = JSON.stringify(formValue);
                const url = `api/sims/dgorganisationdetails/updatedgorganisation`;
                utils.updateForm(this, formRef, url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response);
                }, this.error);
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    closeView() {
        this.props.history.push({
            pathname: '/DGOrganisationSearch',
            //state: { selection: this.props.location.state.organisationID }
        });
    }

    render() {
        return (
            <ViewContainer width="50vh" height="100vh" topPadding="0" title={utils.getEntityViewTitle(this.props.location.state, "", this.state.organisationDetails.LegalName)}>
                <p className="breadcrumbs">
                    {"DG Organisation " + (this.state.organisationDetails.LegalName !== null ? "> " + this.state.organisationDetails.LegalName + (this.state.organisationDetails.SDLNumber !== null ? " - " + this.state.organisationDetails.SDLNumber : "") : "> Add")}
                </p>
                
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar
                                    highlight={false}
                                    title="DGOrganisation"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                            <DGOrganisationDetailsForm
                                ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(DGOrganisation));
