import React from 'react';
import ViewUtils from '../../ViewUtils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { CustomTextField } from '../../widgets/CustomTextField';
import Button from '@material-ui/core/Button';
import { EntityForm } from '../../widgets/Forms';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { makeStyles } from '@material-ui/core/styles';
import DocumentUpload from '../../widgets/DocumentUpload';
import { SelectItem } from '../../widgets/SelectItem';


const utils = new ViewUtils();

export default function DocumentDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [documentRelates, setdocumentRelates] = React.useState("");
    const [comment, setComment] = React.useState("");
    const [selection, setSelection] = React.useState(null);
    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })


    const handleClose = (isCancel) => {
        setOpen(true);
        setFilename("");
        props.closeHandler(!isCancel)
    };


    const cancel = () => {
        handleClose(true);
    };

    const add = () => {
        let data = {};

        data.DocumentRelatesID = documentRelates;
        data.Comment = comment;
        data.EntityID = props.EntityID
        data.OriginalFileName = filename;
        data.Document = {};
        data.Document.name = filename;
        data.Document.size = size;
        data.Document.type = type;
        data.Document.base64 = base64;

        utils.invokeUrl(props.postUrl, (response) => {
            handleClose(true, false);
        }, () => { handleClose(false, false); },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        );
    }





    const handleFormValueChange = (values) => {
        setdocumentRelates(values.DocumentRelatesID);
        setComment(values.Comment)
    }

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name)
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}
                style={{ scroll: "body" }}>
                <div style={{ padding: "32px" }}>

                    <p className="legend" >
                        New Document
                    </p>


                    <div style={{ height: "80%" }}>
                        <EntityForm useCustomSettings={false} valueChangeHandler={values => { handleFormValueChange(values) }}>
                            {form =>
                                <form className={form.classes.container} id="comments">
                                    <div className="row w-50">
                                        <div className="col w-100">
                                            <tr>
                                                <td>

                                                    <SelectItem
                                                        dataUrl="api/sims/OrganisationCRM/GetLookupList?listId=CRMDocumentRelates"
                                                        id="DocumentRelatesID"
                                                        label="Document Relates to"
                                                        className="w-100"
                                                        required={true}
                                                        form={form} />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>

                                                    <CustomTextField
                                                        id="Comment"
                                                        label="Comment"
                                                        className="w-100"
                                                        multiline
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        styles={styles()}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div style={{ height: "80%" }}>
                                                        <DocumentUpload showViewer={false} onUploadHandler={onUploadHandler} filename={filename} buttonLabel="Upload Document"

                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </div>
                                    </div>
                                </form>
                            }
                        </EntityForm>
                    </div>


                    <DialogActions>
                        <Button onClick={add} color="primary">
                            Save
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
