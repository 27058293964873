import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./User.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";
import ViewUtils from "./../../ViewUtils";
import FormDialog from "../../etqa/FormDialog";
import { UserRoleForm } from "./UserRoleForm";
import Prompt from '../../widgets/Prompt';


const headCells = [
    { id: 'Role', numeric: false, disablePadding: true, label: 'Role' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class PermissionSearch extends Component {
    constructor(props) {
        super(props)
    this.state = {
        model: {
            FirstName: "",
            LastName: ""

        },
        addDialogOpen: false,
        UserID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.userID)) ? this.props.location.state.userID : null,
        FirstName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FirstName)) ? this.props.location.state.FirstName : null,
        Surname: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.Surname)) ? this.props.location.state.Surname : null,
        selectedUserRole: null,
        searchParameters: [{ Name: "UserID", Value: this.props.location.state.userID }],
        promptOpen: false,
        promptTitle: "Remove Role from User",
        promptContent: "Are you sure you want to remove this role from the User?"
    }
    this.toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            },
            {
                type: "devider"
            },
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                    { id: 'remove', label: '', tooltip: 'Delete', icon: 'DeleteIcon',disabled: utils.isNull(this.state.selectedUserRole) }
                ]
            }
            
        ]
    };
}

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleToolbarButtonClick(buttonId) {
        if ("add" === buttonId) {
            this.setState({ addDialogOpen: true, Message: "" })
        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/userDetails',
                state: 
                { 
                    selection: this.props.location.state.userID,
                    FirstName: this.state.FirstName,
                    Surname: this.state.Surname
                }
            });
        } 
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedUserRole: selection[0].Id, Message: "" });
        } else {
            this.setState({ selectedUserRole: null, Message: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                addDialogOpen: false,
                editDialogOpen: false,
                selectedContact: null,
                message: "User Role successfully added",
                messageStyle: "success"
            });
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedContact: null,
                message: ""
            })
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedContact: null,
                message: "A system error has occured, please try again later or contact your administrator",
                messageStyle: "warning"
            })
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }

    successfulUserRoletRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedUserRole: null, message: response.Message, messageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulUserRoleRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ message: response.Message, messageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeUserRole(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedUserRole}`;
        utils.invokeUrlDelete(fullUrl, this.successfulUserRoletRemoveCallback, this.unsuccessfulUserRoleRemoveCallback);
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"User > Roles > " + (this.state.FirstName !== null ? this.state.FirstName + " " + this.state.Surname : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />
                    <EnhancedTable 
                        headCells={headCells}
                        dataUrl="api/sims/User/GetUserRoles"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} 
                        paged={true} />
                </div>
                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Add User Role"}
                    viewRef={this}
                    entityId={this.state.selectedUserRole}
                    entityInfo={{ UserID: this.state.UserID }}
                    formRef={addFormRef}
                    saveUrl={"api/sims/user/AddUserRole"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)} 
                >
                    <UserRoleForm 
                    ref={addFormRef}
                    id={this.state.selectedUserRole} 
                    UserID={this.state.UserID}
                    />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeUserRole("api/sims/user/RemoveUserRole") }}
                    closePrompt={this.closePrompt}
                />
                </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(PermissionSearch)
);
