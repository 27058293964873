import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View.jsx";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EnhancedToolbar from '../../widgets/Toolbar';
import EvaluationForm from './EvaluationForm';



const utils = new ViewUtils();
const Ref = React.createRef();
  
const Evaluation = (props) => {
  const [FinancialYear, setFinancialYear] = useState(null)


    const handleFormValueChange = (values) => {
        if (values != null){
            setFinancialYear(values.FinancialYearID)
        }
    }


    const toolbarConfig = {
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: "Close", tooltip: "Close" },
                ]
            }
        ]
    };


    const handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "view") {
            // this.setState({ addDialogOpen: true });
        } else if (buttonId === "close") {
            props.history.push({
                pathname: "/WspAtrEvaluation",
                state: {selection: props.location.state.organisationID}
            })
        }
    }

    
        return (
            <ViewContainer topPadding="0" title={
                " Evaluation"}>
            {/* <AlertItem message={this.state.message}  /> */}
            <table width="100%">

              <tbody>
                <tr>
                    <td>
                        <EnhancedToolbar 
                        highlight={false} 
                        title="Organisation Evaluation" 
                        buttonClickHandler={(buttonId) => handleToolbarButtonClick(buttonId)}
                        // buttonMenuClickHandler={(menu) => handleToolbarButtonMenuClick(menu)}
                        enabled={true}
                        config={toolbarConfig} />
                    </td>
                </tr>
                <tr>
                {props.location.state.FinancialYearID === null ? <p align="center">PLEASE SELECT FINANCIAL YEAR</p> :
                    <td className="formContainer">
                        <EvaluationForm ref={Ref} id={typeof props.location.state !== 'undefined' ? props.location.state.selection : null} valueChangeHandler={(values) => handleFormValueChange(values)}                      
                        />
                    </td>
                }
                </tr> 
                </tbody> 
            </table>
        </ViewContainer>
             
        );
}

export default Evaluation;