import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Assessor.css";
import { AssessorRegistrationDetailsForm } from './AssessorRegistrationDetailsForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "../../View";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const ref = React.createRef();

class AssessorRegistrationDetails extends Component {
    state = {
       message: "",
       toolbarConfig:{
        items: [
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            },
            {
                type: "devider"
            },
            {
                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit}
                ]
            }
        ]
    }
    }

    

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ AssessorRegistrationDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/assessor',
                state: { selection: this.props.location.state.selection }
            });
        }
    }

    successCallback = () => {

        this.setState({ message: "Assessor Registration details updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = ref.current.getValue();
        let data = JSON.stringify(formValue);

        utils.invokeUrl("api/sims/assessorregistrationdetails/UpdateAssessorRegistrationDetails", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: data
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Assessor > Registration Details" + (this.props.location.state !== null ? " > " + this.props.location.state.AssessorName + " - " + this.props.location.state.AssessorIDNo : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Assessor" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <AssessorRegistrationDetailsForm viewRef={this} ref={ref} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit }/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AssessorRegistrationDetails));

