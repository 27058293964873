import React from 'react';
import { connect } from 'react-redux';

const withMenuPermission = (WrappedComponent) => {
    const withMenuPermissionChild = (props) => {
        const { menuPermissionList, dispatch, ...rest } = props;
        const permissionArr = menuPermissionList.filter(e => e.Url.toLowerCase() === props.history.location.pathname.toLowerCase());
        let menuPermissionItem = null;

        if (permissionArr.length === 1) {
            menuPermissionItem = { ...permissionArr[0] };
        } else {
            menuPermissionItem = {
                MenuId: 0,
                Url: "",
                View: false,
                Add: false,
                Edit: false,
                Delete: false
            };
        }

        const getSpecificPermissionItem = (urlLocation) => {
            const permissionSpecificArr = menuPermissionList.filter(e => e.Url.toLowerCase() === urlLocation.toLowerCase());
            let menuSpecificPermissionItem = null;
            if (permissionSpecificArr.length === 1) {
                menuSpecificPermissionItem = { ...permissionSpecificArr[0] };
            } else {
                menuSpecificPermissionItem = {
                    MenuId: 0,
                    Url: "",
                    View: false,
                    Add: false,
                    Edit: false,
                    Delete: false
                };
            }
            return menuSpecificPermissionItem;
        }

        return <WrappedComponent onMenuSpecificPermissionItem={getSpecificPermissionItem} menuPermissionItem={menuPermissionItem} {...rest} />
    }
    return connect(mapStateToProps)(withMenuPermissionChild);
}

function mapStateToProps(state) {
    return {
        menuPermissionList: state.auth.menuPermissionsDetails!==null? state.auth.menuPermissionsDetails.PermissionList : []
    }
}

export default withMenuPermission;