import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';
import { AccreditationHistory } from './AccreditationHistory';

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Learner details', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to programs', theme: true }
            ]
        }
    ]
};

const utils = new ViewUtils();
class AddLearnerAccreditationHistory extends Component {

    state = {
        searchParameters: null,
        message: "",
        selectedIntervension: -1,
        selection: {},
        openDialog: false
    };

    handleToolbarButtonClick(buttonId) {
        if ("back" === buttonId) {
            this.props.stepHandler(2, false, this.props.data);
        } else if ("next" === buttonId) {
            this.props.stepHandler(2, true, this.props.data);
        }
    }


    handleFormValueChange(values) {
    }

    handleDataArrived(data) {
    }

    handleLearnershipToolbarButtonClick(buttonId) {
        if ("add" === buttonId) {
            this.setState({ openDialog: true });
        }
    }

    handleSkillsToolbarButtonClick(buttonId) {
    }

    componentDidMount() {
        this.setState({
            searchParameters: [{ Name: "IDNo", Value: this.props.data.IDNo }]
        });
    }

    render() {
        return (
            <div className="h-100 w-100" style={{ paddingTop: "0 !important", paddingLeft: "32px" }}>
                <table width="100%" height="85%" border="0">
                    <tbody>
                        <tr>
                            <td colSpan="2" valign="top">
                                <AccreditationHistory searchParameters={this.state.searchParameters}/>
                            </td>
                        </tr>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="10%" align="right" height="60px" valign="top">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(
    connect()(AddLearnerAccreditationHistory)
);
