import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Moderator.css";
import { Alert } from "react-bootstrap";
import ViewUtils from "./../../ViewUtils";
import { ModeratorForm } from './ModeratorForm';
import { ViewContainer } from "../../View";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();


const moderatorRef = React.createRef();

class Moderator extends Component {

    state = {
        ModeratorDetails: {
            Person: {
                FirstName: "",
                Surname: ""
            }
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },

                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit },
                        {
                            contextMenu: [
                                { id: "registrationDetails", description: "Registration Details" },
                                { id: "registrationHistory", description: "Registration History" }
                            ],
                            id: "registration", label: "Registration"
                        },

                        {
                            contextMenu: [
                                { id: "skillsProgramme", description: "Skills Programme" },
                                { id: "leanership", description: "Learnership" },
                                { id: "bursary", description: "Bursary" },
                            ],
                            id: 'programs', label: 'Programmes'
                        },

                        { id: 'provider', label: 'Provider', tooltip: '' },
                        { id: 'crm', label: 'CRM', tooltip: 'Client Relationship Management' }

                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ ModeratorDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("close" === buttonId) {
            this.close();
        }
        else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: '/moderatorCRM',
                state: {
                    ModeratorID: this.props.location.state.selection,
                    ModeratorName: `${this.state.ModeratorDetails.FirstName} ${this.state.ModeratorDetails.Surname}`
                }
            })
        }
        else if ("provider" === buttonId) {
            this.props.history.push({
                pathname: '/moderatorProviderSearch',
                state: {
                    ModeratorID: this.props.location.state.selection,
                    ModeratorName: `${this.state.ModeratorDetails.FirstName} ${this.state.ModeratorDetails.Surname}`
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
        if ("registrationDetails" === menu.id) {
            this.props.history.push({
                pathname: '/moderatorRegistrationDetails',
                state: {
                    selection: this.props.location.state.selection,
                    ModeratorName: `${this.state.ModeratorDetails.FirstName} ${this.state.ModeratorDetails.Surname}`
                }
            })
        } else if ("registrationHistory" === menu.id) {
            this.props.history.push({
                pathname: '/moderatorRegistrationHistory',
                state: {
                    ModeratorID: this.props.location.state.selection,
                    ModeratorName: this.state.ModeratorDetails.FirstName + " " + this.state.ModeratorDetails.Surname
                }
            })
        } else if ("skillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: '/moderatorSkillsProgramme',
                state: {
                    ModeratorID: this.props.location.state.selection,
                    ModeratorName: this.state.ModeratorDetails.FirstName + " " + this.state.ModeratorDetails.Surname
                }
            })
        } else if ("leanership" === menu.id) {
            this.props.history.push({
                pathname: '/moderatorlearnership',
                state: {
                    ModeratorID: this.props.location.state.selection,
                    ModeratorName: this.state.ModeratorDetails.FirstName + " " + this.state.ModeratorDetails.Surname
                }
            })
        } else if ("bursary" === menu.id) {
            this.props.history.push({
                pathname: '/moderatorBursary',
                state: {
                    ModeratorID: this.props.location.state.selection,
                    ModeratorName: this.state.ModeratorDetails.FirstName + " " + this.state.ModeratorDetails.Surname
                }
            })
        }
    }

    successCallback = () => {

        this.setState({ message: "Moderator updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = moderatorRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/moderatordetails/UpdateModerator", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    close() {
        this.props.history.push('/moderatorSearch');
    }

    render() {


        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Moderator " + (this.state.ModeratorDetails.FirstName !== null ? "> " + this.state.ModeratorDetails.FirstName + (this.state.ModeratorDetails.Surname !== null ? "  " + this.state.ModeratorDetails.Surname : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Moderator" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "60vh", maxHeight: "60vh" }}>
                                    <ModeratorForm ref={moderatorRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Moderator));

