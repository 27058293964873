import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "../../../etqa/program/LearnershipSetup.css";
import SearchView from "../../../widgets/SearchView";
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "./../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";


const utils = new ViewUtils();
const headCells = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Reason Name' },
    { id: 'Description', numeric: false, disablePadding: true, label: 'Reason Description' }
];

class LetterReasonDialog extends Component {

    constructor(props) {
        super(props)

        this.searchParameters = 
            [
                //{ Name: "WSPATRID", Value: this.props.WspAtrId },
                { Name: "LetterType", Value: this.props.LetterType }
            ]

        if (!utils.isNull(props.exclusions)) {
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        this.state = {
            keyUpSwitch: false,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Remove Learnership", icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={utils.isNull(this.props.showTitle) || this.props.showTitle === true ? 'Query Reasons' : null}>
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/WSPATRVerification/GetWSPATRQueryLetterReasons"}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="QueryReasons" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    keyUpSwitch={this.state.keyUpSwitch}
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    search={false}
                >
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LetterReasonDialog));