import React from 'react';
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';

const headCells = [
    { id: 'SentBy', numeric: false, disablePadding: true, label: 'Sent By' },
    { id: 'SentTo', numeric: false, disablePadding: true, label: 'Sent To' },
    { id: 'Message', numeric: false, disablePadding: true, label: 'Message Sent' },
    { id: 'Date', numeric: false, disablePadding: true, label: 'Date Sent' }



]

export default function Sms(props, ref) {

    return (
        <EnhancedTable headCells={headCells} searchParameters={[{ Name: `${props.moduleName}Id`, Value: props.selection }]} paged={true}
            dataUrl={props.smsDataUrl} height="50vh"
        />
    );
}
