import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Organisation.css";
import { OrganisationDetailsForm } from './OrganisationDetailsForm';


const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        },
        {
            type: "devider"
        },
        {
            type: "buttonGroup",
            buttons: [
                { id: 'contact', label: 'Contact', tooltip: '' },
                { id: 'bankingDetails', label: 'Bank Details', tooltip: '' },
                {
                    contextMenu: [
                        { id: "unions", description: "Unions" },
                        { id: "trainingCommittee", description: "Training Committee" }
                    ],
                    id: 'committee', label: 'Committees', width: "100px"
                },
                { id: 'CFODetails', label: 'CFO Details', tooltip: '' },
           
                { id: 'forms', label: 'WSP & ATR Forms', tooltip: 'WSP & ATR Forms' },
                { id: 'documentsUpload', label: 'Uploads', tooltip: 'WSP and Documents Upload' },
                { id: 'evaluation', label: 'Evaluation', tooltip: '' },
                { id: 'grantsAndLevies', label: 'Grants and Levies', tooltip: ''},
                { id: 'crm', label: 'CRM', tooltip: 'Client Relationship Management' },
                {id: 'letter', label: 'Letter', tooltip: 'SDF Compliance Letter'},
               
            ]
        }
    ]
};

const organisationRef = React.createRef();

class ChildOrganisation extends Component {

    state = {
        organisationDetails: {
            LegalName: "",
        }
    };

    handleFormValueChange(values) {
        this.setState({ organisationDetails: values });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("contact" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisationContacts',
                state: {
                    // organisationID: this.props.location.state.selection,
                    organisationName: this.state.organisationDetails.LegalName
                }
            });
        } else if ("bankingDetails" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisationBankingDetails',
                state: {
                    // organisationID: this.props.location.state.selection,
                    organisationName: this.state.organisationDetails.LegalName
                }
            });
        } else if ("CFODetails" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisationCFODetails',
                state: {
                    // organisationID: this.props.location.state.selection,
                    organisationName: this.state.organisationDetails.LegalName
                }
            })
        } else if ("forms" === buttonId) {
            this.props.history.push({
                pathname: "/wspAtrMain",
                state: {
                    // organisationID: this.props.location.state.selection,
                    organisationName: this.state.organisationDetails.LegalName
                }
            })
        } else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: "/OrganisationCRM",
                state: {
                    // organisationID: this.props.location.state.selection,
                    organisationName: this.state.organisationDetails.LegalName
                }
            })
        }
        else if ("linkedOrganisations" === buttonId) {
            this.props.history.push({
                pathname: "/LinkedOrganisations",
                state: {
                    // organisationID: this.props.location.state.selection,
                    organisationName: this.state.organisationDetails.LegalName
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
        if ("trainingCommittee" === menu.id) {
            this.props.history.push({
                pathname: '/skillsTrainingCommitteeSearch',
                state: {
                    // organisationID: this.props.location.state.selection,
                    organisationName: this.state.organisationDetails.LegalName
                }
            })
        } else if ("unions" === menu.id) {
            this.props.history.push({
                pathname: '/skillsUnion',
                state: {
                    // organisationID: this.props.location.state.selection,
                    organisationName: this.state.organisationDetails.LegalName
                }
            })
        }
    }

    save() {
        let formValue = organisationRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {
            let data = JSON.stringify(formValue);
            const url = `api/indicium/provider/updateProviderDetails?data=${data}`;
            // TODO : Invoke spinner mask
            fetch(url)
                .then(res => res.json())
                .then((responseJson) => {
                    let response = JSON.parse(responseJson);
                    if (response.MessageType !== "ERROR") {
                        this.props.history.push({
                            pathname: '/skillsOrganisation',
                            state: {
                                organisationID: this.props.location.state.selection,
                                organisationName: this.state.organisationDetails.LegalName
                            }
                        });
                    } else {
                        this.setState({ saveMessage: response.Message, hasSaveError: true });
                    }
                }).catch(() => {
                    this.setState({ saveMessage: "A system error has accured. Please contact your system administrator", hasSaveError: true });
                });
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/skillsOrganisationSearch',
            state: { selection: this.props.location.state.organisationID }
        });
    }

    render() {
        return (
            <div className="view w-100 h-100">
                <p >
                    {"Organisation " + (typeof this.props.location.state !== 'undefined' && this.props.location.state.selection !== null ? "> "
                        + (this.state.organisationDetails != null ? this.state.organisationDetails.LegalName : "") : "> Add Child Organisation")}
                </p>
                <p className="message">
                    {this.state.message}
                </p>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Organisation" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <OrganisationDetailsForm 
                                ref={organisationRef} 
                                id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null} 
                                valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(ChildOrganisation);
