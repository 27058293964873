import React from 'react';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SelectItem } from '../../widgets/SelectItem';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const UnionForm = React.forwardRef((props, ref) => {

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="union">
                    <div className="row w-100">
                        <div className="col w-100">
                                <table cellPadding="2" className="w-100">
                                    <tbody>
                        
                                        <tr>
                                            <td>
                                            <SelectItem
                                                dataUrl="api/sims/employer/GetLookupList?listId=Title"
                                                id="TitleID"
                                                key={tabValue}
                                                label="Title"
                                                className="w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                form={form} />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="Name"
                                                    key={tabValue}
                                                    label="Name"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="Surname"
                                                    key={tabValue}
                                                    label="Surname"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="IdNumber"
                                                    key={tabValue}
                                                    label="ID Number"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        {(props.addTrainingCommittee === true) ? 
                                        <tr>
                                            <td colSpan="6">   
                                               
                                                </td>
                                                <td>

                                                </td>
                                        </tr> :
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="NameOfUnion"
                                                    key={tabValue}
                                                    label="Name of Union"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="positionInUnion"
                                                    key={tabValue}
                                                    label="Position in Union"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>}
                                        
                                        <tr>
                                            
                                                {
                                                    (props.addTrainingCommittee === true) ?
                                                    <td> <SelectItem
                                                    dataUrl="api/sims/employer/GetLookupList?listId=Designation"
                                                    id="Designation"
                                                    key={tabValue}
                                                    label="Designation(Employer Union Representative)"
                                                    className="w-100"
                                                    form={form}/>
                                                    </td> :
                                                    <td>
                                                    <SelectItem
                                                    dataUrl="api/sims/employer/GetLookupList?listId=Designation"
                                                    id="Designation"
                                                    key={tabValue}
                                                    label="Designation"
                                                    className="w-100"
                                                    form={form}
                                                    />
                                                    </td>
                                                 
                                                }
                                          
                                            <td>
                                                <CustomTextField
                                                    id="Email"
                                                    key={tabValue}
                                                    label="Email"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>

                                                 
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="CellphoneNumber"
                                                    key={tabValue}
                                                    label="Cellphone Number"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="TelephoneNumber"
                                                    key={tabValue}
                                                    label="Telephone Number"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="FaxNumber"
                                                    key={tabValue}
                                                    label="Fax Number"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                             
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})