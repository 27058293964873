import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import BulkAssociationSearchForm from './BulkAssociationSearchForm';
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'LearnerFirstName', numeric: false, disablePadding: false, label: 'First Name' },
    { id: 'LearnerLastName', numeric: false, disablePadding: false, label: 'Last Name' },
    { id: 'LearnerIDNumber', numeric: false, disablePadding: false, label: 'ID Number' },
    { id: 'AssociationDetailCode', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'AssociationDetailDescription', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'OrganisationSDLNumber', numeric: false, disablePadding: false, label: 'SDL Number' }
];


class BulkGrantAssociation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                FinancialYearID: null,
                DiscretionaryGrantTypeID: null,
                EmployerID: null,
                SocioEconomicStatusID: null
            },
            selectedAssociations: null,
            keyUpSwitch: false,
            LearnerCount: 0,
            showTable: true,
            clearGrid: false,
            GrantsLinked: false,
            message: null,
            messageStyle: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'bulkassociate', label: 'Bulk Associate', tooltip: 'Bulk associate selected grants',
                            disabled: utils.isNull(this.state.selectedAssociations)
                        }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("bulkassociate" === buttonId) {
            this.associationSelectedGrants();
            toolbarRef.current.setButtonDisabled("bulkassociate", true);
        }
    }

    successCallback(response) {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType
                })
            });
        }
        else {
            this.setState({
                message: response.message, messageStyle: response.MessageType
            });
        }
    }

    associationSelectedGrants = () => {
        const IDs = this.state.selectedAssociations.map(e => e.Id);
        let data = {
            IDs: IDs,
            DiscretionaryGrantTypeID: this.state.model.DiscretionaryGrantTypeID,
        }
        let url = "api/sims/BulkGrantAssociation/BulkGrantAssociate";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
            let bulkinfo = JSON.parse(response.Message);
            this.setState({
                clearGrid: true
            });
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelections = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedAssociations: selection, Message: "", LearnerCount: selection.length },
                () => {
                    toolbarRef.current.setButtonDisabled("bulkassociate", selection.length === 0);
                }
            )
        } else {
            this.setState({ selectedAssociations: null, Message: "", LearnerCount: 0 },
                () => {
                    toolbarRef.current.setButtonDisabled("bulkassociate", selection.length === 0);
                }
            )
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values });
        }
    }


    render() {

        return (
            <ViewContainer mode={this.props.mode} title={``}>
                <p className="breadcrumbs">
                    {"Bulk Grant Association"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                {this.state.showTable === true && (
                <SearchView
                    headCells={headCells}
                    dataUrl="api/sims/BulkGrantAssociation/BulkGrantAssociationSearch"
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    mode={this.props.mode}
                    history={this.props.history}
                    multiSelect={true}
                    onLookupValueChange={this.props.onLookupValueChange}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelections(selection) }}
                    autoFetchData={false}
                    toolbarRef={toolbarRef}
                    clearGrid={this.state.clearGrid}
                    onGridCleared={this.onGridCleared}
                    searchLabel={"SEARCH"}
                    handleObject={true}
                >
                        
                        <BulkAssociationSearchForm data={this.state.model} controllerName='BulkGrantAssociation'
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                </SearchView>
                )}
                <div> {'Total Learners: ' + this.state.LearnerCount} </div>  
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(BulkGrantAssociation));

