import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import DocumentUpload from "../../widgets/DocumentUpload";
import HelpdeskComments from "./HelpdeskComments";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const HelpdeskRequestForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [UploadFilename, setUploadFilename] = React.useState("");
    const [CommentURL, setCommentURL] = React.useState(null);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 1) {
            setCommentURL("api/sims/HelpDeskRequest/GetHelpDeskRequestComments");
        }
        else {
            setCommentURL(null);
        }
    };

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })

    const onUploadHandler = (data) => {
        if (data) {
            setUploadFilename(data.name);
        }
    };

    const FileDownload = (rowid, headCell,DocumentType) => {
        
        if(DocumentType === "Comment")
        {
         
            if (headCell.contentType === "document") {
                utils.invokeUrl("api/sims/HelpDeskRequest/DownloadHelpDeskCommentDocument?Id=" + rowid, (response) => {
    
                    var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
                    var url = URL.createObjectURL(blob);
    
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = response.Name;
                    link.target = '_blank';
                    link.click();
                }, (e) => {
                    this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
                }
                );
            }

        }

        else
        {
            utils.invokeUrl("api/sims/HelpDeskRequest/DownloadHelpDeskDocument?Id=" + rowid, (response) => {
    
                var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    const base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    return (
        <EntityForm ref={ref}
            viewId="helpdesk"
            dataURL='api/sims/HelpDeskRequest/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}
            dataArrivedHandler={props.dataArrivedHandler}>
            {form =>
                <form className={form.classes.container} id="HelpDeskRequestForm">
                <div className="row w-100">
                    <div className="col w-100">
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Details" {...a11yProps(0)} />
                                <Tab label="Comments" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ReferenceNumber"
                                                key={tabValue}
                                                label="Reference Number"
                                                className="w-100"
                                                form={form}
                                                disabled={true}/>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Title"
                                                key={tabValue}
                                                label="Title"
                                                className="w-100"
                                                form={form}
                                                required={true}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/skillslettercreationdetails/GetLookupList?listId=HelpdeskRequestType"
                                                id="RequestTypeID"
                                                key={tabValue}
                                                label="Request Type"
                                                className="w-100"
                                                required={true}
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/helpdeskrequest/GetLookupList?listId=HelpdeskIssuePriorities"
                                                id="PriorityID"
                                                key={tabValue}
                                                required={true}
                                                label="Priority"
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/skillslettercreationdetails/GetLookupList?listId=HelpdeskIssueModules"
                                                id="ModuleID"
                                                key={tabValue}
                                                label="Module"
                                                className="w-100"
                                                required={true}
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/helpdeskrequest/GetLookupList?listId=HelpdeskIssueStatus"
                                                id="StatusID"
                                                key={tabValue}
                                                required={true}
                                                label="Status"
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomTextField
                                                id="Message"
                                                key={tabValue}
                                                label="Message"
                                                className="w-100"
                                                required={true}
                                                inputProps={{
                                                    maxLength: 500
                                                }}
                                                multiline
                                                styles={styles()}
                                                form={form}
                                                disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="LoggedBy"
                                                label="Logged By"
                                                className="w-100"
                                                form={form}
                                                disabled={true}/>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="DateLogged"
                                                label="Date Logged"
                                                className="w-100"
                                                form={form}
                                                disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="UpdatedBy"
                                                label="Updated By"
                                                className="w-100"
                                                form={form}
                                                disabled={true}/>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="DateUpdated"
                                                label="Date Updated"
                                                className="w-100"
                                                form={form}
                                                disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="DateClosed"
                                                key={tabValue}
                                                label="Date Closed"
                                                className="w-100"
                                                form={form}
                                                disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomTextField
                                                id="Comments"
                                                key={tabValue}
                                                label="Comments"
                                                className="w-100"
                                                required={true}
                                                inputProps={{
                                                    maxLength: 500
                                                }}
                                                multiline
                                                styles={styles()}
                                                form={form}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <DocumentUpload
                                                showViewer={false}
                                                onUploadHandler={onUploadHandler}
                                                buttonLabel="Upload"
                                                filename={UploadFilename}
                                                form={form}
                                                id="RequestDocument"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                            {form.values["OriginalFileName"] !== "" && !utils.isNull(form.values["OriginalFileName"]) ?
                                                <td>
                                                    <a id="aOriginal Upload" onClick={() => FileDownload(props.id, "","Original")} > Download - {form.values["OriginalFileName"]}</a>
                                                </td>
                                                    :
                                                <td>
                                                    &nbsp;
                                                </td>
                                            }

                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            
                        <table cellPadding="2" className="w-100">
                                <tbody>
                                    </tbody>
                            </table>
                            <div>
                                    <p></p>
                                    
                                    <HelpdeskComments handleCell={(rowid, headCell) => FileDownload(rowid, headCell, "Comment")} DataUrl={CommentURL} selection={props.id} />
                                    </div>
                        </TabPanel>
                    </div>
                </div>
                </form>
            }
        </EntityForm>
    );
})

