import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import ViewUtils from "../../../ViewUtils";
import MenuItem from '@material-ui/core/MenuItem';

const utils = new ViewUtils();
export default function LearnerCRMBulkUploadDialog(props) {

    const [open, setOpen] = React.useState(props.open);

    const handleClose = (save, cancel) => {
        setOpen(false);
        props.closeHandler(save, cancel)
    };

    const cancel = () => {
        handleClose(false, true);
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}
                style={{ scroll: "body" }}>
                <div style={{ padding: "32px" }}>

                    <p className="legend" >
                        Outstanding documents to upload
                    </p>


                    <div className="col form-col">
                        <ul>
                            <MenuItem value={0} key={0} >  </MenuItem>
                            {props.missingDocTypesArray.map((lookupitem, index) => (
                                <li key={lookupitem.Id} >{lookupitem.Description}</li>
                            ))}
                        </ul>
                    </div>

                    <DialogActions>
                        <Button onClick={cancel} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
