import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import HighchartsReact from 'highcharts-react-official';
import { Card, Col } from 'react-bootstrap';
import Highcharts from 'highcharts';
import { withStyles } from "@material-ui/core/styles"

const styles = {
    fullHeightCard: {
        height: "60%",
    },
}

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [{
                name: 'Learner Per Province',
                data: [
                    ['Freestate', 3565],
                    ['Gauteng North', 18405],
                    ['Gauteng South', 13117],
                    ['Kwa-Zulu', 17899],
                    ['Limpopo', 5055],
                    ['Mpumalanga', 5141],
                    ['North West', 3356],
                    ['Northern Cape', 1829],
                    ['Western Cape', 19522]
                ],
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.y:.1f}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            }]
        }
    }

    highChartsRender() {
        
        Highcharts.chart({
            
            chart: {
                type: 'column',
                renderTo: 'atmospheric-composition',
                height: '350px'
            },
           
            title: {
                text: 'Learner Per Province'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Learner Per Province'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: 'Learner Per Provice: <b>{point.y:.1f} Learners</b>'
            },
            plotOptions: {
                colum: {
                    labels: {
                        rotation: -45,
                        style: {
                            fontSize: '13px',
                            fontFamily: 'Verdana, sans-serif'
                        }
                    }
                }
            },
            series: this.state.series
        });
    }

    componentDidMount() {       
        this.highChartsRender();
    }
    render() {
        const { chartOptions, hoverData } = this.state;
        const { classes } = this.props;
        return (
            <Col sm="6">
            <Card>
                    <Card.Header as="h6"></Card.Header>
                    <Card.Body id="atmospheric-composition"></Card.Body>
            </Card>
            </Col>
            )
    }
}

export default withStyles(styles)(BarChart);






