import React from 'react';
import "./widgets.css";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DocumentViewer from './DocumentViewer';
import ViewUtils from '../ViewUtils';
const utils = new ViewUtils();

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    errorMessageDisplay: {
        color: 'red'
    }
}));

export const DocumentUploadComponent = React.forwardRef((props, ref) => {
    const [viewerFile, setViewerFile] = React.useState(!utils.isNull(props.initialFile) ? props.initialFile : null);
    const [files, setFiles] = React.useState(!utils.isNull(props.initialFile) ? [props.initialFile] : null);
    const [loading, setLoading] = React.useState(true);
    const [multiple] = React.useState(props.multiple === true);
    const [show, setShow] = React.useState(false)
    const classes = useStyles();
    const [reference, setReference] = React.useState(ref ? ref : React.createRef());
    const [hasError, setHasError] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [showUploadFileMessage, setShowUploadFileMessage] = React.useState(null);

    const doValidation = (values, currentValue) => {
        let required = props.required;
        let result = utils.validateField(props.id, required, values, currentValue);
        setErrorMessage(result.message);
        setHasError(!result.valid);

        props.form.setFieldError(props.id, typeof result.message === 'undefined' ? null : result.message);
        //console.log(props.id + " - THE VAL " + result.valid + " MSG " + result.message);
        return result.valid;
    }

    const setSelectionAsValue = (selection) => {
        props.form.doHandleChange(props.id, selection);
        setShowUploadFileMessage(true);
    };

    React.useImperativeHandle(ref, () => ({
        validate: (values, currentValue) => {
            return doValidation(values, currentValue);
        },
        setUpload: (selection) => {
            setSelectionAsValue(selection);
        },
        parseValue: (value) => {
            return value;
        },
        getValidationGroup: () => {
            return props.validationGroup;
        }
    }));

    React.useEffect(() => {
        if (!utils.isNull(props.form)) {
            let componentRef = reference === null ? React.createRef() : reference;
            setReference(componentRef);
            let field = props.form.addField(props.id, componentRef, props.parentId);

            if (!utils.isNull(field) && !utils.isNull(field.error)) {
                if (field.error.length > 0) {
                    setHasError(true);
                    setErrorMessage(field.error);
                }
            }
        }
    });

    const handleViewerLoad = (numPages) => {
        setLoading(false);
    }

    const handleChange = () => event => {
        let files = event.target.files;
        var allFiles = [];
        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result
                };

                allFiles.push(fileInfo);
                if (allFiles.length == files.length) {
                    if (multiple === true) {
                        if (!utils.isNull(props.onUploadHandler)) {
                            props.onUploadHandler(allFiles);
                        }
                    } else {
                        if (!utils.isNull(props.onUploadHandler)) {
                            props.onUploadHandler(allFiles[0]);
                        }
                        setLoading(true);
                        setViewerFile(allFiles[0]);
                    }

                    setFiles(allFiles);
                }
            }
            reader.onloadend = () => {
                if (!utils.isNull(props.form)) {
                    setSelectionAsValue(allFiles[0]);
                }
            }
        }
    }

    return (
        <div>
           <div>
                <input
                    accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    className={classes.input}
                    id={
                        props.id ?
                            props.id
                            :
                            "contained-button-file"
                    }
                    multiple={multiple}
                    onChange={handleChange()}
                    type="file"
                />
                <label htmlFor={
                    props.id ?
                        props.id
                        :
                        "contained-button-file"
                }>
                    <Button variant="contained" component="span" className={classes.button} disabled={props.disabled}>
                        {
                            props.buttonLabel ?
                                props.buttonLabel
                                :
                                "Upload File"
                        }
                    </Button>
                </label>

                {
                //    <label>
                //        <Button
                //            variant="contained"
                //            component="span"
                //            className={classes.button}
                //            onClick={() => {
                //                show === true ? setShow(false) : setShow(true)
                //            }}
                //            disabled={utils.isNull(props.filename) || utils.isStringEmpty(props.filename)}
                //        >
                //            {
                //                props.downloadButtonLabel ?
                //                    props.downloadButtonLabel
                //                    :
                //                    "Preview File"
                //            }

                //        </Button>
                //    </label>
                }
                {
                    !utils.isNull(props.onProcessHandler) && files !== null ?
                        <Button variant="contained" component="span" className={classes.button}
                            onClick={() => { props.onProcessHandler(viewerFile) }} disabled={loading}>
                            Extract File
                        </Button>
                        :
                        ""
                }
                {
                    <a href={props.downloadUrl}>
                        {props.filename}
                    </a>
                }

            </div>
            {
                props.showViewer && !multiple || show ?
                    <DocumentViewer file={viewerFile} onDocumentLoadSuccess={handleViewerLoad} imageStyle={props.imageStyle }/>
                    :
                    ""
            }
            {
                showUploadFileMessage ?
                    props.uploadFileMessage
                    :
                    ""
            }
            {
                hasError ?
                    <span className={classes.errorMessageDisplay}>{errorMessage}</span>
                    :
                    ""
            }
        </div>
    );
})

export default function DocumentUpload(props) {
    return (
        <DocumentUploadComponent
            id={props.id}
            ref={React.createRef()}
            parentId={props.parentId}
            children={props.children}
            required={props.required}
            data={props.initialFile}
            form={props.form}
            onUploadHandler={props.onUploadHandler}
            onProcessHandler={props.onProcessHandler}
            initialFile={props.initialFile}
            showViewer={props.showViewer}
            uploadFileMessage={props.uploadFileMessage}
            filename={props.filename}
            buttonLabel={props.buttonLabel}
            downloadUrl={props.downloadUrl}
            imageStyle={props.imageStyle}
            disabled={props.disabled}
        >
        </DocumentUploadComponent>
    );
}