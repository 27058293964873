import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import EditForm from './OrganisationStakeholderEditForm';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import AlertItem from "../../widgets/AlertItem";
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

export default function OrganisationStakeholderEditDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [Message, setMessage] = React.useState("");
    const [MessageStyle, setMessageStyle] = React.useState("");
    const formRef = React.createRef();

    const handleClose = (cancel, save, response) => {
        setOpen(false);
        props.closeHandler(cancel, save, response);
    };

    const cancel = () => {
        handleClose(true, false);
    };

    const update = () => {
        let formValue = formRef.current.getValue();
        //formValue.Id = props.Id;

        if (formValue !== null && typeof formValue !== 'undefined') {

            const url = `api/sims/organisationStakeholder/updateorganisationStakeholder`;

            utils.invokeUrl(url, (response) => {
                handleClose(false, true, response);
            }, () => { handleClose(false, false); },
                {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formValue)
                }
            );
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}>
                <div style={{ padding: "32px" }}>

                    <div style={{ paddingLeft: "24px", paddingRight: "44px" }}>
                        <AlertItem message={Message} alertStyle={MessageStyle} />
                    </div>

                    <div style={{ height: "80%" }}>
                        <EditForm ref={formRef} id={props.id} className="h-100" />
                    </div>
                    <DialogActions>
                        <Button onClick={update} color="primary">
                            Update
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}