import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'StartDate', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'EndDate', numeric: false, disablePadding: true, label: 'End Date' },
    { id: 'ProgrammeType', numeric: false, disablePadding: true, label: 'Programme Type' },
    { id: 'Intervention', numeric: false, disablePadding: true, label: 'Intervention' },
    { id: 'Employment', numeric: false, disablePadding: true, label: 'Employment' },
    { id: 'ProjectNumber', numeric: false, disablePadding: true, label: 'Project Number' }
];


class FacilitatorProjectSearch extends Component {

    state = {
        searchParameters: [{ Name: "FacilitatorID", Value: this.props.location.state.FacilitatorID }],
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'project', label: 'GO TO PROJECT', tooltip: 'go to project', visible: this.props.onMenuSpecificPermissionItem('/GrantSetupSearch').View }
                    ]
                }
            ]
        }
    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/facilitator',
                state: { selection: this.props.location.state.FacilitatorID }
            });
        }
        else if ("project" === buttonId) {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: {
                    TrancheID: this.state.selection[0].Id,
                    hideFields: false
                }
            });
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Facilitator > Projects" + (this.props.location.state !== null ? " > " + this.props.location.state.FacilitatorName + " - " + this.props.location.state.FacilitatorIDNo : "> Add")}
                </p>
                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        title="Facilitator"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.state.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => this.setState({ selection: selection })}
                        dataUrl="api/sims/facilitatorproject/getFacilitatorProjects" searchParameters={this.state.searchParameters} />
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    withMenuPermission(FacilitatorProjectSearch)
);
