import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { ViewContainer } from "../../View.jsx";
import "../../etqa/crm/CRM.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';
import CardLayout from "../../widgets/Card";
import TextField from '@material-ui/core/TextField';
import withMenuPermission from "../../widgets/withMenuPermission";
import MenuItem from '@material-ui/core/MenuItem';
import EnhancedTable from '../../widgets/ResponsiveTable';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import GrantsLeviesPrint from './GrantsLeviesForm';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Section = [
    { Id: 1, Description: "Grant Summary" },
    { Id: 2, Description: "Levy Summary" },
    { Id: 3, Description: "Financial Summary" }
]
const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

const headGrantCells = [
    { id: 'Year', numeric: false, disablePadding: true, label: 'Year' },
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' },
    { id: 'Amount', numeric: false, disablePadding: true, label: 'Amount' }

];

const headLevyCells = [
    { id: 'SARSArrivalDate', numeric: false, disablePadding: true, label: 'SARS Arrival Date' },
    { id: 'DatePosted', numeric: false, disablePadding: true, label: 'Date Posted' },
    { id: 'StakeholderLevyCalculated', numeric: false, disablePadding: true, label: 'Stakeholder Levy Calculated' },
    { id: 'NSFCalculation', numeric: false, disablePadding: true, label: 'NSF Calculation' },
    { id: 'TotalReceivedBySETA', numeric: false, disablePadding: true, label: 'Total Received By SETA' },
    { id: 'MandatoryLevy', numeric: false, disablePadding: true, label: 'Mandatory Levy' },
    { id: 'Discretionary', numeric: false, disablePadding: true, label: 'Discretionary' },
    { id: 'Admin', numeric: false, disablePadding: true, label: 'Admin' },
    { id: 'Penalty', numeric: false, disablePadding: true, label: 'Penalty' },
    { id: 'Interest', numeric: false, disablePadding: true, label: 'Interest' }

];

const headFinCells = [
    { id: 'SARSArrivalDate', numeric: false, disablePadding: true, label: 'SARS Arrival Date' },
    { id: 'Year', numeric: false, disablePadding: true, label: 'Year' },
    { id: 'StakeholderLevyCalculated', numeric: false, disablePadding: true, label: 'Stakeholder Levy Calculated' }

];

class OrganisationGrantsLevies extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataObject: null,
            SectionID: 0,
            Section: null,
            FinancialYearID: 0,
            FinYearDisabled: true,
            lookupFinArray: null,
            searchParameters: [{ Name: "OrganisationID", Value: this.props.location.state.organisationID }, { Name: "FinancialYearID", Value: 0 }],
            model: {},
            openPrintDialog: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'print', label: '', tooltip: 'Print', disabled: utils.isNull(this.state.dataObject), icon: 'PrintIcon' }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisation',
                state: { selection: this.props.location.state.organisationID }
            });
        }
        else if ("print" === buttonId) {
            this.setState({ openPrintDialog: true })
        }
    }

    componentDidMount() {
        this.getlookup(this.state.id)
    }

    getlookup(id) {
        let url = "api/sims/grantsandlevies/getlookuplist?listid=FinancialYear";
        utils.invokeUrl(url, (response) => {
            this.setState({ lookupFinArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    handleSection = (event) => {

        var section = ""

        if (event.target.value === 1) {
            section = "Grant Summary"
        } else if (event.target.value === 2) {
            section = "Levy Summary"
        } else if (event.target.value === 3) {
            section = "Financial Summary"
        }

        this.setState({ SectionID: event.target.value, FinYearDisabled: false, Section: section });
    }
    handleFinancialYear = (event) => {

        let FinancialYearID = event.target.value;
        let newsearchParameters = [...this.state.searchParameters];
        let temp_element = { ...newsearchParameters[1] };
        temp_element.Value = FinancialYearID
        newsearchParameters[1] = temp_element;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            FinancialYearID: FinancialYearID
        }, () => {
            if (this.state.FinancialYearID !== 0) {
                this.getData();
                toolbarRef.current.setButtonDisabled("print", false);

            }
        });

    }

    getData = () => {
        utils.invokeUrl("api/sims/grantsandlevies/getgrantsandlevies?request=" + utils.doGetRequestJson(0, 0, this.state.searchParameters, false), (response) => {
            this.setState({
                dataObject: response
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    createData = (name, description) => {
        return { name, description };
    }

    rows = [
        this.createData('Receipt Date', 'Date of payment to SARS'),
        this.createData('Received by SETA', 'Date the money was received by SETA from the Department of Labour'),
        this.createData('Stakeholder Levy Calculated (100%)', 'The sum of the levy received by SETA + NSF calculation + interest + penalty'),
        this.createData('NSF Calculation (20%)', 'Levy amount calculated as transferred to the National Skills Fund by SARS (20% of total levy),done to balance your actual payment made with the money received by SETA.'),
        this.createData('Total received by SETA (80%)', 'Levy amount received by SETA (80% of total levy)'),
        this.createData('Mandatory Levy (50%)', 'The % of the total levy received by SETA allowable for the mandatory grant.'),
        this.createData('Discretionary (20%)', 'The % of the total levy received by SETA allowable for the discretionary grant.'),
        this.createData('Admin (10%)', 'The 10% of the total levy received by SETA allowable for SETA admin.'),
        this.createData('Interest (80%)', 'SETA receive 80% of the interest charged by SARS as calculated by SARS'),
        this.createData('Penalty (80%)', 'SETA receive 80% of the penalties charged by SARS as calculated by SARS'),
        this.createData('SARS month', 'For internal office use only.')
    ];

    CloseForm = () => {
        this.setState({
            openPrintDialog: false
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <CardLayout title="">

                <div >
                    <p className="legend">
                        {"Organisation > Grants and Levies " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                    </p>

                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <div className="row" style={{ width: "90%" }}>
                        <div className="col w-100">
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <TextField className="w-100" id="SectionID" select margin="normal" size="small" value={!utils.isNull(this.state.SectionID) ? this.state.SectionID : 0} variant="outlined" label="Section" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleSection(values)} >
                                                <MenuItem value={0} key={0} >  </MenuItem>
                                                {Section.map((lookupitem, index) => (

                                                    <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>

                                                ))}
                                            </TextField>
                                        </td>
                                        <td>
                                            <TextField className="w-100" id="FinancialYearID" select margin="normal" size="small" variant="outlined" value={!utils.isNull(this.state.FinancialYearID) ? this.state.FinancialYearID : 0} label="Financial Year" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleFinancialYear(values)} disabled={this.state.FinYearDisabled}>
                                                <MenuItem value={0} key={0} >  </MenuItem>
                                                {!utils.isNull(this.state.lookupFinArray) ? this.state.lookupFinArray.map((lookupFinArray, index) => (

                                                    <MenuItem value={lookupFinArray.Id} key={lookupFinArray.Id} >{lookupFinArray.Description}</MenuItem>

                                                )) : null}
                                            </TextField>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <br />
                    <br />
                    {!utils.isNull(this.state.dataObject) ?

                        <ViewContainer mode={this.props.mode} title="">

                            <div className="row" style={{ width: "90%" }}>
                                <div className="col w-100">
                                    <table cellPadding="2" width="80%">
                                        <tbody>
                                            <tr>
                                                <td align="left">
                                                    <TextField id="OrganisationName" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.OrganisationName : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Organisation Name"> </TextField>
                                                </td>
                                                <td>
                                                    <TextField id="SDLNumber" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.SDLNumber : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="SDL Number"> </TextField>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left">
                                                    <TextField id="FinancialYear" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.FinancialYear : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Financial Year"> </TextField>
                                                </td>
                                                <td>
                                                    <TextField id="PhysicalAddress1" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.PhysicalAddress1 : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Physical Address Line 1"> </TextField>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td align="left">
                                                    <TextField id="PhysicalAddress2" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.PhysicalAddress2 : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Physical Address Line 2"> </TextField>
                                                </td>
                                                <td>
                                                    <TextField id="PhysicalAddress3" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.PhysicalAddress3 : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Physical Address Line 3"> </TextField>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td align="left">
                                                    <TextField id="Municipality" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.Municipality : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Municipality"> </TextField>
                                                </td>
                                                <td>
                                                    <TextField id="PostalCode" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.PostalCode : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="PostalCode"> </TextField>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td align="left">
                                                    <TextField id="PostalAddress1" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.PostalAddress1 : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Postal Address Line 1"> </TextField>
                                                </td>
                                                <td>
                                                    <TextField id="PostalAddress2" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.PostalAddress2 : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Postal Address Line 2"> </TextField>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left">
                                                    <TextField id="PostalAddress3" disabled={true} value={!utils.isNull(this.state.dataObject) ? this.state.dataObject.PostalAddress3 : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} className="w-100" label="Postal Address Line 3"> </TextField>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {
                                this.state.dataObject.GrantSummaryDto.length !== 0 && (this.state.SectionID === 1 || this.state.SectionID === 3) ?
                                    <div className="searchResults">
                                        <EnhancedTable
                                            headCells={headGrantCells}
                                            rows={this.state.dataObject.GrantSummaryDto} paged={false} makeRowBold={true} />
                                    </div> :
                                    ""
                            }

                            {
                                this.state.dataObject.LevySummaryDto.length !== 0 && this.state.SectionID === 2 ?
                                    <div className="searchResults">
                                        <EnhancedTable
                                            headCells={headLevyCells}
                                            rows={this.state.dataObject.LevySummaryDto} paged={false} makeRowBold={true} />


                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><b>Legend</b></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.rows.map((row) => (
                                                        <TableRow key={row.name}>
                                                            <TableCell component="th" scope="row">
                                                                <b>{row.name}</b>
                                                            </TableCell>
                                                            <TableCell >{row.description}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    :
                                    ""
                            }

                            {
                                this.state.dataObject.FinancialSummaryDto.length !== 0 && this.state.SectionID === 3 ?
                                    <div className="searchResults">
                                        <EnhancedTable
                                            headCells={headFinCells}
                                            rows={this.state.dataObject.FinancialSummaryDto} paged={false} makeRowBold={true} />
                                    </div> :
                                    ""
                            }

                        </ViewContainer>
                        :
                        ""
                    }

                </div>

                <Dialog open={this.state.openPrintDialog} onClose={this.CloseForm} TransitionComponent={Transition} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" maxWidth="sm">
                    <GrantsLeviesPrint
                        data={this.state.dataObject}
                        Section={this.state.Section}
                    />
                    <DialogActions>
                        <Button onClick={this.CloseForm} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog >

            </CardLayout>
        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(OrganisationGrantsLevies)));
