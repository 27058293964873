import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import GrantSetupSearch from '../grants/grantsetup/GrantSetupSearch';

export default function LearnerSearchForm(props) {
    return (
        <EntityForm ref={React.createRef()} viewId="assessor" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="AssessorForm">
                    <div className="row search-form-container">
                        {props.mode !== "lookup" && <div className="col form-col">
                            <LookupField
                                id="ProjectID"
                                label="Project"
                                className="w-100"
                                form={form}
                                required
                            >
                                <GrantSetupSearch showTitle={false} mode='lookup' dataUrl="api/sims/learner/GrantSetupSearch"
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("ProjectID", value);
                                    }} autoFetchData={false} selection={props.selection} />
                            </LookupField>
                        </div>}
                        <div className="col form-col">
                            <CustomTextField
                                id="FirstName"
                                label="First Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="MiddleName"
                                label="Middle Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="Surname"
                                label="Surname"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="IDNo"
                                label="ID Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                    </div>
                </form>
            }
        </EntityForm>
    );
}
