import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./UnitStandardSetup.css";
import SearchView from "../../widgets/SearchView";
import UnitStandardSetupSearchForm from './UnitStandardSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const headCells = [
    { id: 'SAQAUnitStandardID', numeric: false, disablePadding: true, label: 'SAQA UnitStandard ID' },
    { id: 'SAQAUnitStandardTitle', numeric: false, disablePadding: true, label: 'SAQA UnitStandard Title' }

];

class GrantSetupElectiveUSSearchModule extends Component {

    constructor(props) {
        super(props)

        let model = {
            SAQAUnitStandardID: "",
            SAQAUnitStandardTitle: "",
            id: this.props.programmeId
        }

        this.searchParameters =
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "SAQAUnitStandardID", Value: "" },
                { Name: "SAQAUnitStandardTitle", Value: "" }
            ]

        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        if (!utils.isNull(props.personId)) {
            model.PersonID = props.personId;
            this.searchParameters.push({ Name: "PersonID", Value: props.personId })
        }

        this.state = {
            model: model,
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Remove Unit Standard", icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }
    }

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }


    render() {
        return (
            <ViewContainer title={utils.isNull(this.props.showTitle) || this.props.showTitle === true ? 'Unit Standards' : null}>
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/UnitStandardSetup/GetSearch"}
                    entityViewPath='/UnitStandardSetup'
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="SAQAUnitStandardTitle" mode={this.props.mode}
                    keyUpSwitch={this.state.keyUpSwitch} history={this.props.history}
                    multiSelect={this.props.multiSelect} selectionCallbackMode={this.props.selectionCallbackMode}
                    deleteUrl={"api/sims/UnitStandardSetup/RemoveUnitStandard"} useDeletePost={true}
                    promptTitle={"Remove Unit Standard"}
                    promptContent={"Removing the unit standard is a permanent action and the unit standard will not be available going forward. Are you sure you want to remove this unit standard?"}
                    editPermission={this.props.menuPermissionItem.Edit}
                >
                    <UnitStandardSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(GrantSetupElectiveUSSearchModule));
