import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import AlertItem from "../../widgets/AlertItem";
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import "./Organisation.css";
import { OrganisationDetailsForm } from './OrganisationDetailsForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const formRef = React.createRef();


class Organisation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            organisationDetails: {
                LegalName: "",
            },
            message: "",
            messageStyle: "",
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        };
        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'contact', label: 'Contact', tooltip: 'Contact', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsOrganisationContacts').View },
                        { id: 'bankingDetails', label: 'Bank Details', tooltip: 'Bank Details', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsOrganisationBankingDetails').View },
                        { id: 'childOrganisations', label: 'Link Organisations', tooltip: 'Link Organisations', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsChildOrganisationSearch').View },

                        { id: "trainingcommittee", description: "Training Commitee / Union", label: 'Training Commitee / Union', tooltip: 'Training Commitee / Unions', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsTrainingCommittee').View },

                        { id: 'CFODetails', label: 'CFO Details', tooltip: 'CFO Details', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsOrganisationCFODetails').View },

                        { id: 'forms', label: 'WSP & ATR Forms', tooltip: 'WSP/ATR Forms', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/WspAtrDashboard').View },
                        { id: 'documentsUpload', label: 'WSP/ATR Document Uploads', tooltip: 'WSP/ATR Document Uploads', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/WspAtrDocumentsUpload').View },
                        { id: 'sdfstatus', label: 'SDF', tooltip: 'SDF', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsOrganisationSDF').View },
                      
                        { id: 'grants', label: 'Grants & Levies', tooltip: 'Grants & Levies', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsOrganisationLevies').View },
                        { id: 'crm', label: 'CRM', tooltip: 'Client Relationship Management', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/OrganisationCRM').View },
                        { id: "intersetatransfer", label: "Inter Seta Transfer", tooltip: 'Inter Seta Transfer', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsOrganisationInterSetaTransfer').View },
                        { id: 'extension', label: 'Request Extension ', tooltip: 'WSP/ATR Request Extension', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/WspAtrExtensionRequest').View },
                        { id: 'apeal', label: 'Lodge Appeal', tooltip: 'WSP/ATR Lodge Appeal', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/WspAtrLodgeApeal').View },
                        { id: 'letter', label: 'SDF Compliance Letter', tooltip: 'SDF Compliance Letter', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/skillsOrganisationLetter').View },
                        { id: 'stackholderfeedback', label: 'Stakeholder Feedback', tooltip: 'Stakeholder Feedback ', disabled: utils.isNull(this.state.id), visible: this.props.onMenuSpecificPermissionItem('/StackhoderFeedback').View }

                    ]
                }
            ]
        }

    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            this.setState({
                organisationDetails: {
                    LegalName: values.LegalName,
                    SDLNumber: values.SDLNumber
                }
            });
            if (this.props.location.state.isDashboard) {
                this.props.history.push({
                    pathname: '/skillsOrganisationSDF',
                    state: {
                        organisationID: this.state.id,
                        organisationName: this.state.organisationDetails.LegalName,
                        sdlnumber: this.state.organisationDetails.SDLNumber
                    }
                });
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("contact" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisationContacts',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        } else if ("bankingDetails" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisationBankingDetails',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            });
        } else if ("childOrganisations" === buttonId) {
            this.props.history.push({
                pathname: '/skillsChildOrganisationSearch',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        } else if ("CFODetails" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisationCFODetails',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        } else if ("forms" === buttonId) {
            this.props.history.push({
                pathname: "/WspAtrDashboard",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        } else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: "/OrganisationCRM",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
        else if ("linkedOrganisations" === buttonId) {
            this.props.history.push({
                pathname: "/LinkedOrganisations",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        } else if ("documentsUpload" === buttonId) {
            this.props.history.push({
                pathname: "/WspAtrDocumentsUpload",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
        else if ("sdfstatus" === buttonId) {
            this.props.history.push({
                pathname: "/skillsOrganisationSDF",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
        else if ("grants" === buttonId) {
            this.props.history.push({
                pathname: "/skillsOrganisationLevies",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
        else if ("intersetatransfer" === buttonId) {
            this.props.history.push({
                pathname: "/skillsOrganisationInterSetaTransfer",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
        else if ("letter" === buttonId) {
            this.props.history.push({
                pathname: "/skillsOrganisationLetter",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
        else if ("trainingcommittee" === buttonId) {
            this.props.history.push({
                pathname: "/skillsTrainingCommittee",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }

        else if ("stackholderfeedback" === buttonId) {
            this.props.history.push({
                pathname: "/StackhoderFeedback",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
        else if ("extension" === buttonId) {
            this.props.history.push({
                pathname: "/WspAtrExtensionRequest",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
        else if ("apeal" === buttonId) {
            this.props.history.push({
                pathname: "/WspAtrLodgeApeal",
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName,
                    sdlnumber: this.state.organisationDetails.SDLNumber
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
        if ("trainingcommittee" === menu.id) {
            this.props.history.push({
                pathname: '/skillsTrainingCommitteeSearch',
                state: {
                    organisationID: this.state.id,
                    organisationName: this.state.organisationDetails.LegalName
                }
            })
        }
    }

    save() {
        let formValue = formRef.current.getValue();
        
        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            console.log(formValue);
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            let data = JSON.stringify(formValue);

            if (isAdd) {
                const url = `api/sims/skillsorganisationdetails/addorganisation`;
                utils.saveForm(this, formRef, url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response);
                }, this.error);
            } else {
                const url = `api/sims/skillsorganisationdetails/updateorganisation`;
                utils.updateForm(this, formRef, url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response);
                }, this.error);
            }
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    closeView() {
        this.props.history.push({
            pathname: '/skillsOrganisationSearch',
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={utils.getEntityViewTitle(this.props.location.state, "", this.state.organisationDetails.LegalName)}>
                <p className="legend">
                    {"Organisation " + (this.state.organisationDetails.LegalName !== null ? "> " + this.state.organisationDetails.LegalName + (this.state.organisationDetails.SDLNumber !== null ? " - " + this.state.organisationDetails.SDLNumber : "") : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td style={{Width:"100px"}}>
                                <EnhancedToolbar
                                    highlight={false}
                                    title="Organisation"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                                    enabled={true}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <OrganisationDetailsForm
                                    entityLookupController={"GetFind"}
                                    ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Organisation));
