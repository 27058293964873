import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import StakeholderSearchForm from './StakeholderSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
class StakeholderSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            model: {
                FirstName: "",
                Surname: "",
                IDNo: "",
                ProvinceID: "0",
                SDLNumber: ""
            },
            keyUpSwitch: false,
            selectedOrganisation: null,
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'View / Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.View }
                    ]
                }
            ]
        }

        this.searchParameters = this.searchParameters = this.props.isLinkSearch ?
            [
                { Name: "FirstName", Value: "" },
                { Name: "Surname", Value: "" },
                { Name: "IDNo", Value: "" },
                { Name: "ProvinceID", Value: "0" },
                { Name: "SDLNumber", Value: "" },
            ]
            :
            [
                { Name: "FirstName", Value: "" },
                { Name: "Surname", Value: "" },
                { Name: "IDNo", Value: "" },
                { Name: "ProvinceID", Value: "0" },
                { Name: "SDLNumber", Value: "" },

            ]

        this.headCells = this.props.isLinkSearch ?
            [
                { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
                { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
                { id: 'IDNo', numeric: false, disablePadding: true, label: 'ID Number' }
            ]
            :
            [
                { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
                { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
                { id: 'IDNo', numeric: false, disablePadding: true, label: 'ID Number' }

            ]
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedOrganisation: selection })
        } else {
            this.setState({ selectedOrganisation: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ selectedOrganisation: null })
        }
    }

    render() {
        return (

            <ViewContainer title={""}>
                <SearchView headCells={this.headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/DGStakeholder/getsearch"}
                    entityViewPath='/stakeholder'
                    model={this.state.model}
                    title='Stakeholder'
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    mode={this.props.mode}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp=""
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <StakeholderSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(StakeholderSearch));