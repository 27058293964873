import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import Button from "@material-ui/core/Button";
// import withMenuPermission from "../../../../../widgets/withMenuPermission";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Item from "antd/lib/list/Item";
import AlertItem from "../../../widgets/AlertItem";
import EnhancedToolbar from "../../../widgets/Toolbar";

import "./Style.css";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [{ id: "close", label: "Back to DG Dashboard", tooltip: "" }],
        },
    ],
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#01579B",
        color: theme.palette.common.white,
        border: "1px solid white",
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        fontWeight: 500,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main,
        },
    },
}))(TableRow);

const useStyles = (theme) => ({
    table: {
        minWidth: 700,
    },
});

class DGForms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            objectProps: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.object)) ? this.props.location.state.object : null,
            formArray: [],
            FinancialYear: '',
            OrganisationName: '',
            Status: '',
            model: {
                Name: "",
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.DGID)) ? this.props.location.state.DGID : null,
            organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
            FinancialYearID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : null,

        };
    }

    componentDidMount() {
        this.getForms(this.state.Id);
        this.getFormHead(this.state.Id);
        this.GetDGFormSubmissionCheck(this.state.Id);
    }
    getForms() {
        let url =
            "api/sims/dgapplication/GetDGForms?DGApplicationID=" + this.state.id;
        utils.invokeUrl(
            url,
            (response) => {
                this.setState({ formArray: response });
            },
            null,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
    }
    getFormHead() {
        let url =
            "api/sims/dgapplication/getfindheader?DGApplicationID=" + this.state.id;
        utils.invokeUrl(
            url,
            (response) => {
                this.setState({
                    FinancialYear: response.FinancialYear,
                    OrganisationName: response.OrganisationName,
                    SDLNumber: response.SDLNumber,
                    Status: response.Status,
                });
            },
            null,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
    }
    GetDGFormSubmissionCheck() {
        let url =
            "api/sims/DGApplication/GetDGFormSubmissionCheck?DGApplicationID=" + this.state.id;
        utils.invokeUrl(
            url,
            (response) => {
                this.setState({
                    CanSubmit: response.Result,
                });
            },
            null,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
    }
    submit(id) {
        let url =
            "api/sims/DGApplication/updateDGApplicationSubmit?DGApplicationID=" + this.state.id;
        utils.invokeUrl(
            url,
            (response) => {
                this.successCallback(response);
            },
            (response) => {
                this.error(response);
            },
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
        this.GetDGFormSubmissionCheck();
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    close() {
        this.props.history.push({
            pathname: "/DGApplicationDashboard",
            state: {
                organisationID: this.state.organisationID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber,
                FinancialYearID: this.state.FinancialYearID
            },
        });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            //this.save();
        } else {
            this.close();
        }
    }
    buttonSubmitClickHandler() {
        this.submit();
    }

    render() {
        const { classes } = this.props;
        const tablerows = this.state.formArray.map((item, index) =>

            item.URL !== "" ? (
                <StyledTableRow key={item.number}>
                    <StyledTableCell component="th" scope="row">
                        {item.Number}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                        {item.FormDescription}
                    </StyledTableCell>
                    <StyledTableCell>{item.DueDate}</StyledTableCell>
                    <StyledTableCell>
                        <Link
                            to={{
                                pathname: item.URL,
                                state: {
                                    DGObject: "",
                                    formId: item.Id,
                                    DGApplicationID: this.state.id,
                                    organisationID: this.state.organisationID,
                                    DGFormID: item.Id,
                                    DGFormName: item.FormDescription,
                                    FinancialYearID: this.state.FinancialYearID
                                },

                            }}
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                color="#01579B"
                                className={classes.margin}
                            >
                                Select
                            </Button>
                        </Link>
                    </StyledTableCell>
                </StyledTableRow>
            ) : (
                <StyledTableRow color="primary">
                    <StyledTableTextCell align="right"></StyledTableTextCell>
                    <StyledTableTextCell align="right"></StyledTableTextCell>

                    <StyledTableTextCell style={{ width: 400 }}>
                        {item.FormDescription}
                    </StyledTableTextCell>

                    <StyledTableTextCell align="right"></StyledTableTextCell>

                    <StyledTableTextCell align="right"></StyledTableTextCell>
                </StyledTableRow>
            )
        );
        const submitrow =
            this.state.CanSubmit == "1" ? (
                <Button
                    variant="outlined"
                    size="small"
                    color="#01579B"
                    onClick={() => this.buttonSubmitClickHandler()}
                    className={classes.margin}
                >
                    SUBMIT
                </Button>
            ) : (
                <b>Application Status : {this.state.Status}</b>
            );

        return (
            <ViewContainer topPadding="0" title="">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%" class="FormsTableHeader">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar
                                    highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) =>
                                        this.handleToolbarButtonClick(buttonId)
                                    }
                                    enabled={true}
                                    config={toolbarConfig}
                                />
                            </td>
                            <td align="center">
                                <b>DG Application Forms</b>
                                <br />
                                <b>{this.state.FinancialYear}</b>
                            </td>
                            <td align="right">
                                <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td colSpan="3" align="right" className="formContainer">
                                {submitrow}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">#</StyledTableCell>
                            <StyledTableCell>Form Description</StyledTableCell>
                            <StyledTableCell>Due Date</StyledTableCell>
                            <StyledTableCell>Select</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{tablerows}</TableBody>
                </Table>
            </ViewContainer>
        );
    }
}
export default withRouter(withStyles(useStyles())(DGForms));
