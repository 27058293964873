import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import { SelectItem } from '../../widgets/SelectItem';
import ProviderSearch from "../provider/ProviderSearch";
import EmployerSearch from "../employer/EmployerSearch";
import LearnerSearch from "../learner/LearnerSearch";
import { Row, Col } from 'react-bootstrap';
import ViewUtils from '../../ViewUtils';
import QualificationSetupSearch from '../program/QualificationSetupSearch';
import LearnershipSetupSearch from '../program/LearnershipSetupSearch';
import SkillsProgramSetupSearch from '../program/SkillsProgramSetupSearch';
import UnitStandardSetupSearch from '../program/UnitStandardSetupSearch';

const utils = new ViewUtils();

const LearnerDocumentSearchForm = React.forwardRef((props, ref) => {
    const [dependency, setDependency] = React.useState(props.dependency);

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    const onCheckFieldValitationArray = (fields, values) => {
        if (values.DocumentTypeID !== null && values.DocumentTypeID.Id === "2") {
            fields = fields.filter(e => e.id !== "PrintTypeID");
        }
        console.log(fields);
        return fields;
    }

    return (
        <EntityForm ref={ref} viewId="learner" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}
            onCheckFieldValitationArray={onCheckFieldValitationArray}
        >
            {form =>
                <form className={form.classes.container} id="LearnerSearchForm">
                    <div className="row search-form-container">

                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/bulklearnercertificate/GetLookupList?listId=DocumentType"
                                id="DocumentTypeID"
                                required={true}
                                label="Document Type"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        {
                            !utils.isNull(form.values["DocumentTypeID"]) && form.values["DocumentTypeID"].Description === "Certificate" ?
                                <div className="col form-col">
                                    <SelectItem
                                        parentId={0}
                                        dataUrl="api/sims/bulklearnercertificate/GetLookupList?listId=PrintType"
                                        id="PrintTypeID"
                                        //hasDependency={true}
                                        //dependency={dependency}
                                        required={true}
                                        label="Print Type"
                                        className="w-100"
                                        form={form}
                                    />
                                </div> : ""
                        }
                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/bulklearnercertificate/GetLookupList?listId=ProgrammeType"
                                id="ProgrammeTypeID"
                                required={true}
                                label="Programme Type"
                                className="w-100"
                                form={form}
                            />
                        </div>
                        {
                            !utils.isNull(form.values["ProgrammeTypeID"]) && form.values["ProgrammeTypeID"].Description === "Qualification" ?
                                <div className="col form-col">
                                    <LookupField
                                        id="ProgrammeID"
                                        label="Qualifications"
                                        className="w-100"
                                        required={false}
                                        form={form}
                                    >
                                        <QualificationSetupSearch
                                            showTitle={true}
                                            mode='lookup'
                                            dataUrl='api/sims/bulklearnercertificate/getqualificationsearch'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("ProgrammeID", value)
                                            }}
                                            autoFetchData={false}
                                            selection={props.selection}
                                        />
                                    </LookupField>
                                </div>
                                :
                                ""
                        }
                        {
                            !utils.isNull(form.values["ProgrammeTypeID"]) && form.values["ProgrammeTypeID"].Description === "Unit Standards" ?
                                <div className="col form-col">
                                    <LookupField
                                        id="ProgrammeID"
                                        label="Unit Standards"
                                        className="w-100"
                                        required={false}
                                        form={form}
                                    >
                                        <UnitStandardSetupSearch
                                            showTitle={true}
                                            mode='lookup'
                                            dataUrl='api/sims/bulklearnercertificate/getunitstandardsearch'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("ProgrammeID", value)
                                            }}
                                            autoFetchData={false}
                                            selection={props.selection}
                                        />
                                    </LookupField>
                                </div>
                                :
                                ""
                        }
                        {
                            !utils.isNull(form.values["ProgrammeTypeID"]) && form.values["ProgrammeTypeID"].Description === "Learnership" ?
                                <div className="col form-col">
                                    <LookupField
                                        id="ProgrammeID"
                                        label="Learnerships"
                                        className="w-100"
                                        required={false}
                                        form={form}
                                    >
                                        <LearnershipSetupSearch
                                            showTitle={true}
                                            mode='lookup'
                                            dataUrl='api/sims/bulklearnercertificate/getlearnershipsearch'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("ProgrammeID", value)
                                            }}
                                            autoFetchData={false}
                                            selection={props.selection}
                                        />
                                    </LookupField>
                                </div>
                                :
                                ""
                        }
                        {
                            !utils.isNull(form.values["ProgrammeTypeID"]) && form.values["ProgrammeTypeID"].Description === "Skills Programmes" ?
                                <div className="col form-col">
                                    <LookupField
                                        id="ProgrammeID"
                                        label="Skills Programmes"
                                        className="w-100"
                                        required={false}
                                        form={form}
                                    >
                                        <SkillsProgramSetupSearch
                                            showTitle={true}
                                            mode='lookup'
                                            dataUrl='api/sims/bulklearnercertificate/getskillsprogrammesearch'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("ProgrammeID", value)
                                            }}
                                            autoFetchData={false}
                                            selection={props.selection}
                                        />
                                    </LookupField>
                                </div>
                                :
                                ""
                        }

                        <div className="col form-col">
                            <LookupField
                                id="LearnerID"
                                label="Learner"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <LearnerSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/bulklearnercertificate/LearnerSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("LearnerID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>

                        <div className="col form-col">
                            <LookupField
                                id="EmployerID"
                                label="Employer"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <EmployerSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/bulklearnercertificate/EmployerSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("EmployerID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>

                        <div className="col form-col">
                            <LookupField
                                id="ProviderID"
                                label="Provider"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <ProviderSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/bulklearnercertificate/ProviderSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("ProviderID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    );
})

export default LearnerDocumentSearchForm;