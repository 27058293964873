import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./SETMISFile.css";
import SearchView from "../../widgets/SearchView";
import { SETMISFileForm } from './SETMISFileForm';
import EnhancedToolbar from '../../widgets/Toolbar';
import CardLayout from '../../widgets/Card';

const headCells = [
    { id: 'ReportName', numeric: false, disablePadding: true, label: 'Report Name' },
    { id: 'ReportDescription', numeric: false, disablePadding: true, label: 'Report Description' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
            ]
        }
    ]
};

const searchParameters = [
    { Name: "Report Name", Value: "" },
    { Name: "Report Description", Value: "" },
]

class SETMISFileSearch extends Component {

    state = {
        model: {
            ReportName: "",
            ReportDescription: ""
        }
    };

    render() {
        return (
            <CardLayout title='SETMIS Files'>
                <div className="container-fluid" style={{ padding: "16px", height: "75vh" }}>
                    <p className="message">
                        {this.state.message}
                    </p>
                    <table width="100%">
                        <tbody>                         
                            <tr>
                                <td className="formContainer">   
                    <SETMISFileForm model={this.state.model} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardLayout>
        );
    }
}

export default withRouter(
    connect()(SETMISFileSearch)
);
