import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import EmailDialog from "../../crm/EmailDialog";
import { makeStyles } from '@material-ui/core/styles';
import DocumentUpload from '../../../widgets/DocumentUpload';

const utils = new ViewUtils();
export const TrackingAddTaskForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [filename, setFilename] = React.useState("");
    const [file, setFile] = React.useState(null)

    function TaskCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "150px !important",
                width: "500px !important"
            }
        }
    })

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name)
            setFile(data.base64)
        }
    }

    return (
        <EntityForm ref={ref} viewId="trackingAddTask" values={props.data} dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="trackingAddTask">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        parentId={0}
                                        dataUrl="api/sims/grantsetuptracking/GetLookupList?listId=projecttask"
                                        id="ProjectTaskID"
                                        required
                                        className="w-100"
                                        key={tabValue}
                                        label="Task"
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="OtherTask"
                                        key={tabValue}
                                        label="Other Task"
                                        className="w-100"
                                        required={TaskCheck(form.values["ProjectTaskID"])}
                                        disabled={!TaskCheck(form.values["ProjectTaskID"])}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="DueDate"
                                        key={tabValue}
                                        label="Due Date"
                                        required
                                        className='{classes.textField} w-100'
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        parentId={0}
                                        dataUrl="api/sims/grantsetuptracking/GetLookupList?listId=TaskStatus"
                                        id="TaskStatusID"
                                        required
                                        key={tabValue}
                                        label="Status"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <div className="row w-50">
                                <div className="col w-100">
                                        <tr>
                                            <td>
                                            <CustomTextField
                                                id="ToAddress"
                                                label="To Address (separate by semicolon (;))"
                                                className="w-100"
                                                styles={styles()}
                                                multiline
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex={/^$|^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/}
                                                validationMessage="Incorrect format"
                                            />
                                            </td>
                                        </tr>
                                    <tr>
                                        <td>

                                            <CustomTextField
                                                id="emailSubject"
                                                label="Subject"
                                                required
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>

                                            <CustomTextField
                                                id="emailBody"
                                                label="Email Body"
                                                required
                                                className="w-100"
                                                multiline
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                styles={styles()}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <DocumentUpload
                                            id="document"
                                            form={form}
                                            showViewer={false}
                                            filename={filename}
                                            onUploadHandler={onUploadHandler}
                                        />
                                    </tr>
                                </div>
                            </div>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
