import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedToolbar from '../../widgets/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
const formRef = React.createRef();


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#01579B',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 11,
    },
  }))(TableCell);

  const StyledTableTextCell = withStyles((theme) => ({
    body: {
      fontSize: 14,
      fontWeight: 500
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        height: theme.palette.action.main
      },
    },
  }))(TableRow);
  
  function createData(number, form, description, duedate, select) {
    return { number, form, description, duedate, select };
  }
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  
const userRef = React.createRef();
const toolbarConfig = {
    items: [
        
        {
            type: "buttonGroup",
            buttons: [
                {
                    id: 'close', label: 'Close', tooltip: ''
                },
                {
                    id: 'print', label: 'Print Friendly', tooltip: '',
                },
            ]
        }
    ]
};


const Grants = (props)=> {
    const classes = useStyles();
    const [FinancialYear, setFinancialYear] = useState(null)
    const [showViewButton, setShow] = React.useState(true);
    const [OrganisationName, setOrganisationName] = useState('')
    const [TradeName, setTradeName] = useState('')
    const [Status, setStatus] = useState('')
    const [SDLNumber, setSDLNumber]= useState('')
    const [PhysicalAddressLine1, setPhysicalAddressLine1]= useState('')
    const [FaxNumber, setFaxNumber]= useState('')
    const [PhysicalAddressLine2, setPhysicalAddressLine2]= useState('')
    const [PhysicalAddressLine3, setPhysicalAddressLine3]= useState('')
    const [Municipality, setMunicipality]= useState('')
    const [ContactTelephoneNumber, setContactTelephoneNumber]= useState('')
    const [PostalCode, setPostalCode]= useState('')
    const [PostalAddressLine1, setPostalAddressLine1]= useState('')
    const [PostalAddressLine2, setPostalAddressLine2]= useState('')
    const [PostalAddressLine3, setPostalAddressLine3]= useState('')
    const [id, setId] = useState(props.location.state.selection)

    const handleToolbarButtonClick=(buttonId)=> {
       if("close" === buttonId){           
           props.history.push({
                pathname: '/skillsOrganisationLevies',
                state: { selection: props.location.state.preSelectedProp 
                }
            });
        }else{
            //print Friendly
        }
    }


    useEffect(()=>{
        console.log(props.location.state.FinancialYearID)
        console.log(props.location.state.selection)
        getGrantData(id)
    }, [])
 

    const getGrantData=(id)=>{
        console.log("GETTING DATA")
        // let url = "api/sims/dgapplication/getfindheader?DGApplicationID="+id;
        // utils.invokeUrl(url, (response) => {
        //     setOrganisationName(response.OrganisationName)
        //     setStatus(response.Status)
        // },
        //     null,
        //     {
        //         method: 'GET',
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //             'Authorization': "Bearer " + localStorage.getItem("token")
        //         }
        //     }
        // )
    }

        
        return (
            <ViewContainer topPadding="0">
                 <table width="100%">
                <tbody>
                <tr>
                    <td>
                        <EnhancedToolbar 
                        highlight={false} 
                        title="Grants" 
                        buttonClickHandler={(buttonId) => handleToolbarButtonClick(buttonId)}
                        enabled={true}
                        config={toolbarConfig} />
                    </td>
                </tr>
            
                </tbody>
            </table>


   
            {props.location.state.FinancialYearID === null ? <p align="center">PLEASE SELECT FINANCIAL YEAR</p> :
         <TableContainer className={classes.container}>
            <Table className={classes.table} size="small" >

            <TableHead>
                <StyledTableCell align="center" colspan="2">  { "Discretionary Grants"} </StyledTableCell>
            </TableHead>
            <TableBody>
                <StyledTableRow >
                    <StyledTableTextCell >Organisation Legal Name</StyledTableTextCell >
                    <StyledTableCell >{OrganisationName}</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Trade Name
                    </StyledTableTextCell >
                    <StyledTableCell >{TradeName}</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Year of Statement
                    </StyledTableTextCell >
                    <StyledTableCell >{FinancialYear}</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Postal Address
                </StyledTableTextCell >
                    <StyledTableCell >{PostalAddressLine1}</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Contact Telephone Number

                </StyledTableTextCell >
                    <StyledTableCell >{ContactTelephoneNumber}</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Fax Number

                </StyledTableTextCell >
                    <StyledTableCell >{FaxNumber}</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Municipality 

                </StyledTableTextCell >
                    <StyledTableCell >{Municipality}</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Physical Address
                </StyledTableTextCell >
                    <StyledTableCell >{PhysicalAddressLine1}</StyledTableCell> 
                </StyledTableRow>
                
            <TableHead>
                <StyledTableCell align="left" colspan="3">  Legend </StyledTableCell>
            </TableHead>





            <StyledTableRow >
                    <StyledTableTextCell >            Receipt Date</StyledTableTextCell >
                    <StyledTableCell > Date of payment to SARS</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Received by SETA 
                    </StyledTableTextCell >
                    <StyledTableCell >Date the money was received by SETA from the 
                    Department of Labour</StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >Year of Statement
                    </StyledTableTextCell >
                    <StyledTableCell >
                Stakeholder Levy 
                Calculated (100%)
                </StyledTableCell> 
                </StyledTableRow>
                <StyledTableRow >
                    <StyledTableTextCell >
                    The sum of the levy received by SETA + NSF 
                    calculation + interest + penalty
                </StyledTableTextCell >
                    <StyledTableCell >
                    NSF Calculation 
                    (20%)</StyledTableCell> 
                </StyledTableRow>

           
          </TableBody>
        </Table>
        </TableContainer>}

            </ViewContainer>
        );
    }
export default Grants;

