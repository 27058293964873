import React from 'react';
import { EntityForm } from '../widgets/Forms';
import { GridItem } from '../widgets/GridItem';

export const EditableTable = React.forwardRef((props, ref) => {
    return (
        <EntityForm ref={ref} org="saseta" valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} >
                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <GridItem
                                                id="props.id"
                                                multiSelect={props.multiSelect}
                                                headCells={props.headCells}
                                                searchParameters={props.searchParameters}
                                                label={props.label}
                                                handleSelectionChange={(selection) => {props.handleSelectionChange(selection)}}
                                                className="w-100"
                                                dataUrl={props.dataUrl}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            }
        </EntityForm>
    );
})
