import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

export default function PersonSearchForm(props) {
    const [labelWidth, setLabelWidth] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (labelRef.current !== null) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, []);

    return (
        <div className='w-100' style={{ width: "100%" }}>
            <table cellPadding="2" width="100%">
                <tbody>
                    <tr>
                        <td>
                            <FormControl className="w-100" variant="outlined">
                                <InputLabel ref={labelRef} htmlFor="IDNo">
                                    ID Number
								</InputLabel>
                                <OutlinedInput
                                    id="IDNo"
                                    onChange={(ev) => props.model.IDNo = ev.target.value}
                                    labelWidth={labelWidth}
                                    onKeyPress={props.onKeyUp}
                                />
                            </FormControl>
                        </td>
                        <td>
                            <FormControl className="w-100" variant="outlined">
                                <InputLabel ref={labelRef} htmlFor="Surname">
                                    Surname
								</InputLabel>
                                <OutlinedInput
                                    id="Surname"
                                    onChange={(ev) => props.model.Surname = ev.target.value}
                                    labelWidth={labelWidth}
                                    onKeyPress={props.onKeyUp}
                                />
                            </FormControl>
                        </td>
                        <td>
                            <FormControl className="w-100" variant="outlined">
                                <InputLabel className="w-100" ref={labelRef} htmlFor="FirstName">
                                    First Name
								</InputLabel>
                                <OutlinedInput
                                    id="FirstName"
                                    onChange={(ev) => props.model.FirstName = ev.target.value}
                                    labelWidth={labelWidth}
                                    onKeyPress={props.onKeyUp}
                                />
                            </FormControl>
                        </td>
                        <td>
                            <FormControl className="w-100" variant="outlined">
                                <InputLabel className="w-100" ref={labelRef} htmlFor="MiddleName">
                                    Middle Name
								</InputLabel>
                                <OutlinedInput
                                    id="MiddleName"
                                    onChange={(ev) => props.model.MiddleName = ev.target.value}
                                    labelWidth={labelWidth}
                                    onKeyPress={props.onKeyUp}
                                />
                            </FormControl>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
