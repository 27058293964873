import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import EditForm from './OrganisationSDFEditForm';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import AlertItem from "../../widgets/AlertItem";
import ViewUtils from '../../ViewUtils';
import LinkDialog from "../../etqa/LinkDialog";
import LetterReasonDialog from "../WspAtrForms/Controls/LetterReasonDialog";

const utils = new ViewUtils();

export default function OrganisationSDFEditDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [Message, setMessage] = React.useState("");
    const [MessageStyle, setMessageStyle] = React.useState("");
    const formRef = React.createRef();
    const [selectedLinkType, setSelectedLinkType] = React.useState(null);
    const [linkDialogOpen, setLinkDialogOpen] = React.useState(null);

    const handleClose = (cancel, save, response) => {
        setOpen(false);
        props.closeHandler(cancel, save, response);
    };

    const cancel = () => {
        handleClose(true, false);
    };

    const CheckStatus = () => {
        let formValue = formRef.current.getValue();
        //formValue.Id = props.Id;

        if (formValue !== null && typeof formValue !== 'undefined') {
            if (formValue.SDFStatusID.Id === "3" || formValue.SDFStatusID.Id === 3) { //Rejection
                setLinkDialogOpen(true);
                setMessage("");
            }
            else {
                update(null);
            }
        }
    }

    const update = (selection) => {
        let formValue = formRef.current.getValue();
        //formValue.Id = props.Id;

        if (formValue !== null && typeof formValue !== 'undefined') {
            let ReasonsList = "";
            if (selection !== null) {
                for (let i = 0; i < selection.length; i++) {
                    if (i === 0) { ReasonsList += selection[i].Id; }
                    else { ReasonsList += "," + selection[i].Id; }

                }
            }

            let SaveObject = { ...formValue, Reasons: ReasonsList };

            const url = `api/sims/organisationsdf/updateorganisationsdf`;

            utils.invokeUrl(url, (response) => {
                handleClose(false, true, response);
            }, () => { handleClose(false, false); },
                {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(SaveObject)
                }
            );
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const postLinkHandler = (isCancel, linked) => {
        setLinkDialogOpen(false);
    }

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}>
                <div style={{ padding: "32px" }}>

                    <div style={{ paddingLeft: "24px", paddingRight: "44px" }}>
                        <AlertItem message={Message} alertStyle={MessageStyle} />
                    </div>

                    <div style={{ height: "80%" }}>
                        <EditForm ref={formRef} id={props.id} className="h-100" />
                    </div>
                    <DialogActions>
                        <Button onClick={CheckStatus} color="primary">
                            Update
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            <LinkDialog open={linkDialogOpen} type={selectedLinkType}
                title="Rejection Reasons"
                closeHandler={(isCancelled, linked) => postLinkHandler(isCancelled, linked)}
                dialogTitle="Rejection Reasons"
                linkName={"Rejection"} linkHandler={(selection) => update(selection)}
                selectionId={props.id}
                buttonName="Save"
            >
                <LetterReasonDialog
                    dataUrl={"api/sims/OrganisationSDF/GetSDFRejectionLetterReasons"}
                    multiSelect={true} showTitle={false}
                    mode="lookup"
                    LetterType="SDF"
                />
            </LinkDialog>
        </div>
    );
}