import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import SearchView from "../../widgets/SearchView";
import Prompt from '../../widgets/Prompt';
import InternshipsSetupSearchForm from '../program/InternshipsSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'SAQAQualificationID', numeric: false, disablePadding: true, label: 'SAQA Qualification ID' },
    { id: 'SAQAQualificationTitle', numeric: false, disablePadding: true, label: 'SAQA Qualification Title' },
    { id: 'ContractNumber', numeric: false, disablePadding: true, label: 'Contract Number' },
    { id: 'InternshipType', numeric: false, disablePadding: true, label: 'Internship Type' },
    { id: 'InternshipStatus', numeric: false, disablePadding: true, label: 'Internship Status' },
    { id: 'InternshipStartDate', numeric: false, disablePadding: true, label: 'Internship Start Date' },
    { id: 'InternshipEndDate', numeric: false, disablePadding: true, label: 'Internship End Date' }
];



const utils = new ViewUtils();

class LearnerInternship extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.location.state.learnerID) ? [
            { Name: "SAQAQualificationID", Value: "" },
            { Name: "SAQAQualificationTitle", Value: "" },
            { Name: "ProgrammeID", Value: this.props.programmeId },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]
            :
            [
                { Name: "SAQAQualificationID", Value: "" },
                { Name: "SAQAQualificationTitle", Value: "" },
                { Name: "LearnerID", Value: this.props.location.state.learnerID }
            ];

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                SAQAQualificationID: "",
                SAQAQualificationTitle: "",
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                SAQAQualificationID: "",
                SAQAQualificationTitle: "",
                LearnerID: this.props.location.state.learnerID
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }


        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                model: model,
                keyUpSwitch: false,
                toolbarConfig: {
                    items: [
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                            ]
                        }
                        ,
                        {
                            type: "devider"
                        },
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', visible: this.props.menuPermissionItem.View },
                                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add},
                                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit},
                                { id: "delete", label: "", tooltip: "Remove internship", icon: "DeleteIcon", visible: this.props.menuPermissionItem.Delete}
                            ]
                        }
                    ]
                }
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };

        //TODO: Add url for Internship removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddLearnerInternships',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    InternshipID: 0,
                    hideFields: true,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/AddLearnerInternships',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    InternshipID: this.state.selectedLearnerInternship[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "view") {
            this.props.history.push({
                pathname: '/AddLearnerInternships',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    InternshipID: this.state.selectedLearnerInternship[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem,
                    viewOnly: true
                }
            })
        }
    }


    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerInternship: selection })
        } else {
            this.setState({ selectedLearnerInternship: null })
        }
    }


    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Internships > ${this.props.location.state.learnerNames}`}
                </p>
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/learnerinternship/GetLearnerInternshipSearch" : this.props.dataUrl}
                    deleteUrl={"api/sims/learnerinternship/unlinkLearnerInternship"} useDeletePost={true}
                    entityViewPath='/AddLearnerInternship'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="InternshipProgrammeDescription" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Learner Internship"}
                    promptContent={"Are you sure you want to remove the learner Internship record?"}
                >
                    <InternshipsSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LearnerInternship));

