import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import CustomMaterialTable from "../../widgets/CustomMaterialTable";
import ViewUtils from '../../ViewUtils';



const customCellRender = (rowData, valueProperty, columnIndex) => {
    let value = parseInt(rowData[valueProperty]);
    let rowId = JSON.stringify(rowData.Id);
    let columnId = JSON.stringify(columnIndex);

    const FileDownload = (rowId, columnId) => {

        utils.invokeUrl("api/sims/expirynotification/ExportExcel?rowId=" + rowId + "&columnId=" + columnId, (response) => {

            var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );

    };

    const base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    return (
        value > 0 ?
            <p><a onClick={() => { FileDownload(rowId, columnId) }}>
                {value}
            </a></p>
            
            :
            <p>{value}</p>
    );
};

const columns = [

    { title: "Category", field: "Category" },
    {
        title: "Financial Year",
        field: "FinancialYear",
        headerStyle: {
            width: "45%",
        },
        cellStyle: {
            width: "45%",
        }
    },
    { title: "Apr", field: "April", render: data => customCellRender(data, "April", 1)},
    { title: "May", field: "May", render: data => customCellRender(data, "May", 2) },
    { title: "Jun", field: "June", render: data => customCellRender(data, "June", 3) },
    { title: "Jul", field: "July", render: data => customCellRender(data, "July", 4) },
    { title: "Aug", field: "August", render: data => customCellRender(data, "August", 5) },
    { title: "Sep", field: "September", render: data => customCellRender(data, "September", 6) },
    { title: "Oct", field: "October", render: data => customCellRender(data, "October", 7) },
    { title: "Nov", field: "November", render: data => customCellRender(data, "November", 8) },
    { title: "Dec", field: "December", render: data => customCellRender(data, "December", 9) },
    { title: "Jan", field: "January", render: data => customCellRender(data, "January", 10) },
    { title: "Feb", field: "February", render: data => customCellRender(data, "February", 11) },
    { title: "Mar", field: "March", render: data => customCellRender(data, "March", 12) },
]
const utils = new ViewUtils();
class ExpiryNotification extends Component {



    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    Expiry Notification
                </p>

                <br/>

                <CustomMaterialTable
                    columns={columns}
                    dataUrl="api/sims/expirynotification/GetExpiryNotification"
                    paged={true}
                    rowsPerPage={5}
                />
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(ExpiryNotification)
);
