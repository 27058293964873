import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const ProviderAccreditationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="providerAccreditationForm" values={props.data}
            dataURL="api/indicium/Provider/getAccreditationDetails"
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="providerAccreditationForm">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=ProviderType"
                                        id="ProviderTypeID"
                                        label="Provider Type"
                                        key={tabValue}
                                        className='w-100'
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=ProviderClass"
                                        id="ProviderClassID"
                                        label="Provider ID"
                                        key={tabValue}
                                        className='w-100'
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=ProviderAccreditationStatus"
                                        id="ProviderAccreditationStatusID"
                                        label="Provider Accreditation Status"
                                        key={tabValue}
                                        className='w-100'
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomDatePicker
                                        id="AccreditationStartDate"
                                        key={tabValue}
                                        label="Accreditation Start Date"
                                        className='{classes.textField} w-100'
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="AccreditationEndDate"
                                        key={tabValue}
                                        label="Accreditation End Date"
                                        className='{classes.textField} w-100'
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="ProviderAccreditationNumber"
                                        key={tabValue}
                                        label="Provider Accreditation Number"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={form.values.BEEProvider === true}
                                                key={tabValue}
                                                value={form.values.BEEProvider || false}
                                                onChange={form.handleCheckboxChange('BEEProvider')}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                            />
                                        }
                                        label="BEE Provider"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=ProviderApplication"
                                        id="ProviderApplication"
                                        label="Provider Application"
                                        key={tabValue}
                                        className='w-100'
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=QualityAssuranceBody"
                                        id="QualityAssuranceBody"
                                        label="Quality Assurance Body"
                                        key={tabValue}
                                        className='w-100'
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="SAQACode"
                                        key={tabValue}
                                        label="SAQA Code"
                                        className="w-100"                                     
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="SAQAProviderCode"
                                        key={tabValue}
                                        label="SAQA Provider Code"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
