import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Role.css";
import ViewUtils from "./../../ViewUtils";
import SearchView from "../../widgets/SearchView";
import { ViewContainer } from "./../../View.jsx";
import RolePermissionSearchForm from './RolePermissionSearchForm';


const headCells = [
    { id: 'MenuItemName', numeric: false, disablePadding: true, label: 'Menu Item Name' },
    { id: 'Viewable', numeric: false, disablePadding: true, label: 'Viewable' },
    { id: 'Add', numeric: false, disablePadding: true, label: 'Add ' },
    { id: 'Edit', numeric: false, disablePadding: true, label: 'Edit' },
    { id: 'Delete', numeric: false, disablePadding: true, label: 'Delete' }
];

const utils = new ViewUtils();

class RolePermissionSearch extends Component {



    constructor(props) {
        super(props);

        this.state = {
            linkDialogOpen: false,
            model: {
                RoleID: this.props.location.state.selection,
                MenuItemName: ""
            },
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'Close', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                        { id: 'delete', label: '', tooltip: 'Delete', icon: 'DeleteIcon', disabled: true }
                    ]
                }

            ]
        };
    }

    searchParameters = [
        { Name: "RoleID", Value: this.props.location.state.selection },
        { Name: "MenuItemName", Value: "" }

    ]

    handleToolbarButtonClick = (buttonId) => {
        if ("add" == buttonId) {
            this.props.history.push({
                pathname: "/rolePermissionMaintenance",
                state: {
                    RoleID: this.props.location.state.selection,
                    RoleName: this.props.location.state.RoleName,
                    RoleSAQACode: this.props.location.state.RoleSAQACode
                }
            })
        }
        else if ("edit" === buttonId) {
            this.props.history.push({
                pathname: "/rolePermissionMaintenance",
                state: {
                    RoleID: this.props.location.state.selection,
                    RoleName: this.props.location.state.RoleName,
                    RoleSAQACode: this.props.location.state.RoleSAQACode,
                    selection: this.state.selectedPermissionID
                }
            })
        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: "/roleMaintenance",
                state: {
                    selection: this.props.location.state.selection,
                    RoleName: this.props.location.state.RoleName,
                    RoleSAQACode: this.props.location.state.RoleSAQACode
                }
            })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedPermissionID: selection[0].Id
            });
        } else {
            this.setState({
                selectedPermissionID: null
            });
        }
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"Role Maintenance > Roles & Permissions > " + this.props.location.state.RoleName + " - " + this.props.location.state.RoleSAQACode + " > Permissions"}
                </p>
                <SearchView headCells={headCells}
                    dataUrl="api/sims/permission/getsearch"
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="Surname" mode={this.props.mode}
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch} deleteUrl={"api/sims/permission/removepermission"} useDeletePost={true}
                    promptTitle={"Remove Permission"} promptContent={"Are you sure you want to remove this permission?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <RolePermissionSearchForm model={this.state.model} />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(RolePermissionSearch);
