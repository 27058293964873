import React from 'react';
import ViewUtils from '../ViewUtils';
import TextField from '@material-ui/core/TextField';
import { BaseField } from "./BaseField"

const AddressCodeItemComponent = React.forwardRef((props, ref) => {
    const utils = new ViewUtils();
    const [provinceId, setProvinceId] = React.useState(props.provinceId);
    const [municipalityId, setMunicipalityId] = React.useState(props.municipalityId);
    const [districtId, setDistrictId] = React.useState(props.districtId);
    const [suburbId, setSuburbId] = React.useState(props.suburbId);
    const [cityId, setCityId] = React.useState(props.cityId);
    const [urbanRuralId, setUrbanRuralId] = React.useState(props.urbanRuralId);
    const [hasProvinceID, setHasProvinceID] = React.useState(props.hasProvinceID);
    const [hasUrbanRuralID, setHasUrbanRuralID] = React.useState(props.hasUrbanRuralID);
    const [hasCityID, setHasCityID] = React.useState(props.hasCityID);
    const [hasMunicipalityID, setHasMunicipalityID] = React.useState(props.hasMunicipalityID);
    const [hasDistrictID, setHasDistrictID] = React.useState(props.hasDistrictID);
    const [hasSuburbID, setHasSuburbID] = React.useState(props.hasSuburbID);
    const base = props.base;

    React.useEffect(() => {
        if (!utils.isNull(props.value)) {
            handleFormValueChange(props.value);
        }
    }, [props.value])

    const handleFormValueChange = (value) => {
        if (props.base.doValidation(props.form.values, value)) {
            let checkToken = true;
            if (typeof props.checkRefreshToken !== 'undefined' && props.checkRefreshToken !== null) {
                checkToken = props.checkRefreshToken;
            }
            if (checkToken) {
                utils.invokeUrl(`api/sims/location/locationByCode?code=${value}`, (data) => {
                    if (!utils.isNull(data)) {
                        setDistrictId(data.DistrictID);
                        setMunicipalityId(data.MunicipalityID);
                        setProvinceId(data.ProvinceID);
                        setUrbanRuralId(data.UrbanRuralID);
                        setSuburbId(data.SuburbID);
                        setCityId(data.CityID);

                        props.form.updateValue(props.addressId + "DistrictID", data.DistrictID);
                        props.form.updateValue(props.addressId + "MunicipalityID", data.MunicipalityID);
                        props.form.updateValue(props.addressId + "ProvinceID", data.ProvinceID);
                        props.form.updateValue(props.addressId + "UrbanRuralID", data.UrbanRuralID);
                        props.form.updateValue(props.addressId + "SuburbID", data.SuburbID);
                        props.form.updateValue(props.addressId + "CityID", data.CityID);
                    }
                }, null);
            } else {
                utils.invokeUrlExternal(`api/sims/location/locationByCode?code=${value}`, (data) => {
                    if (!utils.isNull(data)) {
                        setDistrictId(data.DistrictID);
                        setMunicipalityId(data.MunicipalityID);
                        setProvinceId(data.ProvinceID);
                        setUrbanRuralId(data.UrbanRuralID);
                        setSuburbId(data.SuburbID);
                        setCityId(data.CityID);

                        props.form.updateValue(props.addressId + "DistrictID", data.DistrictID);
                        props.form.updateValue(props.addressId + "MunicipalityID", data.MunicipalityID);
                        props.form.updateValue(props.addressId + "ProvinceID", data.ProvinceID);
                        props.form.updateValue(props.addressId + "UrbanRuralID", data.UrbanRuralID);
                        props.form.updateValue(props.addressId + "SuburbID", data.SuburbID);
                        props.form.updateValue(props.addressId + "CityID", data.CityID);
                    }
                }, null);
            }
        }
    }
    
    const handleChange = (id) => event => {
        let value = event.target.value;
        props.form.doHandleChange(id, value);
        handleFormValueChange(value)
    };

    return (
        <div>
            <TextField
                id={props.id}
                InputProps={props.InputProps}
                ref={props.base.reference}
                required={props.base.required}
                label={props.label}
                value={base.valueObject[base.valueProperty] || ''}
                className={props.multiline ? props.styles.TextArea : props.className}
                onChange={handleChange(props.id)}
                error={props.base.hasError}
                helperText={props.base.errorMessage}
                margin="normal"
                disabled={props.disabled}
                size="small"
                variant="outlined">
            </TextField>

            {
                hasProvinceID ?
                    <TextField
                        id={props.addressId + "ProvinceID"}
                        InputProps={{
                            readOnly: true,
                        }}
                        className={props.className}
                        label={props.addressId + " Province"}
                        value={!utils.isNull(provinceId) ? provinceId.Description : ''}
                        size="small"
                        variant="outlined">
                    </TextField>
                    :
                    ""
            }

            {
                hasMunicipalityID ?
                    <TextField
                        id={props.addressId + "MunicipalityID"}
                        InputProps={{
                            readOnly: true,
                        }}
                        className={props.className}
                        label=" Municipality"
                        value={!utils.isNull(municipalityId) ? municipalityId.Description : ''}
                        size="small"
                        variant="outlined">
                    </TextField>
                    :
                    ""
            }
           
            {
                hasDistrictID ?
                <TextField
                    id={props.addressId + "DistrictID"}
                    InputProps={{
                        readOnly: true,
                    }}
                    className={props.className}
                    label={props.addressId + " District"}
                    value={!utils.isNull(districtId) ? districtId.Description : ''}
                    size="small"
                    variant="outlined">
                </TextField>
                :
                ""
            }

            {
                hasUrbanRuralID ?
                    <TextField
                        id={props.addressId + "UrbanRuralID"}
                        //required flag defaulted to true as getLocationByCode on backend not always checking for NULL UrbanRuralID
                        required={props.requiredURFlag ? props.requiredURFlag : true}
                        InputProps={{
                            readOnly: true,
                        }}
                        className={props.multiline ? props.styles.TextArea : props.className}
                        label={props.addressId + " Urban Rural"}
                        value={!utils.isNull(urbanRuralId) ? urbanRuralId.Description : ''}
                        size="small"
                        variant="outlined">
                    </TextField>
                    :
                    ""
            }

            {
                hasSuburbID ?
                    <TextField
                        id={props.addressId + "SuburbID"}
                        InputProps={{
                            readOnly: true,
                        }}
                        className={props.multiline ? props.styles.TextArea : props.className}
                        label={props.addressId + " Suburb"}
                        value={!utils.isNull(suburbId) ? suburbId.Description : ''}
                        size="small"
                        variant="outlined">
                    </TextField>
                    :
                    ""
            }

            {
                hasCityID ?
                    <TextField
                        id={props.addressId + "CityID"}
                        InputProps={{
                            readOnly: true,
                        }}
                        className={props.multiline ? props.styles.TextArea : props.className}
                        label={props.addressId + " City"}
                        value={!utils.isNull(cityId) ? cityId.Description : ''}
                        size="small"
                        variant="outlined">
                    </TextField>
                    :
                    ""
            }
        </div>
    );
})

export function AddressCodeItem(props) {

    return (
        <BaseField ref={React.createRef()} form={props.form} required={props.required} id={props.id} className={props.className}
            validator={props.validator} validationRegex={props.validationRegex} validationGroup={props.validationGroup}
            validationMessage={props.validationMessage} parentId={props.parentId}>
            {base =>
                <AddressCodeItemComponent
                    id={props.id}
                    base={base}
                    addressId={props.addressId}
                    provinceId={props.provinceId}
                    municipalityId={props.municipalityId}
                    requiredURFlag={props.requiredURFlag}
                    districtId={props.districtId}
                    urbanRuralId={props.urbanRuralId}
                    suburbId={props.suburbId}
                    cityId={props.cityId}
                    InputProps={props.InputProps}
                    required={props.required}
                    label={props.label}
                    className={props.className}
                    readOnly={props.readOnly}
                    form={props.form}
                    styles={props.styles}
                    disabled={props.disabled}
                    value={props.value}
                    hasMunicipalityID={props.hasMunicipalityID}
                    hasProvinceID={props.hasProvinceID}
                    hasCityID={props.hasCityID}
                    hasDistrictID={props.hasDistrictID}
                    hasUrbanRuralID={props.hasUrbanRuralID}
                    hasSuburbID={props.hasSuburbID}
                    checkRefreshToken={props.checkRefreshToken}
                >
                </AddressCodeItemComponent>
            }
        </BaseField>
    );
}