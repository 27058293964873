import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./AdminReport.css";
import SearchView from "../../widgets/SearchView";
import AdminReportSearchForm from './AdminReportSearchForm';
import withMenuPermission from "../../widgets/withMenuPermission";
import { ViewContainer } from "./../../View.jsx";

const headCells = [
    { id: 'ReportName', numeric: false, disablePadding: true, label: 'Report Name' },
    { id: 'ReportDescription', numeric: false, disablePadding: true, label: 'Report Description' }
];

class AdminReportSearch extends Component {
    constructor(props) {
        super(props)

        this.searchParameters = [
            { Name: "ReportName", Value: "" },
            { Name: "ReportDescription", Value: "" },
        ]

        let model = {
            ReportName: "",
            ReportDescription: ""
        };

        this.state = {
            model: model,
            keyUpSwitch: false,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'edit', label: '', tooltip: 'Run Report', icon: 'EditIcon', disabled: true }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Admin Reports"}
                </p>
                <SearchView headCells={headCells} dataUrl="api/sims/AdminReports/getsearch" entityViewPath='/AdminReport'
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="LegalName"
                    mode={this.props.mode}
                    history={this.props.history}
                    multiSelect={this.props.multiSelect}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <AdminReportSearchForm
                        model={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AdminReportSearch));
