import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Benefits.css";
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';
import LinkingSearchForm from './LinkingSearchForm';


const headCells = [

    { id: 'Target', numeric: false, disablePadding: true, label: 'Target' },
    { id: 'Intervention', numeric: false, disablePadding: true, label: 'Intervention' },
    { id: '	Benefit', numeric: false, disablePadding: true, label: '	Benefit' },
    
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                { id: 'delete', label: '', tooltip: 'Delete selected assessor from records', icon: 'DeleteIcon', disabled: true }
            ]
        }
    ]
};

const searchParameters = [

    { Name: "Target", Value: "" },
    { Name: "Intervention", Value: "" },
    { Name: "Benefit", Value: "" }
  
]

class LinkingSearch extends Component {

    state = {
        model: {

            Target: "",
            Intervention: "",
            Benefit: ""
        },
        dialogOpen: false
    };

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/EmailAddForm',

            })
        }
    }

    render() {
        return (
            <CardLayout title={`Benefits Maintenance `} >
            <SearchView headCells={headCells} dataUrl="api/indicium/provider/search" entityViewPath='/LinkingSearch'
                model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                onLookupValueChange={this.props.onLookupValueChange}
                title="Linking"
                descriptionProp=""
                history={this.props.history} hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
            >
                <LinkingSearchForm model={this.state.model} />
                </SearchView>
                </CardLayout >
        );
    }
}

export default withRouter(
    connect()(LinkingSearch)
);
