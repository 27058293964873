import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Skills.css";
import { ViewContainer } from "./../../View.jsx";
import { SkillsRejectionReasonForm } from './SkillsRejectionReasonForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";
const utils = new ViewUtils();


const userRef = React.createRef();

class SkillsRejectionReason extends Component {

    constructor(props) {
        super(props)

        this.state = {
            model: {
                Name: "",
                Description: "",
                LetterType: ""

            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,

        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
                    ]
                },
            ]
        };

    }


    handleToolbarButtonClick(buttonId) {

        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/SkillsRejectionReasonSearch',

            })
        } else if ("save" === buttonId) {
            this.save()
        }
    }
    successCallback(response) {

        // this.props.history.push({
        //     pathname: '/SkillsSearch',
        //
        // })
    }
    error(response) {

    }
    save() {
        let formValue = userRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            //if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
            //    formValue.id = this.props.location.state.selection;
            //    isAdd = false;
            //}
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }
            //let data = JSON.stringify(formValue);

            if (isAdd) {
                const url = `api/sims/skillsrejectionreasons/insertrejectionreasons`;
                utils.saveForm(this, userRef, url, (response) => {
                    this.setState({ message: "Rejection Reason added successfully", messageStyle: "SUCCESS", id: response.EntityId });
                }, this.error);
            } else {
                const url = `api/sims/skillsrejectionreasons/updaterejectionreasons`;
                utils.updateForm(this, userRef, url, (response) => {
                    this.setState({ message: "Rejection Reason updated successfully", messageStyle: "SUCCESS" });
                }, this.error);
            }
        }
    }

    close() {
        this.props.history.push('/SkillsRejectionReasonSearch');
    }

    handleFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            this.setState({
                Name: values.Name
            });
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={utils.getEntityViewTitle(this.props.location.state, "Rejection Reason", this.state.Name)}>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Skills Query Reason " buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true} config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <SkillsRejectionReasonForm ref={userRef}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                    id={this.state.id}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                    editPermission={this.props.menuPermissionItem.Edit}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>

        );
    }
}

export default withRouter(withMenuPermission(SkillsRejectionReason));
