import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Benefits.css";
import CardLayout from '../../widgets/Card';
import { BenefitsTargetAddForm } from './BenefitsTargetAddForm';

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        }
    ]
};

const userRef = React.createRef();

class BenefitsTargetAddFormSearch extends Component {



    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/maintenanceTargets',

            })
        }
    }

    save() {
        let formValue = userRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {
            let data = JSON.stringify(formValue);
            const url = `api/indicium/provider/updateProviderDetails?data=${data}`;
            // TODO : Invoke spinner mask
            fetch(url)
                .then(res => res.json())
                .then((responseJson) => {
                    let response = JSON.parse(responseJson);
                    if (response.MessageType !== "ERROR") {
                        this.props.history.push({
                            pathname: '/BenefitsTargetAddFormSearch'
                        });
                    } else {
                        this.setState({ saveMessage: response.Message, hasSaveError: true });
                    }
                }).catch(() => {
                    this.setState({ saveMessage: "A system error has accured. Please contact your system administrator", hasSaveError: true });
                });
        }
    }

    close() {
        this.props.history.push('/maintenanceTargets');
    }

    render() {
        return (
            <CardLayout >
                <p className="legend">
                 Targets
                </p>

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Add Target" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true} config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <BenefitsTargetAddForm ref={userRef}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                </CardLayout >
        );
    }
}

export default withRouter(BenefitsTargetAddFormSearch);
