import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formTabContainer: {
        height: 'inherit !important',
        overflow: 'hidden !important',
        width: '100%',
        zIndex: '0',
        paddingLeft: '16px'
    },
    formTabPanel: {
        width: '100%',
        height: 'inherit !important',
        overflow: 'hidden !important',
        zIndex: '0',
        paddingLeft: "0px"
    }
}));

export function FormTabContainer(props) {
    const classes = useStyles();

    return (
        <div className={[classes.formTabContainer, "row"].join(' ')}>
            {props.children}
        </div>
    );
}

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div style={{width: "inherit"}}>
    <Typography
          component="div"
          className="w-100"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      >
          <Box p={3} className={classes.formTabPanel}>{children}</Box>
            </Typography>
            </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


