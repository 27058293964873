import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Role.css";
import { ViewContainer } from "./../../View.jsx";
import { RoleForm } from './RoleForm';
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";

const roleRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();
class role extends Component {



    constructor(props) {
        super(props);
        this.state = {
            modal: {
                Description: "",
                SAQACode: ""
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
            RoleName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.RoleName)) ? this.props.location.state.RoleName : null,
            RoleSAQACode: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.RoleSAQACode)) ? this.props.location.state.RoleSAQACode : null
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.state.id !== null ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add },
                        { id: 'rolePermission', label: 'Role Permissions', tooltip: 'Role Permissions', disabled: utils.isNull(this.state.id) }

                    ]
                }
            ]
        };
    }



    handleFormValueChange(values) {
        this.setState({ modal: values });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("rolePermission" === buttonId) {
            this.props.history.push({
                pathname: '/rolePermissionSearch',
                state: {
                    selection: this.state.id,
                    RoleName: this.state.RoleName,
                    RoleSAQACode: this.state.RoleSAQACode
                }
            });
        } else {
            this.close();
        }
    }

    save() {
        let formValue = roleRef.current.getValue();
        console.log(formValue);

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            //if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
            //    formValue.id = this.props.location.state.selection;
            //    isAdd = false;
            //}
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }
            //let data = JSON.stringify(formValue);

            if (isAdd) {
                const url = `api/sims/roledetails/insertrole`;
                utils.saveForm(this, roleRef, url, (response) => {
                    if (response.MessageType === 'SUCCESS') {
                        this.setState({ id: response.EntityId, RoleName: formValue.Description, RoleSAQACode: formValue.SAQACode, message: response.Message, messageStyle: response.MessageType });
                        toolbarRef.current.setButtonDisabled("rolePermission", false);
                    }
                    else {
                        this.setState({ message: response.Message, messageStyle: response.MessageType });
                    }
                }, this.error);
            } else {
                const url = `api/sims/roledetails/updaterole`;
                utils.updateForm(this, roleRef, url, (response) => {
                    this.setState({ message: response.Message, messageStyle: response.MessageType });
                }, this.error);
            }
        }
    }

    error(response) {
        this.setState({ message: "A system error has accured. Please contact your system administrator", messageStyle: "ERROR" });

    }

    close() {
        this.props.history.push('/roleMaintenanceSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title=''>
                <p className="breadcrumbs">
                    {"Role Maintenance > Roles & Permissions" + (this.state.RoleName !== null ? " > " + this.state.RoleName + " - " + this.state.RoleSAQACode : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    ref={toolbarRef}
                                    title="Role"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <RoleForm dataUrl='api/sims/roledetails/getfind' ref={roleRef} id={this.state.id !== null ? this.state.id : typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.state.id !== null ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(role));
