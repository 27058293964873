import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./DGOrganisation.css";
import OrganisationSearchForm from './DGOrganisationSearchForm';
import SearchView from "../../widgets/SearchView";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const headCells = [
    { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
    { id: 'SICCode', numeric: false, disablePadding: true, label: 'SIC Code' },
];
class OrganisationSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            model: {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
                SICCodeID: ""
            },
            keyUpSwitch: false,
            selectedOrganisation: null,
        }


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: utils.isNull(this.state.selectedOrganisation), visible: this.props.menuPermissionItem.Edit },
                    ]
                }
            ]
        };

        this.searchParameters = [
            { Name: "LegalName", Value: "" },
            { Name: "SDLNumber", Value: "" },
            { Name: "TradeName", Value: "" },
            { Name: "SICCodeID", Value: "" }
        ]

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedOrganisation: selection })
        } else {
            this.setState({ selectedOrganisation: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/DGOrganisation'
            })
        }
    }

    render() {
        return (

            <ViewContainer title={""}>

                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/dgorganisation/getsearch"}
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    entityViewPath="/DGOrganisation"
                    title='DG Organisations' 
                    mode={this.props.mode}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="LegalName"
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
           
                >
                    <OrganisationSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(OrganisationSearch));