import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import DocumentUpload from '../../widgets/DocumentUpload';
import ViewUtils from "./../../ViewUtils";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const utils = new ViewUtils();
export default function DocumentsDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [entityId, setEntityId] = React.useState(props.EntityID);
    const [lookupArray, setLookupArray] = React.useState([]);
    const [lookupArrayID, setLookupArrayID] = React.useState({ Id: 0 });

    React.useEffect(() => {
        if (!utils.isNull(props.EntityID)) {
            setEntityId(props.EntityID);
        }
    }, [props.EntityID]);

    React.useEffect(() => {
        if (!utils.isNull(props.showEmployerDocumentType)) {
            utils.invokeUrl(props.getEmployerDocumentType, (response) => {
                setLookupArray(response);
            }, null);
        }
    }, [props.showEmployerDocumentType]);

    React.useEffect(() => {
        if (!utils.isNull(props.showLearnerDocumentType)) {
            utils.invokeUrl(props.getLearnerDocumentType, (response) => {
                setLookupArray(response);
            }, null);
        }
    }, [props.showLearnerDocumentType]);

    const handleClose = (save, cancel) => {
        setOpen(false);
        props.closeHandler(save, cancel)
    };


    const cancel = () => {
        setLookupArrayID({ Id: 0 });
        setFilename("");
        setSize(null);
        setBase64(null);
        setType(null);
        handleClose(false, true);
    };

    const handleChecklistArrayCI = (event) => {
        setLookupArrayID({ Id: event.target.value });
    }

    const save = () => {
        let data = {};
        data.OriginalFileName = filename;
        data.EntityID = entityId;
        data.Document = {};
        data.Document.name = filename;
        data.Document.size = size;
        data.Document.type = type;
        data.Document.base64 = base64;

        if (props.showEmployerDocumentType || props.showLearnerDocumentType) {
            data.DocumentRelatesID = lookupArrayID;
        }

        utils.invokeUrl(props.postUrl, (response) => {
            setLookupArrayID({ Id: 0 });
            setFilename("");
            setSize(null);
            setBase64(null);
            setType(null);
            handleClose(true, false);
        }, () => { handleClose(false, false); },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        );
    }

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}
                style={{ scroll: "body" }}>
                <div style={{ padding: "32px" }}>

                    <p className="legend" >
                        New Document
                    </p>

                    {
                        props.showEmployerDocumentType ?
                            <div className="col form-col">
                                <TextField className="w-30" id={"EmployerDocumentTypeID"} value={lookupArrayID.Id} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => handleChecklistArrayCI(values)}>
                                    <MenuItem value={0} key={0} >  </MenuItem>
                                    {lookupArray.map((lookupitem, index) => (

                                        <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>

                                    ))}
                                </TextField>
                            </div>
                            : props.showLearnerDocumentType ?
                                <div className="col form-col">
                                    <TextField className="w-30" id={"DocumentTypeID"} value={lookupArrayID.Id} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => handleChecklistArrayCI(values)}>
                                        <MenuItem value={0} key={0} >  </MenuItem>
                                        {lookupArray.map((lookupitem, index) => (

                                            <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>

                                        ))}
                                    </TextField>
                                </div> :
                                null
                    }


                    <div style={{ height: "80%" }}>
                        <DocumentUpload showViewer={false} onUploadHandler={onUploadHandler} filename={filename}
                        />
                    </div>


                    <DialogActions>
                        <Button onClick={save} color="primary">
                            Save
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
