import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import EnhancedToolbar from './Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ViewUtils from '../ViewUtils';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';
import '../widgets/ResponsiveTable.css';
const utils = new ViewUtils();

const MULTI_SELECT_CHECKBOX_COL_WIDTH = 60;

function evaluateTableCellWidth(multiSelect, headCell, headCellCount) {
    return multiSelect === true ?
        "calc(" + (typeof headCell.widthRatio !== 'undefined' ? (100 * headCell.widthRatio) + "%" : (100 / headCellCount) + "%") + " - " + (MULTI_SELECT_CHECKBOX_COL_WIDTH / headCellCount) + "px)"
        :
        (typeof headCell.widthRatio !== 'undefined' ? (100 * headCell.widthRatio) + "%" : (100 / headCellCount) + "%");
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, allSelected } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <Thead className={classes.tableHead} style={{ width: "calc(100% - " + props.scrollWidth + "px)" }}>
            <Tr style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                    props.multiSelect === true ?
                        <Th padding="checkbox" width={MULTI_SELECT_CHECKBOX_COL_WIDTH + "px"} className="selectCell">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                //checked={numSelected === rowCount}
                                checked={allSelected}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all' }}
                            />
                        </Th> : null

                }
                {headCells.map(headCell => (
                    <Th
                        key={headCell.id}
                        align={headCell.numeric === true ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableHeaderCell}
                        style={{
                            width: headCell.width
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </Th>
                ))}
            </Tr>
        </Thead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        height: '100%',
        position: 'relative',
        zIndex: '0',
    },
    scrollPadding: {
        padding: '8px !important'
    },
    noScrollPadding: {
        padding: '0 !important',
        width: '1px !important'
    },
    tableBody: {
        display: 'block',
        overflowY: 'auto'
    },
    tableHeaderCell: {
        overflow: 'inherit',
        textAlign: 'left !important',
        color: '#FFFFFF',
        border: '1px solid white'
    },
    tableHead: {
        fontSize: '14px !important',
        display: 'block',
        overflow: 'inherit',
        color: '#FFFFFF',
        backgroundColor: '#01579B'
    },
    tableSelected: {
        backgroundColor: '#00ACC1',
        color: '#FFFFFF',
    },
    tableRow: {
    },
    tableWrapper: {
        height: '100%'
    },
    table: {
        height: '100%',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableRowBold: { fontWeight: 'bold' }
}));

export default function EnhancedTable(props) {

    const utils = new ViewUtils();
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [selectedRowDetails, setSelectedRowDetails] = React.useState([]);
    const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
    const [selectedRow, setSelectedRow] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState(!utils.isNull(props.rows) ? props.rows : []);
    const [allSelected, setAllSelected] = React.useState(false);
    const [scrollWidth, setScrollWidth] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [fetchEnabled, setFetchEnabled] = React.useState(utils.isNull(props.autoFetchData) || props.autoFetchData === true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { actionsToolbar, makeRowBold } = props;
    const [searchParameters, setSearchParameters] = React.useState(props.searchParameters);
    const [headCells, setheadCells] = React.useState(utils.isNull(props.autoHeader) ? props.headCells : []);

    if (!props.autoHeader) {
        for (let i = 0; i < props.headCells.length; i++) {
            let headCell = props.headCells[i];
            headCell.width = evaluateTableCellWidth(props.multiSelect, headCell, props.headCells.length);
        }
    }

    React.useEffect(() => {
        if (!utils.isNull(props.rows)) {
            setRows(props.rows);
        }
    }, [props.rows]);

    React.useEffect(() => {
        if (props.clearGrid) {
            setRows([]);
            setPage(0);
            setRowsPerPage(10);
            setSelectedRow("");
            setSelected([]);
            setAllSelected(false);
            setSelectedRowDetails([]);
            props.onGridCleared();
        }
    }, [props.clearGrid]);

    React.useEffect(() => {
        if (props.clearSelectedRow) {
            setSelectedRow("");
            setSelected([]);
            setAllSelected(false);
            setSelectedRowDetails([]);
            props.onClearSelectedRow();
        }
    }, [props.clearSelectedRow]);

    React.useEffect(() => {
        if (fetchEnabled && !utils.isNull(props.searchParameters) && !utils.isNull(props.dataUrl)) {
            setSelectedRowDetails([]);
            setSelected([]);
            if (!utils.isNull(props.onSelectionChange)) {
                props.onSelectionChange([]);
            }
            setSearchParameters(props.searchParameters);
            let encodedSearchParameters = props.searchParameters;
            if (!utils.isNull(props.searchParameters) && props.searchParameters.length > 0) {
                encodedSearchParameters = [];
                for (let i = 0; i < props.searchParameters.length; i++) {
                    if (!utils.isNull(props.searchParameters[i])) {
                        let searchParam = { ...props.searchParameters[i] };
                        if (!utils.isNull(props.searchParameters[i].Value)) {
                            let searchParamValue = null;
                            if (typeof props.searchParameters[i].Value === 'object' && props.searchParameters[i].Value !== null) {
                                searchParamValue = { ...props.searchParameters[i].Value };
                                searchParamValue.Description = encodeURIComponent(searchParamValue.Description);
                            }
                            else {
                                searchParamValue = encodeURIComponent(props.searchParameters[i].Value);
                            }
                            searchParam.Value = searchParamValue;
                        }
                        encodedSearchParameters.push(searchParam);
                    }
                }
            }

            let requiredValidation = true;
            if (!utils.isNull(props.onSearchRequiredFieldCheck)) {
                requiredValidation = props.onSearchRequiredFieldCheck()
            }
            if (requiredValidation) {
                let checkToken = true;
                if (typeof props.checkRefreshToken !== 'undefined' && props.checkRefreshToken !== null) {
                    checkToken = props.checkRefreshToken;
                }
                if (checkToken) {
                    utils.invokeUrl(props.dataUrl + "?request=" + utils.doGetRequestJson(page, rowsPerPage, encodedSearchParameters, props.paged), (data) => {
                        var result;
                        if (typeof data === "string") {
                            result = JSON.parse(data);
                        } else {
                            result = data;
                        }

                        if (props.autoHeader) {

                            const headCells = []
                            if (data) {
                                for (let i = 0; i < Object.keys(result.Data[0]).length; i++) {
                                    if ((Object.keys(result.Data[0]))[i] !== "Id") {
                                        let ColumnwidthRatio = .047;

                                        if ((Object.keys(result.Data[0]))[i] === "Occupation") {
                                            ColumnwidthRatio = .154
                                        }
                                        headCells.push({
                                            id: (Object.keys(result.Data[0]))[i],
                                            numeric: false,
                                            disablePadding: false,
                                            label: (Object.keys(result.Data[0]))[i].replace(/([A-Z])/g, ' $1').trim(),
                                            widthRatio: ColumnwidthRatio

                                        });
                                    }
                                }
                            }

                            for (let i = 0; i < headCells.length; i++) {
                                let headCell = headCells[i];
                                headCell.width = evaluateTableCellWidth(props.multiSelect, headCell, props.headCells.length);
                            }

                            setheadCells(headCells);

                        }

                        if (!utils.isNull(result) && !utils.isNull(result.Data)) {
                            setRows(result.Data);
                            setTotalNumberOfRows(result.TotalNumberOfRows);
                            if (!utils.isNull(props.onDataArrive)) {
                                props.onDataArrive(result);
                            }

                            updateScrollBarPadding();
                        }
                    }, null);
                } else {
                    utils.invokeUrlExternal(props.dataUrl + "?request=" + utils.doGetRequestJson(page, rowsPerPage, encodedSearchParameters, props.paged), (data) => {
                        var result;
                        if (typeof data === "string") {
                            result = JSON.parse(data);
                        } else {
                            result = data;
                        }

                        if (props.autoHeader) {

                            const headCells = []
                            if (data) {
                                for (let i = 0; i < Object.keys(result.Data[0]).length; i++) {
                                    if ((Object.keys(result.Data[0]))[i] !== "Id") {
                                        let ColumnwidthRatio = .047;

                                        if ((Object.keys(result.Data[0]))[i] === "Occupation") {
                                            ColumnwidthRatio = .154
                                        }
                                        headCells.push({
                                            id: (Object.keys(result.Data[0]))[i],
                                            numeric: false,
                                            disablePadding: false,
                                            label: (Object.keys(result.Data[0]))[i].replace(/([A-Z])/g, ' $1').trim(),
                                            widthRatio: ColumnwidthRatio

                                        });
                                    }
                                }
                            }

                            for (let i = 0; i < headCells.length; i++) {
                                let headCell = headCells[i];
                                headCell.width = evaluateTableCellWidth(props.multiSelect, headCell, props.headCells.length);
                            }

                            setheadCells(headCells);

                        }

                        if (!utils.isNull(result) && !utils.isNull(result.Data)) {
                            setRows(result.Data);
                            setTotalNumberOfRows(result.TotalNumberOfRows);
                            if (!utils.isNull(props.onDataArrive)) {
                                props.onDataArrive(result);
                            }

                            updateScrollBarPadding();
                        }
                    }, null);
                }
            }
        }
        setFetchEnabled(true);
        return;
    }, [props.searchParameters]);

    React.useEffect(() => {
        updateScrollBarPadding();
    }, [rows]);

    function updateScrollBarPadding() {
        var tbody = document.getElementById('mainTableBody');
        if (tbody !== null && typeof tbody !== 'undefined') {
            setScrollWidth(tbody.offsetWidth - tbody.clientWidth);
        }
    }

    window.onresize = function onresize() {
        updateScrollBarPadding();
    }

    function getRequestJson(currentPage, curretRowsPerPage, paged) {
        return utils.doGetRequestJson(currentPage, curretRowsPerPage, searchParameters, paged);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const pageRowIds = rows.map(n => { return n.Id });
            const currentSelected = selected.filter(e => !pageRowIds.includes(e));
            const concatArray = currentSelected.concat(pageRowIds);

            const otherPageSelectedRowDetails = selectedRowDetails.filter(e => !pageRowIds.includes(e.Id));
            const concatArrayRowDetails = otherPageSelectedRowDetails.concat(rows);

            setSelected(concatArray);
            setSelectedRowDetails(concatArrayRowDetails);
            props.onSelectionChange(concatArrayRowDetails);
            checkSelectedAllValue(concatArray, rows);
        }
        else {
            const pageRowIds = rows.map(n => n.Id);
            const newSelected = selected.filter(e => !pageRowIds.includes(e));
            const otherPageSelectedRowDetails = selectedRowDetails.filter(e => !pageRowIds.includes(e.Id));

            setSelected(newSelected);
            setSelectedRowDetails(otherPageSelectedRowDetails);
            props.onSelectionChange(otherPageSelectedRowDetails);
            checkSelectedAllValue(newSelected, rows);
        }
    }

    function doHandleClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (props.multiSelect === true) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);

            }
            else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            }
            else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            }
            else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
        } else {
            newSelected.push(id);
        }

        const pageRowIds = rows.map(n => { return n.Id });
        const otherPageSelectedRowDetails = selectedRowDetails.filter(e => !pageRowIds.includes(e.Id));

        let selectedRows = [];
        for (var i = 0; i < rows.length; i++) {
            let row = rows[i];
            for (var j = 0; j < newSelected.length; j++) {
                if (row.Id === newSelected[j]) {
                    selectedRows.push(row);
                }
            }
        }

        const newSelectedRowDetails = otherPageSelectedRowDetails.concat(selectedRows);
        setSelectedRowDetails(newSelectedRowDetails);
        setSelected(newSelected);
        if (!utils.isNull(props.onSelectionChange)) {
            props.onSelectionChange(newSelectedRowDetails);
        }
        setSelectedRow(id);
        checkSelectedAllValue(newSelected, rows);
    }

    function checkSelectedAllValue(newSelected, rows) {
        const selectedNumberRows = getNumberSelected(newSelected, rows);
        if (selectedNumberRows === rows.length) {
            setAllSelected(true);
        }
        else {
            setAllSelected(false);
        }
    }

    function handleClick(event, id) {
        if (!utils.isNull(props.onCellSelectionChange)) {
            return;
        }
        doHandleClick(event, id);
    }

    function handleCellClick(event, id, headCell) {
        if (headCell.contentType !== "document" && !utils.isNull(props.onCellSelectionChange)) {
            // TODO : Evaluate if this call is necessary
            doHandleClick(event, id);
        }

        else if (!utils.isNull(props.onCellSelectionChange)) {
            props.onCellSelectionChange(id, headCell);
        }
    }

    function handleChangePage(event, newPage) {
        if (utils.isNull(props.multiSelect)) {
            setSelectedRowDetails([]);
            setSelected([]);
            if (!utils.isNull(props.onSelectionChange)) {
                props.onSelectionChange([]);
            }
        }
        let checkToken = true;
        if (typeof props.checkRefreshToken !== 'undefined' && props.checkRefreshToken !== null) {
            checkToken = props.checkRefreshToken;
        }
        if (checkToken) {
            utils.invokeUrl(props.dataUrl + "?request=" + getRequestJson(newPage, rowsPerPage, true), (data) => {
                setRows(data.Data);
                setPage(newPage);
                checkSelectedAllValue(selected, data.Data);
                if (!utils.isNull(props.onDataArrive)) {
                    props.onDataArrive(data);
                }

            }, null);
        } else {
            utils.invokeUrlExternal(props.dataUrl + "?request=" + getRequestJson(newPage, rowsPerPage, true), (data) => {
                setRows(data.Data);
                setPage(newPage);
                checkSelectedAllValue(selected, data.Data);
                if (!utils.isNull(props.onDataArrive)) {
                    props.onDataArrive(data);
                }

            }, null);
        }
    }

    function handleChangeRowsPerPage(event) {
        if (utils.isNull(props.multiSelect)) {
            setSelectedRowDetails([]);
            setSelected([]);
            if (!utils.isNull(props.onSelectionChange)) {
                props.onSelectionChange([]);
            }
        }
        var curretRowsPerPage = + event.target.value;
        let checkToken = true;
        if (typeof props.checkRefreshToken !== 'undefined' && props.checkRefreshToken !== null) {
            checkToken = props.checkRefreshToken;
        }
        if (checkToken) {
            utils.invokeUrl(props.dataUrl + "?request=" + getRequestJson(0, curretRowsPerPage, true), (result) => {
                setRows(result.Data);
                setRowsPerPage(curretRowsPerPage);
                setPage(0);
                checkSelectedAllValue(selected, result.Data);
                if (!utils.isNull(props.onDataArrive)) {
                    props.onDataArrive(result);
                }

                updateScrollBarPadding();
            }, null);
        } else {
            utils.invokeUrlExternal(props.dataUrl + "?request=" + getRequestJson(0, curretRowsPerPage, true), (result) => {
                setRows(result.Data);
                setRowsPerPage(curretRowsPerPage);
                setPage(0);
                checkSelectedAllValue(selected, result.Data);
                if (!utils.isNull(props.onDataArrive)) {
                    props.onDataArrive(result);
                }

                updateScrollBarPadding();
            }, null);
        }
    }

    function handleChangeDense(event) {
        setDense(event.target.checked);
        updateScrollBarPadding();
    }

    function handleCellValueChange(value, row, column) {
        let editedRow = null;

        for (let i = 0; i < rows.length; i++) {
            if (row === rows[i].Id) {
                editedRow = rows[i];
            }
        }

        if (editedRow !== null) {
            editedRow[column] = value;

            if (!utils.isNull(props.cellValueChangeHandler)) {
                props.cellValueChangeHandler(editedRow, column);
            }
        }
    }


    const isSelected = id => selected.indexOf(id) !== -1;

    const getNumberSelected = (selected, rows) => {
        let number = 0;
        const pageRowIds = rows.map(n => n.Id);
        const newSelected = selected.filter(e => pageRowIds.includes(e));
        return newSelected.length;
    }

    return (
        <div>
            <Paper className={classes.paper}>
                <div className='responsive-table' style={{ height: props.height }}>
                    <Table className="table table-hover"
                        cellspacing="0"
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        style={{ width: '100%' }}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={getNumberSelected(selected, rows)}
                            scrollWidth={scrollWidth}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={totalNumberOfRows}
                            headCells={headCells}
                            multiSelect={props.multiSelect}
                            allSelected={allSelected}
                        />
                        <Tbody className={classes.tableBody} style={{ maxHeight: utils.isNull(props.bodyMaxHeight) ? props.bodyMaxHeight : "42vh" }} id="mainTableBody">
                            {stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
                                const isItemSelected = isSelected(row.Id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                let counter = 0;

                                return (
                                    <Tr
                                        style={{ display: 'flex', flexWrap: 'wrap' }}
                                        hover
                                        onClick={event => handleClick(event, row.Id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.Id}
                                        selected={isItemSelected}
                                        className={selectedRow === row.Id ? classes.tableSelected : row.Id < 0 && makeRowBold === true ? classes.tableRowBold : classes.tableRow}

                                    >
                                        {
                                            props.multiSelect === true ?
                                                <Td padding="checkbox" width={MULTI_SELECT_CHECKBOX_COL_WIDTH + "px"} className="selectCell">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        onClick={event => { doHandleClick(event, row.Id); }}
                                                    />
                                                </Td> : null

                                        }
                                        {headCells.map(headCell => (
                                            counter++ === headCells.length - 1 && actionsToolbar !== null && typeof actionsToolbar !== "undefined" ?
                                                <Td
                                                    style={{
                                                        width: headCell.width
                                                    }}
                                                    align="left"
                                                    key={headCell.id}>
                                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => props.buttonClickHandler(buttonId)} config={actionsToolbar} />

                                                </Td> :
                                                headCell.numeric === true ?
                                                    <Td
                                                        style={{
                                                            width: headCell.width
                                                        }}
                                                        align="right"
                                                        key={headCell.id}
                                                        onClick={event => handleCellClick(event, row.Id, headCell)}
                                                    >
                                                        <TableCellContent
                                                            selected={true} row={row.id} column={headCell.id} cellChangeHandler={handleCellValueChange}
                                                            contentValue={row[headCell.id]} contentType={props.contentType} editor={headCell.editor}
                                                        /></Td> :
                                                    <Td
                                                        style={{
                                                            width: headCell.width
                                                        }}
                                                        align="left"
                                                        key={headCell.id}
                                                        onClick={event => handleCellClick(event, row.Id, headCell)}
                                                    >
                                                        <TableCellContent
                                                            selected={true} row={row.Id} column={headCell.id} cellChangeHandler={handleCellValueChange}
                                                            contentValue={row[headCell.id]} contentType={headCell.contentType} editor={headCell.editor}
                                                        />
                                                    </Td>

                                        ))}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>

                </div>
                {props.paged === false ? "" : <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalNumberOfRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
            </Paper>
        </div>

    );
}

class TableCellContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentValue: props.contentValue,
            contentType: props.contentType,
            className: props.className,
            options: [],
            optionsLoaded: false
        }
    }

    getFormattedDate = (date) => {
        if (!utils.isNull(date)) {
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let year = date.getFullYear();

            return day + '/' + month + '/' + year;
        }
    }

    handleDateChange = date => {
        this.setState({ contentValue: this.getFormattedDate(date) });
        this.props.cellChangeHandler(this.getFormattedDate(date), this.props.row, this.props.column);
    };

    handleValueChange(event) {
        this.setState({ contentValue: event.target.value });
        this.props.cellChangeHandler(event.target.value, this.props.row, this.props.column);
    };

    handleSelectValueChange(event) {
        let objectReferenceValue = null;
        let value = event.target.value;

        if (!utils.isNull(this.state.options)) {
            for (let i = 0; i < this.state.options.length; i++) {
                if (value === this.state.options[i].Id) {
                    objectReferenceValue = this.state.options[i];
                }
            }
        }

        this.setState({ contentValue: objectReferenceValue });
        this.props.cellChangeHandler(objectReferenceValue, this.props.row, this.props.column);
    };


    componentDidMount() {
        if (!utils.isNull(this.props.editor) && !utils.isNull(this.props.editor.dataUrl)
            && !this.state.optionsLoaded) {
            utils.invokeUrl(this.props.editor.dataUrl, (data) => {
                this.setState({ options: data });
            }, null);

            this.setState({ optionsLoaded: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contentValue !== this.props.contentValue) {
            this.setState({ contentValue: this.props.contentValue });
        }
    }

    render() {
        let displayValue;

        const linkStyle = {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: 'blue',
        }

        if (!utils.isNull(this.props.editor) && this.props.selected) {
            if (this.props.editor.type === "TEXT") {
                displayValue =
                    <TextField
                        id={"textEditor" + this.props.row}
                        style={{ width: "100%" }}
                        value={this.state.contentValue || ''}
                        onChange={(event) => this.handleValueChange(event)}
                        margin="normal"
                        variant="outlined">
                    </TextField>
            } else if (this.props.editor.type === "DATE") {
                displayValue =
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                            <KeyboardDatePicker
                                id={"dateEditor" + this.props.row}
                                format="dd/MM/yyyy"
                                value={this.state.contentValue || null}
                                onChange={this.handleDateChange}
                                inputVariant="outlined"
                                InputProps={{
                                    readOnly: true
                                }}
                                size="small"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ minWidth: "150px" }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
            } else if (this.props.editor.type === "SELECT") {
                displayValue =
                    <TextField
                        select
                        style={{ width: "100%" }}
                        id={"selectEditor" + this.props.row}
                        value={!utils.isNull(this.state.contentValue) ? this.state.contentValue.Id : null}
                        onChange={(event) => this.handleSelectValueChange(event)}
                        margin="normal"
                        variant="outlined">
                        {
                            this.props.editor.allowEmptyValue === true ?
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem> : <span />
                        }
                        {this.state.options.map(option => (
                            <MenuItem key={option.Id} value={option.Id}>{utils.isNull(option.Description) ? "" : option.Description.trim()}</MenuItem>
                        ))}
                    </TextField>
            }
            else if (this.props.editor.type === "DOCUMENT") {
                displayValue =
                    <a download
                        href={!utils.isNull(this.props.editor.url) ? `${this.props.editor.url}?documentID=${this.props.row}` : null}
                        style={linkStyle}
                        target={"_blank"}
                        onClick={this.props.editor.onClick}
                    >
                        {!utils.isNull(this.state.contentValue) ? this.state.contentValue : "No Document"}
                    </a>
            }
        } else {
            if (this.state.contentType !== 'undefined' && this.state.contentType == 'document') {
                displayValue = <span style={linkStyle}>{this.state.contentValue}</span>
            }
            else {
                displayValue = this.state.contentValue;

                if (!utils.isNull(displayValue) && typeof displayValue === 'object') {
                    displayValue = displayValue.Description;
                }
            }
        }

        return utils.isNull(displayValue) || displayValue.length === 0 ? "" : displayValue;
    }
}