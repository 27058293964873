import React from 'react';
import { EntityForm } from './widgets/Forms';
import { GridItem } from './widgets/GridItem';

//const ref = React.createRef();

const testHeadCells = [
    {
        id: 'Name', numeric: false, disablePadding: false, label: 'Name', widthRatio: .4,
        editor: {
            type: "TEXT"
        }
    },
    {
        id: 'DateOfBirth', numeric: false, disablePadding: false, label: 'Date of Birth', widthRatio: .3,
        editor: {
            type: "DATE"
        }
    },
    {
        id: 'Gender', numeric: false, disablePadding: false, label: 'Gender', widthRatio: .3,
        editor: {
            type: "SELECT",
            dataUrl: "api/indicium/employer/GetLookupList?listId=Gender"
        }
    }
];

const searchParameters = [{ Name: "OrganisationTradeName", Value: "" },
{ Name: "OrganisationSDLNumber", Value: "" },
    { Name: "OrganisationLegalName", Value: "" }];

export const TestForm = React.forwardRef((props, ref) => {
    return (
        <EntityForm ref={ref} viewId="test" org="saseta" valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="testForm">
                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <GridItem
                                                id="Person"
                                                headCells={testHeadCells}
                                                searchParameters={searchParameters}
                                                label="Person"
                                                className="w-100"
                                                dataUrl="api/indicium/demo/editableTableData"
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            }
        </EntityForm>
    );
})
