import React from 'react';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./LearnerDocuments.css"
import EnhancedTable from '../../widgets/ResponsiveTable';

const headCells = [
    { id: 'Position', numeric: false, disablePadding: true, label: 'Position' },
    { id: 'Company', numeric: false, disablePadding: true, label: 'Company' },
    { id: 'Duration', numeric: false, disablePadding: true, label: 'Duration' },
    { id: 'Location', numeric: false, disablePadding: true, label: 'Location' },
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' },
    { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' }
]

export default function EmploymentHistoryDocuments(props, ref) {
    return (
        <EnhancedTable headCells={headCells} onCellSelectionChange={(rowid, columnid) => { props.handleCell(rowid, columnid) }} searchParameters={[{ Name: `${props.moduleName}ID`, Value: props.selection }]} paged={false}
            dataUrl={props.documentsDataUrl !== null ? props.documentsDataUrl : null} 
        />
    );
}
