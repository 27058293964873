import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./MasterFile.css";
import SearchView from "../../widgets/SearchView";
import MasterFileSearchForm from './MasterFileSearchForm';
import CardLayout from '../../widgets/Card';

const headCells = [
    { id: 'ReportName', numeric: false, disablePadding: true, label: 'Report Name' },
    { id: 'ReportDescription', numeric: false, disablePadding: true, label: 'Report Description' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
            ]
        }
    ]
};

const searchParameters = [
    { Name: "ReportName", Value: "" },
    { Name: "ReportDescription", Value: "" },
]

class MasterFileSearch extends Component {

    state = {
        model: {
            ReportName: "",
            ReportDescription: ""
        }
    };

    render() {
        return (
            <CardLayout title='Master File Reports'>
                <SearchView headCells={headCells} dataUrl="api/indicium/MasterFileReport/search" entityViewPath='/MasterFile'
                 model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                onLookupValueChange={this.props.onLookupValueChange}
                descriptionProp="LegalName" mode={this.props.mode}
                history={this.props.history}
            >
            <MasterFileSearchForm model={this.state.model} />
            </SearchView>
            </CardLayout>
        );
    }
}

export default withRouter(
    connect()(MasterFileSearch)
);
