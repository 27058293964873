import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Clients.css";
import SearchView from "../../widgets/SearchView";
import ClientsEmailsSearchForm from './ClientsEmailsSearchForm';
import CardLayout from '../../widgets/Card';

const headCells = [
    { id: 'Subject', numeric: false, disablePadding: true, label: 'Subject' },
    { id: 'Body', numeric: false, disablePadding: true, label: 'Body' },
    { id: 'OriginalFileName', numeric: false, disablePadding: true, label: 'Original File Name' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
               
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                { id: 'delete', label: '', tooltip: 'Delete selected assessor from records', icon: 'DeleteIcon', disabled: true }
            ]
        },

        
    ]
};


const searchParameters = [

    { Name: "Subject", Value: "" },
    { Name: "Body", Value: "" },
    { Name: "OriginalFileName", Value: "" }
    
]

class ClientsEmailsSearch extends Component {

    state = {
        model: {
            Subject: "",
            Body: "",
            OriginalFileName: ""
           
        },
        dialogOpen: false
    };


    //handleFormValueChange(values) {
    //    //this.state.model.SICCodeID = values["SICCodeID"];
    //    let newState = Object.assign({}, this.state);
    //    newState.model.FormType = values["FormType"];
    //    this.setState(newState);
    //}

    //valueChangeHandler = {(values) => this.handleFormValueChange(values)}

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "close") {
            this.props.history.push({
                pathname: '/maintenanceLetterCreation'
            })
        }
    } 

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/EmailAdd'
            })
        }
    } 
    

    close() {
        this.props.history.push('/maintenanceLetterCreation');
    }



    render() {
        return (
            <CardLayout title='Emails'>
                <SearchView headCells={headCells} dataUrl="api/indicium/provider/search" entityViewPath='/clientSearch'
                    model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="client Search" mode={this.props.mode}
                    history={this.props.history} hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                >
                    <ClientsEmailsSearchForm model={this.state.model} />

                </SearchView>
            </CardLayout>
        );
    }
}

export default withRouter(
    connect()(ClientsEmailsSearch)
);
