import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import ViewUtils from '../../../ViewUtils';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import "./BulkAssociation.css";


export default function BulkAssociationSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="GrantSetup" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                    <form className={form.classes.container} id="GrantSearchForm">   
                    <td>
                        <LookupField
                            id="EmployerID"
                            label="Employer"
                            className="filterContainer"
                            form={form}
                        >
                            <EmployerSearch showTitle={false} mode='lookup' dataUrl={"api/sims/" + props.controllerName + "/EmployerSearch"}
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("EmployerID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=FinancialYear"}
                            id="FinancialYearID"
                            label="Financial Year"
                            className="filterContainer"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=DGType"}
                            id="DiscretionaryGrantTypeID"
                            label="Intervention Type"
                            className="filterContainer"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=SocioEconomicStatus"}
                            id="SocioEconomicStatusID"
                            label="Socio Economic Status"
                            className="filterContainer"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <br>
                        </br>
                </form>
            }
        </EntityForm>
    );
}
