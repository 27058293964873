import React from 'react';
import ViewUtils from '../ViewUtils';

export const BaseField = React.forwardRef((props, ref) => {
    const utils = new ViewUtils();
    const [hasError, setHasError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [reference, setReference] = React.useState(ref);
    const [valueObject, setValueObject] = React.useState({});
    const [valueProperty, setValueProperty] = React.useState(null);
    const [required, setRequired] = React.useState(props.required === true || props.form.isFieldRequired(props.id));
    const requiredRef = React.useRef(required);
    const loadingRef = React.useRef(true);

    const doValidation = (values, currentValue) => {
        let path = props.form.getValuePath(props.id, values);
        let result = utils.validateField(path.valueProperty, requiredRef.current,
            path.valueObject, currentValue,
            props.validationRegex, props.validationMessage);

        if (result.valid && !utils.isNull(props.validator) && !utils.isNull(props.validator.script)) {
            result = {};
            result.valid = props.validator.script(values);
            if (!result.valid) {
                result.message = props.validator.message;
            }
        }

        setErrorMessage(result.message);
        setHasError(!result.valid);

        props.form.setFieldError(props.id, typeof result.message === 'undefined' ? null : result.message);

        return result.valid;
    }

    React.useEffect(() => {
        setRequired(props.required);
        requiredRef.current = props.required;
        if (!loadingRef.current) {
            doValidation(props.form.values, null);
        }

        loadingRef.current = false;
    }, [props.required]);

    React.useEffect(() => {
        let path = props.form.getValuePath(props.id, null);
        if (!utils.isNull(path.valueObject) && !utils.isNull(path.valueProperty)) {
            setValueObject(path.valueObject);
            setValueProperty(path.valueProperty);
        }
    }, [props.form.values]);

    React.useEffect(() => {
        let componentRef = reference === null ? React.createRef() : reference;
        setReference(componentRef);
        let field = props.form.addField(props.id, componentRef, props.parentId);

        if (!utils.isNull(field) && !utils.isNull(field.error)) {
            if (field.error.length > 0) {
                setHasError(true);
                setErrorMessage(field.error);
            }
        }
    });

    React.useImperativeHandle(ref, () => ({
        validate: (values, currentValue) => {
            return doValidation(values, currentValue);
        },
        parseValue: (value) => {
            if (!utils.isNull(props.valueParser)) {
                return props.valueParser(value);
            }

            return value;
        },
        getId: () => {
            return props.id;
        },
        getValidationGroup: () => {
            return props.validationGroup;
        }
    }));

    return <span>
        {props.children({
            reference: reference,
            required: required,
            valueObject: valueObject,
            valueProperty: valueProperty,
            hasError: hasError,
            doValidation: doValidation,
            errorMessage: errorMessage
        })}
        </span>
})
