import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const styles = makeStyles({
    TextArea: {
        '& .MuiOutlinedInput-inputMultiline': {
            height: "200px !important",
            width: "600px !important"
        }
    }
})

const utils = new ViewUtils();
export const AudienceOptionsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="user" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="AudienceOptionsForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Audience Options " {...a11yProps(0)} />

                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={form.values.PrimarySDF === true}
                                                        key={tabValue}
                                                        value={form.values.PrimarySDF || false}
                                                        onChange={form.handleCheckboxChange('PrimarySDF')}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                }
                                                label="Primary SDF"
                                            />
                                        </td>

                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={form.values.SecondarySDF === true}
                                                        key={tabValue}
                                                        value={form.values.SecondarySDF || false}
                                                        onChange={form.handleCheckboxChange('SecondarySDF')}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                }
                                                label=" Secondary SDF"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={form.values.TrainingProvider === true}
                                                        key={tabValue}
                                                        value={form.values.TrainingProvider || false}
                                                        onChange={form.handleCheckboxChange('TrainingProvider')}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                }
                                                label="Training Provider"
                                            />
                                        </td>

                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={form.values.Assessors === true}
                                                        key={tabValue}
                                                        value={form.values.Assessors || false}
                                                        onChange={form.handleCheckboxChange('Assessors')}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                }
                                                label=" Assessors"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={form.values.Moderators === true}
                                                        key={tabValue}
                                                        value={form.values.Moderators || false}
                                                        onChange={form.handleCheckboxChange('Moderators')}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                }
                                                label="Moderators"
                                            />
                                        </td>

                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={form.values.Employers === true}
                                                        key={tabValue}
                                                        value={form.values.Employers || false}
                                                        onChange={form.handleCheckboxChange('Employers')}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                }
                                                label="Employers"
                                            />
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

