import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import OFOSearch from './OFOSearch';
import { LookupField } from '../../widgets/LookupField';
import QualificationSetupSearch from './QualificationSetupSearch';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnershipSetupForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="learnershipSetup"
            dataURL='api/sims/learnershipsetupdetails/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnershipSetupForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Learnership Setup Details" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="LearnershipCode"
                                                key={tabValue}
                                                label="Learnership Code"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={props.id}
                                                
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="LearnershipTitle"
                                                key={tabValue}
                                                label="Learnership Title"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnershipsetupdetails/GetLookupList?listId=LearnershipType"
                                                id="LearnershipTypeID"
                                                key={tabValue}
                                                label="Learnership Type"
                                                className="w-100"
                                                required
                                                form={form} 
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="QualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                                required
                                            >
                                                <QualificationSetupSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/LearnershipSetupDetails/GetQualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("QualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="MinimumElectiveCredits"
                                                key={tabValue}
                                                label="Minimum Elective Credits"
                                                className="w-100 "
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="This field must be numeric"
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && formValues.Credits <= 1000
                                                    }, message: "This field must be numeric"
                                                }}
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>

                                        <td>
                                            <CustomTextField
                                                id="Credits"
                                                key={tabValue}
                                                label="Credits"
                                                className="w-100 "
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="This field must be numeric"
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && formValues.Credits <= 1000
                                                    }, message: "This field must be numeric"
                                                }}
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnershipsetupdetails/GetLookupList?listId=QualityAssuranceBody"
                                                id="QualityAssuranceBodyID"
                                                key={tabValue}
                                                required
                                                label="Quality Assurance Body"
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnershipsetupdetails/GetLookupList?listId=NQFLevel"
                                                id="NQFLevelID"
                                                key={tabValue}
                                                label="NQF Level"
                                                className="w-100"
                                                required
                                                form={form} 
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="OFO Occupation"
                                                required
                                                className="w-100"
                                                form={form} 
                                                disabled={!props.editPermission}
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/LearnershipSetupDetails/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationStartDate"
                                                key={tabValue}
                                                label="Registration Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationEndDate"
                                                key={tabValue}
                                                label="Registration End Date"
                                                className='{classes.textField} w-100 '
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.RegistrationStartDate)
                                                            && formValues.RegistrationStartDate.getTime() < formValues.RegistrationEndDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
