import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { SelectItem } from '../../widgets/SelectItem';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import DocumentUpload from '../../widgets/DocumentUpload';
import ViewUtils from "./../../ViewUtils";

const utils = new ViewUtils();
export default function ProgramDocumentsDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [entityId, setEntityId] = React.useState(props.EntityID);
    const [docType, setDocType] = React.useState('');

    React.useEffect(() => {
        if (!utils.isNull(props.EntityID)) {
            setEntityId(props.EntityID);
        }
    }, [props.EntityID]);

    const handleClose = (save, cancel) => {
        setOpen(false);
        props.closeHandler(save, cancel)
    };

    const handleDocTypeChange = (DocumentType) => {
        setDocType(DocumentType);
    }
    

    const cancel = () => {
        handleClose(false, true);
    };

    const save = () => {
        let data = {};
        data.OriginalFileName = filename;
        data.EntityID = entityId;
        data.DocumentType = docType;
        data.Document = {};
        data.Document.name = filename;
        data.Document.size = size;
        data.Document.type = type;
        data.Document.base64 = base64;

        utils.invokeUrl(props.postUrl, (response) => {
            handleClose(true, false);
        }, () => { handleClose(false, false); },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        );
    }

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}
                style={{ scroll: "body" }}>
                <div style={{ padding: "32px" }}>

                    <p className="legend" >
                        New Document Upload 
                    </p>

                    <br />

                    <select name="DocumentType" label="Document Type" value={docType} onChange={event => handleDocTypeChange(event.target.value)}>
                        <option id="0" >  </option>
                        <option id="1" >Proof of placement</option>
                        <option id="2" >ID copy</option>
                        <option id="3" >LOA</option>
                        <option id="4" >Work based learning agreement</option>
                        <option id="5" >Highest qualification</option>
                        <option id="6" >Proof of completion</option>
                        <option id="7" >Proof of Termination</option>
                    </select>

                    <br />

                    <div style={{ height: "80%" }}>
                        <DocumentUpload showViewer={false} onUploadHandler={onUploadHandler} filename={filename}
                        />
                    </div>

                    <br />

                    <DialogActions>
                        <Button onClick={save} color="primary">
                            Save
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
