import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewUtils from '../ViewUtils';

const utils = new ViewUtils();
const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        "& tr td": {
            verticalAlign: "top"
        }
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 120,
        minHeight: 48
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
    }
}));

export const EntityForm = React.forwardRef((props, ref) => {
    const [values, setValues] = React.useState(!utils.isNull(props.values) ? props.values : {});
    const [settings, setSettings] = React.useState(null);
    const viewId = props.viewId;
    const entityLookupController = props.entityLookupController;
    const entityLookupId = props.entityLookupId;
    const org = props.org;
    const useCustomSettings = props.useCustomSettings;
    const classes = useStyles();
    const [fields] = React.useState([]);
    const [fieldParentErrors, setFieldParentErrors] = React.useState({});

    const doGetValue = () => {
        let parentErrors = {};
        let currentValue = values;
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];

            if (field.ref.validate(values, null) === false) {
                currentValue = null;
                if (!utils.isNull(field.parentId)) {
                    parentErrors[field.parentId] = true;
                }
            }
        }

        setFieldParentErrors(parentErrors);
        if (currentValue === null) {
            utils.setLegendColor("red");
        } else {
            utils.setLegendColor(null);
        }

        return currentValue;
    }

    React.useImperativeHandle(ref, () => ({
        getValue: () => {
            return doGetValue();
        },

        doUpdateValue: (name, value) => {
            return updateValue(name, value);
        },

        setValue: (id, value) => {
            doHandleChange(id, value)
        }
    }));

    function fetchSettings(touch) {
        const settingsUrl = `api/indicium/` + viewId + `/getViewSettings?organisationId=` + org + `&viewId=` + viewId;
        utils.invokeUrl(settingsUrl, (data) => {
            setSettings(data);
        }, null);
    }

    function parseValues(data) {
        let parsedValues = JSON.parse(JSON.stringify(data));

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            let path = getValuePath(field.id, parsedValues);
            let objectValue = path.valueObject[path.valueProperty];
            path.valueObject[path.valueProperty] = field.ref.parseValue(objectValue);
        }

        setValues(parsedValues);

        if (!utils.isNull(props.dataArrivedHandler)) {
            props.dataArrivedHandler(parsedValues);
        }

        props.valueChangeHandler(parsedValues);
        if (useCustomSettings) {
            fetchSettings(true);
        }
    }

    React.useEffect(() => {
        if (!utils.isNull(props.values)) {
            parseValues(props.values);
        }
    }, [props.values]);

    React.useEffect(() => {
        if (typeof entityLookupId !== 'undefined' && entityLookupId !== null) {
            const url = props.dataURL ? `${props.dataURL}?id=${entityLookupId}` :
                `api/indicium/` + (entityLookupController != null ? entityLookupController : viewId) + `/find?id=${entityLookupId}`;
            utils.invokeUrl(url, (data) => {
                parseValues(data);       
            }, null);
        }

        if (useCustomSettings) {
            fetchSettings(false);
        }
    }, []);

    const getField = (id) => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].id === id) {
                return fields[i];
            }
        }

        return null;
    }

    const removeField = (id) => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].id === id) {
                fields.splice(i, 1);
            }
        }
    }

    const validateField = (field, id, value) => {
        field.error = null;
        let invalid = field.ref.validate({ ...values, [id]: value }, value) === false;
        if (!utils.isNull(field.parentId)) {
            let isParentInvalid = invalid;
            for (let i = 0; i < fields.length; i++) {
                if (fields[i] !== field && fields[i].parentId === field.parentId) {
                    isParentInvalid = isParentInvalid || !(utils.isNull(fields[i].error) || fields[i].error.length === 0);
                }
            }

            setFieldParentErrors({ ...fieldParentErrors, [field.parentId]: isParentInvalid });
        }

        return invalid;
    }

    const getValuePath = (id, currentValues) => {
        let path = {};
        if (currentValues === null) {
            currentValues = values;
        }

        let tokens = id.split(".");
        if (tokens.length === 1) {
            path.valueObject = currentValues;
            path.valueProperty = tokens[0];
        } else if (tokens.length > 1) {
            let vo = currentValues;
            for (let i = 0; i < tokens.length - 1; i++) {
                vo = vo[tokens[i]];
            }

            if (!utils.isNull(vo)) {
                path.valueObject = vo;
                path.valueProperty = tokens[tokens.length - 1];
            }
        }

        return path;
    }

    const doHandleChange = (id, value) => {
        let path = getValuePath(id, null);
        if (!utils.isNull(path.valueObject) && !utils.isNull(path.valueProperty)) {
            path.valueObject[path.valueProperty] = value;
            let invalid = false;
            let field = getField(id);

            if (!utils.isNull(field)) {
                invalid = validateField(field, id, value);
                for (let i = 0; i < fields.length; i++) {
                    let groupField = fields[i];
                    let validationGroup = groupField.ref.getValidationGroup();
                    if (!utils.isNull(validationGroup)) {
                        if (field !== groupField && validationGroup === field.ref.getValidationGroup()) {
                            let groupFieldId = groupField.ref.getId();
                            validateField(groupField, groupFieldId, values[groupFieldId]);
                        }
                    }
                }
            }

            if (!utils.isNull(props.valueChangeHandler)) {
                //props.valueChangeHandler(invalid ? null : { ...values, [id]: value });
                props.valueChangeHandler(invalid ? null : values);
            }
        }
    }

    const handleChange = (id) => event => {
        doHandleChange(id, event.target.value);
    };

    const setFieldError = (id, error) => {
        let field = getField(id);
        if (!utils.isNull(field)) {
            field.error = error;
        }
    }

    const addField = (id, ref, parentId) => {
        let field = getField(id);
        let error = null;

        if (ref.current !== null) {
            if (!utils.isNull(field)) {
                error = field.error;
                removeField(id);
            }

            field = {};
            field.id = id;
            field.ref = ref.current;
            field.error = error;
            field.parentId = parentId;
            fields.push(field);
        }

        return field;
    }

    const handleSelectItemChange = name => event => {
        let objectReference = {};
        objectReference.Id = event.target.value;
        doHandleChange(name, objectReference);
    };

    const handleCheckboxChange = name => event => {
        doHandleChange(name, event.target.checked);
    };

    const updateValue = (name, value) => {
        setValues({ ...values, [name]: value });
        props.valueChangeHandler({ ...values, [name]: value });
    };

    const isFieldRequired = name => {
        return utils.isFieldRequired(name, settings, viewId + "Form");
    };

    const isFieldVisible = name => {
        return utils.isFieldVisible(name, settings);
    };

    const setLookupSelection = (id, value) => {
        let field = getField(id);
        if (utils.isNull(field)) {
            console.warn("Form.jsx - NULL REF " + id + " on setLookupSelection");
        } else {
            field.ref.setSelection(value);
        }
    };

    return <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {props.children({
            handleCheckboxChange: handleCheckboxChange,
            handleSelectItemChange: handleSelectItemChange,
            updateValue: updateValue,
            handleChange: handleChange,
            doHandleChange: doHandleChange,
            isFieldRequired: isFieldRequired,
            isFieldVisible: isFieldVisible,
            classes: classes,
            values: values,
            settings: settings,
            addField: addField,
            setFieldError: setFieldError,
            fields: fields,
            fieldParentErrors: fieldParentErrors,
            getValuePath: getValuePath,
            setLookupSelection: setLookupSelection
        })}</div>
})
