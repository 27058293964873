import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { withAlert } from 'react-alert'
import * as actions from '../../store/actions/auth';
import "./Login.css";
import simslogoLoading from '../../content/images/logos/sims_logo_loading.png'
import LoadingIndicatorLogin from '../LoadingIndicatorLogin';
import ViewUtils from "../ViewUtils";
import { withMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from './ADAuthConfig';

const utils = new ViewUtils();

class ADLoginHandler extends Component {

    state = {
        username: "",
        password: "",
        message: "",
        //display: "none",
        //loading: true
    };

    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 3000, // custom timeout just for this one alert
            type: msgType,
            onOpen: () => {
                //console.log('open')
            }, // callback that will be executed after this alert open
            onClose: () => {
                //console.log('closed')
            } // callback that will be executed after this alert is removed
        })
    }

    componentDidMount() {
        if (this.props.location.pathname !== "/ADlogin") {
            this.props.history.push('/ADlogin');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated && this.props.authenticated) {
            this.props.history.push('/role');
        }
        if (prevProps.otpSent !== this.props.otpSent && this.props.otpSent) {
            this.props.history.push('/confirmOTP');
        }
        if (prevProps.passwordExpired !== this.props.passwordExpired && this.props.passwordExpired) {
            this.props.history.push('/reset');
        }

        if (prevProps.message !== this.props.message && this.props.message) {
            this.setState({ message: this.props.message });
        }

        if (prevProps.loginErrorMessage !== this.props.loginErrorMessage && this.props.loginErrorMessage) {
            this.showAlert(this.props.loginErrorMessage, 'error');
        }

        if (prevProps.loginMessage !== this.props.loginMessage && this.props.loginMessage) {
            this.showAlert(this.props.loginMessage, 'error');
        }
    }

    login = () => {
        this.callLogin();

    }

    callLogin() {
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        const msalInstance = this.props.msalContext.instance;

        // If a user is not logged in and authentication is not already in progress, invoke login
        if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
            msalInstance.loginPopup(loginRequest).then((loginResponse) => {
                this.props.onUserADAuth('/ADlogin', loginResponse.accessToken);
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    render() {

        return (
            <div className="login-container">
                <div className="card-container sec-card">
                    <img src={simslogoLoading} className="img-responsive" />
                    <form>
                        <Button
                            block
                            onClick={this.login}
                            className="loginButton"
                        >
                            Login
                        </Button>
                    </form>
                </div>
                <div className="loginCopyright">
                    @ {new Date().getFullYear()} Copyright SIMS. All rights reserved. Developed By SoluGrowth
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        userDetails: state.auth.userDetails,
        authenticated: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Authenticated : false,
        otpSent: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.OTPSent : false,
        passwordExpired: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.PasswordExpired : false,
        loginMessage: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Message : null,
        loginErrorMessage: state.auth.errorMessage
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUserADAuth: (loginPath, Token) => dispatch(actions.authADUser(loginPath, Token))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(withMsal(ADLoginHandler))));

