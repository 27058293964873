import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';

const headCells = [
    { id: 'AgreementReferenceNumber', numeric: false, disablePadding: true, label: 'Unit Standard Reference No.' },
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'Unit Standard Code' },
    { id: 'ProgrammeTitle', numeric: false, disablePadding: true, label: 'Unit Standard Title' },
    { id: 'CommencementDate', numeric: false, disablePadding: true, label: 'Commencement Date' },
    { id: 'CompletionDate', numeric: false, disablePadding: true, label: 'Completion Date' },
    { id: 'RequiredCredits', numeric: false, disablePadding: true, label: 'Required Credits' },
    { id: 'TotalCredits', numeric: false, disablePadding: true, label: 'Total Credits' },
    { id: 'AchievedCredits', numeric: false, disablePadding: true, label: 'Achieved Credits' },
    { id: 'CertificateNumber', numeric: false, disablePadding: true, label: 'Certificate Number' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'TrainingProvider', numeric: false, disablePadding: true, label: 'Training Provider' }
];



const utils = new ViewUtils();
const toolbarRef = React.createRef();

class LearnerUnitStandardAchievement extends Component {
    constructor(props) {
        super(props);

       

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                UnitStandardProgrammeCode: "",
                UnitStandardProgrammeDescription: "",
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                UnitStandardProgrammeCode: "",
                UnitStandardProgrammeDescription: "",
                LearnerID: this.props.location.state.learnerID
            }

        this.state = 
            {
                model: model,
                showTable: true,
                selectedUnitStandard: null,
                searchParameters: [{ Name: "LearnerID", Value: this.props.location.state.learnerID }],
                keyUpSwitch: false
            }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        };
    }

   

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedUnitStandard: selection[0].Id });
        } else {
            this.setState({ selectedUnitStandard: null });
        }
        toolbarRef.current.setButtonDisabled("achievements", selection === null || selection.length === 0);
    }


    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Unit Standard Achievements > ${this.props.location.state.learnerNames}`}
                </p>
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/LearnerUnitStandardAchievement/GetLearnerAchievedUnitStandardsSearch`}
                            height="60vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />)}

                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(LearnerUnitStandardAchievement)
);
