import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import withMenuPermission from "../../widgets/withMenuPermission";
import ViewUtils from '../../ViewUtils';
import simslogoLoading from '../../../content/images/logos/sims_logo_loading.png';
import LoadingIndicatorLogin from '../../LoadingIndicatorLogin';
import { ViewContainer } from "../../View";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AlertItem from "../../widgets/AlertItem";

const utils = new ViewUtils();

class WspAtrPrintReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            queueData: null
        }
    }

    componentDidMount() {
        this.getFormHead();
    }

    getFormHead() {
        let url = "api/sims/wspatr/GetFindQueue?WSPATRID=" + this.props.WSPATRID;
        utils.invokeUrl(url, (response) => {
            this.setState({
                isLoading: false,
                queueData: response
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    FileDownload = () => {

        var blob = new Blob([this.base64ToArrayBuffer(this.state.queueData.Document.Base64)], { type: this.state.queueData.Document.Type });
        var url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = this.state.queueData.Document.Name;
        link.target = '_blank';
        link.click();

    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    checkGenerateReportActive = () => {
        if (!utils.isNull(this.state.queueData)) {
            if (!utils.isNull(this.state.queueData.WSPATRReportsPrintQueueStatusID) && this.state.queueData.WSPATRReportsPrintQueueStatusID.Id == 3 /*completed*/) {
                return true;
            }
            else if (utils.isNull(this.state.queueData.WSPATRReportsPrintQueueStatusID)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    GenerateReport = () => {
        this.setState({ isLoading: true })
        let url = "api/sims/wspatr/WSPATRPrintQueueStart?WSPATRID=" + this.props.WSPATRID;
        utils.invokeUrl(url, (response) => {
            if (response.MessageType === "SUCCESS") {
                this.getFormHead();
            }
            else {
                this.setState({ isLoading: false })
                this.setState({ message: response.Message, messageStyle: response.MessageType });
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    CheckProgress = () => {
        this.setState({ isLoading: true })
        this.getFormHead();
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <LoadingIndicatorLogin show={this.state.isLoading} />
                {!this.state.isLoading ?
                    <ViewContainer topPadding="0" title="">
                        <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                        <br />
                        <TableContainer component={Paper}>
                            <table className={classes.table} aria-label="simple table" size="small">
                                <tbody>
                                    <TableRow>
                                        <td align="left">
                                            <b>Status</b><br />
                                        </td>
                                        <td align="left">
                                            <TextField id="WSPATRReportsPrintQueueStatus" disabled={true} value={!utils.isNull(this.state.queueData) ? !utils.isNull(this.state.queueData.WSPATRReportsPrintQueueStatusID) ? this.state.queueData.WSPATRReportsPrintQueueStatusID.Description : "" : ""} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }}> </TextField>
                                        </td>
                                    </TableRow>
                                    <TableRow>
                                        <td align="left">
                                            <b>Last Ran</b><br />
                                        </td>
                                        <td align="left">
                                            <TextField id="DateUpdated" disabled={true} value={!utils.isNull(this.state.queueData) ? this.state.queueData.DateUpdated : ""} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }}> </TextField>
                                        </td>
                                    </TableRow>
                                </tbody>
                            </table>
                        </TableContainer>
                        <br />
                        <Button variant="outlined" size="small" id="btnDownloadFile" color="#01579B" disabled={!utils.isNull(this.state.queueData) ? !utils.isNull(this.state.queueData.Document) ? false : true : true} onClick={this.FileDownload}>Download File</Button>&nbsp;
                        <Button variant="outlined" size="small" id="btnGeneratePrintReport" color="#01579B" disabled={!this.checkGenerateReportActive()} onClick={this.GenerateReport}>Generate Report</Button>&nbsp;
                        <Button variant="outlined" size="small" id="btnCheckProgress" color="#01579B" disabled={this.checkGenerateReportActive()} onClick={this.CheckProgress}>Check Progress</Button>&nbsp;
                    </ViewContainer> :
                    null
                }
            </Fragment>
        );
    }
}

export default withRouter(withMenuPermission(WspAtrPrintReport));