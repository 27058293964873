import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import HighchartsReact from 'highcharts-react-official';
import { Card, Col} from 'react-bootstrap';
import Highcharts from 'highcharts';
import './piechart.css';
import { withStyles } from "@material-ui/core/styles";

const styles = {
    fullHeightCard: {
        height: "60%",
    },
}


class PieChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [{
                type: 'pie',
                allowPointSelect: true,
                keys: ['name', 'y', 'selected', 'sliced'],
                data: [
                    ['Achieved', 1, false],
                    ['Enrolled', 3, false],
                    ['Pending Verification', 3, false],
                    ['Withdrawn', 1, false]                   
                ],
                showInLegend: true
            }]
            
        }
    }

    highChartsRender() {
        Highcharts.chart('piecontainer',{
            chart: {
               styledMode: true,
                height: '350px',
            },

            title: {
                text: 'Learner Learneship'
            },

            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            },
            series: this.state.series          
        });
    }

    componentDidMount() {
        this.highChartsRender();
    }
    render() {
        const { chartOptions, hoverData } = this.state;
        const { classes } = this.props;
        return (
            <Col sm="6">
            <Card>                   
                    <Card.Header as="h6"></Card.Header>
                    <Card.Body id="piecontainer"></Card.Body>
            </Card>
            </Col>
            )
    }
}

export default withStyles(styles)(PieChart);   
