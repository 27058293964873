import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BaseField } from "./BaseField";
import ViewUtils from '../ViewUtils';

const utils = new ViewUtils();
const CheckboxComponent = React.forwardRef((props, ref) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.base.valueObject[props.base.valueProperty] || false}
                    key={props.key}
                    ref={props.base.reference}
                    value={props.base.valueObject[props.base.valueProperty] || false}
                    onChange={!utils.isNull(props.onChange) ? props.onChange(props.form) : props.form.handleCheckboxChange(props.id)}
                    error={props.base.hasError}
                    helperText={props.base.errorMessage}
                    size="small"
                    inputProps={props.inputProps}
                    disabled={props.disabled}
                />
            }
            label={props.label}
        />
  );
})

export function CustomCheckbox(props) {

    return (
        <BaseField ref={React.createRef()} form={props.form} required={props.required} id={props.id} className={props.className}
            validator={props.validator} validationRegex={props.validationRegex} validationGroup={props.validationGroup}
            validationMessage={props.validationMessage} parentId={props.parentId}>
            {base =>
                <CheckboxComponent
                    key={props.key}
                    base={base}
                    onChange={props.onChange}
                    id={props.id}
                    InputProps={props.InputProps}
                    required={props.required}
                    label={props.label}
                    className={props.className}
                    readOnly={props.readOnly}
                    form={props.form}
                    styles={props.styles}
                    disabled={props.disabled}
                >
                </CheckboxComponent>
            }
        </BaseField>
    );
}