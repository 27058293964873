import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import FormDialog from "../FormDialog";
import { MonitoringAndEvaluationForm } from "./MonitoringAndEvaluationForm";
import { ViewContainer } from "./../../View.jsx";
import { EditableTable } from "../EditableTable";

const headCells = [
    { id: 'MonitorEvaluationDate', numeric: false, disablePadding: true, label: 'Monitor Evaluation Date' },
    { id: 'MentorName', numeric: false, disablePadding: true, label: 'Mentor Name' },
    { id: 'ReasonForMonitorEvaluation', numeric: false, disablePadding: true, label: 'Reason For Monitor Evaluation' },
    { id: 'Outcomes', numeric: false, disablePadding: true, label: 'Outcome' },
    {
        id: 'Document', numeric: false, document: true, disablePadding: false, label: 'Document',
        editor: {
            type: "DOCUMENT",
            url: '/api/indicium/Employer/getCRMDocument' ,
           // onClick: () => {} //method to be executed when the link is clicked (can be null)
        }
    }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class ProviderMonitoringAndEvaluation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formDialogOpen: false, 
            propmtOpen: false,
            promptTitle: "Remove monitoring and evaluation from provider",
            promptContent: "Are you sure you want to remove this monitoring and evaluation from the provider?",
            Message: "",
            MessageStyle: "",
            searchParameters: [{ Name: "providerID", Value: this.props.location.state.selection }],
            isUpdate: false,
            isAdd: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', tooltip: 'Link Monitoring and Evaluation', label: "Add" },
                        { id: 'remove', tooltip: 'Remove monitoring and evaluation from provider', label: "Remove", disabled: utils.isNull(this.state.selectedId) },
                        { id: 'update', tooltip: 'Update selected monitoring and evaluation', label: "Update", disabled: utils.isNull(this.state.selectedId) },
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/provider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ formDialogOpen: true, isAdd: true })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("update" === buttonId) {
            this.setState({isUpdate: true, formDialogOpen: true})
        }
    }

    postAddHandler = (cancel, save) => {
        if (save === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false,
                selectedId: null,
                isAdd: false,
                isUpdate: false,
            });
        }
        else if (cancel === true) {
            this.setState({
                formDialogOpen: false,
                isAdd: false,
                isUpdate: false,
                Message: "",
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedId: selection[0].Id, Message: "" });
        } else {
            this.setState({ selectedId: null, Message: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("upload", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedId: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    remove(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("upload", true);
            toolbarRef.current.setButtonDisabled("update", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedId}&providerId=${this.props.location.state.selection}`;
        utils.invokeUrl(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={`Providers > ${this.props.location.state.providerName} > Monitoring and Evaluation`}>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EditableTable
                        multiSelect={false}
                        handleSelectionChange={selection => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters}
                        id={"ProviderAssessors"}
                        headCells={headCells}
                        dataUrl={`api/indicium/Provider/getMonitoringAndEvaluationRecords`}
                    />
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Monitoring and Evaluation"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={this.state.isUpdate ? "api/indicium/provider/updateMonitoringAndEvaluation" : "api/indicium/provider/saveProviderMonitoringAndEvaluation"}
                    closeHandler={(isCancelled, linked) => this.postAddHandler(isCancelled, linked)}
                    hasDocumentUpload={true}
                    entityId={this.state.selectedId}
                >
                    <MonitoringAndEvaluationForm
                        dataURL="api/indicium/Provider/getMonitoringAndEvaluation"
                        ref={addFormRef}
                        findParameters={this.state.isAdd ? null : {
                            parentParameterName: "ProviderID", parentId: this.props.location.state.selection,
                            childParameterName: "ID", childId: this.state.selectedId
                        }}
                    />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.remove("api/indicium/provider/removeMonitoringAndEvaluation") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ProviderMonitoringAndEvaluation)
);
