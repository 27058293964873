import React, { Component } from 'react';
import { connect } from 'react-redux';
import ViewUtils from './components/ViewUtils';
import * as actions from './store/actions/auth';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

const utils = new ViewUtils();

class InterfaceVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promptOpen: false,
            promptTitle: "Application Update",
            promptContent: "There is an update available, do you want to continue with the update?",
        }
    }


    componentDidMount() {
        const interfaceVersionValue = utils.GetInterfaceVersion(true);
        this.getInterfaceVersion(interfaceVersionValue);
    }

    componentDidUpdate(prevProps) {
        if (this.props.interfaceVersionUpdateConfirm !== prevProps.interfaceVersionUpdateConfirm && this.props.interfaceVersionUpdateConfirm) {
            this.setState({ promptOpen: true })
        }
    }

    closePrompt = () => {
        this.setState({ promptOpen: false })
        this.props.onInterfaceVersionCheck(false);
        this.props.onInterfaceVersionShouldCheck(false);
    }

    handleYes = () => {
        this.props.onInterfaceVersionCheck(false);
        this.setState({ promptOpen: false })
        window.location.reload(true);
    }

    getInterfaceVersion(interfaceVersionValue) {
        let url = "api/sims/settings/GetInterfaceVersion";
        utils.invokeUrlExternal(url, (response) => {
            console.log("Latest Interface Version: " + response);
            const localStoreName = 'InterfaceVersionTryCount';
            const getCount = localStorage.getItem(localStoreName) ? parseInt(localStorage.getItem(localStoreName)) : 1;
            const latestValue = parseInt(response);
            if (latestValue > interfaceVersionValue && getCount < 3) {
                console.log('Interface Try Count: ' + getCount);
                localStorage.setItem(localStoreName, getCount + 1)
                window.location.reload(true);
            } else if (getCount == 3) {
                localStorage.setItem(localStoreName, 1);
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    render() {
        return (
            <Dialog
                open={this.state.promptOpen}
                onClose={this.closePrompt}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="breadcrumbs" disableTypography id="alert-dialog-title">{this.state.promptTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.promptContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button onClick={this.handleYes} color="primary" autoFocus>
                            Yes
                        </Button>
                        <Button onClick={this.closePrompt} color="primary">
                            No
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        interfaceVersionUpdateConfirm: state.auth.interfaceVersionUpdateConfirm,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInterfaceVersionCheck: (interfaceVersionUpdateConfirm) => dispatch(actions.interfaceVersionCheck(interfaceVersionUpdateConfirm)),
        onInterfaceVersionShouldCheck: (interfaceVersionShouldBeChecked) => dispatch(actions.interfaceVersionShouldCheck(interfaceVersionShouldBeChecked))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InterfaceVersion);