import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TestForm } from './TestForm';

const testFormRef = React.createRef();
class Test extends Component {
    render() {
        return (
            <div style={{ padding: "32px", width: "100%"}}>
                <p class='legend'>Editable Table</p>
                <TestForm ref={testFormRef} valueChangeHandler={(values) => { alert(values) }} />
            </div>
        );
    }
}

export default withRouter(
    connect()(Test)
);
