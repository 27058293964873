import React from 'react';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./LearnerDocuments.css"
import EnhancedTable from '../../widgets/ResponsiveTable';

const headCells = [
    { id: 'ExperienceWork', numeric: false, disablePadding: true, label: 'Experience Work' },
    { id: 'ExperienceWorkDateFrom', numeric: false, disablePadding: true, label: 'Work Date From' },
    { id: 'ExperienceWorkDateTo', numeric: false, disablePadding: true, label: 'Work Date To' },
    { id: 'ReferenceTitle', numeric: false, disablePadding: true, label: 'Reference Title' },
    { id: 'NatureOfWork', numeric: false, disablePadding: true, label: 'Nature Of Work' },
    { id: 'ReferenceName', numeric: false, disablePadding: true, label: 'Reference Name' },
    { id: 'ReferenceSurname', numeric: false, disablePadding: true, label: 'Reference Surname' },
    { id: 'ReferenceContactNumber', numeric: false, disablePadding: true, label: 'Reference Contact Number' },
    { id: 'WhereExperientialWorWasUndertaken', numeric: false, disablePadding: true, label: 'Where Experiential Work Was Undertaken' },
    { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' }
]

export default function ExperientialLearningDocuments(props, ref) {
    return (
        <EnhancedTable headCells={headCells} onCellSelectionChange={(rowid, columnid) => { props.handleCell(rowid, columnid) }} searchParameters={[{ Name: `${props.moduleName}ID`, Value: props.selection }]} paged={false}
            dataUrl={props.documentsDataUrl !== null ? props.documentsDataUrl : null} 
        />
    );
}
