import React from 'react';
import "./widgets.css";
import { Document, Page, pdfjs } from 'react-pdf';
import ViewUtils from '../ViewUtils';

const pdfVersion = "2.6.347"
const pdfWorkerUrl = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfVersion}/pdf.worker.js`
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const utils = new ViewUtils();
const DocumentViewer = (props) => {
    const [pageNumber] = React.useState(1);
    const [numPages, setNumPages] = React.useState(null);


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        if (!utils.isNull(props.onDocumentLoadSuccess)) {
            props.onDocumentLoadSuccess(numPages);
        }
    }

    return (
        <div>
            {
                !utils.isNull(props.file) ?
                    <div style={{ border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "4px", padding: "16px", width: "calc(100% - 32px)" }}>
                        {
                            props.file.type === "application/pdf" ?
                                <div>
                                    <Document
                                        style={{ width: "98%" }}
                                        file={props.file.base64}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                    <p>Page {pageNumber} of {numPages}</p>
                                </div>
                                :
                            <div>
                                {props.imageStyle ?
                                    <div style={{ width: "280px", height: "190px" }}>
                                        <img src={props.file.base64} style={{ width: "275px", height: "185px" }} />
                                        {props.onDocumentLoadSuccess(1)}
                                    </div>
                                    : 
                                    <div>
                                            <img src={props.file.base64} style={{ width: "98%" }} />
                                            {props.onDocumentLoadSuccess(1)}
                                    </div>
                                    }
                            </div>
                        }
                    </div>
                    :
                    ""
            }
        </div>
    );
}

export default DocumentViewer;