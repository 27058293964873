import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import BursarySetupSearch from '../program/BursarySetupSearch';
import ViewUtils from '../../ViewUtils';
import { OutComeStatusForm } from '../moderator/OutComeStatusForm';
import FormDialog from "../FormDialog";
import ModeratorInterventionAudit from '../moderator/ModeratorInterventionAudit';


const headCells = [
    { id: 'BursaryCode', numeric: false, disablePadding: true, label: 'Bursary Code' },
    { id: 'BursaryTitle', numeric: false, disablePadding: true, label: 'Bursary Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'Outcome', numeric: false, disablePadding: true, label: 'Outcome' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class BursarySearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                BursaryCode: "",
                BursaryTitle: "",
                ModeratorID: this.props.location.state.ModeratorID
            },
            showTable: true,
            linkDialogOpen: false,
            auditDialogOpen: false,
            updateDialogOpen: false,
            selectedBursary: null,
            propmtOpen: false,
            promptTitle: "Unlink Bursary Programme From Moderator",
            promptContent: "Are you sure you want to unlink this Bursary from the moderator?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "ModeratorID", Value: this.props.location.state.ModeratorID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Bursary Programme/s to the moderator', icon: 'LinkIcon' },
                        { id: 'update', label: '', tooltip: 'Update the status and outcome of the selected bursary', disabled: utils.isNull(this.state.selectedBursary), icon: 'UpdateIcon' },
                        { id: 'audit', label: '', tooltip: 'View Record Audit Trail', disabled: utils.isNull(this.state.selectedBursary) , icon: 'AuditIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected bursary from the moderator', disabled: utils.isNull(this.state.selectedBursary), icon: 'LinkOffIcon' }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/Moderator',
                state: { selection: this.props.location.state.ModeratorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("update" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedBursary: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
            })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    successUnlinkCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedBursary: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unlinkBursary(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedBursary}&moderatorId=${this.props.location.state.ModeratorID}`;
        utils.invokeUrlDelete(fullUrl, this.successUnlinkCallback, this.unsuccessfulUnlinkCallback);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, auditDialogOpen: false, selectedBursary: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedBursary: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        } else {
            this.setState({
                selectedBursary: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("update", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    unsuccessfulUnlinkCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false, auditDialogOpen: false, selection: this.props.location.state.selection, selectedBursary: null
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false
            })
        }
    }

    render() {
        return (

            <ViewContainer title="">

                <p className="breadcrumbs">
                    {"Moderator > Bursary Programmes " + (this.props.location.state.ModeratorName !== null ? "> " + this.props.location.state.ModeratorName : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (

                        <EnhancedTable
                            height="50vh"
                            headCells={headCells}
                            dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/ModeratorBursary/GetModeratorBursaries"}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.BursaryTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Bursaries to Moderator > ${this.props.location.state.ModeratorName}`}
                    linkName={"Bursaries"} postUrl={"api/sims/ModeratorBursary/linkModeratorBursaries"}
                    selectionId={this.props.location.state.ModeratorID} usePost={true}
                >

                    <BursarySetupSearch dataUrl="api/sims/ModeratorBursary/GetModeratorLinkableBursaries"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.ModeratorID}
                        mode="lookup"
                    />
                </LinkDialog>

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Update Outcome and Status"}
                    viewRef={this}
                    entityId={this.state.selectedBursary}
                    formRef={addFormRef}
                    usePut={true}
                    saveUrl={"api/sims/ModeratorBursary/updateModeratorBursary"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeStatusForm
                        ref={addFormRef}
                        id={this.state.selectedBursary}
                        controllerName={"ModeratorBursary"}
                        dataURL='api/sims/ModeratorBursary/GetFindBursaryOutcome'
                    />
                </FormDialog>

                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedBursary}
                >

                    <ModeratorInterventionAudit dataUrl={"api/sims/ModeratorBursary/getModeratorBursaryAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedBursary}
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkBursary("api/sims/ModeratorBursary/RemoveModeratorBursary")}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(BursarySearch)
);
