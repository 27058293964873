import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Skills.css";
import { ViewContainer } from "./../../View.jsx";
import { SkillsTNumberConversionCreationForm } from './SkillsTNumberConversionCreationForm';

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        },
    ]
};

const userRef = React.createRef();

class SkillsTNumberConversion extends Component {

    state = {
        model: {
            Name: "",
            Description: "",
            LetterType: ""

        }
    };

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/maintenanceQueryReasons',

            })
        }
    }

    save() {
        let formValue = userRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {
            let data = JSON.stringify(formValue);
            const url = `api/indicium/provider/updateProviderDetails?data=${data}`;
            // TODO : Invoke spinner mask
            fetch(url)
                .then(res => res.json())
                .then((responseJson) => {
                    let response = JSON.parse(responseJson);
                    if (response.MessageType !== "ERROR") {
                        this.props.history.push({
                            pathname: '/SkillsQueryReason'
                        });
                    } else {
                        this.setState({ saveMessage: response.Message, hasSaveError: true });
                    }
                }).catch(() => {
                    this.setState({ saveMessage: "A system error has accured. Please contact your system administrator", hasSaveError: true });
                });
        }
    }

    close() {
        this.props.history.push('/maintenanceQueryReasons');
    }

    render() {
        return (
            <ViewContainer title={`T Number Conversion`}>
              
                    <p className="legend">
                    {"T Number Conversion"}
                    </p>

                    <table width="100%">
                        <tbody>
                            <tr>
                                <td>
                                <EnhancedToolbar highlight={false} title="T Number Conversion" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                        enabled={true} config={toolbarConfig} />
                                </td>
                            </tr>
                            <tr>
                                <td className="formContainer">
                                <SkillsTNumberConversionCreationForm ref={userRef}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </ViewContainer>
         
        );
    }
}

export default withRouter(SkillsTNumberConversion);
