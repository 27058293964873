import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Organisation.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/Table';
import UnionCreateDialog from "./UnionCreateDialog";
import UnionEditDialog from "./UnionEditDialog";
import SearchView from "../../widgets/SearchView";
import ViewUtils from '../../ViewUtils';


const utils = new ViewUtils();


const headCells = [
    { id: 'NameOfUnion', numeric: false, disablePadding: true, label: 'Name of Union' },
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'JobTitle', numeric: false, disablePadding: true, label: 'Job Title' },
    { id: 'Designation', numeric: false, disablePadding: true, label: 'Union Committee Designation' },
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: "Close", tooltip: "Close" },
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
            ]
        }
    ]
};

class UnionSearchRecord extends Component {

 

    constructor(props) {
        super(props);
        this.state = {
            organisationDetails: {
                LegalName: "",
            },
            message: "",
            messageStyle: "",
            title: "",
               addDialogOpen: false,
        editDialogOpen: false,
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        }};

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });
        } else if (buttonId === "close") {
            this.close();
        }
        else if (buttonId === "edit") {
            this.setState({ editDialogOpen: true });
        }
    }

    close() {
        this.props.history.push({
            pathname: "/skillsOrganisation",
            state: {selection: this.props.location.state.organisationID}
        })
    }
    render() {
        return (

            <div className = "view w-100 h-100" >
                <p className="legend">
                    {"Organisation > " + this.props.location.state.organisationName + " > Union Records"}
                </p>

                <UnionCreateDialog open={this.state.addDialogOpen} />
                <UnionEditDialog open={this.state.editDialogOpen} />

                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        title="Provider"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={toolbarConfig} />

                <SearchView headCells={headCells}  
                dataUrl={"/sims/TrainingCommittee/GetTrainingCommitteeByOrganisationId"}
                    entityViewPath='/skillsOrganisation'
                    search={false}
                    toolbarConfig={toolbarConfig}
                    // searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    mode={this.props.mode}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange}
                    deleteUrl={"api/sims/"}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode} 
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch} 
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Organisation"}
                    promptContent={"Are you sure you want to remove this union member?"}
                >
                </SearchView>
                    {/* <EnhancedTable headCells={headCells} onSelectionChange={(selection) => this.setState({ selection: selection })}
                        dataUrl="/sims/TrainingCommittee/GetTrainingCommitteeByOrganisationId" searchParameters={[{ Name: "OrganisationID", Value: this.props.location.state.organisationID }]} /> */}
                </div>
            </div >
        );
    }
}

export default withRouter(
    connect()(UnionSearchRecord)
);
