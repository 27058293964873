import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./SAQAXMLImport.css";
import { SAQAXMLImportForm } from './SAQAXMLImportForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const saqaxmlimportRef = React.createRef();
const utils = new ViewUtils();

const toolbarRef = React.createRef();
class SAQAXMLImport extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'Import', tooltip: 'Import', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Add }
                    ]
                }
            ]
        };
    }

    handleFormValueChange = (values) => {

        if (!utils.isNull(values)) {
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
    }

    successCallback = (response) => {
        utils.showMessage(this, response.Message, response.MessageType);
    }

    errorCallBack = (response) => {
        utils.showMessage(this, "A system error has occurred. Please contact your system administrator", "ERROR");
    }

    save() {
        let formValue = saqaxmlimportRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            const data = JSON.stringify(formValue);

            const url = "api/sims/saqaxmlimport/insertsaqaprogramme";
            utils.invokeUrl(url, this.successCallback,
                this.errorCallBack,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: data
                }
            )
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"SAQA Import"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    ref={toolbarRef}
                                    title="SAQAImport"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <SAQAXMLImportForm ref={saqaxmlimportRef}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} menuPermissionItem={this.props.menuPermissionItem} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(SAQAXMLImport));

