import React from 'react';
import { Card } from 'react-bootstrap';
import "./widgets.css";
import ViewUtils from '../ViewUtils';
import { maxHeight } from '@material-ui/system';

const utils = new ViewUtils();
//const styles = {
//    height: "-webkit-fill-available",
//}

const CardLayout = (props) => {

    React.useEffect(() => {
        if (props.mode !== "lookup") {
            utils.setLegend(props.title);
        }
    }, [props.title]);

    return (
        <Card >
            <Card.Body>
                {props.mode === "lookup" ? <div className="legend">{props.title}</div> : null}
             {props.children}
            </Card.Body>       
        </Card>
    );
}

export default CardLayout;