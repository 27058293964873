import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const CreatingLinkingSearchForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

return (
    <EntityForm ref={ref} viewId="user" values={props.data}
        entityLookupController={props.entityLookupController} org="saseta"
        entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
        {form =>
            <form className={form.classes.container} id="SkillsLetterCreationForm">
                <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                        <Tab label="Create Linking" {...a11yProps(0)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=BenefitsTargetID"
                                        id="BenefitsTargetID"
                                        key={tabValue}
                                        label="Benefits Target"
                                        className="w-100"
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=BenefitsInterventionID"
                                        id="BenefitsInterventionID"
                                        key={tabValue}
                                        label="Benefits Intervention"
                                        className="w-100"
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/indicium/employer/GetLookupList?listId=BenefitsBenefitID"
                                        id="BenefitsBenefitID"
                                        key={tabValue}
                                        label="Benefits Benefit"
                                        className="w-100"
                                        form={form} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </TabPanel>

            </form>
        }
    </EntityForm>
);
})

