import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';
import AlertItem from "../../../widgets/AlertItem";
import Prompt from '../../../widgets/Prompt';
import ViewUtils from '../../../ViewUtils';
import FormDialog from "../../FormDialog";
import { TrackingAddTaskForm } from "./TrackingAddTaskForm";
import { ViewContainer } from "./../../../View.jsx";

const headCells = [
    { id: 'Task', numeric: false, disablePadding: true, label: 'Task' },
    { id: 'OtherTask', numeric: false, disablePadding: true, label: 'Other Task' },
    { id: 'TaskStatus', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'DueDate', numeric: false, disablePadding: true, label: 'Due Date' },
    { id: 'ToAddress', numeric: false, disablePadding: true, label: 'Email Address List' },
    { id: 'Subject', numeric: false, disablePadding: true, label: 'Email Subject' },
    { id: 'Document', numeric: false, disablePadding: true, label: 'Document', contentType: 'document' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
const editFormRef = React.createRef();

class TrancheTracking extends Component {

    constructor(props) {
        super(props)
        this.state = {
            editFormDialogOpen: false,
            formDialogOpen: false,
            selectedTask: null,
            propmtOpen: false,
            promptTitle: "Remove task from project",
            promptContent: "Are you sure you want to remove this task from the project setup?",
            Message: "",
            MessageStyle: "",
            searchParameters: [{ Name: "TrancheID", Value: this.props.location.state.TrancheID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add task to project', icon: 'AddIcon' },
                        { id: 'edit', label: '', tooltip: 'Edit project task', icon: 'EditIcon', disabled: utils.isNull(this.state.selectedTask) },
                        { id: 'remove', label: '', tooltip: 'Remove task from project', icon: 'DeleteIcon', disabled: utils.isNull(this.state.selectedTask) }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: { TrancheID: this.props.location.state.TrancheID }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ formDialogOpen: true, editFormDialogOpen: false, Message: "" })
        }
        else if ("edit" === buttonId) {
            this.setState({ formDialogOpen: false, editFormDialogOpen: true, Message: "" })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false,
                editFormDialogOpen: false,
                Message: "Grant Task successfully saved",
                MessageStyle: "success"
            });
        }
        else if (cancel === true) {
            this.setState({
                formDialogOpen: false,
                editFormDialogOpen: false,
                Message: ""
            })
        }
        else {
            this.setState({
                formDialogOpen: false,
                editFormDialogOpen: false,
                Message: "A system error has occured, please try again later or contact your administrator",
                MessageStyle: "warning"
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedTask: selection[0].Id, Message: "" });
        } else {
            this.setState({ selectedTask: null, Message: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            utils.invokeUrl("api/sims/grantsetuptracking/downloaddocument?id=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    successfulTaskRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedTask: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulTaskRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeTask(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedTask}&projectID=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulTaskRemoveCallback, this.unsuccessfulTaskRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, editFormDialogOpen: false
            }, () => { this.setState({ showTable: true }) });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                editFormDialogOpen: false
            })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {`Project Setup > Tracking > ${this.props.location.state.ProjectNumber}`}
                </p>

                {
                    !utils.isStringEmpty(this.props.saveMessage) ?
                        <p className={this.props.hasSaveError ? "errorMessage" : "message"}>
                            {this.props.saveMessage}
                        </p>
                        :
                        ""
                }

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/grantsetuptracking/GetGrantTasks`}
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Add New Task"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={"api/sims/grantsetuptracking/InsertNewTask"}
                    usePost={true}
                    entityInfo={{ TrancheID: this.props.location.state.TrancheID }}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                    hasDocumentUpload={true}
                >
                    <TrackingAddTaskForm ref={addFormRef}/>
                </FormDialog>

                <FormDialog
                    open={this.state.editFormDialogOpen}
                    dialogTitle={"Update Tracking Task Details"}
                    viewRef={this}
                    formRef={editFormRef}
                    hasDocumentUpload={true}
                    entityId={this.state.selectedTask}
                    entityInfo={{ TrancheID: this.props.location.state.TrancheID }}
                    saveUrl={"api/sims/grantsetuptracking/UpdateProjectTask"} usePut={true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <TrackingAddTaskForm
                        ref={editFormRef}
                        id={this.state.selectedTask}
                        dataURL='api/sims/grantsetuptracking/GetProjectTask'
                    />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeTask("api/sims/grantsetuptracking/RemoveGrantTask") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(TrancheTracking)
);
