import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";
import DocumentViewer from '../../widgets/DocumentViewer';

const utils = new ViewUtils();

const config = {
}

const toolbarRef = React.createRef();
class AssessorIDDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {
            document: null,
            loading: true
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' }
                    ]
                }
            ]
        };
    }

    state = {
        document: null
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/assessor',
                state: { selection: this.props.location.state.AssessorID }
            });
        }
    }

    componentDidMount() {
        if (this.state.document === null) {
            utils.invokeUrl(`api/indicium/assessor/getIDDocument?assessorId=${this.props.location.state.AssessorID}`, (data) => {
                if (data !== null && data.Base64 !== null) {
                    let file = {};
                    file.base64 = "data:image/jpeg;base64," + data.Base64;
                    this.setState({ document: file, loading: false });
                } else {
                    this.setState({ loading: false });
                }
            });
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={`Assessors > ${this.props.location.state.AssessorName} > ID Document`}>
                <AlertItem message={this.state.unlinkMessage} alertStyle={this.state.unlinkMessageStyle} />
                <div>
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    {
                        this.state.document === null ?
                            (<div style={{ paddingLeft: "32px" }}> {this.state.loading ? <p>Loading...</p> : <p>No document to display</p>} </div>)
                            : <div style={{ overflow: "auto", maxHeight: "70vh" }}>
                                <DocumentViewer file={this.state.document} onDocumentLoadSuccess={() => { }} />
                              </div>
                    }
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AssessorIDDocument)
);
