import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { LookupField } from '../../widgets/LookupField';
import SchoolEMISSearch from '../SchoolEMISSearch';
import STATSSAAreaCodeSearch from '../STATSSAAreaCodeSearch';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { GPSCoordinatesAPI } from '../../widgets/GPSCoordinatesAPI';
import QualificationSetupSearch from '../program/QualificationSetupSearch';
import OFOSearch from '../program/OFOSearch';
import InstitutionSearch from '../program/InstitutionSearch';
import { Button } from "antd";
import Icon from '@ant-design/icons';
import EducationalHistoryDocuments from "../learner/EducationalHistoryDocuments";
import LearnerDocumentDialog from "../learner/LearnerDocumentDialog";
import ExperientialLearningDocuments from "../learner/ExperientialLearningDocuments";
import ExperientialLearningDialog from "../learner/ExperientialLearningDialog";
import EmploymentHistoryDocuments from "../learner/EmploymentHistoryDocuments";
import EmploymentHistoryDialog from "../learner/EmploymentHistoryDialog";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const PersonForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [documentsDialogOpen, setdocumentsDialogOpen] = React.useState(false);
    const [ELdocumentsDialogOpen, setELdocumentsDialogOpen] = React.useState(false);
    const [EHdocumentsDialogOpen, setEHdocumentsDialogOpen] = React.useState(false);
    const [DocURL, setDocURL] = React.useState(null);
    const [elDocURL, setelDocURL] = React.useState(null);
    const [ehDocURL, setehDocURL] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 5) {
            setDocURL("api/indicium/person/getCRMDocuments");
        }
        else if (newValue == 6) {
            setelDocURL("api/indicium/person/getExperientialLearningDocuments");
        }
        else if (newValue == 7) {
            setehDocURL("api/indicium/person/getEmploymentHistoryDocuments");
        }
        else {
            setDocURL(null);
            setelDocURL(null);
            setehDocURL(null);
        }
    };

    const handleUsePhysicalAddressForPostal = form => event => {
        if (event.target.checked === true) {
            form.values['PostalAddressLine1'] = form.values["PhysicalAddress1"];
            form.values['PostalAddressLine2'] = form.values["PhysicalAddress2"];
            form.values['PostalAddressLine3'] = form.values["PhysicalAddress3"];
            form.values['PostalCode'] = form.values["PhysicalCode"];
            setPostalCodeValue(form.values["PhysicalCode"]);
        }
        form.updateValue('UsePhysicalAddressForPostalAddress', event.target.checked);
    }

    const updateGPSAddress = (address, form) => {
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    function getDescription(values, form) {
        if (values !== undefined && values !== null) {
            if (values["SecondDescription"] !== undefined) {
                form.values["QualificationTitle"] = values["SecondDescription"];
            }
        }
    }

    function getOFODescription(values, form) {
        if (values !== undefined && values !== null) {
            if (values["SecondDescription"] !== undefined) {
                form.values["LinkedOFODescription"] = values["SecondDescription"];
            }
        }
    }

    const config = {
        moduleName: "Person",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/indicium/Person/addEvidenceofQualification"
        }
    };

    const ELconfig = {
        moduleName: "Person",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/indicium/Person/addELDocument"
        }
    };

    const EHconfig = {
        moduleName: "Person",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/indicium/Person/addEHDocument"
        }
    };


    const setDialogOpen = () => {
        setdocumentsDialogOpen(true);
    };

    const setelDialogOpen = () => {
        setELdocumentsDialogOpen(true);
    };

    const setehDialogOpen = () => {
        setEHdocumentsDialogOpen(true);
    };

    const handleCellSelect = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            const link = document.createElement('a');
            link.href = '/api/indicium/Person/getEvidenceofQualification?documentID=' + rowid;
            link.download = 'download';
            link.target = '_blank';
            link.click();
        }
    };

    const postUploadHandler = (save, cancel) => {
        //Successful upload
        if (save === true) {
            setdocumentsDialogOpen(false);
            setELdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
        }
        //Close dialog
        else if (cancel === true) {
            setdocumentsDialogOpen(false);
            setELdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
        }
        //Error on upload
        else {
            setdocumentsDialogOpen(false);
            setELdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
        }
    };

    return (
        <EntityForm ref={ref} viewId="Person" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="PersonForm">
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/Person/GetLookupList?listId=Title"
                                                id="TitleID"
                                                label="Title"
                                                className="w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="FirstName"
                                                label="First Name"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="MiddleName"
                                                label="Middle Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Surname"
                                                label="Surname"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="General Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Contact Details" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                                <Tab label="Address Details" {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null }} />
                                <Tab label="Educational Details" {...a11yProps(3)} style={{ color: form.fieldParentErrors[3] === true ? "red" : null }} />
                                <Tab label="Post School Educational Details" {...a11yProps(4)} style={{ color: form.fieldParentErrors[4] === true ? "red" : null }} />
                                <Tab label="Educational History" {...a11yProps(5)} style={{ color: form.fieldParentErrors[5] === true ? "red" : null }} />
                                <Tab label="Experiential Learning" {...a11yProps(6)} style={{ color: form.fieldParentErrors[6] === true ? "red" : null }} />
                                <Tab label="Employment History" {...a11yProps(7)} style={{ color: form.fieldParentErrors[7] === true ? "red" : null }} />
                                <Tab label="CV" {...a11yProps(8)} style={{ color: form.fieldParentErrors[8] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=HasSouthAfricanID"
                                                id="HasSouthAfricanID"
                                                parentId={0}
                                                //required
                                                key={tabValue}
                                                label="Does the Person have a South African ID Number?"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=AlternateIDType"
                                                id="AlternateIDTypeID"
                                                parentId={0}
                                                //required
                                                key={tabValue}
                                                label="Alternate ID Type"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="IDNo"
                                                key={tabValue}
                                                parentId={0}
                                                label="ID Number"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ProofOfLifeStatus"
                                                parentId={0}
                                                key={tabValue}
                                                label="Proof of Life Status"
                                                className="w-100"
                                                value="Deceased"
                                                form={form}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Initials"
                                                key={tabValue}
                                                parentId={0}
                                                label="Initials"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="DateofBirth"
                                                parentId={0}
                                                key={tabValue}
                                                label="Date of Birth"
                                                className='{classes.textField} w-100'
                                                required

                                                form={form}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=Gender"
                                                id="GenderID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Gender"
                                                className="{classes.select} w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    disabled: false,
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=Equity"
                                                id="EquityID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Equity"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=Disability"
                                                id="DisabilityID"
                                                //required
                                                parentId={0}
                                                key={tabValue}
                                                label="Disability"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=HomeLanguage"
                                                id="HomeLanguageID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Home Language"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=CitizenResidentialStatus"
                                                id="CitizenResidentialStatusID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Citizen Residential Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=Nationality"
                                                id="NationalityID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Nationality"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TelephoneNumber"
                                                //required
                                                parentId={1}
                                                key={tabValue}
                                                label="Telephone Number"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CellPhoneNumber"
                                                required
                                                parentId={1}
                                                key={tabValue}
                                                label="Cell Phone Number"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FaxNumber"
                                                //required
                                                parentId={1}
                                                key={tabValue}
                                                label="Fax Number"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EMail"
                                                required
                                                parentId={1}
                                                key={tabValue}
                                                label="E Mail"
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <table cellPadding="2" width="70%">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomCheckbox
                                                id="UsePhysicalAddressForPostalAddress"
                                                key={tabValue}
                                                form={form}
                                                onChange={handleUsePhysicalAddressForPostal}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Use Physical Address for Postal Address?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <GPSCoordinatesAPI
                                                id="Address"
                                                key={tabValue}
                                                className="w-100"
                                                label="Address"
                                                form={form}
                                                updateGPSAddress={updateGPSAddress}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="PhysicalAddress1"
                                                required
                                                parentId={2}
                                                key={tabValue}
                                                label="Physical Address"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine1"
                                                required
                                                parentId={2}
                                                key={tabValue}
                                                label="Postal Address"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="PhysicalAddress2"
                                                required
                                                parentId={2}
                                                key={tabValue}
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine2"
                                                required
                                                parentId={2}
                                                key={tabValue}
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="PhysicalAddress3"
                                                key={tabValue}
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="PostalAddressLine3"
                                                key={tabValue}
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <AddressCodeItem
                                                id="PhysicalCode"
                                                required={true}
                                                addressId="Physical"
                                                value={physicalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                                className="w-100"
                                                label="Physical Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={false}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={false}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <AddressCodeItem
                                                id="PostalCode"
                                                required={true}
                                                addressId="Postal"
                                                value={postalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PostalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PostalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PostalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PostalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PostalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PostalCityID : null}
                                                className="w-100"
                                                label="Postal Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={false}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={false}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <LookupField
                                                id="SchoolEMISID"
                                                required
                                                label="Last School EMIS"
                                                className="w-100"
                                                form={form}
                                            >
                                                <SchoolEMISSearch showTitle={true} mode='lookup'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SchoolEMISID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>

                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=LastSchoolYearID"
                                                id="LastSchoolYearID"
                                                required
                                                key={tabValue}
                                                label="Last School Year"
                                                className="{classes.select} w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="STATSSAAreaCodeID"
                                                label="STATSSA Area Code"
                                                className="w-100"
                                                required
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                disabled={props.disableFields}
                                            >
                                                <STATSSAAreaCodeSearch showTitle={true} mode='lookup'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("STATSSAAreaCodeID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=POPIActStatus"
                                                id="POPIActStatusID"
                                                key={tabValue}
                                                required
                                                label="POPI Act Status"
                                                className="{classes.select} w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomDatePicker
                                                id="POPIActStatusDate"
                                                required
                                                parentId={3}
                                                key={tabValue}
                                                label="POPI Act Status Date"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="QualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                form={form}
                                                onChange={getDescription(form.values["QualificationID"], form)}
                                            >
                                                <QualificationSetupSearch showTitle={true} mode='lookup'
                                                    onLookupValueChange={(values) => form.setLookupSelection("QualificationID", values)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="QualificationTitle"
                                                key={tabValue}
                                                parentId={4}
                                                label="Qualification Name"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="DateAchieved"
                                                key={tabValue}
                                                label="Date Achieved"
                                                parentId={4}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="Linked OFO Code"
                                                className="w-100"
                                                onChange={getOFODescription(form.values["OFOOccupationID"], form)}
                                                form={form}
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="LinkedOFODescription"
                                                key={tabValue}
                                                parentId={4}
                                                label="Linked OFO Description"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={5}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/indicium/employer/GetLookupList?listId=HighestEducationLevel"
                                                id="HighestEducationLevelID"
                                                parentId={5}
                                                key={tabValue}
                                                label="Highest Education Level"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EducationTitle"
                                                key={tabValue}
                                                parentId={4}
                                                label="Education Title / Description"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="InstitutionID"
                                                label="Institution"
                                                className="w-100"
                                                form={form}
                                            >
                                                <InstitutionSearch showTitle={true} mode='lookup'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("InstitutionID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <br />
                                </tbody>
                            </table>

                            <Button onClick={setDialogOpen} color="primary">
                                Save Educational History Record
                                         </Button>
                            <p></p>
                            <EducationalHistoryDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell)}
                                documentsDataUrl={DocURL} moduleName={config.moduleName} selection={form.values["IDNo"]} />


                            <div>
                                <LearnerDocumentDialog
                                    open={documentsDialogOpen}
                                    closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                    postUrl={config.postUrls.documents}
                                    EntityID={form.values["IDNo"]}
                                    EducationTitle={form.values["EducationTitle"]}
                                    HighestEducation={form.values["HighestEducationLevelID"]}
                                    Institute={form.values["InstitutionID"]}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={6}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ExperienceWork"
                                                key={tabValue}
                                                parentId={4}
                                                label="Experience Work"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="ExperienceWorkDateFrom"
                                                key={tabValue}
                                                label="Date From"
                                                parentId={6}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="ExperienceWorkDateTo"
                                                key={tabValue}
                                                label="Date To"
                                                parentId={6}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="NatureOfWork"
                                                key={tabValue}
                                                parentId={6}
                                                label="Nature Of Work"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/indicium/Person/GetLookupList?listId=Title"
                                                id="ReferenceTitleID"
                                                label="Title"
                                                className="w-100"
                                                parentId={6}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ReferenceName"
                                                label="Name"
                                                parentId={6}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ReferenceSurname"
                                                label="Surname"
                                                parentId={6}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ReferenceContactNumber"
                                                parentId={6}
                                                key={tabValue}
                                                label="Contact Number"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="WhereExperientialWorWasUndertaken"
                                                label="Employer where experiential work was undertaken"
                                                parentId={6}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <br />
                                </tbody>
                            </table>
                            <div>
                                <Button onClick={setelDialogOpen} color="primary">
                                    Save Experiential Learning Record
                                         </Button>
                                <p></p>
                                <ExperientialLearningDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell)}
                                    documentsDataUrl={elDocURL} moduleName={ELconfig.moduleName} selection={form.values["IDNo"]} />

                            </div>
                            <br />
                            <div>
                                <ExperientialLearningDialog
                                    open={ELdocumentsDialogOpen}
                                    closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                    postUrl={ELconfig.postUrls.documents}
                                    EntityID={form.values["IDNo"]}
                                    ExperienceWork={form.values["ExperienceWork"]}
                                    ExperienceWorkDateFrom={form.values["ExperienceWorkDateFrom"]}
                                    ExperienceWorkDateTo={form.values["ExperienceWorkDateTo"]}
                                    ReferenceTitleID={form.values["ReferenceTitleID"]}
                                    NatureOfWork={form.values["NatureOfWork"]}
                                    ReferenceName={form.values["ReferenceName"]}
                                    ReferenceSurname={form.values["ReferenceSurname"]}
                                    ReferenceContactNumber={form.values["ReferenceContactNumber"]}
                                    WhereExperientialWorWasUndertaken={form.values["WhereExperientialWorWasUndertaken"]}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={7}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmploymentHistoryPosition"
                                                label="Position"
                                                parentId={7}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EmploymentHistoryCompany"
                                                label="Company"
                                                parentId={7}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmploymentHistoryDuration"
                                                label="Duration"
                                                parentId={7}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EmploymentHistoryLocation"
                                                label="Location"
                                                parentId={7}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmploymentHistoryDescription"
                                                label="Description"
                                                parentId={7}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <br />
                                </tbody>
                            </table>
                            <div>
                                <Button onClick={setehDialogOpen} color="primary">
                                    Save Employment History Record
                                         </Button>
                                <p></p>
                                <EmploymentHistoryDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell)}
                                    documentsDataUrl={ehDocURL} moduleName={EHconfig.moduleName} selection={form.values["IDNo"]} />

                            </div>
                            <br />
                            <div>
                                <EmploymentHistoryDialog
                                    open={EHdocumentsDialogOpen}
                                    closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                    postUrl={EHconfig.postUrls.documents}
                                    EntityID={form.values["IDNo"]}
                                    Position={form.values["EmploymentHistoryPosition"]}
                                    Company={form.values["EmploymentHistoryCompany"]}
                                    Duration={form.values["EmploymentHistoryDuration"]}
                                    Location={form.values["EmploymentHistoryLocation"]}
                                    Description={form.values["EmploymentHistoryDescription"]}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={8}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <Button onClick={setehDialogOpen} color="primary">
                                        Upload Photo
                                         </Button>
                                    <p></p>
                                    <Button color="primary">
                                        Download CV
                                         </Button>
                                    <p></p>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

