import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ViewUtils from '../ViewUtils';
import { BaseField } from "./BaseField";

const SelectItemComponent = React.forwardRef((props, ref) => {
    const [options, setOptions] = React.useState([]);
    const [optionsLoaded, setOptionsLoaded] = React.useState(false);
    const [loadFullOptionList, setLoadFullOptionList] = React.useState(false);
    const [hasDependency, setHasDependency] = React.useState(props.hasDependency);
    const [dependency, setDependency] = React.useState(props.dependency);
    const [hasDefault, sethasDefault] = React.useState(props.hasDefault);
    const utils = new ViewUtils();
    const base = props.base;

    const onHandleChange = () => event => {
        props.form.handleSelectItemChange(props.id, options)(event)
        if (!utils.isNull(props.onChange)) {
            props.onChange(props.form, props.id, event);
        }
    };

    React.useEffect(() => {
        if (props.clearOptions) {
            setLoadFullOptionList(false);
            setOptionsLoaded(false);
            setOptions([]);
            props.clearOptionsHandler();
        }
    }, [props.clearOptions]);


    React.useEffect(() => {
        if (!utils.isNull(base.valueObject[base.valueProperty]) && !optionsLoaded) {
            let defaultOptions = [];
            defaultOptions.push(base.valueObject[base.valueProperty]);
            setOptions(defaultOptions);
        }
    }, [base.valueObject[base.valueProperty]]);

    React.useEffect(() => {
        if (loadFullOptionList === true || (!utils.isNull(props.dependency) && JSON.stringify(props.dependency) !== JSON.stringify(dependency))) {
            let url = props.dataUrl;
            if (!utils.isNull(props.dependency)) {
                url += JSON.stringify(props.dependency);
                setDependency(props.dependency);
                if (!utils.isNull(base.valueObject[base.valueProperty]) && !props.hasDefault) {
                    props.form.clearSelectItemChange(props.id);
                }
            }
            let checkToken = true;
            if (typeof props.checkRefreshToken !== 'undefined' && props.checkRefreshToken !== null) {
                checkToken = props.checkRefreshToken;
            }
            if (checkToken) {
                utils.invokeUrl(url, (data) => {
                    if (!utils.isNull(data) || !utils.isStringEmpty(data)) {
                        setOptions(data);
                    }
                }, null);
            }
            else {
                utils.invokeUrlExternal(url, (data) => {
                    if (!utils.isNull(data) || !utils.isStringEmpty(data)) {
                        setOptions(data);
                    }
                }, null);
            }

            setOptionsLoaded(true);
        }
    }, [loadFullOptionList, props.dependency]);


    return (
        <TextField
            select
            style={{ width: "100% !important", minWidth: "200px" }}
            InputProps={props.InputProps}
            id={props.id}
            ref={base.reference}
            required={base.required}
            //disabled={hasDependency === true && utils.isNull(props.dependency) && !props.hasDefault || props.disabled}
            disabled={props.hasDefault === true ? false : (hasDependency === true && utils.isNull(props.dependency)) || props.disabled}
            label={props.label}
            SelectProps={{
                onOpen: () => {
                    if (!loadFullOptionList && !optionsLoaded) {
                        setLoadFullOptionList(true);
                    }
                },
                displayEmpty: props.allowEmptyValue || false
            }}
            value={!utils.isNull(base.valueObject[base.valueProperty]) ? base.valueObject[base.valueProperty].Id : ''}
            className={props.className}
            onChange={onHandleChange()}
            error={base.hasError}
            helperText={base.errorMessage}
            margin="normal"
            size="small"
            variant="outlined">
            {options
                .filter(option => {
                    if (props.filterOptions) {
                        return props.filterOptions(option);
                    }
                    return true;
                })
                .map(option => (
                    <MenuItem
                        key={option.Id}
                        value={option.Id}>{utils.isNull(option.Description) ? "" : option.Description.trim()}
                    </MenuItem>
                ))
            }
        </TextField>
    );
})

export function SelectItem(props) {
    return (
        <BaseField
            ref={React.createRef()}
            form={props.form}
            required={props.required}
            id={props.id}
            className={props.className}
            validator={props.validator}
            validationRegex={props.validationRegex}
            validationGroup={props.validationGroup}
            validationMessage={props.validationMessage}
            parentId={props.parentId}
            filterOptions={props.filterOptions}
            hasDefault={props.hasDefault}>
            {base =>
                <SelectItemComponent
                    id={props.id}
                    base={base}
                    InputProps={props.InputProps}
                    dataUrl={props.dataUrl}
                    disabled={props.disabled}
                    ref={React.createRef()}
                    required={props.required}
                    label={props.label}
                    className={props.className}
                    validationGroup={props.validationGroup}
                    validationRegex={props.validationRegex}
                    readOnly={props.readOnly}
                    parentId={props.parentId}
                    form={props.form}
                    allowEmptyValue={props.allowEmptyValue}
                    dependency={props.dependency}
                    hasDependency={props.hasDependency}
                    checkRefreshToken={props.checkRefreshToken}
                    clearOptions={props.clearOptions}
                    clearOptionsHandler={props.clearOptionsHandler}
                    onChange={props.onChange}
                    filterOptions={props.filterOptions}
                    hasDefault={props.hasDefault}
                >
                </SelectItemComponent>
            }
        </BaseField>
    );
}