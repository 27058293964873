import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { PDFDownloadLink, Document, Page, StyleSheet, Text, Image, View } from '@react-pdf/renderer';
import simslogoLoading from '../../../content/images/logos/sims_logo_loading.png';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const utils = new ViewUtils();

const styles = StyleSheet.create({
    header: {
        fontSize: 20,
        marginBottom: 10,
        textAlign: 'center',
        color: '#114084',
        fontWeight : 500
    },
    text: {
        fontSize: 9,
        marginBottom: 10,
        textAlign: 'left',
        color: 'black',
    },
    body: {
        flexDirection: 'column',
        padding: 25
    },
    image: {
        marginBottom: 10,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#003F6B',
        height: '100px',
        width: '200px',
        padding: 10
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#114084'
    },
    tableCol: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
        color: 'white'
    },
    tableCell: {
        margin: "auto",
        margin: 5,
        fontSize: 10
    }
});


export default function GrantsLeviesPrint(props) {


    const initiateHeaderValue = (str) => {
        return str
            .replace(/(_|-)/g, ' ')
            .trim()
            .replace(/\w\S*/g, function (str) {
                return str.charAt(0).toUpperCase() + str.substr(1)
            })
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    }
    
    const GrantData = props.data.GrantSummaryDto.map((innerItem, index) => {
        return { Year: innerItem.Year, Description: innerItem.Description, Amount: innerItem.Amount };
    });

    const LevyData = props.data.LevySummaryDto.map((innerItem, index) => {
        return {
            SARSArrivalDate: innerItem.SARSArrivalDate,
            DatePosted: innerItem.DatePosted,
            StakeholderLevyCalculated: innerItem.StakeholderLevyCalculated,
            NSFCalculation: innerItem.NSFCalculation,
            TotalReceivedBySETA: innerItem.TotalReceivedBySETA,
            MandatoryLevy: innerItem.MandatoryLevy,
            Discretionary: innerItem.Discretionary,
            Admin: innerItem.Admin,
            Penalty: innerItem.Penalty,
            Interest: innerItem.Interest
        };
    });

    const FinSummaryData = props.data.FinancialSummaryDto.map((innerItem, index) => {
        return {
            SARSArrivalDate: innerItem.SARSArrivalDate,
            Year: innerItem.Year,
            StakeholderLevyCalculated: innerItem.StakeholderLevyCalculated
        };
    });

    const GrantContents = GrantData.length !== 0 ?
        (

            <Fragment key={0}>
                <View style={styles.table}>
                    <View key={"0"} style={styles.tableRow} fixed>
                        {Object.keys(GrantData[0]).map((innerItem, index) => (
                            <View style={{ ...styles.tableColHeader, width: (100 / Object.keys(GrantData[0]).length).toString() + '%' }} key={index}>
                                <Text style={styles.tableCellHeader}>{initiateHeaderValue(innerItem)}</Text>
                            </View>

                        ))}
                    </View>
                    {GrantData.map((dataItem, index) => (
                        <View key={index} style={styles.tableRow} wrap={false}>
                            {Object.keys(dataItem).map((innerItem, index) => (
                                <View style={{ ...styles.tableCol, width: (100 / Object.keys(GrantData[0]).length).toString() + '%' }} key={index}>
                                    <Text style={styles.tableCell}>{dataItem[innerItem]}</Text>
                                </View>
                            ))}



                        </View>
                    ))}
                </View>
            </Fragment>

        ) :
        null;

    const LevyContents = LevyData.length !== 0 ?
        (

            <Fragment key={0}>
                <View style={styles.table}>
                    <View key={"0"} style={styles.tableRow} fixed>
                        {Object.keys(LevyData[0]).map((innerItem, index) => (
                            <View style={{ ...styles.tableColHeader, width: (100 / Object.keys(LevyData[0]).length).toString() + '%' }} key={index}>
                                <Text style={styles.tableCellHeader}>{initiateHeaderValue(innerItem)}</Text>
                            </View>

                        ))}
                    </View>
                    {LevyData.map((dataItem, index) => (
                        <View key={index} style={styles.tableRow} wrap={false}>
                            {Object.keys(dataItem).map((innerItem, index) => (
                                <View style={{ ...styles.tableCol, width: (100 / Object.keys(LevyData[0]).length).toString() + '%' }} key={index}>
                                    <Text style={styles.tableCell}>{dataItem[innerItem]}</Text>
                                </View>
                            ))}



                        </View>
                    ))}
                </View>
            </Fragment>

        ) :
        null;

    const FinSummaryContents = FinSummaryData.length !== 0 ?
        (

            <Fragment key={0}>
                <View style={styles.table}>
                    <View key={"0"} style={styles.tableRow} fixed>
                        {Object.keys(FinSummaryData[0]).map((innerItem, index) => (
                            <View style={{ ...styles.tableColHeader, width: (100 / Object.keys(FinSummaryData[0]).length).toString() + '%' }} key={index}>
                                <Text style={styles.tableCellHeader}>{initiateHeaderValue(innerItem)}</Text>
                            </View>

                        ))}
                    </View>
                    {FinSummaryData.map((dataItem, index) => (
                        <View key={index} style={styles.tableRow} wrap={false}>
                            {Object.keys(dataItem).map((innerItem, index) => (
                                <View style={{ ...styles.tableCol, width: (100 / Object.keys(FinSummaryData[0]).length).toString() + '%' }} key={index}>
                                    <Text style={styles.tableCell}>{dataItem[innerItem]}</Text>
                                </View>
                            ))}



                        </View>
                    ))}
                </View>
            </Fragment>

        ) :
        null;


        const MyDoc = () => (
            <Document>
                <Page orientation={props.Section === "Grant Summary" ? "portrait" : "landscape"} style={styles.body} size="A4" wrap>
                    <Image style={styles.image} src={simslogoLoading}></Image>
                    <Text style={styles.header}>{props.Section}</Text>
                    <Text style={styles.text}>{props.data.OrganisationName}</Text>
                    <Text style={styles.text}>{props.data.SDLNumber}</Text>
                    <Text style={styles.text}>{props.data.FinancialYear}</Text>
                    <Text style={styles.text}>{props.data.PhysicalAddress1}</Text>
                    <Text style={styles.text}>{props.data.PhysicalAddress2}</Text>
                    <Text style={styles.text}>{props.data.PhysicalAddress3}</Text>
                    <Text style={styles.text}>{props.data.Municipality}</Text>
                    <Text style={styles.text}>{props.data.PostalCode}</Text>
                    <Text style={styles.text}>{props.data.PostalAddress1}</Text>
                    <Text style={styles.text}>{props.data.PostalAddress2}</Text>
                    <Text style={styles.text}>{props.data.PostalAddress3}</Text>
                    {props.Section === "Grant Summary" || props.Section === "Financial Summary" ?

                        GrantContents  : null
                    }
                    {props.Section === "Levy Summary" ?
                        LevyContents : null
                    }

                    {props.Section === "Financial Summary" ?
                       <Text>{'\n'}</Text> : null
                    }

                    {props.Section === "Financial Summary" ?
                        
                        FinSummaryContents : null
                    }
                </Page>
            </Document>
          );

    return (

            <div>
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Generate Document for {props.Section}
                    </DialogContentText>
                <PDFDownloadLink document={<MyDoc />} fileName={props.data.OrganisationName + " - " + props.data.SDLNumber + " - " + props.Section + " - " + props.data.FinancialYear} >
                {({ blob, url, loading, error }) =>
                    loading ? 'Generating Document...' : 'Download Document!'
                }
                </PDFDownloadLink>
                
                </DialogContent>
            </div>

    );
}