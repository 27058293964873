
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
const LodgeApealDetailsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [docName, setDocName] = React.useState('');

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })


    return (
        <EntityForm ref={ref}
            viewId="dgApplication"
            dataURL='api/sims/requestextension/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="requestextensionform">

                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>


                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/requestextension/GetLookupList?listId=YesNo"
                                        id="Letterhead"
                                        key={tabValue}
                                        label="Is the extension request on a company letterhead?"
                                        className="w-100"
                                        required={true}
                                        form={form} />
                                </td>



                            </tr>

                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/requestextension/GetLookupList?listId=YesNo"
                                        id="AuthorisedSignatory"
                                        key={tabValue}
                                        label="Is the request signed off by an appropriate level of management with the correct authority?"
                                        required={true}
                                        className="w-100"
                                        form={form} />
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/requestextension/GetLookupList?listId=YesNo"
                                        id="Reasons"
                                        key={tabValue}
                                        label="Does the request include the reasons why the WSP/ATR cannot be submitted by 30 April?"
                                        required={true}
                                        className="w-100"
                                        form={form} />
                                </td>


                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

export default LodgeApealDetailsForm;