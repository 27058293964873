import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import FormDialog from "../../etqa/FormDialog";
import { OrganisationContactForm } from "./OrganisationContactForm";
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'Title', numeric: false, disablePadding: true, label: 'Title' },
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'Designation', numeric: false, disablePadding: true, label: 'Designation' },
    { id: 'TelephoneNumber', numeric: false, disablePadding: true, label: 'Telephone Number' },
    { id: 'CellPhoneNumber', numeric: false, disablePadding: true, label: 'CellPhone Number' },
    { id: 'FaxNumber', numeric: false, disablePadding: true, label: 'Fax Number' },
    { id: 'EMail', numeric: false, disablePadding: true, label: 'EMail' }

];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class OrganisationContact extends Component {

    constructor(props) {
        super(props)
        this.state = {
            addDialogOpen: false,
            editDialogOpen: false,
            selectedContact: null,
            promptOpen: false,
            promptTitle: "Remove contact from Organisation",
            promptContent: "Are you sure you want to remove this contact from the Organisation?",
            Message: "",
            MessageStyle: "",
            searchParameters: [{ Name: "OrganisationID", Value: this.props.location.state.organisationID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: 'Add Contact', tooltip: 'Add contact to Organisation', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: 'Edit Contact', tooltip: 'Edit contact on Organisation', icon: 'EditIcon', disabled: utils.isNull(this.state.selectedContact), visible: this.props.menuPermissionItem.Edit },
                        { id: 'remove', label: 'Remove Contact', tooltip: 'Remove contact from Organisation', disabled: utils.isNull(this.state.selectedContact), icon: "Delete", visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisation',
                state: { selection: this.props.location.state.organisationID }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ addDialogOpen: true, Message: "" })
        }
        else if ("edit" === buttonId) {
            this.setState({ editDialogOpen: true, Message: "" })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                addDialogOpen: false,
                editDialogOpen: false,
                selectedContact: null,
                Message: "Organisation Contact successfully saved",
                MessageStyle: "success"
            });

            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedContact: null,
                Message: ""
            })
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedContact: null,
                Message: "A system error has occured, please try again later or contact your administrator",
                MessageStyle: "warning"
            })
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedContact: selection[0].Id, Message: "" });
        } else {
            this.setState({ selectedContact: null, Message: "" });
        }
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulContactRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedContact: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulContactRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeContact(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedContact}&OrganisationID=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulContactRemoveCallback, this.unsuccessfulContactRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ promptOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Organisation > Contacts " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />
                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/skillsorganisationcontact/GetSearch`}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Add Organisation Contact"}
                    viewRef={this}
                    entityInfo={{ OrganisationID: this.props.location.state.organisationID }}
                    formRef={addFormRef}
                    saveUrl={"api/sims/skillsorganisationcontact/AddOrganisationContact"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <OrganisationContactForm ref={addFormRef} />
                </FormDialog>


                <FormDialog
                    open={this.state.editDialogOpen}
                    dialogTitle={"Edit Organisation Contact"}
                    viewRef={this}
                    formRef={addFormRef}
                    entityId={this.state.selectedContact}
                    saveUrl={"api/sims/skillsorganisationcontact/UpdateOrganisationContact"}
                    usePut={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <OrganisationContactForm
                        ref={addFormRef}
                        id={this.state.selectedContact}
                        dataURL='api/sims/skillsorganisationcontact/GetFind'
                    />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeContact("api/sims/skillsorganisationcontact/RemoveOrganisationContact") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(OrganisationContact));
