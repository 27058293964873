import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Moderator.css";
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "../../View";
import ProviderSearch from "../provider/ProviderSearch";
import { headCells } from "../provider/ProviderSearch";
import { format } from "date-fns";
import EnhancedTable from '../../widgets/ResponsiveTable';
import LinkDialog from "../LinkDialog";

const linkToolbarRef = React.createRef();
const toolbarRef = React.createRef();
const utils = new ViewUtils();

class AddModeratorTrainingProvider extends Component {
    constructor(props) {
        super(props);

        let nextButtonDisabled = true;
        if (!utils.isNull(this.props.data) && !utils.isNull(this.props.data.trainingProviderDetails)) {
            nextButtonDisabled = this.props.data.trainingProviderDetails.trainingProviders.length === 0;
        }

        let trainingProviderIds = "";
        if (!utils.isNull(props.data) && !utils.isNull(props.data.trainingProviderDetails)) {
            trainingProviderIds = this.getIdList(props.data.trainingProviderDetails.trainingProviders);
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'back', label: 'BACK', tooltip: 'Back to Search', theme: true },
                        { id: 'next', label: 'NEXT', tooltip: 'Proceed to Training Provider', theme: true, disabled: nextButtonDisabled }
                    ]
                }
            ]
        };

        this.linkToolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: 'Link', tooltip: 'link the selected provider to the Moderator' },
                        { id: 'unlink', label: 'Unlink', tooltip: 'Un-link the selected provider from the Moderator', disabled: true }
                    ]
                }
            ]
        };
        this.state = {
            model: !utils.isNull(props.data) && !utils.isNull(props.data.trainingProviderDetails) ? props.data.trainingProviderDetails : { trainingProviders: [] },
            searchParameters: null,
            message: "",
            trainingProviderIds: trainingProviderIds,
            linkDialogOpen: false,
            propmtOpen: false,
            ModeratorName: this.props.data.FirstName + " " + this.props.data.Surname
        };

    }

    postLinkHandler(isCancel, linked) {
        this.setState({ linkDialogOpen: !isCancel && !linked })
    }

    getIdList(programmes) {
        let exclusions = "";

        for (let i = 0; i < programmes.length; i++) {
            exclusions += programmes[i].Id;
            if (i < programmes.length - 1) {
                exclusions += ","
            }
        }

        return exclusions;
    }

    saveTrainingProvider(selection) {

        for (let i = 0; i < selection.length; i++) {
            selection[i].ModeratorAssessmentEndDate = format(this.props.data.registrationDetails.RegistrationEndDate, "yyyy/MM/dd HH:mm:ss");
            this.state.model.trainingProviders.push(selection[i]);
        }

        let trainingProviderIdList = "";
        trainingProviderIdList = this.getIdList(this.state.model.trainingProviders);

        this.setState({
            trainingProviderIds: trainingProviderIdList
        }
        )

        toolbarRef.current.setButtonDisabled("next", this.state.model.trainingProviders.length === 0);
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedTrainingProviderId: selection[0].Id });
        } else {
            this.setState({ selectedTrainingProviderId: null });
        }
        linkToolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    handleToolbarButtonClick(buttonId) {
        if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true });
        }
        else if ("unlink" === buttonId) {
            if (!utils.isNull(this.state.selectedTrainingProviderId)) {
                let trainingProviders = this.state.model.trainingProviders;
                let newTrainingProviders = [];

                for (let i = 0; i < trainingProviders.length; i++) {
                    if (trainingProviders[i].Id !== this.state.selectedTrainingProviderId) {
                        newTrainingProviders.push(trainingProviders[i]);
                    }
                }

                let newIds = this.getIdList(newTrainingProviders);
                this.setState({ trainingProviderIds: newIds });

                this.setState({ model: { ...this.state.model, trainingProviders: newTrainingProviders } }, () => {
                    toolbarRef.current.setButtonDisabled("next", newIds.length === 0);
                });
            }
        } else if ("back" === buttonId) {
            this.props.stepHandler(3, false, this.state.model);
        } else if ("next" === buttonId) {
            this.props.stepHandler(3, true, this.state.model);
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <div style={{ margin: "8px 0 32px 8px", height: "37vh" }}>
                    <p className="legend">
                        Training Providers
                    </p>
                    <div style={{ margin: "8px 0 16px 0px", width: "99%", paddingLeft: "8px", paddingRight: "8px" }}>
                        <EnhancedToolbar
                            highlight={false}
                            multiSelect={false}
                            buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                            ref={linkToolbarRef} config={this.linkToolbarConfig} />
                    </div>
                    <div style={{ height: "90%", overflow: "auto", width: "99%", paddingLeft: "8px", paddingRight: "8px" }}>
                        <EnhancedTable
                            headCells={headCells} autoFetchData={false} multiSelect={false}
                            rows={this.state.model.trainingProviders}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={false} />
                    </div>
                    <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => { this.saveTrainingProvider(selection) }}
                        closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                        dialogTitle={`Link Training Provider to Moderator: ${this.state.ModeratorName}`}
                    >
                        <ProviderSearch dataUrl="api/sims/ModeratorDetails/getModeratorProvider"
                            multiSelect={true} showTitle={false} selectionCallbackMode="full"
                            programmeType="Q" exclusions={this.state.trainingProviderIds}
                            mode="lookup"
                        />
                    </LinkDialog>
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar ref={toolbarRef} highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={this.toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddModeratorTrainingProvider);
