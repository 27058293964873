import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import SearchView from "../../../widgets/SearchView";
import NavigationSearchForm from './NavigationSearchForm';
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "MenuItemName", numeric: false, disablePadding: true, label: "Menu Item Name" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select Menu Item from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

class NavigationSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.RoleID) ?
            //For search of providers that can be linked to a particular entity
            [
                { Name: "RoleID", Value: this.props.RoleID },
                { Name: "MenuItemName", Value: "" }
            ]
            :
            //For search on the provider search screen
            [
                { Name: "MenuItemName", Value: "" }
            ]

        let model = !utils.isNull(this.props.RoleID) ?
            {
                MenuItemName: "",
                RoleID: this.props.RoleID
            }
            :
            {
                MenuItemName: ""
            }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedOFO: null,
            linkDialogOpen: false
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Menu Item' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/OFOOccupation/search"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="MenuItemName"
                    model={this.state.model}
                    searchParameters={this.searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                >
                    <NavigationSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} />
                </SearchView>
            </ViewContainer>
        )
    }
}

export default withRouter(NavigationSearch);