import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Moderator.css";
import { ModeratorRegistrationDetailsForm } from './ModeratorRegistrationDetailsForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "../../View";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to Moderator details', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to add registration details', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class ModeratorRegistrationDetails extends Component {
    constructor(props) {
        super(props)
        let data = {};

        if (!utils.isNull(props.data) && !utils.isNull(props.data.registrationDetails)) {
            data = props.data.registrationDetails;
        }

        this.state = {
            model: data
        }
    }

    handleFormValueChange(values) {
    }

    handleToolbarButtonClick(buttonId) {
        let formValue = formRef.current.getValue();
        if ("back" === buttonId) {
            this.props.stepHandler(2, false, formValue);
        } else if ("next" === buttonId) {
            if (!utils.isNull(formValue)) {
                this.props.stepHandler(2, true, formValue);
            }
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <p className="legend">
                    Registration Details
                </p>
                <div style={{ height: "65%", maxHeight: "42vh" }}>
                    <ModeratorRegistrationDetailsForm editPermission={this.props.permissionItem.Edit} viewRef={this} ref={formRef} data={this.state.model} valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(ModeratorRegistrationDetails);
