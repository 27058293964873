import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Row, Col, Form } from 'react-bootstrap';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import QualificationSetupSearch from './QualificationSetupSearch';
import OFOSearch from './OFOSearch';
import { LookupField } from '../../widgets/LookupField';
import DocumentUpload from "../../widgets/DocumentUpload";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const QualificationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [filename, setFilename] = React.useState("");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleReplacementChange = form => event => {
        if (!event.target.checked) {
            form.values['qualification'] = null;
            form.setLookupSelection("qualification", null);
        }

        form.updateValue('IsReplacement', event.target.checked);
    };

    const handleReRegisteredChange = form => event => {
        if (!event.target.checked) {
            form.values['NewRegistrationStartDate'] = null;
            form.values['NewRegistrationEndDate'] = null;
            form.values['NewLastEnrolmentDate'] = null;
            form.values['NewLastAchievementDate'] = null;
        }

        form.updateValue('IsReregistered', event.target.checked);
    };

    function offsetDate(date, yearOffset, monthOffset, dayOffset) {
        let year = date.getFullYear() + yearOffset;
        let day = date.getDate() + dayOffset;
        let month = date.getMonth() + monthOffset;

        return new Date(year, month, day);
    }

    function dateChange(form, date) {

        let newregend = offsetDate(date, 4, 0, 0);
        let newlastenrolment = offsetDate(date, 6, 0, 0);
        let newlastachievement = offsetDate(date, 9, 0, 0);

        form.values['NewRegistrationEndDate'] = newregend;
        form.values['NewLastEnrolmentDate'] = newlastenrolment;
        form.values['NewLastAchievementDate'] = newlastachievement;
    }

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
        }
    };

    function FileDownload(id) {
        utils.invokeUrl("api/sims/qualificationsetupdetails/DownloadDocument?id=" + id, (response) => {

            var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    function base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    return (
        <EntityForm
            ref={ref}
            viewId="qualificationSetup"
            dataURL='api/sims/qualificationsetupdetails/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id}
            useCustomSettings={false}
            defaultHandlerObj={props.defaultHandlerObj}
            valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <Form id="qualificationSetupForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Qualification Setup Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <Form.Row>
                                <Col xs={2} md={2} lg="6" >
                                    <DocumentUpload
                                        showViewer={false}
                                        onUploadHandler={onUploadHandler}
                                        buttonLabel="Upload MOU"
                                        filename={filename}
                                        form={form}
                                        required={false}
                                        id="Document"
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                {(form.values["OriginalFileName"] !== "" && !utils.isNull(form.values["OriginalFileName"])) || !utils.isNull(props.savedOriginalFileName) ?
                                    <Col xs={2} md={2} lg="6" >
                                        <a id="aDocument" onClick={() => FileDownload(props.id)} >Download Document</a>
                                    </Col>
                                    :
                                    <Col xs={2} md={2} lg="6" >
                                        &nbsp;
                                    </Col>
                                }
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="SAQAQualificationID"
                                        key={tabValue}
                                        validationRegex={/^[0-9]+$/g}
                                        validationMessage="This field must be numeric"
                                        label="SAQA Qualification ID"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        disabled={!props.editPermission} /*disabled={props.id}*/
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="SAQAQualificationTitle"
                                        key={tabValue}
                                        required={true}
                                        label="SAQA Qualification Title"
                                        className="w-100 "
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="MinimumElectiveCredits"
                                        key={tabValue}
                                        label="Minimum Elective Credits"
                                        className="w-100 "
                                        required
                                        validationRegex={/^[0-9]+$/g}
                                        validationMessage="This field must be numeric"
                                        validator={{
                                            script: (formValues) => {
                                                return !utils.isNull(formValues)
                                                    && formValues.Credits <= 1000
                                            }, message: "This value must be less than 1000"
                                        }}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="Credits"

                                        key={tabValue}
                                        label="Credits"
                                        className="w-100 "
                                        required
                                        validationRegex={/^[0-9]+$/g}
                                        validationMessage="This field must be numeric"
                                        validator={{
                                            script: (formValues) => {
                                                return !utils.isNull(formValues)
                                                    && formValues.Credits <= 1000
                                            }, message: "This value must be less than 1000"
                                        }}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="RegistrationStartDate"
                                        validationGroup="registrationDates"
                                        key={tabValue}
                                        required
                                        InputProp={{
                                            readOnly: true
                                        }}
                                        label="Registration Start Date"
                                        className='{classes.textField} w-100 '
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="RegistrationEndDate"
                                        validationGroup="registrationDates"
                                        required
                                        key={tabValue}
                                        InputProp={{
                                            readOnly: true
                                        }}
                                        validator={{
                                            script: (formValues) => {
                                                return !utils.isNull(formValues)
                                                    && !utils.isNull(formValues.RegistrationStartDate)
                                                    && formValues.RegistrationStartDate.getTime() < formValues.RegistrationEndDate.getTime()
                                            }, message: "Start date must be before the end date"
                                        }}
                                        label="Registration End Date"
                                        className='{classes.textField} w-100 '
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="LastEnrolmentDate"
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        required
                                        key={tabValue}
                                        label="Last Enrolment Date"
                                        className='{classes.textField} w-100 '
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="LastAchievementDate"
                                        required
                                        key={tabValue}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        label="Last Achievement Date"
                                        validator={{
                                            script: (formValues) => {
                                                return !utils.isNull(formValues)
                                                    && !utils.isNull(formValues.LastEnrolmentDate)
                                                    && formValues.LastEnrolmentDate.getTime() < formValues.LastAchievementDate.getTime()
                                            }, message: "Last achievement date must be greater than last enrolment date"
                                        }}
                                        className='{classes.textField} w-100 '
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/qualificationsetupdetails/GetLookupList?listId=QualityAssuranceBody"
                                        id="QualityAssuranceBodyID"
                                        key={tabValue}
                                        label="SETA"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/qualificationsetupdetails/GetLookupList?listId=QualificationType"
                                        id="QualificationTypeID"
                                        key={tabValue}
                                        label="Qualification Type"
                                        required={true}
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <LookupField
                                        id="OFOOccupationID"
                                        label="OFO Occupation"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                    >
                                        <OFOSearch showTitle={true} mode='lookup'
                                            dataUrl='api/sims/qualificationsetupdetails/getofooccupationsearch'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("OFOOccupationID", value)
                                            }} autoFetchData={false} selection={props.selection} />
                                    </LookupField>
                                </Col>

                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/qualificationsetupdetails/GetLookupList?listId=nqfLevel"
                                        id="NQFLevelID"
                                        key={tabValue}

                                        required
                                        label="NQF Level"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission} />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomCheckbox
                                        id="IsReregistered"
                                        key={tabValue}
                                        form={form} disabled={!props.editPermission}
                                        onChange={handleReRegisteredChange}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Is Re-registered?"
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="NewRegistrationStartDate"
                                        key={tabValue}
                                        validationGroup="newRegistrationDates"
                                        label="New Registration Start Date"
                                        required={form.values.IsReregistered}
                                        disabled={!form.values.IsReregistered}
                                        onChange={dateChange}
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !form.values.IsReregistered
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="NewRegistrationEndDate"
                                        key={tabValue}
                                        validationGroup="newRegistrationDates"
                                        label="New Registration End Date"
                                        className='{classes.textField} w-100 '
                                        required={form.values.IsReregistered}
                                        disabled={!form.values.IsReregistered}
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !form.values.IsReregistered
                                        }}

                                        validator={{
                                            script: (formValues) => {
                                                return !formValues.IsReregistered || (formValues.IsReregistered && !utils.isNull(formValues)
                                                    && !utils.isNull(formValues.NewRegistrationStartDate)
                                                    && formValues.NewRegistrationStartDate.getTime() < formValues.NewRegistrationEndDate.getTime())
                                            }, message: "New registration end date  must be greater than new registration start date"
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="NewLastEnrolmentDate"
                                        key={tabValue}
                                        validationGroup="newEnrolmentDates"
                                        label="New Last Enrolment Date"
                                        className='{classes.textField} w-100 '
                                        required={form.values.IsReregistered}
                                        disabled={!form.values.IsReregistered}
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !form.values.IsReregistered
                                        }}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomDatePicker
                                        id="NewLastAchievementDate"
                                        key={tabValue}
                                        validationGroup="newEnrolmentDates"
                                        label="New Last Achievement Date"
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !form.values.IsReregistered
                                        }}
                                        validator={{
                                            script: (formValues) => {
                                                return !formValues.IsReregistered || (!utils.isNull(formValues)
                                                    && !utils.isNull(formValues.NewRegistrationStartDate)
                                                    && formValues.NewLastEnrolmentDate.getTime() < formValues.NewLastAchievementDate.getTime())
                                            }, message: "New achievement end date must be greater than new enrolment last enrolment date"
                                        }}

                                        required={form.values.IsReregistered}
                                        disabled={!form.values.IsReregistered}
                                        form={form} disabled={!props.editPermission}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <CustomCheckbox
                                        id="IsReplacement"
                                        key={tabValue}
                                        form={form} disabled={!props.editPermission}
                                        onChange={handleReplacementChange}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        label="Is Replacement?"
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <LookupField
                                        id="QualificationToBeReplacedID"
                                        label="Qualification To Be Replaced"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                        required={form.values.IsReplacement}
                                        disabled={!form.values.IsReplacement}
                                    >
                                        <QualificationSetupSearch
                                            showTitle={true} mode='lookup'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("QualificationToBeReplacedID", value)
                                            }} autoFetchData={true} selection={props.selection} />
                                    </LookupField>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/qualificationsetupdetails/GetLookupList?listId=AccreditationOwner"
                                        id="AccreditationOwnerID"
                                        key={tabValue}
                                        label="Who owns the Accreditation"
                                        className="w-100"
                                        form={form} disabled={!props.editPermission}
                                        required
                                    />
                                </Col>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="MoUWith"
                                        key={tabValue}
                                        label="MoU With"
                                        className="w-100"
                                        form={form}
                                        disabled={!props.editPermission}
                                        required
                                    />
                                </Col>
                            </Form.Row>
                        </TabPanel>
                    </FormTabContainer>
                </Form>
            }
        </EntityForm>
    );
})
