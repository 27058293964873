import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();
export const DiAssociationDetailForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="DiAssociationDetail" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
    {form =>
                <form className={form.classes.container} id="DiAssociationDetail">
        <table cellPadding="2" className="w-100">
            <tbody>
                            <tr>

                                <td>
                                    <CustomTextField id="RequestedBy"
                                        key={tabValue}
                                        label="Requested By"
                                        required={true}
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="LogitNumber"
                                        required={true}
                                        key={tabValue}
                                        label="Logit Number to reverse Claim"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                </tr>
                <tr>
                                <td>
                                    <CustomDatePicker
                                        id="RequestedDate"
                                        key={tabValue}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label="Requested Date"
                                        className='{classes.textField} w-100'
                                        form={form}
                                    />
                                </td>
                </tr>
            </tbody>
        </table>
    </form>
    }
</EntityForm>
    );
})
