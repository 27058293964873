import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { LookupField } from '../../widgets/LookupField';
import SchoolEMISSearch from '../SchoolEMISSearch';
import STATSSAAreaCodeSearch from '../STATSSAAreaCodeSearch';
import { GPSCoordinatesAPI } from '../../widgets/GPSCoordinatesAPI';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

const utils = new ViewUtils();
export const ProjectOwnerForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleUsePhysicalAddressForPostal = form => event => {


        if (event.target.checked === true) {
            form.values['PostalAddressLine1'] = form.values["PhysicalAddress1"];
            form.values['PostalAddressLine2'] = form.values["PhysicalAddress2"];
            form.values['PostalAddressLine3'] = form.values["PhysicalAddress3"];
            form.values['PostalCode'] = form.values["PhysicalCode"];
            setPostalCodeValue(form.values["PhysicalCode"]);
        }
        form.updateValue('UsePhysicalAsPostal', event.target.checked);


    }

    const updateGPSAddress = (address, form) => {
        console.log(address);
        form.values["GPSCoordinates"] = address.gpscoordinates.coordinate;
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    const classes = useStyles();

    return (
        <EntityForm ref={ref} viewId="projectowner" values={props.data} dataURL='api/sims/projectownerdetails/getfind'
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ProjectOwnerForm">
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/projectownerdetails/GetLookupList?listId=Title"
                                                id="TitleID"
                                                key={tabValue}
                                                label="Title"
                                                className="w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="FirstName"
                                                key={tabValue}
                                                label="First Name"
                                                className="w-100"
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="MiddleName"
                                                key={tabValue}
                                                label="Middle Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Surname"
                                                key={tabValue}
                                                label="Surname"
                                                className="w-100"
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="General Details" wrapped {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Contact Details" wrapped {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                                <Tab label="Address Details" wrapped {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null }} />
                                {/*<Tab label="Health Functioning Values" wrapped {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null, width: "50px" }} />*/}
                                {/*<Tab label="Education Details" wrapped {...a11yProps(4)} style={{ color: form.fieldParentErrors[4] === true ? "red" : null }} />*/}
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="IDNo"
                                                parentId={0}
                                                key={tabValue}
                                                label="ID Number"
                                                className="w-100"
                                                validationRegex={/^[0-9]{13}$/g}
                                                validationMessage="The ID Number must be 13 digits long"
                                                required

                                                form={form}
                                                disabled={props.id}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/projectownerdetails/GetLookupList?listId=AlternateIDType"
                                                id="AlternateIDTypeID"
                                                key={tabValue}
                                                label="Alternate ID Type"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Initials"
                                                parentId={0}
                                                key={tabValue}
                                                label="Initials"
                                                className="w-100"
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="DateofBirth"
                                                parentId={0}
                                                key={tabValue}
                                                label="Date of Birth"
                                                className='{classes.textField} w-100'
                                                required

                                                form={form}
                                                disabled={!props.editPermission}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/projectownerdetails/GetLookupList?listId=HomeLanguage"
                                                id="HomeLanguageID"
                                                required={true}
                                                parentId={0}
                                                key={tabValue}
                                                label="Home Language"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/projectownerdetails/GetLookupList?listId=Nationality"
                                                id="NationalityID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Nationality"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/projectownerdetails/GetLookupList?listId=CitizenResidentialStatus"
                                                id="CitizenResidentialStatusID"
                                                key={tabValue}
                                                parentId={0}
                                                required={true}
                                                label="Citizen Residential Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    disabled: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/projectownerdetails/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                key={tabValue}
                                                parentId={0}
                                                required={true}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/projectownerdetails/GetLookupList?listId=Gender"
                                                id="GenderID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Gender"
                                                className="{classes.select} w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    disabled: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            {/*<SelectItem*/}
                                            {/*    dataUrl="api/sims/projectownerdetails/GetLookupList?listId=Equity"*/}
                                            {/*    id="EquityID"*/}
                                            {/*    parentId={0}*/}
                                            {/*    required={true}*/}
                                            {/*    key={tabValue}*/}
                                            {/*    label="Equity"*/}
                                            {/*    className="{classes.select} w-100"*/}
                                            {/*    allowEmptyValue={true}*/}
                                            {/*    form={form}*/}
                                            {/*    disabled={!props.editPermission} />*/}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={1}
                                                id="TelephoneNumber"
                                                key={tabValue}
                                                label="Telephone Number"
                                                //validationRegex={/^[0-9]+$/g}
                                                //validationMessage="This field must be numeric"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CellPhoneNumber"
                                                validationRegex={"^0\\d{9}$|^2\\d{10}$"}
                                                validationMessage="Starting with 0 then number should be 10 digits long else starting with 2 it should be 11"
                                                key={tabValue}
                                                required={true}
                                                parentId={1}
                                                label="Cell Phone Number"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EMail"
                                                key={tabValue}
                                                required={true}
                                                parentId={1}
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                validationMessage="Email not in the correct format"
                                                label="E Mail"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            {/*<CustomTextField*/}
                                            {/*    parentId={1}*/}
                                            {/*    id="FaxNumber"*/}
                                            {/*    key={tabValue}*/}
                                            {/*    label="Fax Number"*/}
                                            {/*    className="w-100"*/}
                                            {/*    form={form}*/}
                                            {/*    disabled={!props.editPermission}*/}
                                            {/*/>*/}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <table cellPadding="2" width="70%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                parentId={2}
                                                id="UsePhysicalAddressForPostalAddress"
                                                key={tabValue}
                                                form={form}
                                                disabled={!props.editPermission}
                                                onChange={handleUsePhysicalAddressForPostal}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Use Physical Address for Postal Address?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <GPSCoordinatesAPI
                                                parentId={2}
                                                id="Address"
                                                key={tabValue}
                                                className="w-100"
                                                label="Address"
                                                form={form}
                                                disabled={!props.editPermission}
                                                updateGPSAddress={updateGPSAddress}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="GPSCoordinates"
                                                key={tabValue}
                                                label="GPS Coordinates"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PhysicalAddress1"
                                                required
                                                key={tabValue}
                                                label="Physical Address 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PostalAddressLine1"
                                                key={tabValue}
                                                required
                                                label="Postal Address Line 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PhysicalAddress2"
                                                key={tabValue}
                                                required
                                                label="Physical Address 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PostalAddressLine2"
                                                required
                                                key={tabValue}
                                                label="Postal Address Line 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PhysicalAddress3"
                                                key={tabValue}
                                                label="Physical Address 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PostalAddressLine3"
                                                key={tabValue}
                                                label="Postal Address Line 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <AddressCodeItem
                                                parentId={2}
                                                id="PhysicalCode"
                                                required={true}
                                                addressId="Physical"
                                                value={physicalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                                className="w-100"
                                                label="Physical Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>

                                        <td>
                                            <AddressCodeItem
                                                parentId={2}
                                                id="PostalCode"
                                                required={true}
                                                addressId="Postal"
                                                value={postalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PostalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PostalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PostalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PostalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PostalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PostalCityID : null}
                                                className="w-100"
                                                label="Postal Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        {/*<TabPanel value={tabValue} index={2}>*/}
                        {/*    <table cellPadding="2" width="80%">*/}
                        {/*        <tbody>*/}
                        {/*            <tr>*/}
                        {/*                <td>*/}
                        {/*                    <SelectItem*/}
                        {/*                        dataUrl="api/sims/projectownerdetails/GetLookupList?listId=HealthFunctioningRating"*/}
                        {/*                        id="SeeingID"*/}
                        {/*                        key={tabValue}*/}
                        {/*                        label="Seeing"*/}
                        {/*                        className="w-100"*/}
                        {/*                        required={true}*/}
                        {/*                        parentId={2}*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission} />*/}
                        {/*                </td>*/}
                        {/*                <td>*/}
                        {/*                    <SelectItem*/}
                        {/*                        dataUrl="api/sims/projectownerdetails/GetLookupList?listId=HealthFunctioningRating"*/}
                        {/*                        id="HearingID"*/}
                        {/*                        required={true}*/}
                        {/*                        parentId={2}*/}
                        {/*                        key={tabValue}*/}
                        {/*                        label="Hearing	"*/}
                        {/*                        className="w-100"*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission} />*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td>*/}
                        {/*                    <SelectItem*/}
                        {/*                        dataUrl="api/sims/projectownerdetails/GetLookupList?listId=HealthFunctioningRating"*/}
                        {/*                        id="CommunicatingID"*/}
                        {/*                        key={tabValue}*/}
                        {/*                        parentId={2}*/}
                        {/*                        required={true}*/}
                        {/*                        label="Communicating"*/}
                        {/*                        className="w-100"*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission} />*/}
                        {/*                </td>*/}
                        {/*                <td>*/}
                        {/*                    <SelectItem*/}
                        {/*                        dataUrl="api/sims/projectownerdetails/GetLookupList?listId=HealthFunctioningRating"*/}
                        {/*                        id="WalkingID"*/}
                        {/*                        parentId={2}*/}
                        {/*                        required={true}*/}
                        {/*                        key={tabValue}*/}
                        {/*                        label="Walking"*/}
                        {/*                        className="w-100"*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission} />*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td>*/}
                        {/*                    <SelectItem*/}
                        {/*                        dataUrl="api/sims/projectownerdetails/GetLookupList?listId=HealthFunctioningRating"*/}
                        {/*                        id="RememberingID"*/}
                        {/*                        parentId={2}*/}
                        {/*                        required={true}*/}
                        {/*                        key={tabValue}*/}
                        {/*                        label="Remembering"*/}
                        {/*                        className="w-100"*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission} />*/}
                        {/*                </td>*/}
                        {/*                <td>*/}
                        {/*                    <SelectItem*/}
                        {/*                        dataUrl="api/sims/projectownerdetails/GetLookupList?listId=HealthFunctioningRating"*/}
                        {/*                        id="SelfcareID"*/}
                        {/*                        parentId={2}*/}
                        {/*                        required={true}*/}
                        {/*                        key={tabValue}*/}
                        {/*                        label="Selfcare"*/}
                        {/*                        className="w-100"*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission} />*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}

                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</TabPanel>*/}
                        {/*<TabPanel value={tabValue} index={4} >*/}
                        {/*    <table cellPadding="2" width="80%">*/}
                        {/*        <tbody>*/}
                        {/*            <tr>*/}
                        {/*                <td width="50%">*/}
                        {/*                    <LookupField*/}
                        {/*                        id="SchoolEMISID"*/}
                        {/*                        required*/}
                        {/*                        label="Last School EMIS"*/}
                        {/*                        className="w-100"*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission}*/}
                        {/*                    >*/}
                        {/*                        <SchoolEMISSearch showTitle={true} mode='lookup' dataUrl="api/sims/projectownerdetails/GetLastSchoolEMIS"*/}
                        {/*                            onLookupValueChange={(value) => {*/}
                        {/*                                form.setLookupSelection("SchoolEMISID", value)*/}
                        {/*                            }} autoFetchData={false} selection={props.selection} />*/}
                        {/*                    </LookupField>*/}
                        {/*                </td>*/}
                        {/*                <td>*/}

                        {/*                    <SelectItem*/}
                        {/*                        dataUrl="api/sims/projectownerdetails/GetLookupList?listId=LastSchoolYearID"*/}
                        {/*                        id="LastSchoolYearID"*/}
                        {/*                        required*/}
                        {/*                        key={tabValue}*/}
                        {/*                        label="Last School Year"*/}
                        {/*                        className="{classes.select} w-100"*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission}*/}
                        {/*                        InputProps={{*/}
                        {/*                            readOnly: props.disableFields*/}
                        {/*                        }}*/}
                        {/*                    />*/}

                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td>*/}
                        {/*                    <LookupField*/}
                        {/*                        id="STATSSAAreaCodeID"*/}
                        {/*                        label="STATSSA Area Code"*/}
                        {/*                        className="w-100"*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission}*/}
                        {/*                    >*/}
                        {/*                        <STATSSAAreaCodeSearch showTitle={true} mode='lookup' dataUrl="api/sims/projectownerdetails/GetSTATSSAAreaCode"*/}
                        {/*                            onLookupValueChange={(value) => {*/}
                        {/*                                form.setLookupSelection("STATSSAAreaCodeID", value)*/}
                        {/*                            }} autoFetchData={false} selection={props.selection} />*/}
                        {/*                    </LookupField>*/}
                        {/*                </td>*/}

                        {/*                <td>*/}
                        {/*                    <SelectItem*/}
                        {/*                        dataUrl="api/sims/projectownerdetails/GetLookupList?listId=POPIActStatus"*/}
                        {/*                        id="POPIActStatusID"*/}
                        {/*                        key={tabValue}*/}
                        {/*                        label="POPI Act Status"*/}
                        {/*                        className="{classes.select} w-100"*/}
                        {/*                        allowEmptyValue={true}*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission} />*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <td colSpan="2">*/}
                        {/*                    <CustomDatePicker*/}
                        {/*                        id="POPIActStatusDate"*/}
                        {/*                        key={tabValue}*/}
                        {/*                        InputProps={{*/}
                        {/*                            readOnly: true,*/}
                        {/*                        }}*/}
                        {/*                        label="POPI Act Status Date"*/}
                        {/*                        className='{classes.textField} w-100'*/}
                        {/*                        form={form}*/}
                        {/*                        disabled={!props.editPermission}*/}
                        {/*                    />*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</TabPanel>*/}
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

