import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { CustomTextField } from '../../widgets/CustomTextField';
import Button from '@material-ui/core/Button';
import { EntityForm } from '../../widgets/Forms';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { makeStyles } from '@material-ui/core/styles';
import ViewUtils from "./../../ViewUtils";

const utils = new ViewUtils();
export default function CommentsDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [comment, setComment] = React.useState(null)

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })


    const handleClose = (isCancel) => {
        setOpen(true);
        props.closeHandler(!isCancel)
    };


    const cancel = () => {
        handleClose(true);
    };

    const add = () => {
        if (comment !== null || comment !== "") {
            let data = {};
            data.comment = comment;
            data.EntityID = props.EntityID;

            utils.invokeUrl(props.postUrl, (response) => {
                handleClose(true, false);
            }, () => { handleClose(false, false); },
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(data)
                }
            );
        }
    }

    const handleFormValueChange = (values) => {
        setComment(values.comment)
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}
                style={{ scroll: "body" }}>
                <div style={{ padding: "32px" }}>

                    <p className="legend" >
                        New Comment
                    </p>


                    <div style={{ height: "80%" }}>
                        <EntityForm viewId="learnerProgramIntervension" useCustomSettings={false} valueChangeHandler={(value) => handleFormValueChange(value)}>
                            {form =>
                                <form className={form.classes.container} id="comments">
                                    <div className="row w-50">
                                        <div className="col w-100">

                                            <td>
                                                <CustomTextField
                                                    id="comment"
                                                    label="Comment"
                                                    className="w-100"
                                                    multiline
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    styles={styles()}

                                                    form={form}
                                                />
                                                </td>

                                        </div>
                                    </div>
                                </form>
                            }
                        </EntityForm>
                    </div>


                    <DialogActions>
                        <Button onClick={add} color="primary">
                            Save
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
