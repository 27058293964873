import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import { SelectItem } from '../../widgets/SelectItem';
import ProviderSearch from "../provider/ProviderSearch";
import ModeratorSearch from "../moderator/ModeratorSearch";
import { Row, Col } from 'react-bootstrap';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

export default function ModeratorDocumentSearchForm(props) {
    const [dependency, setDependency] = React.useState(props.dependency);

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    return (
        <EntityForm ref={React.createRef()} viewId="moderator" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ModeratorSearchForm">
                    <div className="row search-form-container">

                        <div className="col form-col">
                            <LookupField
                                id="ModeratorID"
                                label="Moderator"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <ModeratorSearch
                                    showTitle={false}
                                    dataUrl="api/sims/moderatorletters/ModeratorSearch"
                                    mode='lookup'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("ModeratorID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>

                        {/*<div className="col form-col">
                            <LookupField
                                id="Provider"
                                label="Provider"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <ProviderSearch
                                    showTitle={false}
                                    mode='lookup'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("Provider", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>*/}
                    </div>
                </form>
            }
        </EntityForm >
    );
}