import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerLearnershipForm } from '../../etqa/program/registration/LearnerLearnershipForm';

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
            ]
        }
        ,
        {
            type: "devider"
        },
        {
            type: "buttonGroup",
            buttons: [
                { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        }
    ]
};

const LearnershipRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerLearnership extends Component {

    state = {
        LearnershipDetails: {
            LearnershipProgrammeDescription: "",
            LearnershipProgrammeCode: ""
        },
        messageStyle: "message",
        id: null,
        showTable: true,
        CurrentProgramme: this.props.location.state.LearnershipID,
        CreateNewFlag: this.props.location.state.hideFields,
        Learner: this.props.location.state.LearnerID,
        model: null
    };


    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            this.setState({
                LearnershipSetupDetails: {
                    LearnershipProgrammeCode: values.LearnershipID,
                    LearnershipProgrammeDescription: values.LearnershipTitle
                },
                Learner: this.props.location.state.LearnerID
            });
            const newValues = { ...values };
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                this.setState({
                    model: newValues
                });
            }
        }
    }

    trancheSetupDetailsHandler = (ProjectID) => {
        if (!utils.isNull(ProjectID)) {
            utils.invokeUrl("api/sims/learnerlearnership/gettranchesetupdetails?id=" + ProjectID.Id, (response) => {
                let model = {};
                if (this.state.model !== null && typeof this.state.model !== "undefined") {
                    model = { ...this.state.model };
                }
                model.ProjectID = ProjectID;
                model.LeadProviderID = response.ProviderID;
                model.LearnershipID = response.InterventionID;
                model.CommencementDate = response.StartDate;
                model.CompletionDate = response.EndDate;
                model.SocioEconomicStatusID = response.SocioEconomicStatusID;

                this.setState({ model: model });
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    save() {
        let formValue = LearnershipRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerlearnership/LinkLearnerLearnership", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/LearnerLearnershipSearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New Learnership > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update Learnership > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "80vh" }}>
                                    {this.state.showTable === true && (
                                        <LearnerLearnershipForm ref={LearnershipRef}
                                            viewRef={this}
                                            id={this.state.CurrentProgramme}
                                            hideFields={this.state.CreateNewFlag}
                                            editPermission={this.props.location.state.permissions}
                                            viewOnly={this.props.location.state.viewOnly}
                                            lid={this.props.location.state.LearnerID}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            data={this.state.model}
                                            model={this.state.model}
                                            trancheSetupDetailsHandler={this.trancheSetupDetailsHandler}
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddLearnerLearnership);
