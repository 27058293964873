import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Employer.css";
import EmployerSearchForm from './EmployerSearchForm';
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';

const headCells = [
    { id: 'OrganisationLegalName', numeric: false, disablePadding: false, label: 'Organisation Legal Name', widthRatio: .5 },
    { id: 'OrganisationSDLNumber', numeric: false, disablePadding: false, label: 'Organisation SDL Number', widthRatio: .25 },
    { id: 'OrganisationTradeName', numeric: false, disablePadding: false, label: 'Organisation Trade Name', widthRatio: .25 }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', modes: 'default,lookup' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', modes: 'default' }
            ]
        }
    ]
};

const searchParameters = [{ Name: "OrganisationTradeName", Value: "" },
{ Name: "OrganisationSDLNumber", Value: "" },
{ Name: "OrganisationLegalName", Value: "" }];

class EmployerSearch extends Component {

    state = {
        model: { OrganisationTradeName: "", OrganisationSDLNumber: "", OrganisationLegalName: "" },
        keyUpSwitch: false
    };

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <CardLayout title='Employer'>
            
                    <EmployerSearchForm model={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}/>
               
            </CardLayout>

        );
    }
}

export default connect()(EmployerSearch);
