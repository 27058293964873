import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./ArtisansSetup.css";
import { OutComeForm } from './OutcomeForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";

const utils = new ViewUtils();
const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        }

    ]
};

const outcomeRef = React.createRef();
class Outcome extends Component {

    state = {
        OutcomeDetails: {
            ProviderUnitStandardID: "",
            ModeOfDelivery: "",
            ProgramOutcome: "",
            Specialisation: ""
        }
    };

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        }
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ OutcomeDetails: values });
        }
    }

    successCallback = () => {

        this.setState({ message: "Proivder unit standard updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        utils.saveForm(this, outcomeRef, `api/indicium/provider/saveProviderOutcomeAndMode`, null, null, "/provider");

    }

    render() {
        return (
            <ViewContainer topPadding="0" title={utils.getEntityViewTitle(this.props.location.state, "Provider unit standard update", null, null)}>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title="Provider"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true}
                                    config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <OutComeForm ref={outcomeRef} id={this.props.location.state.selection}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(Outcome);
