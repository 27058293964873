import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import ViewUtils from "../../ViewUtils";
import { ViewContainer } from "../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";
import DGVerificationSearchForm from "./DGVerificationSearchForm";



const utils = new ViewUtils();
const toolbarRef = React.createRef();
class DGVerificationSearch extends Component {

  constructor(props) {
    super(props);

    this.toolbarConfig = {
      items: [
        {
    
          type: "buttonGroup",
          buttons: [
            { id: "close",label: "Close", tooltip: "Close", icon: "CloseIcon"},
          ]
        },
          {
            type: "devider"
          },
        {
         type: "buttonGroup",
          buttons: [  
            { id: "view", label: "", tooltip: "View", icon: "ViewIcon", disabled: true },
    
          ],
        },
      ],
    };

    this.headCells = [
      {id: "FinancialYear",numeric: false,disablePadding: true,label: "Financial Year"}, 
      {id: "FundingWindow",numeric: false,disablePadding: true,label: "Funding Window"},
      {id: "SubmissionNumber",numeric: false,disablePadding: true,label: "Submission Number"},
      {id: "DGType", numeric: false, disablePadding: true, label: "DG Type"},
      {id: "DGIntervention",numeric: false,disablePadding: true,label: "DG Intervention"},
      {id: "DGApplicationStatus",numeric: false,disablePadding: true,label: "DG Application Status"},
    ];

    let model = {
      OrganisationID: this.props.location.state.organisationID,
      FinancialYearID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : "",
      FundingWindowID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FundingWindowID)) ? this.props.location.state.FundingWindowID : "",
    };

    this.searchParameters = [
      { Name: "OrganisationID", Value: this.props.location.state.organisationID },
      { Name: "FinancialYearID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : "" } ,
      { Name: "FundingWindowID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FundingWindowID)) ? this.props.location.state.FundingWindowID : "" } ,
    ];

    this.state = {
      model: model,
      keyUpSwitch: false,
      selectedDG: null,
      OrganisationID: this.props.location.state.organisationID,
      FinancialYearID : null,
      FundingWindowID : null,
      dependency: null
    };
  }
    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedDG: selection[0].Id });
        } else {
            this.setState({ selectedDG: null });
        }
    toolbarRef.current.setButtonDisabled("view", false);

  }
  
  handleFormValueChange = (values) => {
    let newValues = null;
    if (!utils.isNull(values)) {
        newValues = { ...values };
    }
    if (!utils.isNull(newValues)) {
        if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
            this.setState({
                model: newValues,
                clearGrid: true,
                selectedDG: null,
                Message: "",
                FinancialYearID: values.FinancialYearID.Id
            });
            if (!utils.isNull(newValues.FinancialYearID) && JSON.stringify(this.state.dependency) !== JSON.stringify(newValues.FinancialYearID)) {
                const financialYearID = {
                    Name: "FinancialYearID",
                    Value: newValues.FinancialYearID.Id
                }
                if (!utils.isNull(financialYearID.Value)) {
                    this.setState({ dependency: financialYearID })
                }
            };
        }
    }
}


   handleToolbarButtonClick = (buttonId) => {
    if ("view" === buttonId) {
      this.props.history.push({
         pathname: "/DGVerificationMain",
          state: {
            OrganisationID: this.state.OrganisationID,
            DGApplicationID: this.state.selectedDG,
            FinancialYearID : this.state.model.FinancialYearID,
            FundingWindowID : this.state.model.FundingWindowID
          },
       });
     }
     else if ("close" === buttonId) {
      this.props.history.push({
        pathname: '/DGOrganisation',
        state: { selection: this.props.location.state.organisationID }
    });
    }
   }

  render() {
    return (
      <ViewContainer topPadding="0">
        <p className="breadcrumbs">
                    {"DG Verification " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>

                <br />
        <SearchView
          headCells={this.headCells}
          dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/dgverification/getsearch"}
          entityViewPath='/DGVerificationMain'
          model={this.state.model}
          toolbarConfig={this.toolbarConfig}
          searchParameters={this.searchParameters}
          buttonClickHandler={this.handleToolbarButtonClick}
          mode={this.props.mode}
          multiSelect={this.props.multiSelect}
          onLookupValueChange={this.props.onLookupValueChange}
          descriptionProp="LegalName"
          selectionCallbackMode={this.props.selectionCallbackMode}
          history={this.props.history}
          keyUpSwitch={this.state.keyUpSwitch}
          setSelection={(selection) => { this.handleSelection(selection) }}
          toolbarRef={toolbarRef}
        >
          <DGVerificationSearchForm
            data={this.state.model}
            onKeyUp={(e) => { this.onkeyup(e) }}
            valueChangeHandler={this.handleFormValueChange}
            dependency={this.state.dependency}
            />
        </SearchView>
      </ViewContainer>
    );
  }
}

export default withRouter(connect()(DGVerificationSearch));
