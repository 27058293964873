import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./BursarySetup.css";
import SearchView from "../../widgets/SearchView";
import BursarySetupSearchForm from './BursarySetupSearchForm';
import ViewUtils from '../../ViewUtils';
import CardLayout from '../../widgets/Card';
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const headCells = [
    { id: 'BursaryCode', numeric: false, disablePadding: true, label: 'Bursary Code' },
    { id: 'BursaryTitle', numeric: false, disablePadding: true, label: 'Bursary Title' }

];

class BursarySetupSearch extends Component {

    constructor(props) {
        super(props)


        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "BursaryCode", Value: "" },
                { Name: "BursaryTitle", Value: "" }
            ]
            :
            [
                { Name: "BursaryCode", Value: "" },
                { Name: "BursaryTitle", Value: "" },
            ]

        let model = !utils.isNull(this.props.programmeId) ?
            {
                BursaryCode: "",
                BursaryTitle: "",
                id: this.props.programmeId
            }
            :
            {
                BursaryCode: "",
                BursaryTitle: ""
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        if (!utils.isNull(props.personId)) {
            model.PersonID = props.personId;
            this.searchParameters.push({ Name: "PersonID", Value: props.personId })
        }

        this.state = {
            model: model,
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: '', tooltip: 'Remove Bursary', icon: 'DeleteIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }
    }

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    clearSearch(e) {
        this.setState({
            model: {
                BursaryCode: "",
                BursaryTitle: "",
                id: this.props.programmeId
            }
        });
    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/BursarySetup/GetSearch"}
                    entityViewPath='/BursarySetup'
                    title='Bursary'
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="BursaryTitle" mode={this.props.mode}
                    keyUpSwitch={this.state.keyUpSwitch} history={this.props.history}
                    multiSelect={this.props.multiSelect} selectionCallbackMode={this.props.selectionCallbackMode}
                    deleteUrl={"api/sims/BursarySetup/RemoveBursary"} useDeletePost={true}
                    promptTitle={"Remove Bursary"}
                    promptContent={"Removing the bursary is a permanent action and the bursary will not be available going forward. Are you sure you want to remove this bursary?"}
                    editPermission={this.props.menuPermissionItem.Edit}
                    allowClear={this.props.allowClear}
                    clearPress={(e) => { this.clearSearch(e) }}
                >
                    <BursarySetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(BursarySetupSearch));
