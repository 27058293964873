import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import EditForm from './WSPandATREditForm';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import AlertItem from "../../widgets/AlertItem";
import ViewUtils from '../../ViewUtils';
import LetterReasonDialog from "./Controls/LetterReasonDialog.jsx";

const utils = new ViewUtils();

export default function WspAtrEditDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [Message, setMessage] = React.useState("");
    const [MessageStyle, setMessageStyle] = React.useState("");
    const formRef = React.createRef();
    const [showReasons, setShowReasons] = React.useState(false);
    const [reasonUrl, setReasonUrl] = React.useState("");
    const [FormData, setFormData] = React.useState("");
    const [selectedReasons, setSelectedReasons] = React.useState("");

    const handleClose = (cancel, save, response) => {
        setOpen(false);
        props.closeHandler(cancel, save, response);
    };

    const cancel = () => {
        handleClose(true, false);
    };

    const save = () => {
        //console.log(selectedReasons);
        console.clear();
        console.log(FormData);

        const url = `api/sims/wspatr/Updatewspatr`;
        let FinalData = "";

        let ReasonsList = "";
        if (selectedReasons !== null) {
            for (let i = 0; i < selectedReasons.length; i++) {
                if (i === 0) { ReasonsList += selectedReasons[i].Id; }
                else { ReasonsList += "," + selectedReasons[i].Id; }

            }
        }

        if (FormData.WSPStatusID.Id === "4" || FormData.WSPStatusID.Id === 4) {
            FinalData = { ...FormData, RejectionReasons: ReasonsList }
        }
        else if (FormData.WSPStatusID.Id === "5" || FormData.WSPStatusID.Id === 5) {
            FinalData = { ...FormData, QueryReasons: ReasonsList }
        }
        else {
            FinalData = FormData
        }
        

        utils.invokeUrl(url, (response) => {
            handleClose(false, true, response);
            setReasonUrl("");
            setShowReasons(false);
        }, () => { handleClose(false, false); },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(FinalData)
            }
        );
    }

    const update = () => {
        let formValue = formRef.current.getValue();
        //formValue.Id = props.Id;
        setFormData(formValue);
        setReasonUrl("");
        setShowReasons(false);

        if (formValue !== null && typeof formValue !== 'undefined') {
            console.clear();
            console.log(formValue.WSPStatusID.Id);
            if (formValue.WSPStatusID.Id === "4" || formValue.WSPStatusID.Id === 4) {
                setReasonUrl("api/sims/wspatr/GetWSPATRRejectionLetterReasons");
                setShowReasons(true);
            }
            else if (formValue.WSPStatusID.Id === "5" || formValue.WSPStatusID.Id === 5) {
                setReasonUrl("api/sims/wspatr/GetWSPATRQueryLetterReasons");
                setShowReasons(true);
            }
            else {
                save();
            }
        }
    }

    const SetReasonSelections = (selection) => {
        //console.log(selection);
        setSelectedReasons(selection);
    }

    const handleFormValueChange = (values) => {
        setFormData(values);
    }


    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}>
                <div style={{ padding: "32px" }}>
                    <p className="legend">
                        WSP/ATR Update
                    </p>

                    <div style={{ paddingLeft: "24px", paddingRight: "44px" }}>
                        <AlertItem message={Message} alertStyle={MessageStyle} />
                    </div>

                    
                    {showReasons ?
                        <div style={{ height: "80%" }}>
                            <LetterReasonDialog
                                dataUrl={reasonUrl}
                                multiSelect={true} showTitle={false}
                                WspAtrId={props.id}
                                mode="lookup"
                                LetterType="WSP"
                                onLookupValueChange={SetReasonSelections}
                            />
                        </div>
                        : 
                        <div style={{ height: "80%" }}>
                            <EditForm ref={formRef} id={props.id} className="h-100" valueChangeHandler={(values) => handleFormValueChange(values)} />
                        </div>
                    }
                    <DialogActions>
                        {showReasons ?
                            <Button onClick={save} color="primary">
                                Save
                        </Button>
                            :
                            <Button onClick={update} color="primary">
                                Update
                        </Button>
                        }
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}