import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import PivotalProgrammeSearchForm from './PivotalProgrammeSearchForm';
import { ViewContainer } from "./../../View.jsx";
import CardLayout from '../../widgets/Card';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "PivotalProgrammeName", numeric: false, disablePadding: true, label: "Pivotal Programme Name" },
    { id: "SAQACode", numeric: false, disablePadding: true, label: "SAQA Code" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select PivotalProgramme from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

const searchParameters = [
    { Name: "PivotalProgrammeName", Value: "" },
    { Name: "SAQACode", Value: "" }
]

class PivotalProgrammeSearch extends Component {
    state = {
        model: {
            PivotalProgrammeName: "",
            SAQACode: ""
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Pivotal Programme' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl="api/indicium/PivotalProgramme/search"
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="PivotalProgrammeName"
                    model={this.state.model}
                    searchParameters={searchParameters}
                >
                    <PivotalProgrammeSearchForm model={this.state.model} />
                </SearchView>
            </ViewContainer>
            )
    }
}

export default withRouter(
    connect()(PivotalProgrammeSearch)
);