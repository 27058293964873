import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import InstitutionSearchForm from './InstitutionSearchForm';
import { ViewContainer } from "./../../View.jsx";
import CardLayout from '../../widgets/Card';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "InstitutionName", numeric: false, disablePadding: true, label: "Institution Name" },
    { id: "InstitutionType", numeric: false, disablePadding: true, label: "Institution Type" }
]

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'info', label: '', tooltip: 'Select Institution from the table and click save to add', icon: 'InfoIcon' }
            ]
        }
    ]
}

const searchParameters = [
    { Name: "InstitutionName", Value: "" },
    { Name: "InstitutionType", Value: "" }
]

class InstitutionSearch extends Component {
    state = {
        model: {
            InstitutionName: "",
            InstitutionType: ""
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Institution' : null}>
                <SearchView
                    config={toolbarConfig}
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/indicium/Institution/search"}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="InstitutionName"
                    model={this.state.model}
                    searchParameters={searchParameters}
                >
                    <InstitutionSearchForm model={this.state.model} />
                </SearchView>
            </ViewContainer>
            )
    }
}

export default withRouter(
    connect()(InstitutionSearch)
);