import React from 'react';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "../Helpdesk.css";
import EnhancedTable from '../../widgets/ResponsiveTable';

export default function HelpdeskComments(props, ref) {

    const headCells = 
        [
            { id: 'Comment', numeric: false, disablePadding: true, label: 'Comment' },
            { id: 'CreatedBy', numeric: false, disablePadding: true, label: 'Created By' },
            { id: 'DateCreated', numeric: false, disablePadding: true, label: 'Date Created' },
            { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' }
        ]
    return (
        <EnhancedTable headCells={headCells} onCellSelectionChange={(rowid, columnid) => { props.handleCell(rowid, columnid) }} searchParameters={[{ Name: "ID", Value: props.selection }]} paged={true}
            dataUrl={props.DataUrl} 
        />
    );
}
