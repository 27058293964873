import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { UnionForm } from './UnionForm';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';


const utils = new ViewUtils();
export default function UnionCreateDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [message, setMessage] = React.useState()
    const [messageStyle, setMessageStyle] = React.useState()
    const formRef = React.createRef();

    const handleClose = () => {
        setOpen(false);
    };

    const cancel = () => {
        handleClose(true);
    };

    const add = () => {

        let formValue = formRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            if (!utils.isNull(props) && !utils.isNull(props.id)) {
                formValue.id = props.id;
                isAdd = false;
            }
            let data = JSON.stringify(formValue);
            if (isAdd) {
                console.log(data);
                const url = `api/sims/TrainingCommittee/SaveTrainingCommittee`;
                utils.saveForm(data, url, (response)=>{
                    setMessageStyle("SUCCESS" );
                    setMessage("Added contact successfully!" );
                    alert("SUCCESS", response);

                }, ()=>{setMessage("Added contact failed!" );});
            }
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
      
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}>
                <div style={{ padding: "32px" }}>
                    <p className="legend">
                        Create
                    </p>
                    <div style={{ height: "80%" }}>
                        <UnionForm ref={formRef} className="h-100" addTrainingCommittee={props.addTrainingCommittee} />
                    </div>
                    <DialogActions>
                        <Button onClick={add} color="primary">
                            Create
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
