import React from 'react';
import ViewUtils from '../ViewUtils';
import EnhancedTable from './ResponsiveTable';
import { BaseField } from "./BaseField";

const GridItemComponent = React.forwardRef((props, ref) => {

    const utils = new ViewUtils();

    const handleSelectionChange = (selection) => {
    }

    const handleDataArrived = (data) => {
    }

    const handleCellValueChange = (row, changedColumn) => {
        let currentValue = props.base.valueObject[props.base.valueProperty];
        if (utils.isNull(currentValue)) {
            currentValue = [];
        }

        let valueRow = null;
        for (let i = 0; i < currentValue.length; i++) {
            if (row.Id === currentValue[i].Id) {
                valueRow = currentValue[i];
                valueRow[changedColumn] = row[changedColumn];
            } 
        }

        if (valueRow === null) {
            currentValue.push(row);
        }

        props.base.valueObject[props.base.valueProperty] = currentValue;
    }

    return (
        <div style={{ height: "60vh"}}>
            <EnhancedTable
                headCells={props.headCells}
                paged={false}
                dataUrl={props.dataUrl}
                onDataArrive={(data) => handleDataArrived(data)}
                mode={props.mode}
                onSelectionChange={selection => props.handleSelectionChange(selection)}
                autoFetchData={props.autoFetchData}
                cellValueChangeHandler={handleCellValueChange}
                searchParameters={props.searchParameters}
                multiSelect={props.multiSelect}
            />
        </div>
    );
})

export function GridItem(props) {
    return (
        <BaseField ref={React.createRef()} form={props.form} required={props.required} id={props.id} className={props.className}
            validator={props.validator} validationRegex={props.validationRegex} validationGroup={props.validationGroup}
            validationMessage={props.validationMessage} parentId={props.parentId}>
            {base =>
                <GridItemComponent
                    id={props.id}
                    base={base}
                    dataUrl={props.dataUrl}
                    headCells={props.headCells}
                    mode={props.mode}
                    autoFetchData={props.autoFetchData}
                    searchParameters={props.searchParameters}
                    InputProps={props.InputProps}
                    required={props.required}
                    label={props.label}
                    className={props.className}
                    validationMessage={props.validationMessage}
                    parentId={props.parentId}
                    handleSelectionChange={selection => props.handleSelectionChange(selection)}
                    multiSelect={props.multiSelect}
                    form={props.form}>
                </GridItemComponent>
            }
        </BaseField>
    );
}