import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import { ViewContainer } from "../../View";
import ViewUtils from "./../../ViewUtils";


const utils = new ViewUtils();

const headCells = [
    { id: 'Id', numeric: false, disablePadding: true, label: 'Nr' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'DateUpdated', numeric: false, disablePadding: true, label: 'Date Changed' },
    { id: 'UpdatedBy', numeric: false, disablePadding: true, label: 'User Updated' }
];

const toolbarConfig = {
    items: [
        {

        }


    ]
};

class FacilitatorInterventionAudit extends Component {

    state = {
        auditDialogOpen: false,
        selectedInterventionRecord: null
    }


    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/Facilitator',
                state: { selection: this.props.location.state.FacilitatorID }
            });
        }
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true });
    }

    postLinkHandler(linked) {
        this.setState({ linkDialogOpen: false, selectedInterventionRecord: null });
    }

    render() {
        return (
            <ViewContainer title="">
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={toolbarConfig} />
                    <EnhancedTable
                        headCells={headCells}
                        height="50vh"
                        dataUrl={utils.isNull(this.props.dataUrl) ? "" : this.props.dataUrl}
                        searchParameters={[{ Name: "ID", Value: this.props.Id }]} paged={true} />

                </div>

            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(FacilitatorInterventionAudit)
);
