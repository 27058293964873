import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import "./../Trade/TradeSetup.css";
import SearchView from "../../../widgets/SearchView";
import TradeSetupSearchForm from './../Trade/TradeSetupSearchForm';
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "./../../../ViewUtils";
import withMenuPermission from "../../../widgets/withMenuPermission";


const headCells = [
    { id: 'TradeID', numeric: false, disablePadding: true, label: 'Trade ID' },
    { id: 'TradeTitle', numeric: false, disablePadding: true, label: 'Trade Title' },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' }
];

const searchParameters = [
    { Name: "TradeID", Value: "" },
    { Name: "TradeTitle", Value: "" }
];

const utils = new ViewUtils();

class TradeSetupSearch extends Component {

    constructor(props) {
        super(props)

        this.state = {
            model: {
                TradeID: "",
                TradeTitle: ""
            },           
            selectedTrade: null,
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add},
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit},
                        { id: "delete", label: "", tooltip: "Remove Trade", icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete}
                    ]
                }
            ]
        }

        //TODO: Add actual url for trade removal
        this.deleteUrl = ""
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedTrade: selection })
        } else {
            this.setState({ selectedTrade: null })
        }
    }

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView
                    headCells={headCells} dataUrl="api/sims/TradeSetup/GetSearch"
                    entityViewPath='/Tradesetup' model={this.state.model}
                    title='Trades' 
                    toolbarConfig={this.toolbarConfig} searchParameters={searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    keyUpSwitch={this.state.keyUpSwitch}
                    history={this.props.history}
                    multiSelect={this.props.multiSelect}
                    deleteUrl={"api/sims/TradeSetup/RemoveTrade"} useDeletePost={true}
                    promptTitle={"Remove Trade"}
                    promptContent={"Removing the trade is a permanent action and the trade will not be available going forward. Are you sure you want to remove this trade?"}
                >
                    <TradeSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>

        );
    }
}


export default withRouter(withMenuPermission(TradeSetupSearch));

