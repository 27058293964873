import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./SETMISFile.css";
import { SETMISFileForm } from './SETMISFileForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
            ]
        },
        {
            type: "devider"
        },
        {
            type: "buttonGroup",
            buttons: [
                { id: 'organisationDetails', label: 'Organisation Details', tooltip: 'Organisation Details' }
            ]
        }
    ]
};

const setmisfileRef = React.createRef();
const utils = new ViewUtils();

class SETMISFile extends Component {

    state = {
        sdfDetails: {
            FirstName: "",
            Surname: "",
        }
    };

    handleFormValueChange(values) {
        this.setState({ sdfDetails: values });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("organisationDetails" === buttonId) {
            this.props.history.push({
                pathname: '/sdfOrgDetails'
            });
        }
    }

    handleToolbarButtonMenuClick(menu) {
        if ("organisationDetails" === menu.id) {
            this.props.history.push({
                pathname: '/providerAccreditationDetails',
                state: {
                    sdfID: this.props.location.state.selection,
                    sdfName: this.state.sdfDetails.FirstName
                }
            })
        }
    }
        save() {
            let formValue = setmisfileRef.current.getValue();
            if (formValue !== null && typeof formValue !== 'undefined') {
                let data = JSON.stringify(formValue);
                const url = `api/indicium/provider/updateProviderDetails?data=${data}`;
                // TODO : Invoke spinner mask
                fetch(url)
                    .then(res => res.json())
                    .then((responseJson) => {
                        let response = JSON.parse(responseJson);
                        if (response.MessageType !== "ERROR") {
                            this.props.history.push({
                                pathname: '/provider',
                                state: {
                                    sdfID: this.props.location.state.selection,
                                    sdfName: this.state.sdfDetails.FirstName
                                }
                            });
                        } else {
                            this.setState({ saveMessage: response.Message, hasSaveError: true });
                        }
                    }).catch(() => {
                        this.setState({ saveMessage: "A system error has accured. Please contact your system administrator", hasSaveError: true });
                    });
            }
        
        }

        closeView() {
            this.props.history.push({
                pathname: '/sdfSearch',
                state: { selection: this.props.location.state.sdfID }
            });
        }

        render() {
            return (
                <ViewContainer topPadding="0" title={utils.getEntityViewTitle(this.props.location.state, "SETMIS Files", this.state.sdfDetails.FirstName, this.state.sdfDetails.Surname)}>
                    <p className="message">
                        {this.state.message}
                    </p>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <EnhancedToolbar highlight={false} title="sdf" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={toolbarConfig} />
                                </td>
                            </tr>
                            <tr>
                                <td className="formContainer">
                                    <SETMISFileForm ref={setmisfileRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null} valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ViewContainer>
            );
        }
    }


export default withRouter(SETMISFile);
