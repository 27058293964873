import * as actionTypes from '../actions/actionTypes';

const initialState = {
    userDetails: null,
    isLoading: false,
    errorMessage: null,
    redirectToLogin: false,
    userLoginTimer: null,
    userLogoutTimer: null,
    autoLogout: false,
    menuPermissionsDetails: null,
    interfaceVersionShouldBeChecked: true,
    interfaceVersionUpdateConfirm: false
};

const authUserStart = (state, action) => {
    return {
        ...state,
        userDetails: null,
        isLoading: true,
        errorMessage: null,
        redirectToLogin: false,
        userLoginTimer: null
    };
};

const authUserSuccess = (state, action) => {
    return {
        ...state,
        userDetails: action.authUserData,
        isLoading: false,
        userLoginTimer: action.authUserLoginTimer,
        autoLogout: false,
        loginRedirectToLoginPath: action.loginRedirectToLoginPath
    };
};

const authUserFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError,
        autoLogout: false
    };
};

const authUserRedirectToLogin = (state, action) => {
    return {
        ...state,
        userDetails: null,
        redirectToLogin: true,
        userLoginTimer: null
    };
}

const authOTPStart = (state, action) => {
    let userDetails = { ...state.userDetails };
    userDetails.Message = null;
    return {
        ...state,
        userDetails: userDetails,
        isLoading: true,
        errorMessage: null
    };
};

const authOTPSuccess = (state, action) => {
    return {
        ...state,
        userDetails: action.authUserData,
        isLoading: false
    };
};

const authOTPFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError
    };
};

const authRoleGetStart = (state, action) => {
    let userDetails = { ...state.userDetails };
    userDetails.Message = null;
    return {
        ...state,
        userDetails: userDetails,
        isLoading: true,
        errorMessage: null
    };
};

const authRoleGetSuccess = (state, action) => {
    return {
        ...state,
        userDetails: action.authUserData,
        isLoading: false
    };
};

const authRoleGetFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError
    };
};

const authRoleSelectStart = (state, action) => {
    let userDetails = { ...state.userDetails };
    userDetails.Message = null;
    return {
        ...state,
        userDetails: userDetails,
        isLoading: true,
        errorMessage: null
    };
};

const authRoleSelectSuccess = (state, action) => {
    let currentUserDetails = { ...state.userDetails };
    let newUserDetails = { ...action.authUserData };
    newUserDetails.Roles = currentUserDetails.Roles;
    return {
        ...state,
        userDetails: newUserDetails,
        isLoading: false,
        Roles: newUserDetails.Roles,
        RoleKey: newUserDetails.RoleKey,
        userLogoutTimer: action.userLogoutTimer
    };
};

const authRoleSelectFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError
    };
};

const authLogout = (state, action) => {
    return {
        userDetails: null,
        isLoading: false,
        errorMessage: null,
        redirectToLogin: false,
        userLoginTimer: null,
        userLogoutTimer: null,
        autoLogout: true,
        loginRedirectToLoginPath: state.loginRedirectToLoginPath,
        menuPermissionsDetails: null
    }
};

const authProviderSelectStart = (state, action) => {
    let userDetails = { ...state.userDetails };
    userDetails.Message = null;
    return {
        ...state,
        userDetails: userDetails,
        isLoading: true,
        errorMessage: null
    };
};

const authProviderSelectSuccess = (state, action) => {
    return {
        ...state,
        userDetails: action.authUserData,
        isLoading: false,
        userLogoutTimer: action.userLogoutTimer
    };
};

const authProviderSelectFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError
    };
};

const authEmployerSelectStart = (state, action) => {
    let userDetails = { ...state.userDetails };
    userDetails.Message = null;
    return {
        ...state,
        userDetails: userDetails,
        isLoading: true,
        errorMessage: null
    };
};

const authEmployerSelectSuccess = (state, action) => {
    return {
        ...state,
        userDetails: action.authUserData,
        isLoading: false,
        userLogoutTimer: action.userLogoutTimer
    };
};

const authEmployerSelectFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError
    };
};

const authSwitchProfileStart = (state, action) => {
    return {
        ...state,
        userDetails: null,
        isLoading: true,
        errorMessage: null,
        redirectToLogin: false,
        userLoginTimer: null,
        menuPermissionsDetails: null
    };
};

const authSwitchProfileSuccess = (state, action) => {
    return {
        ...state,
        userDetails: action.authUserData,
        isLoading: false,
        userLoginTimer: action.authUserLoginTimer,
        autoLogout: false,
        loginRedirectToLoginPath: action.loginRedirectToLoginPath
    };
};

const authSwitchProfileFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError,
        autoLogout: false
    };
};


const authForgotPasswordStart = (state, action) => {
    return {
        ...state,
        userDetails: null,
        isLoading: true,
        errorMessage: null
    };
};

const authForgotPasswordSuccess = (state, action) => {
    return {
        ...state,
        userDetails: action.authUserData,
        isLoading: false
    };
};

const authForgotPasswordFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError
    };
};

const authResetPasswordStart = (state, action) => {
    let newUserDetails = { Username: state.userDetails.Username }
    return {
        ...state,
        userDetails: newUserDetails,
        isLoading: true,
        errorMessage: null
    };
};

const authResetPasswordSuccess = (state, action) => {
    return {
        ...state,
        userDetails: action.authUserData,
        isLoading: false
    };
};

const authResetPasswordFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authUserError
    };
};

const authMenuPermissionStart = (state, action) => {
    return {
        ...state,
        isLoading: true,
        errorMessage: null
    };
};

const authMenuPermissionSuccess = (state, action) => {
    return {
        ...state,
        menuPermissionsDetails: action.authMenuPermissionData,
        isLoading: false
    };
};

const authMenuPermissionFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        errorMessage: action.authMenuPermissionError
    };
};

const interfaceVersionUpdateConfirm = (state, action) => {
    return {
        ...state,
        interfaceVersionUpdateConfirm: action.interfaceVersionUpdateConfirm
    };
};

const interfaceVersionShouldBeChecked = (state, action) => {
    return {
        ...state,
        interfaceVersionShouldBeChecked: action.interfaceVersionShouldBeChecked
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_USER_START: return authUserStart(state, action);
        case actionTypes.AUTH_USER_SUCCESS: return authUserSuccess(state, action);
        case actionTypes.AUTH_USER_FAIL: return authUserFail(state, action);
        case actionTypes.AUTH_USER_REDIRECTTOLOGIN: return authUserRedirectToLogin(state, action);
        case actionTypes.AUTH_OTP_START: return authOTPStart(state, action);
        case actionTypes.AUTH_OTP_SUCCESS: return authOTPSuccess(state, action);
        case actionTypes.AUTH_OTP_FAIL: return authOTPFail(state, action);
        case actionTypes.AUTH_ROLEGET_START: return authRoleGetStart(state, action);
        case actionTypes.AUTH_ROLEGET_SUCCESS: return authRoleGetSuccess(state, action);
        case actionTypes.AUTH_ROLEGET_FAIL: return authRoleGetFail(state, action);
        case actionTypes.AUTH_ROLESELECT_START: return authRoleSelectStart(state, action);
        case actionTypes.AUTH_ROLESELECT_SUCCESS: return authRoleSelectSuccess(state, action);
        case actionTypes.AUTH_ROLESELECT_FAIL: return authRoleSelectFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.AUTH_PROVIDERSELECT_START: return authProviderSelectStart(state, action);
        case actionTypes.AUTH_PROVIDERSELECT_SUCCESS: return authProviderSelectSuccess(state, action);
        case actionTypes.AUTH_PROVIDERSELECT_FAIL: return authProviderSelectFail(state, action);
        case actionTypes.AUTH_EMPLOYERSELECT_START: return authEmployerSelectStart(state, action);
        case actionTypes.AUTH_EMPLOYERSELECT_SUCCESS: return authEmployerSelectSuccess(state, action);
        case actionTypes.AUTH_EMPLOYERSELECT_FAIL: return authEmployerSelectFail(state, action);
        case actionTypes.AUTH_SWITCHPROFILE_START: return authSwitchProfileStart(state, action);
        case actionTypes.AUTH_SWITCHPROFILE_SUCCESS: return authSwitchProfileSuccess(state, action);
        case actionTypes.AUTH_SWITCHPROFILE_FAIL: return authSwitchProfileFail(state, action);
        case actionTypes.AUTH_FORGOTPASSWORD_START: return authForgotPasswordStart(state, action);
        case actionTypes.AUTH_FORGOTPASSWORD_SUCCESS: return authForgotPasswordSuccess(state, action);
        case actionTypes.AUTH_FORGOTPASSWORD_FAIL: return authForgotPasswordFail(state, action);
        case actionTypes.AUTH_RESETPASSWORD_START: return authResetPasswordStart(state, action);
        case actionTypes.AUTH_RESETPASSWORD_SUCCESS: return authResetPasswordSuccess(state, action);
        case actionTypes.AUTH_RESETPASSWORD_FAIL: return authResetPasswordFail(state, action);
        case actionTypes.AUTH_MENUPERMISSION_START: return authMenuPermissionStart(state, action);
        case actionTypes.AUTH_MENUPERMISSION_SUCCESS: return authMenuPermissionSuccess(state, action);
        case actionTypes.AUTH_MENUPERMISSION_FAIL: return authMenuPermissionFail(state, action);
        case actionTypes.INTERFACE_VERSION_CHECK: return interfaceVersionUpdateConfirm(state, action);
        case actionTypes.INTERFACE_VERSION_CHECK_UPDATE: return interfaceVersionShouldBeChecked(state, action);
        default:
            return state;
    }
};

export default reducer;