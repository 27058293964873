import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./ShortCourseSetup.css";
import SearchView from "../../widgets/SearchView";
import ShortCourseSetupSearchForm from './ShortCourseSetupSearchForm';
import CardLayout from '../../widgets/Card';


const headCells = [
    { id: 'ShortCourseCode', numeric: false, disablePadding: true, label: 'Course Code' },
    { id: 'ShortCourseTitle', numeric: false, disablePadding: true, label: 'Course Title' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
            ]
        }
    ]
};

const searchParameters = [
    { Name: "ShortCourseCode", Value: "" },
    { Name: "ShortCourseTitle", Value: "" }
];

class  ShortCourseSetupSearch extends Component {

    state = {
        model: {
            ShortCourseCode: "",
            ShortCourseTitle: ""
        },
    };


    render() {
        return (
            <CardLayout title='ShortCourse'>
            <SearchView headCells={headCells} dataUrl="api/indicium/ShortCourseSetup/search" entityViewPath='/ShortCourseSetup'
                 model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                onLookupValueChange={this.props.onLookupValueChange}
                descriptionProp="ShortCourseTitle" mode={this.props.mode}
                history={this.props.history}
            >
                <ShortCourseSetupSearchForm model={this.state.model} />
                </SearchView>
            </CardLayout>
        ); 
    }
}

export default withRouter(
    connect()(ShortCourseSetupSearch)
);
