import React from 'react';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';

const headCells = [
    { id: 'Date', numeric: false, disablePadding: true, label: 'Date' },
    { id: 'Comment', numeric: false, disablePadding: true, label: 'Comment' },
    { id: 'CommentBy', numeric: false, disablePadding: true, label: 'Comment By' }
]

export default function Comments(props, ref) {

    return (
        <EnhancedTable headCells={headCells} searchParameters={[{ Name: `${props.moduleName}ID`, Value: props.selection }]} paged={true}
            dataUrl={props.mneDataUrl} height="50vh"
        />
    );
}
