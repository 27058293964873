import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { EntityForm } from '../../widgets/Forms';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SelectItem } from '../../widgets/SelectItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

export default function BenefitsTargetsForm(props) {
    const [labelWidth, setLabelWidth, tabValue] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (labelRef.current !== null) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, []);

    return (
        <EntityForm viewId="Benefits" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={classes.container} >
                    <table cellPadding="2">
                        <tbody>
                            <tr>
                                <td>
                                    <FormControl className={classes.formControl} variant="outlined">
                                        <InputLabel ref={labelRef} htmlFor="Number">
                                            Number
								            </InputLabel>
                                        <OutlinedInput
                                            id="Number"
                                            onChange={(ev) => props.model.Create = ev.target.value}
                                            labelWidth={labelWidth}
                                        />
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl className={classes.formControl} variant="outlined">

                                        <InputLabel ref={labelRef} htmlFor="Description">
                                            Description
								            </InputLabel>
                                        <OutlinedInput
                                            id="Description"
                                            onChange={(ev) => props.model.Edit = ev.target.value}
                                            labelWidth={labelWidth}
                                        />
                                    </FormControl>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form >
            }
        </EntityForm>
    );
}
