import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { GPSCoordinatesAPI } from '../../widgets/GPSCoordinatesAPI';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const DGOrganisationDetailsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [selectedValue, showField] = React.useState(null);
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleUsePhysicalAddressForPostal = form => event => {


        if (event.target.checked === true) {
            form.values['PostalAddressLine1'] = form.values["PhysicalAddress1"];
            form.values['PostalAddressLine2'] = form.values["PhysicalAddress2"];
            form.values['PostalAddressLine3'] = form.values["PhysicalAddress3"];
            form.values['PostalCode'] = form.values["PhysicalCode"];
            setPostalCodeValue(form.values["PhysicalCode"]);
        }
        form.updateValue('UsePhysicalAsPostal', event.target.checked);


    }
    const updateGPSAddress = (address, form) => {
        console.log(address);
        form.values["GPSCoordinates"] = address.gpscoordinates.coordinate;
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }
    function checkValue(value) {

        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return false
            }
            else {
                return true
            }
        } else {
            return true
        }
    }

    function ConfirmDetailsDisableCheck(value) {

        if (value !== undefined && value !== null) {
            if (value === true) {
                return true
            }
            else if (value !== true) {
                return false
            }
        }
    }

    return (
        <EntityForm ref={ref} 
            viewId="dgorganisation"
            dataURL='api/sims/dgorganisationdetails/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} 
            org="saseta"
            useCustomSettings={false}
            valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="dgorganisationDetailsForm">
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TradeName"
                                                key={tabValue}
                                                label="Trade Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="LegalName"
                                                key={tabValue}
                                                label="Legal Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="SDLNumber"
                                                key={tabValue}
                                                label="SDL Number"
                                                InputProps={{
                                                    readOnly: false,
                                                    disabled: !utils.isNull(props.id)
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="General Details" {...a11yProps(0)} />
                                <Tab label="Contact Details" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                                <Tab label="Address Details" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="OrganisationRegistrationNumber"
                                                required
                                                key={tabValue}
                                                label="Registration Number"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dgorganisationdetails/GetLookupList?listId=LevyNumberType"
                                                id="LevyNumberTypeID"
                                                required
                                                key={tabValue}
                                                label="SDL Number Type"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dgorganisationdetails/GetLookupList?listId=OrganisationRegistrationNumberType"
                                                id="OrganisationRegistrationNumberTypeID"
                                                required
                                                key={tabValue}
                                                label="Organisation Registration Number Type"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="SARSNumber"
                                                required
                                                key={tabValue}
                                                label="SARS Number"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex={/^[0-9]+$/g}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dgorganisationdetails/GetLookupList?listId=Partnership"
                                                id="PartnershipID"
                                                required
                                                key={tabValue}
                                                label="Partnership"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dgorganisationdetails/GetLookupList?listId=SICCode"
                                                id="SICCodeID"
                                                required
                                                key={tabValue}
                                                label="SIC Code"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>


                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="NumberOfEmployees"
                                                required
                                                key={tabValue}
                                                label="Number of Employees"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}

                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="NumberOfEmployeesProfile"
                                                key={tabValue}
                                                label="Number of Employees as Per the Employment Profile"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dgorganisationdetails/GetLookupList?listId=OrganisationSize"
                                                id="OrganisationSizeID"
                                                required
                                                key={tabValue}
                                                label="Organisation Size"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dgorganisationdetails/GetLookupList?listId=LegalStatus"
                                                id="LegalStatusID"
                                                required
                                                key={tabValue}
                                                label="Legal Status"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}


                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                id="ConfirmDetails"
                                                key={tabValue}
                                                form={form}
                                                disabled={ConfirmDetailsDisableCheck(form.values["ConfirmDetails"])}
                                                InputProps={{
                                                    'aria-label': 'primary checkbox'

                                                }}
                                                label="Confirm Details"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>

                        <TabPanel className="w-100" value={tabValue} index={1}>

                            <table cellPadding="2" width="80%" index={0}>

                                <tbody>
                                    <tr>
                                        {/* <td>
                                    
                                        <CustomTextField
                                            id="OrganisationContactPerson"
                                            required
                                            key={tabValue}
                                            label="Organisation Contact Person"
                                            className="w-100"
                                            InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                            disabled={!props.editPermission}
                                                />
                                        
                                    </td> */}
                                        <td>
                                            <CustomTextField
                                                id="Email"
                                                key={tabValue}
                                                required
                                                label="Organisation Email Address"
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            // disabled={!props.editPermission}
                                            />

                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FaxNumber"
                                                key={tabValue}
                                                label="Organisation Fax Number"
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            // disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>

                                            <CustomTextField
                                                id="PhoneNumber"
                                                required
                                                key={tabValue}
                                                label="Organisation Phone Number"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            // disabled={!props.editPermission}
                                            />

                                        </td>

                                    </tr>


                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel className="w-100" value={tabValue} index={2}>
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomCheckbox
                                            id="UsePhysicalAsPostal"
                                            key={tabValue}
                                            form={form}
                                            onChange={handleUsePhysicalAddressForPostal}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                            label="Use Physical Address for Postal Address?"
                                        />
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <GPSCoordinatesAPI
                                            id="Address"
                                            key={tabValue}
                                            className="w-100"
                                            label="Address"
                                            form={form}
                                            updateGPSAddress={updateGPSAddress}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="GPSCoordinates"
                                            key={tabValue}
                                            label="GPS Coordinates"
                                            className="w-100"
                                            form={form}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <CustomTextField
                                            parentId={1}
                                            id="PhysicalAddress1"
                                            required
                                            key={tabValue}
                                            label="Physical Address 1"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="PostalAddressLine1"
                                            key={tabValue}
                                            required
                                            label="Postal Address Line 1"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            parentId={1}
                                            id="PhysicalAddress2"
                                            key={tabValue}
                                            required
                                            label="Physical Address 2"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="PostalAddressLine2"
                                            required
                                            key={tabValue}
                                            label="Postal Address Line 2"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <CustomTextField
                                            parentId={1}
                                            id="PhysicalAddress3"
                                            key={tabValue}
                                            label="Physical Address 3"
                                            className="w-100"
                                            required
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="PostalAddressLine3"
                                            key={tabValue}
                                            label="Postal Address Line 3"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <AddressCodeItem
                                            id="PhysicalCode"
                                            required={true}
                                            addressId="Physical"
                                            value={physicalCodeValue}
                                            key={tabValue}
                                            validationRegex={/^[0-9]{4,5}$/g}
                                            validationMessage="The code must be 4 or 5 digits long"
                                            provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                            municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                            districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                            urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                            suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                            cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                            className="w-100"
                                            label="Physical Code"
                                            hasMunicipalityID={true}
                                            hasProvinceID={true}
                                            hasCityID={true}
                                            hasDistrictID={false}
                                            hasUrbanRuralID={true}
                                            hasSuburbID={true}
                                            form={form}
                                        />
                                    </td>

                                    <td>
                                        <AddressCodeItem
                                            id="PostalCode"
                                            required={true}
                                            addressId="Postal"
                                            value={postalCodeValue}
                                            key={tabValue}
                                            validationRegex={/^[0-9]{4,5}$/g}
                                            validationMessage="The code must be 4 or 5 digits long"
                                            provinceId={!utils.isNull(form.values) ? form.values.PostalProvinceID : null}
                                            municipalityId={!utils.isNull(form.values) ? form.values.PostalMunicipalityID : null}
                                            districtId={!utils.isNull(form.values) ? form.values.PostalDistrictID : null}
                                            urbanRuralId={!utils.isNull(form.values) ? form.values.PostalUrbanRuralID : null}
                                            suburbId={!utils.isNull(form.values) ? form.values.PostalSuburbID : null}
                                            cityId={!utils.isNull(form.values) ? form.values.PostalCityID : null}
                                            className="w-100"
                                            label="Postal Code"
                                            hasMunicipalityID={true}
                                            hasProvinceID={true}
                                            hasCityID={true}
                                            hasDistrictID={false}
                                            hasUrbanRuralID={true}
                                            hasSuburbID={true}
                                            form={form}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            </TabPanel>
                    </FormTabContainer>
                </form >
            }
        </EntityForm >
    );
})