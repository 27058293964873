import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { Row, Col } from 'react-bootstrap';



export default function SkillsProgramSetupSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="skillsprogrammesetup" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form => <form className={form.classes.container} id="skillsprogramme">
                <div className="row search-form-container">
                    <div className="col form-col">
                        <CustomTextField
                            id="SkillsProgrammeCode"
                            label="Skills Programme Code"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                    <div className="col form-col">
                        <CustomTextField
                            id="SkillsProgrammeTitle"
                            label="Skills Programme Title"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                </div>
            </form>
            }
        </EntityForm>
    );
}
