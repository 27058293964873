import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import ViewUtils from '../../ViewUtils';
import { EntityForm } from '../../widgets/Forms';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';

const utils = new ViewUtils();
export const AddLearnerIDUploadForm = React.forwardRef((props, ref) => {

    function HasSAIDcheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "No") {
                return true
            }
            else if (value.Description !== "Yes") {
                return false
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="addLearnerIDUpload"
            values={props.values} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                < form className={form.classes.container} id="addLearnerIDUploadForm" >
                    <div className="row" style={{ width: "100%" }}>
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learner/GetLookupList?listId=HasSouthAfricanID"
                                                id="HasSouthAfricanID"
                                                required
                                                label="Does the Person have a South African ID Number?"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learner/GetLookupList?listId=AlternateIDType"
                                                id="AlternateIDTypeID"
                                                required={HasSAIDcheck(form.values["HasSouthAfricanID"])}
                                                label="Alternate ID Type"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (props.isSouthAfricanFlag) ?
                                            <tr>
                                                <td colSpan="2">
                                                    <CustomTextField
                                                        id="IDNo"
                                                        label="ID Number"
                                                        className="w-100"
                                                        required
                                                        validationRegex={/^[0-9]{13}$/g}
                                                        validationMessage="The ID Number must be 13 digits long"
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan="2">
                                                    <CustomTextField
                                                        id="IDNo"
                                                        label="Non South African ID Number"
                                                        className="w-100"
                                                        required
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                    }
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="DateofBirth"
                                                label="Date of Birth"
                                                className='w-100'
                                                required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learner/GetLookupList?listId=CitizenResidentialStatus"
                                                id="CitizenResidentialStatusID"
                                                label="Residential Status"
                                                className="w-100"
                                                allowEmptyValue={true}
                                                required
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learner/GetLookupList?listId=Gender"
                                                id="GenderID"
                                                label="Gender"
                                                className="w-100"
                                                allowEmptyValue={true}
                                                required
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Surname"
                                                label="Surname"
                                                className="w-100"
                                                required
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FirstName"
                                                label="First Name"
                                                className="w-100"
                                                required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="MiddleName"
                                                label="Middle Name"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    )
})