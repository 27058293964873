import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./AdminReport.css";
import { AdminReportForm } from './AdminReportForm';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Button from "@material-ui/core/Button";
import AlertItem from "../../widgets/AlertItem";

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
            ]
        }
    ]
};

const AdminReportRef = React.createRef();
const utils = new ViewUtils();

class AdminReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            AdminReportDetails: "",
            model: {}
        };
    }

    componentDidMount() {
        let url = "api/sims/Adminreportsdetails/GetFind?AdminReportID=" + this.props.location.state.selection;
        utils.invokeUrl(url, (data) => {
            this.setState({ AdminReportDetails: data });
        }, (e) => {
            this.setState({ message: "Unable to get report details. A system error has occurred. Please contact your system administrator", messageStyle: "ERROR" });
        });
    }

    handleFormValueChange(values) {
        this.setState({ model: values });
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.closeView();
        }
    }

    closeView() {
        this.props.history.push('/AdminReportSearch');
    }

    handleExecuteReport = () => {

        let searchParameters = [];
        const properties = Object.getOwnPropertyNames(this.state.model);
        if (!utils.isNull(properties) && properties.length > 0) {
            for (let i = 0; i < properties.length; i++) {
                let searchParameter = {};
                searchParameter.Name = properties[i];
                searchParameter.Value = this.state.model[properties[i]];
                searchParameters.push(searchParameter);
            }
        }

        searchParameters.push({ Name: "AdminReportID", Value: this.props.location.state.selection })

        utils.invokeUrl("api/sims/AdminReportsDetails/GetAdminReportsExcel?request=" + utils.doGetRequestJson(0, 0, searchParameters, true), (data) => {
            var result;
            if (typeof data === "string") {
                result = JSON.parse(data);
            } else {
                result = data;
            }

            var blob = new Blob([this.base64ToArrayBuffer(result.Base64)], { type: result.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = result.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ message: "Unable to execute report. A system error has occurred. Please contact your system administrator", messageStyle: "ERROR" });
        });
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Admin Reports " + (this.state.AdminReportDetails.ReportName !== null ? "> " + this.state.AdminReportDetails.ReportName : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <AdminReportForm ref={AdminReportRef} AdminReportDetails={this.state.AdminReportDetails} valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: "10px" }}>
                                <Button size="small" color="primary" onClick={this.handleExecuteReport}>
                                    Execute Report
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(AdminReport);
