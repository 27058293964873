import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Provider.css";
import SearchView from "../../widgets/SearchView";
import ProviderSearchForm from './ProviderSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
//import OrganisationSearch from "../../skills/organisation/OrganisationSearch";
//import OrganisationLinkDialog from "../../skills/organisation/OrganisationLinkDialog";
import withMenuPermission from "../../widgets/withMenuPermission";


export const headCells = [
    { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
    { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: "ProAccNumber", numeric: false, disablePadding: true, label: "Accreditation Number" },
    { id: "AccreditationStatus", numeric: false, disablePadding: true, label: "Accreditation Status" },
    { id: "Province", numeric: false, disablePadding: true, label: "Province" }
];

const utils = new ViewUtils();

class ProviderSearch extends Component {

    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            //For search of providers that can be linked to a particular entity
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "LegalName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "TradeName", Value: "" },
                { Name: "ProviderAccreditationNumber", Value: "" }
            ]
            :
            //For search on the provider search screen
            [
                { Name: "LegalName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "TradeName", Value: "" },
                { Name: "ProviderAccreditationNumber", Value: "" }
            ]

        let model = !utils.isNull(this.props.programmeId) ?
            {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
                ProviderAccreditationNumber: "",
                id: this.props.programmeId
            }
            :
            {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
                ProviderAccreditationNumber: ""
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedProvider: null,
            linkDialogOpen: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Delete", icon: "Delete", disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        }

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedProvider: selection })
        } else {
            this.setState({ selectedProvider: null })
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ linkDialogOpen: true })
        }
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    render() {

        return (
            <ViewContainer title="">

                <SearchView
                    headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/provider/GetSearch"}
                    entityViewPath='/provider'
                    model={this.state.model}
                    //addViewPath={"/providerSearch"}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    mode={this.props.mode}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange}
                    deleteUrl={"api/sims/Provider/RemoveProvider"}
                    useDeletePost={true}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Provider"}
                    promptContent={"Are you sure you want to remove this provider?"}
                    title='Provider'
                >
                    <ProviderSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />

                </SearchView>

                {/*<OrganisationLinkDialog*/}
                {/*    open={this.state.linkDialogOpen}*/}
                {/*    dialogTitle={"Link Organisation To Provider"}*/}
                {/*    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}*/}
                {/*    history={this.props.history}*/}
                {/*    entityViewPath={"/provider"}*/}
                {/*    linkName={"Provider"}*/}
                {/*    postUrl={"api/sims/provider/LinkOrganisationToProvider"}*/}
                {/*    usePost={true}*/}
                {/*>*/}
                {/*    <OrganisationSearch*/}
                {/*        isLinkSearch={true}*/}
                {/*        dataUrl="api/sims/provider/GetLinkableOrganisations"*/}
                {/*        mode="lookup"*/}
                {/*        showTitle={false}*/}
                {/*    />*/}
                {/*</OrganisationLinkDialog>*/}
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(ProviderSearch));

