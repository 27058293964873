import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Benefits.css";
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';
import InterventionTitlesForm from './InterventionTitlesForm';


const headCells = [
    
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                { id: 'delete', label: '', tooltip: 'Delete selected assessor from records', icon: 'DeleteIcon', disabled: true }
            ]
        }
    ]
};

const searchParameters = [
    
    { Name: "Description", Value: "" }

]

class BenefitsTargetsSearch extends Component {

    state = {
        model: {
            
            Description: ""
        },
        dialogOpen: false
    };

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/CreateInterventionTitles',

            })
        }
    }

    close() {
        this.props.history.push('/CreateInterventionTitles');
    }

    render() {
        return (
            <CardLayout title={`Benefits Maintenance`}>
                <SearchView headCells={headCells} dataUrl="api/indicium/provider/search" entityViewPath='/CreateInterventionTitles'
                model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                onLookupValueChange={this.props.onLookupValueChange}
                title='Intervention Titles'
                descriptionProp=""
                history={this.props.history} hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
            >
                <InterventionTitlesForm model={this.state.model} />


                </SearchView>
                </CardLayout >
        );
    }
}

export default withRouter(
    connect()(BenefitsTargetsSearch)
);
