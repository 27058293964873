import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../View.css";
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import SearchView from "../widgets/SearchView";
import UserSearchForm from './UserSearchForm';
import ViewUtils from '../ViewUtils';
import { ViewContainer } from "./../View.jsx";

const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: "IDNo", numeric: false, disablePadding: true, label: "ID Number"}
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                { id: "delete", label: "", tooltip: "Remove User", icon: "DeleteIcon", disabled: true }
            ]
        }
    ]
};

const utils = new ViewUtils();

class UserSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = this.searchParameters = !utils.isNull(this.props.programmeId) ?

            [
                { Name: "ID", Value: this.props.programmeId },
                { Name: "FirstName", Value: "" },
                { Name: "Surname", Value: "" },
                { Name: "IDNo", Value: "" }
            ]
            :

            [
                { Name: "FirstName", Value: "" },
                { Name: "Surname", Value: "" },
                { Name: "IDNo", Value: "" }
            ]

        if (!utils.isNull(this.props.programmeId)) {
            this.state = {
                model: {
                    FirstName: "",
                    Surname: "",
                    IDNo: "",
                    ID: this.props.programmeId,
                },
                keyUpSwitch: false,
                selectedUser: null
            };
        }
        else {
            this.state = {
                model: {
                    FirstName: "",
                    Surname: "",
                    IDNo: "",
                },
                keyUpSwitch: false,
                selectedUser: null
            };
        }

        
    }


    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedUser: selection })
        } else {
            this.setState({ selectedUser: null })
        }
    }

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Users' : null}>
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/indicium/employer/searchLinkableUsers" : this.props.dataUrl}
                    deleteUrl={"api/indicium/unitStandardSetup/unlinkUnitstandard"}
                    entityViewPath='/users'
                    model={this.state.model} toolbarConfig={toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="FirstName" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    keyUpSwitch={this.state.keyUpSwitch}
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    promptTitle={"Remove Unit Standard"}
                    promptContent={"Removing the user is a permanent action and the user will not be available going forward. Are you sure you want to remove this user?"}
                >
                    <UserSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(UserSearch)
);
