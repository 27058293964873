import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import ProcessSearchFormShared from './ProcessSearchFormShared';
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'BatchNumber', numeric: false, disablePadding: false, label: 'Batch Number' },
    { id: 'OrganisationSDLNumber', numeric: false, disablePadding: false, label: 'Organisation SDL Number' }
];


class AXProcessorSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedClaims: null,
            message: null,
            messageStyle: null,
            model: {
                EmployerID: null,
                DiscretionaryGrantTypeID: null,
                SocioEconomicStatusID: null
            },
            keyUpSwitch: false

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'ProcessBatch', label: 'Process Batch', tooltip: 'Process Batch',disabled: utils.isNull(this.state.selectedClaims)},
                        {id: 'downloadBatchForm', label: 'Download Batch Form', icon: 'DownloadIcon', tooltip: 'Download batch form for selected record',disabled: utils.isNull(this.state.selectedClaims)}
                    ]
                }
            ]
        };

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelections = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedClaims: selection, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("ProcessBatch", selection.length === 0);
                    toolbarRef.current.setButtonDisabled("downloadBatchForm", selection.length === 0);
                }
            )
        } else {
            this.setState({ selectedClaims: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("ProcessBatch", selection.length === 0);
                    toolbarRef.current.setButtonDisabled("downloadBatchForm", selection.length === 0);
                }
            )
        }
    }

    downloadBatchForm = () => {

        let data = {
            Id: this.state.selectedClaims[0].Id,
            DiscretionaryGrantTypeID: this.state.model.DiscretionaryGrantTypeID.Id
        }
        let url = "api/sims/AXProcessor/BatchFormDownload";
        utils.invokeUrl(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
            toolbarRef.current.setButtonDisabled("downloadBatchForm", true);
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    processBatch = () => {
        const IDs = this.state.selectedClaims.map(e => e.Id);
        let data = {
            IDs: IDs,
            DiscretionaryGrantTypeID: this.state.model.DiscretionaryGrantTypeID
        }
        let url = "api/sims/AXProcessor/AXBatchProcess";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            null,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values, selectedGrant: null });
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "close") {
            this.props.history.push({
                pathname: '/ProcessingNav'
            })
        } else if ("ProcessBatch" === buttonId) {
            this.processBatch();
            toolbarRef.current.setButtonDisabled("ProcessBatch", true);
        } else if ("downloadBatchForm" === buttonId) {
            this.downloadBatchForm();
            toolbarRef.current.setButtonDisabled("downloadBatchForm", true);
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    

    render() {

        return (
            <ViewContainer title={''}>
                <p className="breadcrumbs">
                    {"Grant Processing > Process Batch"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <SearchView headCells={headCells} dataUrl="api/sims/AXProcessor/GrantAXProcessBatchGetDetails"
                    model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    searchLabel={"SEARCH"}
                    multiSelect={true}
                    handleObject={true}
                    autoFetchData={false}
                    keyUpSwitch={this.state.keyUpSwitch}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelections(selection) }}
                >
                    <ProcessSearchFormShared data={this.state.model} controllerName='AXProcessor'
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(AXProcessorSearch));



