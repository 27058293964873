import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import BulkAssociationSearchForm from './BulkAssociationSearchForm';
import SearchView from "../../../widgets/SearchView";
import withMenuPermission from "../../../widgets/withMenuPermission";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { DiAssociationDetailForm } from "./DiAssociationDetailForm";
import FormDialog from "../../FormDialog";

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

const headCells = [
    { id: 'LearnerFirstName', numeric: false, disablePadding: false, label: 'First Name' },
    { id: 'LearnerLastName', numeric: false, disablePadding: false, label: 'Last Name' },
    { id: 'LearnerIDNumber', numeric: false, disablePadding: false, label: 'ID Number' },
    { id: 'AssociationDetailCode', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'AssociationDetailDescription', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'OrganisationSDLNumber', numeric: false, disablePadding: false, label: 'SDL Number' }
];


class BulkGrantDeAssociation extends Component {

    constructor(props) { 
        super(props) 
        this.state = { 
            model: { 
                FinancialYearID: null, 
                DiscretionaryGrantTypeID: null, 
                EmployerID: null, 
                SocioEconomicStatusID: null 
            }, 
            IDs: null, 
            keyUpSwitch: false,
            LearnerCount: 0,
            clearGrid: false,
            formDialogOpen: false,
            GrantsLinked: false,
            messsageAlert: null,
            messageStyle: null,
            DiscretionaryGrantTypeID: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'bulkdiassociate', label: 'Bulk De-Associate', tooltip: 'Bulk de-associate selected grants',
                            disabled: utils.isNull(this.state.IDs)
                        }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("bulkdiassociate" === buttonId) {
            this.setState({ formDialogOpen: true, removeMessage: "", DiscretionaryGrantTypeID: this.state.model.DiscretionaryGrantTypeID })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.diassociationSelectedGrants();
            toolbarRef.current.setButtonDisabled("bulkdiassociate", true);
            this.setState({
                formDialogOpen: false, IDs: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false
            })
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelections = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ IDs: selection, Message: "", LearnerCount: selection.length },
                () => {
                    toolbarRef.current.setButtonDisabled("bulkdiassociate", selection.length === 0);
                }
            )
        } else {
            this.setState({ IDs: null, Message: "", LearnerCount: 0 },
                () => {
                    toolbarRef.current.setButtonDisabled("bulkdiassociate", selection.length === 0);
                }
            )
        }
    }

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values });
        }
    }


    render() {

        return (
            <ViewContainer mode={this.props.mode} title={``}>
                <p className="breadcrumbs">
                    {"Bulk Grant De-Association"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <SearchView
                    headCells={headCells}
                    dataUrl="api/sims/BulkGrantDiAssociation/BulkGrantDiAssociationSearch"
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    mode={this.props.mode}
                    history={this.props.history}
                    multiSelect={true}
                    onLookupValueChange={this.props.onLookupValueChange}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelections(selection) }}
                    autoFetchData={false}
                    toolbarRef={toolbarRef}
                    searchLabel={"SEARCH"}
                    handleObject={true}
                >
                    <BulkAssociationSearchForm data={this.state.model} controllerName='BulkGrantDiAssociation'
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />

                    <FormDialog
                        open={this.state.formDialogOpen}
                        dialogTitle={"Bulk Grant Di-Association Details"}
                        viewRef={this}
                        saveUrl={"api/sims/BulkGrantDiAssociation/BulkGrantDiAssociate"}
                        usePut={true}
                        formRef={addFormRef}
                        multiEntity={this.state.IDs}
                        entityInfo={this.state.model}
                        closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    >
                        <DiAssociationDetailForm ref={addFormRef} />

                    </FormDialog>
                </SearchView>
                <div> {'Total Learners: ' + this.state.LearnerCount} </div>  
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(BulkGrantDeAssociation));

