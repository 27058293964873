import React from 'react';
import { CustomTextField } from '../../../../widgets/CustomTextField';
import { EntityForm } from '../../../../widgets/Forms';
import ViewUtils from '../../../../ViewUtils';
import TabPanel from '../../../../widgets/TabPanel';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { LookupField } from '../../../../widgets/LookupField';
import Table from '@material-ui/core/Table';
import { SelectItem } from '../../../../widgets/SelectItem';
import { CustomDatePicker } from '../../../../widgets/CustomDatePicker';
import SkillsProgrammeSearch from '../../Controls/SkillsProgrammeSearch';
import ProviderSearch from '../../Controls/ProviderSearch';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

const styles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: 50,
            textAlign: 'center',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        minWidth: 200
    },
    inputForm: {
        minWidth: '200px',
    },
    table: {
        minWidth: 700,
    },
    inputCenter: {
        textAlign: 'center'
    },
    paper: {
        position: "absolute",
        width: 600,
        height: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0.1)
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
        height: 6
    },
    body: {
        fontSize: 11,
    },

}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // width: 50,
            textAlign: 'center'
        },
    },
    body: {
        fontSize: 14,
        fontWeight: 500
    }

}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    table: {
        minWidth: 700,
    },
});

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 5;
    const left = 5;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

export const SkillsProgrammeForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm
        ref={ref} 
        viewId={null}
        values={props.data}
        entityLookupController={props.entityLookupController}
        org="saseta"
        entityLookupId={props.id}
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="EmploymentNumber">
                    <div className="row w-100">
                        <div className="col w-100">
                            <TabPanel value={tabValue} index={0} className=''>
                                <table cellPadding="2" width="80%">
                                    <tbody>
                                        <tr>
                                            
                                        <td width="50%">
                                                <LookupField
                                                    id="SkillsProgrammeID"
                                                    required
                                                    label="Skills Programme"
                                                    className="w-100"
                                                    form={form}
                                                >
                                                    <SkillsProgrammeSearch showTitle={true} mode='lookup' dataUrl="api/sims/DGApplicationForms2022/GetDGFormSPSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("SkillsProgrammeID", value)
                                                        }} autoFetchData={false} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                            <td width="40%">
                                                &nbsp;
                                            </td>
                                        </tr>
                                            <td width="40%">
                                                <LookupField
                                                    id="ProviderID"
                                                    required
                                                    label="Training Provider"
                                                    className="w-100"
                                                    form={form}
                                                >
                                                    <ProviderSearch showTitle={true} mode='lookup' dataUrl="api/sims/DGApplicationForms2022/GetDGFormProviderSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("ProviderID", value)
                                                        }} autoFetchData={false} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                            <td width="40%">
                                                <CustomTextField
                                                    id="AccreditationBody"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Accreditation Body"
                                                    className="w-100"
                                                    form={form}
                                                />
                                            </td>
                                        <tr>
                                            <td width="40%">
                                                <CustomTextField
                                                    id="NoOfLearnersEmployed"
                                                    key={tabValue}
                                                    label="Number of Employed Learners"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                            <td width="40%">
                                                <CustomDatePicker
                                                    id="StartDate"
                                                    label="Start Date"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="40%">
                                                <CustomDatePicker
                                                    id="EndDate"
                                                    label="End Date"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                            <td width="40%">
                                                <CustomTextField
                                                    id="Mentor"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Name of Mentor"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Table className={styles.table} size="small" >
                                    <TableHead>

                                        <TableRow>
                                            <StyledTableCell align="center" colSpan={3}>African</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>Coloured</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>Indian/Asian</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>White</StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow color="primary">
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="IndianMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="IndianFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="IndianDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                                </TabPanel>
                        </div>  
                    </div>
                </form >
            }
        </EntityForm >
    );
})