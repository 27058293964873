import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import EnhancedToolbar from '../widgets/Toolbar';
import withMenuPermission from "../widgets/withMenuPermission";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';



import TrainingProviderListPerAccreditationStatusLineChart from '../QALPDashboardCharts/TrainingProviderListPerAccreditationStatusLineChart';
import TrainingProviderListPerAccreditationStatusBarChart from '../QALPDashboardCharts/TrainingProviderListPerAccreditationStatusBarChart';
import TrainingProviderListPerAccreditationStatusPieChart from '../QALPDashboardCharts/TrainingProviderListPerAccreditationStatusPieChart';

import TrainingProviderListPerRegionBarChart from '../QALPDashboardCharts/TrainingProviderListPerRegionBarChart';
import TrainingProviderListPerRegionLineChart from '../QALPDashboardCharts/TrainingProviderListPerRegionLineChart';
import TrainingProviderListPerRegionPieChart from '../QALPDashboardCharts/TrainingProviderListPerRegionPieChart';

import TrainingProviderListPerProviderClassPieChart from '../QALPDashboardCharts/TrainingProviderListPerProviderClassPieChart';
import TrainingProviderListPerProviderClassBarChart from '../QALPDashboardCharts/TrainingProviderListPerProviderClassBarChart';
import TrainingProviderListPerProviderClassLineChart from '../QALPDashboardCharts/TrainingProviderListPerProviderClassLineChart';


import AssessorPerRegionBarChart from '../QALPDashboardCharts/AssessorPerRegionBarChart';
import AssessorPerRegionLineChart from '../QALPDashboardCharts/AssessorPerRegionLineChart';
import AssessorPerRegionPieChart from '../QALPDashboardCharts/AssessorPerRegionPieChart';

import AssessorPerRegistrationStatusBarChart from '../QALPDashboardCharts/AssessorPerRegistrationStatusBarChart';
import AssessorPerRegistrationStatusLineChart from '../QALPDashboardCharts/AssessorPerRegistrationStatusLineChart';
import AssessorPerRegistrationStatusPieChart from '../QALPDashboardCharts/AssessorPerRegistrationStatusPieChart';

import AssessorPerAssessorTypeBarChart from '../QALPDashboardCharts/AssessorPerAssessorTypeBarChart';
import AssessorPerAssessorTypeLineChart from '../QALPDashboardCharts/AssessorPerAssessorTypeLineChart';
import AssessorPerAssessorTypePieChart from '../QALPDashboardCharts/AssessorPerAssessorTypePieChart';


import ModeratorPerModeratorTypeBarChart from '../QALPDashboardCharts/ModeratorPerModeratorTypeBarChart';
import ModeratorPerModeratorTypeLineChart from '../QALPDashboardCharts/ModeratorPerModeratorTypeLineChart';
import ModeratorPerModeratorTypePieChart from '../QALPDashboardCharts/ModeratorPerModeratorTypePieChart';

import ModeratorPerRegistrationStatusBarChart from '../QALPDashboardCharts/ModeratorPerRegistrationStatusBarChart';
import ModeratorPerRegistrationStatusLineChart from '../QALPDashboardCharts/ModeratorPerRegistrationStatusLineChart';
import ModeratorPerRegistrationStatusPieChart from '../QALPDashboardCharts/ModeratorPerRegistrationStatusPieChart';

import ModeratorPerRegionBarChart from '../QALPDashboardCharts/ModeratorPerRegionBarChart';
import ModeratorPerRegionLineChart from '../QALPDashboardCharts/ModeratorPerRegionLineChart';
import ModeratorPerRegionPieChart from '../QALPDashboardCharts/ModeratorPerRegionPieChart';


import LearnerLearnershipsPerMunicipalityBarChart from '../QALPDashboardCharts/LearnerLearnershipsPerMunicipalityBarChart';
import LearnerLearnershipsPerMunicipalityLineChart from '../QALPDashboardCharts/LearnerLearnershipsPerMunicipalityLineChart';
import LearnerLearnershipsPerMunicipalityPieChart from '../QALPDashboardCharts/LearnerLearnershipsPerMunicipalityPieChart';

import LearnerLearnershipsPerSponsorshipBarChart from '../QALPDashboardCharts/LearnerLearnershipsPerSponsorshipBarChart';
import LearnerLearnershipsPerSponsorshipLineChart from '../QALPDashboardCharts/LearnerLearnershipsPerSponsorshipLineChart';
import LearnerLearnershipsPerSponsorshipPieChart from '../QALPDashboardCharts/LearnerLearnershipsPerSponsorshipPieChart';

import LearnerLearnershipsPerAgreementStatusBarChart from '../QALPDashboardCharts/LearnerLearnershipsPerAgreementStatusBarChart'; 
import LearnerLearnershipsPerAgreementStatusLineChart from '../QALPDashboardCharts/LearnerLearnershipsPerAgreementStatusLineChart';
import LearnerLearnershipsPerAgreementStatusPieChart from '../QALPDashboardCharts/LearnerLearnershipsPerAgreementStatusPieChart';

import LearnerLearnershipsPerLearnershipTitleBarChart from '../QALPDashboardCharts/LearnerLearnershipsPerLearnershipTitleBarChart';
import LearnerLearnershipsPerLearnershipTitleLineChart from '../QALPDashboardCharts/LearnerLearnershipsPerLearnershipTitleLineChart';
import LearnerLearnershipsPerLearnershipTitlePieChart from '../QALPDashboardCharts/LearnerLearnershipsPerLearnershipTitlePieChart';


import LearnerSkillsProgrammesPerMunicipalityBarChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerMunicipalityBarChart';
import LearnerSkillsProgrammesPerMunicipalityLineChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerMunicipalityLineChart';
import LearnerSkillsProgrammesPerMunicipalityPieChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerMunicipalityPieChart';

import LearnerSkillsProgrammesPerBursaryNameBarChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerBursaryNameBarChart';
import LearnerSkillsProgrammesPerBursaryNameLineChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerBursaryNameLineChart';
import LearnerSkillsProgrammesPerBursaryNamePieChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerBursaryNamePieChart';

import LearnerSkillsProgrammesPerSponsorshipBarChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerSponsorshipBarChart';
import LearnerSkillsProgrammesPerSponsorshipLineChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerSponsorshipLineChart';
import LearnerSkillsProgrammesPerSponsorshipPieChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerSponsorshipPieChart';

import LearnerSkillsProgrammesPerBursaryStatusBarChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerBursaryStatusBarChart';
import LearnerSkillsProgrammesPerBursaryStatusLineChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerBursaryStatusLineChart';
import LearnerSkillsProgrammesPerBursaryStatusPieChart from '../QALPDashboardCharts/LearnerSkillsProgrammesPerBursaryStatusPieChart';


import LearnerBursaryPerMunicipalityBarChart from '../QALPDashboardCharts/LearnerBursaryPerMunicipalityBarChart';
import LearnerBursaryPerMunicipalityLineChart from '../QALPDashboardCharts/LearnerBursaryPerMunicipalityLineChart';
import LearnerBursaryPerMunicipalityPieChart from '../QALPDashboardCharts/LearnerBursaryPerMunicipalityPieChart';
              
import LearnerBursaryPerBursaryNameBarChart from '../QALPDashboardCharts/LearnerBursaryPerBursaryNameBarChart';
import LearnerBursaryPerBursaryNameLineChart from '../QALPDashboardCharts/LearnerBursaryPerBursaryNameLineChart';
import LearnerBursaryPerBursaryNamePieChart from '../QALPDashboardCharts/LearnerBursaryPerBursaryNamePieChart';
              
import LearnerBursaryPerSponsorshipBarChart from '../QALPDashboardCharts/LearnerBursaryPerSponsorshipBarChart';
import LearnerBursaryPerSponsorshipLineChart from '../QALPDashboardCharts/LearnerBursaryPerSponsorshipLineChart';
import LearnerBursaryPerSponsorshipPieChart from '../QALPDashboardCharts/LearnerBursaryPerSponsorshipPieChart';
              
import LearnerBursaryPerBursaryStatusBarChart from '../QALPDashboardCharts/LearnerBursaryPerBursaryStatusBarChart';
import LearnerBursaryPerBursaryStatusLineChart from '../QALPDashboardCharts/LearnerBursaryPerBursaryStatusLineChart';
import LearnerBursaryPerBursaryStatusPieChart from '../QALPDashboardCharts/LearnerBursaryPerBursaryStatusPieChart';



//LEARNER
import LearnerPerAllocationStatusBarChart from '../QALPDashboardCharts/LearnerPerAllocationStatusBarChart';
import LearnerPerAllocationStatusLineChart from '../QALPDashboardCharts/LearnerPerAllocationStatusLineChart';
import LearnerPerAllocationStatusPieChart from '../QALPDashboardCharts/LearnerPerAllocationStatusPieChart';



import { Row } from 'react-bootstrap';


const utils = new ViewUtils();


class QALPDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {

            trainingproviderlistperaccreditationstatusshow: false,            
            trainingproviderlistperregionshow: false,            
            trainingproviderlistperproviderclassshow: false,
           
            assessorperregionshow: false,
            assessorperregistrationstatusshow: false,
            assessorperassessortypeshow: false,
            
            moderatorpermoderatortypeshow: false,
            moderatorperregistrationstatusshow: false,
            moderatorperregionshow: false,
     
            learnerlearnershipsperagreementstatusshow: false,
            learnerlearnershipspermunicipalityshow: false,
            learnerlearnershipspersponsorshipshow: false,
            learnerlearnershipsperlearnershiptitleshow: false,
            
            learnerskillsprogrammespermunicipalityshow: false,
            learnerskillsprogrammesperbursarynameshow: false,  
            learnerskillsprogrammespersponsorshipshow: false,
            learnerskillsprogrammesperbursarystatusshow: false,  


            learnerbursarypermunicipalityshow: false,
            learnerbursaryperbursarynameshow: false,
            learnerbursarypersponsorshipshow: false,
            learnerbursaryperbursarystatusshow: false,
            
            learnerperallocationstatusshow: false

        };
    }

    componentDidMount() {
        utils.setLegend("QA And LP Dashboard");
        this.setState({ trainingproviderlistperaccreditationstatusshow: true });
    }


    handleReportNameChanged = (event) => {

        var divToShow = event.target.value;

        if (divToShow == 'divTrainingProviderListPerAccreditationStatus') {
            this.setState({ trainingproviderlistperaccreditationstatusshow: true });

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });

        }
        else if (divToShow == 'divTrainingProviderListPerRegion') {
            this.setState({ trainingproviderlistperregionshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divTrainingProviderListPerProviderClass') {
            this.setState({ trainingproviderlistperproviderclassshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divAssessorPerRegion')
        {
            this.setState({ assessorperregionshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divAssessorPerRegistrationStatus') {
            this.setState({ assessorperregistrationstatusshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divAssessorPerRegion')
        {
            this.setState({ assessorperregionshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divAssessorPerAssessorType')
        {
            this.setState({ assessorperassessortypeshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divModeratorPerModeratorType')
        {
            this.setState({ moderatorpermoderatortypeshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divModeratorPerRegistrationStatus')
        {
            this.setState({ moderatorperregistrationstatusshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }

        else if (divToShow == 'divModeratorPerRegion')
        {
            this.setState({ moderatorperregionshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });

            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }

        else if (divToShow == 'divLearnerLearnershipsPerLearnershipAgreementStatus')
        {
            this.setState({ learnerlearnershipsperagreementstatusshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerLearnershipsPerMunicipality')
        {
            this.setState({ learnerlearnershipspermunicipalityshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerLearnershipsPerSponsorship')
        {
            this.setState({ learnerlearnershipspersponsorshipshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerLearnershipsPerLearnershipTitle')
        {
            this.setState({ learnerlearnershipsperlearnershiptitleshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerSkillsProgrammesReportPerMunicipality')
        {
            this.setState({ learnerskillsprogrammespermunicipalityshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerSkillsProgrammesReportPerBursaryName')
        {
            this.setState({ learnerskillsprogrammesperbursarynameshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerSkillsProgrammesReportPerSponsorship')
        {
            this.setState({ learnerskillsprogrammespersponsorshipshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspermunicipalityshow: false });
            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerSkillsProgrammesReportPerBursaryStatus')
        {
            this.setState({ learnerskillsprogrammesperbursarystatusshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerBursaryReportPerMunicipality')
        {
            this.setState({ learnerbursarypermunicipalityshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerBursaryReportPerBursaryName')
        {
            this.setState({ learnerbursaryperbursarynameshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });


            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerBursaryReportPerSponsorship')
        {
            this.setState({ learnerbursarypersponsorshipshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });

            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnerBursaryReportPerBursaryStatus')
        {
            this.setState({ learnerbursaryperbursarystatusshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });
            this.setState({ learnerbursaryperbursarynameshow: false });


            this.setState({ learnerperallocationstatusshow: false });
        }
        else if (divToShow == 'divLearnersPerAllocatedAndUnAllocatedLearners')
        {
            this.setState({ learnerperallocationstatusshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperproviderclassshow: false });

            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ assessorperassessortypeshow: false });

            this.setState({ moderatorpermoderatortypeshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });

            this.setState({ learnerlearnershipspersponsorshipshow: false });
            this.setState({ learnerlearnershipsperagreementstatusshow: false });
            this.setState({ learnerlearnershipsperlearnershiptitleshow: false });
            this.setState({ learnerlearnershipspermunicipalityshow: false });

            this.setState({ learnerskillsprogrammespermunicipalityshow: false });
            this.setState({ learnerskillsprogrammesperbursarynameshow: false });
            this.setState({ learnerskillsprogrammespersponsorshipshow: false });
            this.setState({ learnerskillsprogrammesperbursarystatusshow: false });

            this.setState({ learnerbursaryperbursarynameshow: false });
            this.setState({ learnerbursaryperbursarystatusshow: false });
            this.setState({ learnerbursarypermunicipalityshow: false });
            this.setState({ learnerbursarypersponsorshipshow: false });

        }


    }



    render() {
        return (

            <div className="container-fluid">
                <br />
                <Row>
                    <TextField className="w-30" id="ReportNameID" label="Report Name: " select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleReportNameChanged(values)}>
                        <MenuItem value="divTrainingProviderListPerAccreditationStatus" key="1">Training Provider List Per Accreditation Status</MenuItem>
                        <MenuItem value="divTrainingProviderListPerRegion" key="2">Training Provider List Per Region</MenuItem>
                        <MenuItem value="divTrainingProviderListPerProviderClass" key="3">Training Provider List Per Provider Class</MenuItem>
                        
                        <MenuItem value="divAssessorPerRegion" key="4">Assessor Per Region</MenuItem>
                        <MenuItem value="divAssessorPerRegistrationStatus" key="5">Assessor Per Registration Status</MenuItem>
                        <MenuItem value="divAssessorPerAssessorType" key="6">Assessor Per Assessor Type</MenuItem>

                        <MenuItem value="divModeratorPerModeratorType" key="7">Moderator Per Moderator Type</MenuItem>
                        <MenuItem value="divModeratorPerRegistrationStatus" key="8">Moderator Per Registration Status</MenuItem>
                        <MenuItem value="divModeratorPerRegion" key="9">Moderator Per Region</MenuItem>

                        <MenuItem value="divLearnerLearnershipsPerLearnershipAgreementStatus" key="10">Learner Learnerships Per Learnership Agreement Status</MenuItem>
                        <MenuItem value="divLearnerLearnershipsPerMunicipality" key="11">Learner Learnerships Per Municipality</MenuItem>
                        <MenuItem value="divLearnerLearnershipsPerSponsorship" key="12">Learner Learnerships Per Sponsorship</MenuItem>
                        <MenuItem value="divLearnerLearnershipsPerLearnershipTitle" key="13">Learner Learnerships Per Learnership Title</MenuItem>

                        <MenuItem value="divLearnerSkillsProgrammesReportPerMunicipality" key="14">Learner Skills Programmes Report Per Municipality</MenuItem>
                        <MenuItem value="divLearnerSkillsProgrammesReportPerBursaryName" key="15">Learner Skills Programmes Report Per Bursary Name</MenuItem>
                        <MenuItem value="divLearnerSkillsProgrammesReportPerSponsorship" key="16">Learner Skills Programmes Report Per Sponsorship</MenuItem>
                        <MenuItem value="divLearnerSkillsProgrammesReportPerBursaryStatus" key="17">Learner Skills Programmes Report Per Bursary Status</MenuItem>

                        <MenuItem value="divLearnerBursaryReportPerMunicipality" key="18">Learner Bursary Report Per Municipality</MenuItem>
                        <MenuItem value="divLearnerBursaryReportPerBursaryName" key="19">Learner Bursary Report Per Bursary Name</MenuItem>
                        <MenuItem value="divLearnerBursaryReportPerSponsorship" key="20">Learner Bursary Report Per Sponsorship</MenuItem>
                        <MenuItem value="divLearnerBursaryReportPerBursaryStatus" key="21">Learner Bursary Report Per Bursary Status</MenuItem>


                        <MenuItem value="divLearnersPerAllocatedAndUnAllocatedLearners" key="22">Learners Per Allocated And UnAllocated Learners</MenuItem>
                    </TextField>
                </Row>

                <br />

                {
                    this.state.trainingproviderlistperaccreditationstatusshow ?
                        <div id="divTrainingProviderListPerAccreditationStatus" key="1" >
                            <Row>
                                <TrainingProviderListPerAccreditationStatusPieChart />
                                <TrainingProviderListPerAccreditationStatusLineChart />

                            </Row>
                            <Row>
                                <TrainingProviderListPerAccreditationStatusBarChart />
                            </Row>
                        </div> : null
                }

              
                {
                    this.state.trainingproviderlistperregionshow ?
                        <div id="divTrainingProviderListPerRegion" key="2">
                            <Row>
                                <TrainingProviderListPerRegionPieChart />
                                <TrainingProviderListPerRegionLineChart />
                            </Row>
                            <Row>
                                <TrainingProviderListPerRegionBarChart />
                            </Row>
                        </div> : null
                }

               

                {
                    this.state.trainingproviderlistperproviderclassshow ?
                        <div id="divTrainingProviderListPerProviderClass" key="3">
                            <Row>
                                <TrainingProviderListPerProviderClassPieChart />
                                <TrainingProviderListPerProviderClassLineChart />
                            </Row>
                            <Row>
                                <TrainingProviderListPerProviderClassBarChart />
                            </Row>
                        </div> : null
                }

              

                {
                    this.state.assessorperregionshow ?
                        <div id="divAssessorPerRegion" key="4">
                            <Row>
                                <AssessorPerRegionPieChart />
                                <AssessorPerRegionLineChart />
                            </Row>
                            <Row>
                                <AssessorPerRegionBarChart />
                            </Row>
                        </div> : null
                }

                                             

                {
                    this.state.assessorperregistrationstatusshow ?
                        <div id="divAssessorPerRegistrationStatus" key="5">
                            <Row>
                                <AssessorPerRegistrationStatusPieChart />
                                <AssessorPerRegistrationStatusLineChart />
                            </Row>
                            <Row>
                                <AssessorPerRegistrationStatusBarChart />
                            </Row>
                        </div> : null
                }


                {
                    this.state.assessorperassessortypeshow ?
                        <div id="divAssessorPerAssessorType" key="6">
                            <Row>
                                <AssessorPerAssessorTypePieChart />
                                <AssessorPerAssessorTypeLineChart />
                            </Row>
                            <Row>
                                <AssessorPerAssessorTypeBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.moderatorpermoderatortypeshow ?
                        <div id="divModeratorPerModeratorType" key="7">
                            <Row>
                                <ModeratorPerModeratorTypePieChart />
                                <ModeratorPerModeratorTypeLineChart />
                            </Row>
                            <Row>
                                <ModeratorPerModeratorTypeBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.moderatorperregistrationstatusshow ?
                        <div id="divModeratorPerRegistrationStatus" key="8">
                            <Row>
                                <ModeratorPerRegistrationStatusBarChart />
                                <ModeratorPerRegistrationStatusPieChart />
                            </Row>
                            <Row>
                                <ModeratorPerRegistrationStatusLineChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.moderatorperregionshow ?
                        <div id="divModeratorPerRegion" key="9">
                            <Row>
                                <ModeratorPerRegionBarChart />
                                <ModeratorPerRegionPieChart />
                            </Row>
                            <Row>
                                <ModeratorPerRegionLineChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerlearnershipsperagreementstatusshow ?
                        <div id="divLearnerLearnershipsPerLearnershipAgreementStatus" key="10">
                            <Row>
                                <LearnerLearnershipsPerAgreementStatusPieChart />
                                <LearnerLearnershipsPerAgreementStatusLineChart />
                            </Row>
                            <Row>
                                <LearnerLearnershipsPerAgreementStatusBarChart />
                            </Row>
                        </div> : null
                }


                {
                    this.state.learnerlearnershipspermunicipalityshow ?
                        <div id="divLearnerLearnershipsPerMunicipality" key="11">
                            <Row>
                                <LearnerLearnershipsPerMunicipalityPieChart />
                                <LearnerLearnershipsPerMunicipalityLineChart />
                            </Row>
                            <Row>
                                <LearnerLearnershipsPerMunicipalityBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerlearnershipspersponsorshipshow ?
                        <div id="divLearnerLearnershipsPerSponsorship" key="12">
                            <Row>
                                <LearnerLearnershipsPerSponsorshipPieChart />
                                <LearnerLearnershipsPerSponsorshipLineChart />
                            </Row>
                            <Row>
                                <LearnerLearnershipsPerSponsorshipBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerlearnershipsperlearnershiptitleshow ?
                        <div id="divLearnerLearnershipsPerLearnershipTitle" key="13">
                            <Row>
                                <LearnerLearnershipsPerLearnershipTitlePieChart />
                                <LearnerLearnershipsPerLearnershipTitleLineChart />
                            </Row>
                            <Row>
                                <LearnerLearnershipsPerLearnershipTitleBarChart />
                            </Row>
                        </div> : null
                }




                {
                    this.state.learnerskillsprogrammespermunicipalityshow ?
                        <div id="divLearnerSkillsProgrammesReportPerMunicipality" key="14">
                            <Row>
                                <LearnerSkillsProgrammesPerMunicipalityBarChart />
                                <LearnerSkillsProgrammesPerMunicipalityLineChart />
                            </Row>
                            <Row>
                                <LearnerSkillsProgrammesPerMunicipalityPieChart />
                            </Row>
                        </div> : null
                }
                 
                  
                 
                {
                    this.state.learnerskillsprogrammesperbursarynameshow ?
                        <div id="divLearnerSkillsProgrammesReportPerBursaryName" key="15">
                            <Row>
                                <LearnerSkillsProgrammesPerBursaryNamePieChart />
                                <LearnerSkillsProgrammesPerBursaryNameLineChart />
                            </Row>
                            <Row>
                                <LearnerSkillsProgrammesPerBursaryNameBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerskillsprogrammespersponsorshipshow ?
                        <div id="divLearnerSkillsProgrammesReportPerSponsorship" key="16">
                            <Row>
                                <LearnerSkillsProgrammesPerSponsorshipPieChart />
                                <LearnerSkillsProgrammesPerSponsorshipLineChart />
                            </Row>
                            <Row>
                                <LearnerSkillsProgrammesPerSponsorshipBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerskillsprogrammesperbursarystatusshow ?
                        <div id="divLearnerSkillsProgrammesReportPerBursaryStatus" key="17">
                            <Row>
                                <LearnerSkillsProgrammesPerBursaryStatusPieChart />
                                <LearnerSkillsProgrammesPerBursaryStatusLineChart />
                            </Row>
                            <Row>
                                <LearnerSkillsProgrammesPerBursaryStatusBarChart />
                            </Row>
                        </div> : null
                }


                {
                    this.state.learnerbursarypermunicipalityshow ?
                        <div id="divLearnerBursaryReportPerMunicipality" key="18">
                            <Row>
                                <LearnerBursaryPerMunicipalityPieChart />
                                <LearnerBursaryPerMunicipalityLineChart />
                            </Row>
                            <Row>
                                <LearnerBursaryPerMunicipalityBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerbursaryperbursarynameshow ?
                        <div id="divLearnerBursaryReportPerBursaryName" key="19">
                            <Row>
                                <LearnerBursaryPerBursaryNamePieChart />
                                <LearnerBursaryPerBursaryNameLineChart />
                            </Row>
                            <Row>
                                <LearnerBursaryPerBursaryNameBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerbursarypersponsorshipshow ?
                        <div id="divLearnerBursaryReportPerSponsorship" key="20">
                            <Row>
                                <LearnerBursaryPerSponsorshipPieChart />
                                <LearnerBursaryPerSponsorshipLineChart />
                            </Row>
                            <Row>
                                <LearnerBursaryPerSponsorshipBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerbursaryperbursarystatusshow ?
                        <div id="divLearnerBursaryReportPerBursaryStatus" key="21">
                            <Row>
                                <LearnerBursaryPerBursaryStatusPieChart />
                                <LearnerBursaryPerBursaryStatusLineChart />
                            </Row>
                            <Row>
                                <LearnerBursaryPerBursaryStatusBarChart />
                            </Row>
                        </div> : null
                }


                {
                    this.state.learnerperallocationstatusshow ?
                        <div id="LearnersPerAllocatedAndUnAllocatedLearners" key="22">
                            <Row>
                                <LearnerPerAllocationStatusPieChart />
                                <LearnerPerAllocationStatusLineChart />
                            </Row>
                            <Row>
                                <LearnerPerAllocationStatusBarChart />
                            </Row>
                        </div> : null
                }






            </div>
        );

    }
}

export default withRouter(withMenuPermission(QALPDashboard));
