import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Skills.css";
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';
import SkillsSearchForm from './SkillsLetterCreationSearchForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";
const utils = new ViewUtils();

const filterParameters = [

]
const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'CRMLetterGrantType', numeric: false, disablePadding: true, label: 'CRM Letter Grant Type' },
    { id: 'CRMLetterType', numeric: false, disablePadding: true, label: 'CRM Letter Type' },
    { id: 'CRMLetterSalutation', numeric: false, disablePadding: true, label: 'CRM Letter Salutation' },
    { id: 'HeaderA', numeric: false, disablePadding: true, label: 'Header A' },
    { id: 'HeaderB', numeric: false, disablePadding: true, label: 'Header B' },
    { id: 'CRMLetterGreeting', numeric: false, disablePadding: true, label: 'CRM Letter Greeting' },
    { id: 'SignOffName', numeric: false, disablePadding: true, label: 'Sign off Name' },
    { id: 'JobTitle', numeric: false, disablePadding: true, label: 'Job Title' }

];



const formRef = React.createRef();

class SkillsLetterCreationSearch extends Component {

    constructor(props){
        super(props)
        this.state = {
            model: {
                FinancialYearID: "",
                CRMLetterGrantTypeID: "",
                CRMLetterTypeID: ""

            },
            keyUpSwitch: false
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'add',
                            label: '',
                            tooltip: 'Add',
                            icon: 'AddIcon',
                            visible: this.props.menuPermissionItem.Add
                        },
                        {
                            id: 'edit',
                            label: '',
                            tooltip: 'Edit',
                            icon: 'EditIcon',
                            disabled: true,
                            visible: this.props.menuPermissionItem.Edit
                        },
                        {
                            id: 'delete',
                            label: '',
                            tooltip: 'Delete selected letter from records',
                            icon: 'DeleteIcon',
                            disabled: true,
                            visible: this.props.menuPermissionItem.Delete
                        }
                    ]
                }
            ]
        };

        this.searchParameters = [
            { Name: "FinancialYearID", Value: "" },
            { Name: "CRMLetterGrantTypeID", Value: "" },
            { Name: "CRMLetterTypeID", Value: "" }

        ]
    }


    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/SkillsLetterCreation'
            })
        }
    } 

    postLinkHandler(linked) {
        this.setState({ linkDialogOpen: false, selectedLinkType: null, selectedLinkStandards: null });
    }

    handleFormValueChange(values) {
        this.setState({ model: values });
    }
    handleSelection = (selection) => {
        /*console.log(selection)*/
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <CardLayout title='Skills Maintenance'>
                <SearchView headCells={headCells}
                            dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/skillslettercreation/getsearch"}
                            entityViewPath='/SkillsLetterCreation'
                            title='Letter Creation'
                            model={this.state.model}
                            toolbarConfig={this.toolbarConfig}
                            searchParameters={this.searchParameters}
                            filterParameters={filterParameters}
                            onLookupValueChange={this.props.onLookupValueChange}
                            descriptionProp="HeaderA"
                            mode={this.props.mode}
                            history={this.props.history}
                            buttonClickHandler={this.handleToolbarButtonClick}
                            keyUpSwitch={this.state.keyUpSwitch}
                            deleteUrl={"api/sims/skillslettercreation/removelettercreation"}
                            useDeletePost={true}
                            promptTitle={"Remove Letter"}
                            promptContent={"Are you sure you want to remove this Letter ?"}
                            setSelection={(selection) => { this.handleSelection(selection) }}



            >
                    <SkillsSearchForm model={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }} ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
            />
            </SearchView>
            </CardLayout>
        );
    }
}

export default withRouter(withMenuPermission(SkillsLetterCreationSearch));
