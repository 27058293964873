import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import LinearFlowHeading from '../../widgets/LinearFlowHeading';
import AddPersonRoleSearch from './../person/AddPersonRoleSearch';
import AddProjectOwnerDetails from './AddProjectOwnerDetails';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";

const steps = [
    { name: "Identity Details", tooltip: "Search the system for an existing person. If one does not extist, click proceed to create a learner with a new person" },
    { name: "ProjectOwner Details", tooltip: "Capture Project Owner details" },
];

const utils = new ViewUtils();
class AddAssesorFlow extends Component {
    state = {
        currentStepIndex: 0,
        data: null,
        documents: {},
        saveMessage: "",
        hasSaveError: false
    }

    navigate(index, forward) {
        if (forward) {
            if (index !== 4) {
                this.setState({ currentStepIndex: index + 1 });
            }
        } else {
            this.setState({ currentStepIndex: index - 1 });
        }
    }

    stepHandler(index, forward, data) {
        console.clear();
        console.log(index);
        console.log(forward);
        console.log(data);
        if (index === 0) {
            if (!utils.isNull(this.state.data)
                && !utils.isNull(this.state.data.personRoleDetails) && !forward) {
                this.setState({
                    data: {
                        ...this.state.data,
                        personRoleDetails: {
                            ...this.state.data.personRoleDetails,
                            Person: data.Person
                        }
                    }
                }, () => { this.navigate(index, forward) });
            } else {
                this.setState({
                    data: data
                }, () => { this.navigate(index, forward) });
            }
        } else if (index === 1) {
            if (!utils.isNull(data) && forward) {

                this.setState({
                    data: { ...this.state.data, personRoleDetails: data }
                }, () => {
                    let consultantData = {};
                    consultantData.Person = this.state.data.personRoleDetails;

                        utils.invokeUrl("api/sims/projectowner/AddProjectOwner", (data) => {
                        this.props.history.push({
                            pathname: '/projectownerSearch'
                        })
                    }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                        body: JSON.stringify(consultantData)
                    });
                })
            } else {
                this.setState({
                    data: { ...this.state.data, scopeDetails: data }
                }, () => { this.navigate(index, forward) });
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
    }

    componentDidMount() {
        utils.setLegend("Add Project Owner");
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <Row>
                    <Col>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col> <LinearFlowHeading steps={steps} currentIndex={this.state.currentStepIndex} /></Col>
                        </Row>
                        {this.state.currentStepIndex === 0 ?

                            <AddPersonRoleSearch role="projectowner"
                                data={this.state.data}
                                alreadyExistErrorMessage="A Project Owner with the specified criteria already exist"
                                stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} />
                            : null}
                        {this.state.currentStepIndex === 1 ? <AddProjectOwnerDetails permissionItem={this.props.onMenuSpecificPermissionItem('/projectowner')} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                    </Col>
                </Row>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AddAssesorFlow));
