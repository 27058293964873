import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { SelectItem } from '../../widgets/SelectItem';

export default function OrganisationSearchForm(props) {
    return (
        <EntityForm ref={React.createRef()} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="OrganisationSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <CustomTextField
                                id="LegalName"
                                label="Legal Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="TradeName"
                                label="Trade Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="SDLNumber"
                                label="SDL Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                                <div className="col form-col">
                                    <SelectItem

                                        dataUrl = "api/sims/dgorganisation/getlookuplist?listid=SICCode"
                                        id="SICCodeID"
                                        label="SIC Code"
                                        className="w-100"
                                        form={form}
                                    />
                                </div>
                        
                    </div>
                </form>
            }
        </EntityForm >
    );
}
