import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';

export default function ProviderReportSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="dggrantreports" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form => <form className={form.classes.container} id="dgreports">
                <div className="row search-form-container">
                    <div className="col form-col">
                        <CustomTextField
                            id="ReportName"
                            label="Report Name"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                    <div className="col form-col">
                        <CustomTextField
                            id="ReportDescription"
                            label="Report Description"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </div>
                </div>
            </form>
            }
        </EntityForm>
    );
}
