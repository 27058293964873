import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();
export const LinkTrancheForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function getTrancheType(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Progress") {
                return false
            }
            else {
                return true
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="LinkTrancheDetail" values={props.data} dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.identity} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
    {form =>
                <form className={form.classes.container} id="LinkTrancheDetailForm">
        <table cellPadding="2" className="w-100">
            <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl={"api/sims/GrantSetup/GetLookupList?listId=TrancheType"}
                                        id="TrancheTypeID"
                                        label="Tranche Type"
                                        className="w-100"
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                    />
                                </td>
                                <td>
                                    <CustomTextField id="TrancheAmount"
                                        key={tabValue}
                                        label="Tranche Amount"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                </tr>
                <tr>
                                <td>
                                    <CustomDatePicker
                                        id="DueDate"
                                        key={tabValue}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label="Due Date"
                                        disabled
                                        className='{classes.textField} w-100'
                                        form={form}
                                    />
                                </td>
                              
                                <td>
                                    <CustomTextField id="TranchePercentage"
                                        key={tabValue}
                                        label="Tranche Percentage"
                                        className="w-100"
                                        disabled={getTrancheType(form.values["TrancheTypeID"])}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                </tr>
            </tbody>
        </table>
    </form>
    }
</EntityForm>
    );
})
