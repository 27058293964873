import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Benefits.css";
import SearchView from "../../widgets/SearchView";
import BenefitsTargetsForm from './BenefitsTargetsForm';
import CardLayout from '../../widgets/Card';


const headCells = [

    { id: 'Number', numeric: false, disablePadding: true, label: 'Number' },
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                { id: 'delete', label: '', tooltip: 'Delete selected assessor from records', icon: 'DeleteIcon', disabled: true }
               
            ]
        }
    ]
};

const searchParameters = [
    { Name: "Number", Value: "" },
    { Name: "Description", Value: "" }
   
]

class BenefitsTargetsSearch extends Component {

    state = {
        model: {
            Number: "",
            Description: ""
        },
        dialogOpen: false
    };

  

    render() {
        return (
            <CardLayout title='Benefits Maintenance'>
            <SearchView headCells={headCells} dataUrl="api/indicium/provider/search" entityViewPath='/Benefits'
                 model={this.state.model} toolbarConfig={toolbarConfig} searchParameters={searchParameters}
                 onLookupValueChange={this.props.onLookupValueChange}
                 descriptionProp=""
                 title='Targets'
                 history={this.props.history} hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
            >
                <BenefitsTargetsForm model={this.state.model} />              
                </SearchView>
            </CardLayout>
        );
    }
}

export default withRouter(
    connect()(BenefitsTargetsSearch)
);
