import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Stakeholder.css";
import "./Stakeholder.css";
import { StakeholderForm } from './StakeholderForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";
import LoadingIndicator from '../../../components/LoadingIndicator';

const formRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();


class Stakeholder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stakeholderDetails: {
                FirstName: "",
                Surname: "",
                IDNo: "",
            },
            message: "",
            messageStyle: "",
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
            IsExternal: utils.isNull(this.props.IsExternal) ? false : this.props.IsExternal,
            IsDashboard: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.IsDashboard)) ? this.props.location.state.IsDashboard : null,
            counter: 0
        };
        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }

                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.IsExternal ? true : utils.isNull(this.props.location.state.selection) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add },
                        { id: 'organisationDetails', label: 'Organisation Details', tooltip: 'Organisation Details', disabled: utils.isNull(this.state.id) }

                    ]
                }
            ]
        }

    }

    //componentDidMount() {
    //    let LookupUrl = `api/sims/DGStakeholderOrganisation/`;
    //    let path = '/StakeholderOrganisationDetails';
    //    if (this.props.IsExternal) { LookupUrl = `api/sims/DGStakeholderRegistration/`; path = '/stakeholderRegistrationOrgDetails'; }
    //    this.props.history.push({
    //        pathname: path,
    //        state: {
    //            StakeholderID: 4,
    //            stakeholderName: 'Test' + " " + 'Test',
    //            IDNo: '34343434',
    //            IsExternal: this.state.IsExternal,
    //            LookupUrl: LookupUrl
    //        }
    //    })
    //}

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({
                stakeholderDetails: {
                    FirstName: values.FirstName,
                    Surname: values.Surname,
                    IDNo: values.IDNo
                }
            });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("organisationDetails" === buttonId) {
            let LookupUrl = `api/sims/DGStakeholderOrganisation/`;
            let path = '/StakeholderOrganisationDetails';
            if (this.props.IsExternal) { LookupUrl = `api/sims/DGStakeholderRegistration/`; path = '/stakeholderRegistrationOrgDetails';}
            this.props.history.push({
                pathname: path,
                state: {
                    StakeholderID: this.state.id,
                    stakeholderName: this.state.stakeholderDetails.FirstName + " " + this.state.stakeholderDetails.Surname,
                    IDNo: this.state.stakeholderDetails.IDNo,
                    IsDashboard: this.state.IsDashboard,
                    IsExternal: this.state.IsExternal,
                    LookupUrl: LookupUrl
                }
            });
        }
    }

    save() {
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;

            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            let data = JSON.stringify(formValue);

            if (isAdd) {
                this.checkIDNumber(formValue);

                if (this.state.message === "") {
                    let url = `api/sims/DGStakeholderPerson/AddStakeholder`;
                    if (this.props.IsExternal) {
                        url = `api/sims/DGStakeholderRegistration/AddStakeholder`;
                        utils.saveFormExternal(this, formRef, url, (response) => {
                            this.setState({ id: response.EntityId }, () => { toolbarRef.current.setButtonDisabled("organisationDetails", false); });
                            utils.showMessage(this, response.Message, response.MessageType);
                            response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                        }, this.error);
                    } else {
                        utils.saveForm(this, formRef, url, (response) => {
                            this.setState({ id: response.EntityId }, () => { toolbarRef.current.setButtonDisabled("organisationDetails", false); });
                            utils.showMessage(this, response.Message, response.MessageType);
                            response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                        }, this.error);
                    }
                }
            } else {
                const url = `api/sims/DGStakeholderPerson/UpdateStakeholder`;
                utils.updateForm(this, formRef, url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    response.MessageType === "ERROR" ? this.errorCallback(response) : this.successCallback(response);
                }, this.error);
            }
        }
    }

    checkIDNumber(formValues) {
        this.setState({ message: "", messageStyle: "" });
        //console.log(formValues);
        if (formValues.IDNo.length !== 13) {
            if (formValues.AlternateIDTypeID.Description === "None") {
                this.setState({ message: "Invalid ID Number / Alternate ID Type combination.", messageStyle: "ERROR" });
            }
        }
        if (formValues.IDNo.length === 13 && isNaN(formValues.IDNo) && formValues.AlternateIDTypeID.Description !== "None") {
            this.setState({ message: "Invalid ID Number / Alternate ID Type combination.", messageStyle: "ERROR" });
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType, counter: this.state.counter + 1 });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    closeView() {
        let url = `/stakeholdersearch`;
        if (this.state.IsDashboard) { url = `/stakeholderdashboard`; }
        this.props.history.push({
            pathname: url,
            state: { selection: null }
        });
    }

    dataArrivedHandler = (values) => {
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] });
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                {this.state.IsExternal ? <LoadingIndicator /> : null}
                <div className="view w-100 h-100">
                    <p className="breadcrumbs">
                        Stakeholder Details
                    </p>
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td className="btn-margin">
                                    <EnhancedToolbar highlight={false} title="stakeholder" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                        ref={toolbarRef} enabled={true} config={this.toolbarConfig} />
                                </td>
                            </tr>
                            <tr>
                                <td className="formContainer">
                                    <StakeholderForm key={this.state.counter} ref={formRef} id={this.state.id} valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                        entityLookupController={"GetFind"} IsExternal={this.props.IsExternal} LookupUrl={"api/sims/DGStakeholderRegistration/GetLookupList?listId="}
                                        dataArrivedHandler={(values) => this.dataArrivedHandler(values)} dataUrl={typeof this.props.IsExternal != 'undefined' ? "api/sims/DGStakeholderRegistration/getfind" : "api/sims/DGStakeholderPerson/getfind"} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(Stakeholder));
