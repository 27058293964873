import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./ProjectOwner.css";
import { ProjectOwnerForm } from './ProjectOwnerForm';
import { ViewContainer } from "../../View";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();


const projectownerRef = React.createRef();

class ProjectOwner extends Component {

    state = {
        ProjectOwnerDetails: {
            Person: {
                FirstName: "",
                Surname: "",
                IDNo: ""
            }
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },

                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit }

                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ ProjectOwnerDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.close();
        }
    }

    handleToolbarButtonMenuClick(menu) {
        
    }

    close() {
        this.props.history.push('/projectownerSearch');
    }

    successCallback = () => {

        this.setState({ message: "Project Owner updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = projectownerRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/projectownerdetails/UpdateProjectOwner", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    render() {

        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Project Owner " + (this.state.ProjectOwnerDetails.FirstName !== null ? "> " + this.state.ProjectOwnerDetails.FirstName + " " + this.state.ProjectOwnerDetails.Surname + (this.state.ProjectOwnerDetails.IDNo !== null ? " - " + this.state.ProjectOwnerDetails.IDNo : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="ProjectOwner" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "64vh", maxHeight: "64vh" }}>
                                    <ProjectOwnerForm ref={projectownerRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(ProjectOwner));
