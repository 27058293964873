import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { EntityForm } from '../../../widgets/Forms';
import { CustomTextField } from '../../../widgets/CustomTextField';

const TrancheSetupEAPTargetDetailsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref}
            viewId="eaptarget"
            dataURL='api/sims/eaptarget/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="eaptargetform">
                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/eaptarget/GetLookupList?listId=Equity"
                                        id="EquityID"
                                        key={tabValue}
                                        label="Race"
                                        className="w-100"
                                        required={true}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/eaptarget/GetLookupList?listId=Gender"
                                        id="GenderID"
                                        key={tabValue}
                                        label="Gender"
                                        required={true}
                                        className="w-100"
                                        form={form} />
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="Number"
                                        key={tabValue}
                                        label="Number"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        required
                                        validationRegex={/^[0-9]+$/g}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

export default TrancheSetupEAPTargetDetailsForm;