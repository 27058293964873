import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import { ViewContainer } from "../../View";
import ViewUtils from "./../../ViewUtils";


const utils = new ViewUtils();

const headCells = [
    { id: 'AccreditationStartDate', numeric: false, disablePadding: true, label: 'Accreditation Start Date' },
    { id: 'AccreditationEndDate', numeric: false, disablePadding: true, label: 'Accreditation End Date' },
    { id: 'AccreditationStatus', numeric: false, disablePadding: true, label: 'Accreditation Status' }
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'CLOSE', tooltip: 'Close' }
            ]
        }

    ]
};

class ProviderAccreditationHistory extends Component {

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/provider',
                state: { selection: this.props.location.state.selection }
            });
        }
    }

    render() {
        return (
            <ViewContainer title={`Providers > ${this.props.location.state.providerName} > Accreditation History`}>
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={toolbarConfig} />
                    <EnhancedTable
                        headCells={headCells}
                        height="50vh"
                        dataUrl="api/indicium/Provider/getAccreditationHistory"
                        searchParameters={[{ Name: "providerID", Value: this.props.location.state.selection }]} paged={false} />
                </div>

            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ProviderAccreditationHistory)
);
