import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./bda.css";
import EnhancedTable from '../../widgets/Table';
import EnhancedToolbar from '../../widgets/Toolbar';
import BDASearchForm from './BDASearchForm';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const headCells = [
    { id: 'OrganisationLegalName', numeric: false, disablePadding: true, label: 'Organisation Legal Name' },
    { id: 'OrganisationSDLNUmber', numeric: false, disablePadding: true, label: 'Organisation SDL Number' },
    { id: 'OrganisationTradeName', numeric: false, disablePadding: true, label: 'Organisation Trade Name' }

];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
            ]
        }
    ]
};

const handler = (state) => {
    return {
        //isLoading: state.learner.isLoading,
        //message: state.learner.message
    };
};

class BDASearch extends Component {

    state = {
        model: {},
        searchParameters: [{ Name: "OrganisationTradeName", Value: "" },
            { Name: "OrganisationSDLNUmber", Value: "" },
        { Name: "OrganisationLegalName", Value: "" }],
        selection: []
    };

    handleToolbarButtonClick(buttonId) {
        if ("add" === buttonId) {
            this.props.history.push('/external-moderator');
        } else {
            this.props.history.push({
                pathname: '/external-moderator',
                state: { selection: this.state.selection[0] }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            this.setState({ message: this.props.message });
        }
    }
    
    search() {
        this.setState({
            searchParameters: [{ Name: "OrganisationTradeName", Value: this.state.model.organisationTradeName },
                { Name: "OrganisationSDLNUmber", Value: this.state.model.organisationSDLNUmber },
            { Name: "OrganisationLegalName", Value: this.state.model.organisationLegalName }]
        });
    }

    render() {
        return (
            <div className="view w-100 h-100">
                <p className="legend">
                  BDA
                </p>
                <p className="message">
                    {this.state.message}
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <BDASearchForm model={this.state.model} />
                            </td>
                            <td>
                                <IconButton
                                    onClick={this.search.bind(this)}
                                    aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="searchResults">
                    <EnhancedToolbar highlight={false} title="BDA" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={toolbarConfig}/>
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => this.setState({ selection: selection})}
                        dataUrl="api/indicium/bda/search" paged={false}
                        searchParameters={this.state.searchParameters} />
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect()(BDASearch)
);
