import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import LinearFlowHeading from '../../widgets/LinearFlowHeading';
import AddPersonRoleSearch from './../person/AddPersonRoleSearch';
import AddAssessorDetails from './AddAssessorDetails';
import AddAssessorRegistrationDetails from './AddAssessorRegistrationDetails';
import AddAssessorProgrammeDetails from './AddAssessorProgrammeDetails';
import AddAssessorTrainingProvider from './AddAssessorTrainingProvider';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";

const steps = [
    { name: "Identity Details", tooltip: "Search the system for an existing person. If one does not extist, click proceed to create a learner with a new person" },
    { name: "Assessor Details", tooltip: "Capture assessor details" },
    { name: "Registration Details", tooltip: "Capture registration details" },
    { name: "Training Providers", tooltip: "Link training providers" },
    { name: "Scope", tooltip: "Capture scope details" }
];

const utils = new ViewUtils();
class AddAssesorFlow extends Component {
    state = {
        currentStepIndex: 0,
        data: null,
        documents: {},
        saveMessage: "",
        hasSaveError: false
    }

    navigate(index, forward) {
        if (forward) {
            if (index !== 4) {
                this.setState({ currentStepIndex: index + 1 });
            }
        } else {
            this.setState({ currentStepIndex: index - 1 });
        }
    }

    stepHandler(index, forward, data) {
        if (index === 0) {
            if (!utils.isNull(this.state.data)
                && !utils.isNull(this.state.data.personRoleDetails) && !forward) {
                this.setState({
                    data: {
                        ...this.state.data,
                        personRoleDetails: {
                            ...this.state.data.personRoleDetails,
                            Person: data.Person
                        }
                    }
                }, () => { this.navigate(index, forward) });
            } else {
                this.setState({
                    data: data
                }, () => { this.navigate(index, forward) });
            }
        } else if (index === 1) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, personRoleDetails: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 2) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, registrationDetails: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 3) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, trainingProviderDetails: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 4) {
            if (!utils.isNull(data) && forward) {
                let assessorData = {};
                assessorData.Person = this.state.data.personRoleDetails;
                assessorData.RegistrationDetails = this.state.data.registrationDetails;
                assessorData.Scope = data;
                assessorData.Scope.selectedIntervension = null;
                assessorData.TrainingProviders = this.state.data.trainingProviderDetails.trainingProviders;

                utils.invokeUrl("api/sims/assessor/AddAssessor", (data) => {
                    this.props.history.push({
                        pathname: '/assessorSearch'
                    })
                }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(assessorData)
                });
            } else {
                this.setState({
                    data: { ...this.state.data, scopeDetails: data }
                }, () => { this.navigate(index, forward) });
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
    }

    componentDidMount() {
        utils.setLegend("Add Assessor");
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <Row>
                    <Col>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col> <LinearFlowHeading steps={steps} currentIndex={this.state.currentStepIndex} /></Col>
                        </Row>
                        {this.state.currentStepIndex === 0 ?

                            <AddPersonRoleSearch role="assessor"
                                data={this.state.data}
                                alreadyExistErrorMessage="An assessor with the specified criteria already exist"
                                stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} />
                            : null}
                        {this.state.currentStepIndex === 1 ? <AddAssessorDetails permissionItem={this.props.onMenuSpecificPermissionItem('/assessor')} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 2 ? <AddAssessorRegistrationDetails permissionItem={this.props.onMenuSpecificPermissionItem('/assessorregistrationdetails')} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 3 ? <AddAssessorTrainingProvider permissionItem={this.props.onMenuSpecificPermissionItem('/assessorprovidersearch')} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 4 ? <AddAssessorProgrammeDetails data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                    </Col>
                </Row>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AddAssesorFlow));
