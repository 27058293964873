import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import *  as InterventionSearch from './InterventionIndex';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';


export default function ApproveClaimSearchForm(props) {

    function InterventionTypeChange(value, form) {
        if (typeof value !== 'undefined' && value !== null) {
            if (value.Description === "Learnership") {
                var type = 'LearnershipSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/ApproveClaim/LearnershipSearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "Skills Programme") {
                var type = 'SkillsProgramSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/ApproveClaim/SkillsProgrammeSearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "AET") {
                var type = 'AETSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/ApproveClaim/AETSearch" autoFetchData={false} selection={props.selection} />
            }
        }
    }


    return (
        <EntityForm ref={React.createRef()} viewId="ApproveClaimSearchForm" values={props.data}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="GenerateClaimSearchForm">
                    <tr>
                        <td>
                            <SelectItem
                                dataUrl={"api/sims/ApproveClaim/GetLookupList?listId=DGType"}
                                id="DiscretionaryGrantTypeID"
                                label="Intervention Type"
                                className="w-150"
                                form={form}
                                InputProps={{
                                    readOnly: props.disableFields
                                }}
                            />
                        </td>
                        <td>
                            <LookupField
                                id="EmployerID"
                                label="Employer"
                                className="w-100"
                                form={form}
                            >
                                <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/ApproveClaim/EmployerSearch"
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("EmployerID", value)
                                    }} autoFetchData={false} selection={props.selection} />
                            </LookupField>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <LookupField
                                required={true}
                                id="InterventionID"
                                label="Intervention"
                                className="w-100"
                                form={form}
                            >
                                {InterventionTypeChange(form.values["DiscretionaryGrantTypeID"], form)}
                            </LookupField>
                        </td>
                    </tr>
                    <br>
                    </br>
                </form>
            }
        </EntityForm>
    );
}
