import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Benefits.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import CardLayout from '../../widgets/Card';
import { BenefitsUploadForm } from './BenefitsUploadForm';


const headCells = [

    { id: 'Target', numeric: false, disablePadding: true, label: 'Target' },
    { id: 'Intervention', numeric: false, disablePadding: true, label: 'Intervention' },
    { id: '	Benefit', numeric: false, disablePadding: true, label: '	Benefit' },
    
];

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                { id: 'delete', label: '', tooltip: 'Delete selected assessor from records', icon: 'DeleteIcon', disabled: true }
            ]
        }
    ]
};

const searchParameters = [

    { Name: "Target", Value: "" },
    { Name: "Intervention", Value: "" },
    { Name: "Benefit", Value: "" }
  
]

const userRef = React.createRef();

class BenefitsUpload extends Component {

    state = {
        model: {

            Target: "",
            Intervention: "",
            Benefit: ""
        },
        dialogOpen: false
    };

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/EmailAddForm',

            })
        }
    }

    render() {
        return (
            <CardLayout title={`Benefits Maintenance `} >        
                <div className="view w-100 h-100">
                    <p className="legend">
                       Benefits Uploading
                </p>
                    <table width="100%">
                        <tbody>
                           
                            <tr>
                                <td className="formContainer">
                                    <BenefitsUploadForm ref={userRef}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>               
            </CardLayout >
        );
    }
}

export default withRouter(
    connect()(BenefitsUpload)
);
