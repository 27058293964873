import React from 'react';

import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { CustomTextField } from '../../widgets/CustomTextField';



const utils = new ViewUtils();
export const LearnershipOutcomeForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref} viewId="LearnershipOutcomeForm"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LearnershipOutcomeForm">
                    <table cellPadding="2" width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/providerlearnership/GetLookupList?listId=ProgramOutcome"
                                        id="ProgramOutcomeID"
                                        key={tabValue}
                                        label="Outcome"
                                        className="w-100"
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="Specialisation"
                                        key={tabValue}
                                        label="Specialisation"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </form>
            }
        </EntityForm>
    );
})
