import React, { useState } from 'react';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View.jsx";
import EnhancedToolbar from '../../widgets/Toolbar';
import ApprovalsForm from './ApprovalDetailsForm';
import AlertItem from "../../widgets/AlertItem";



const Approval = (props) => {
    const utils = new ViewUtils();
    const approvalRef = React.createRef();
    const [message, setMessage] = useState("")
    const [messageStyle, setMessageStyle] = useState("")

    const handleFormValueChange = (values) => {
        if (values != null) {
            // setFinancialYear(values.FinancialYearID)
        }
    }

    const toolbarConfig = {
        items: [

            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: 'CLOSE', tooltip: 'Close' }
                ]
            },
            {
                type: "devider"
            },
            {

                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'Save', tooltip: 'Save Approval', icon: 'SaveIcon' },
             
                ]
            }

        ]
    };


      //  save() {
    //         utils.saveForm(this, formRef, `api/sims/DGOrganisation/updateDGOrganisation`, this.successCallback, null, null, '/DGOrganisationSearch');
    //     }

    const handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "save") {
            // this.setState({ addDialogOpen: true });
        } else if (buttonId === "close") {
            props.history.push({
                pathname: "/assessment",
                state: { selection: props.location.state.organisationID }
            })
        }
    }

    return (
        <ViewContainer topPadding="0" title={
            " Approval"}>


            <div>
                {/* <AlertItem message={this.state.message} alertStyle={messageStyle}  /> */}
            </div>
            <table width="100%">

                <tbody>
                    <tr>
                        <td>
                            <EnhancedToolbar
                                highlight={false}
                                title="WSP/ATR Approval"
                                buttonClickHandler={(buttonId) => handleToolbarButtonClick(buttonId)}
                                // buttonMenuClickHandler={(menu) => handleToolbarButtonMenuClick(menu)}
                                enabled={true}
                                config={toolbarConfig} />
                        </td>
                    </tr>
                    <tr>
                        <td className="formContainer">
                            <ApprovalsForm ref={approvalRef} id={typeof props.location.state !== 'undefined' ? props.location.state.selection : null} valueChangeHandler={(values) => handleFormValueChange(values)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </ViewContainer>

    );
}

export default Approval;