import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./AccreditationHistory.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';

const historyHeadCells = [
    { id: 'Code', numeric: false, disablePadding: false, label: 'Code', widthRatio: .2 },
    { id: 'Title', numeric: false, disablePadding: false, label: 'Title', widthRatio: .2 },
    { id: 'Credits', numeric: false, disablePadding: false, label: 'Credits', widthRatio: .1 },
    { id: 'RegistrationStart', numeric: false, disablePadding: false, label: 'Type', widthRatio: .2 },
    { id: 'RegistrationEnd', numeric: false, disablePadding: false, label: 'Completion Date', widthRatio: .3 },
];

const activeProgrammeHeadCells = [
    { id: 'Code', numeric: false, disablePadding: false, label: 'Code', widthRatio: .2 },
    { id: 'Title', numeric: false, disablePadding: false, label: 'Title', widthRatio: .2},
    { id: 'Type', numeric: false, disablePadding: false, label: 'Type', widthRatio: .2 },
    { id: 'Institution', numeric: false, disablePadding: false, label: 'Institution', widthRatio: .2 },
    { id: 'RegistrationStart', numeric: false, disablePadding: false, label: 'Date of Registration', widthRatio: .2 },
];

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AccreditationHistory = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    

    return (
        <FormTabContainer>
            <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                    <Tab label="Current Enrollments" {...a11yProps(0)} />
                    <Tab label="Achieved Unit Standards" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0} style={{ height: "55vh" }}>
                <div className="searchResults">
                    <EnhancedTable height="50vh"  dataUrl="api/indicium/Learner/getEnrolmentHistory" searchParameters={props.searchParameters} headCells={activeProgrammeHeadCells} />
                </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1} style={{ height: "55vh" }}>
                <div className="searchResults">
                    <EnhancedTable height="50vh"  dataUrl="api/indicium/Learner/getLearnerAchievedUS" searchParameters={props.searchParameters} headCells={historyHeadCells} />
                </div>
            </TabPanel>
        </FormTabContainer>
    );
})
