import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import { SelectItem } from '../../widgets/SelectItem';
import ProviderSearch from "../provider/ProviderSearch";
import EmployerSearch from "../employer/EmployerSearch";
import LearnerSearch from "../learner/LearnerSearch";
import ViewUtils from '../../ViewUtils';
import SkillsProgramSetupSearch from '../program/SkillsProgramSetupSearch';
import { makeStyles } from '@material-ui/core/styles';

const utils = new ViewUtils();

const LearnerEndorsementLetterSearchForm = React.forwardRef((props, ref) => {
    const [dependency, setDependency] = React.useState(props.dependency);

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "50px !important",
                width: "600px !important"
            }
        }
    })
    
    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    return (
        <EntityForm ref={ref} viewId="learner" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}
        >
            {form =>
                <form className={form.classes.container} id="LearnerEndorsementLetterSearchForm">
                    <div className="row search-form-container">

                        <div className="col form-col">
                            <SelectItem
                                parentId={0}
                                dataUrl="api/sims/learnerendorsementletter/GetLookupList?listId=SPEndorsementLetterType"
                                id="PrintTypeID"
                                required={true}
                                label="Print Type"
                                className="w-100"
                                form={form}
                            />
                        </div>
                       <div className="col form-col">
                                    <LookupField
                                        id="ProgrammeID"
                                        label="Skills Programmes"
                                        className="w-100"
                                        required={false}
                                        form={form}
                                    >
                                        <SkillsProgramSetupSearch
                                            showTitle={true}
                                            mode='lookup'
                                            dataUrl='api/sims/learnerendorsementletter/getskillsprogrammesearch'
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("ProgrammeID", value)
                                            }}
                                            autoFetchData={false}
                                            selection={props.selection}
                                        />
                                    </LookupField>
                                </div>

                        <div className="col form-col">
                            <LookupField
                                id="ProviderID"
                                label="Provider"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <ProviderSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/learnerendorsementletter/ProviderSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("ProviderID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>

                        <div className="col form-col">
                            <LookupField
                                id="EmployerID"
                                label="Employer"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <EmployerSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/learnerendorsementletter/EmployerSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("EmployerID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>

                        <div className="col form-col">
                            <LookupField
                                id="LearnerID"
                                label="Learner"
                                className="w-100"
                                required={false}
                                form={form}
                            >
                                <LearnerSearch
                                    showTitle={true}
                                    mode='lookup'
                                    dataUrl='api/sims/learnerendorsementletter/LearnerSearch'
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("LearnerID", value)
                                    }}
                                    autoFetchData={false}
                                    selection={props.selection}
                                />
                            </LookupField>
                        </div>
                                <div className="col form-col">
                                    <SelectItem
                                        parentId={0}
                                        dataUrl="api/sims/learnerendorsementletter/GetLookupList?listId=ReasonForReprint"
                                        id="ReasonForReprintID"
                                        disabled={!utils.isNull(form.values["PrintTypeID"]) && form.values["PrintTypeID"].Description === "Reprint Endorsement Letter" ? false:true}
                                        required={!utils.isNull(form.values["PrintTypeID"]) && form.values["PrintTypeID"].Description === "Reprint Endorsement Letter" ? true:false}
                                        label="Reason for Reprint"
                                        className="w-100"
                                        form={form}
                                    />
                                </div>
                                <div className="col form-col">
                                    <CustomTextField
                                        id="PrintingErrorReason"
                                        hidden={!utils.isNull(form.values["PrintTypeID"]) && form.values["PrintTypeID"].Description === "Reprint Endorsement Letter" ? false:true}
                                        required={!utils.isNull(form.values["ReasonForReprintID"]) && form.values["ReasonForReprintID"].Description === "Printing error" ? true:false}
                                        label="Printing Error Reason"
                                        className="w-100"
                                        multiline={true}
                                        form={form}
                                        styles={styles()}
                                        
                                    />
                                </div>
                    </div>
                </form>
            }
        </EntityForm >
    );
})

export default LearnerEndorsementLetterSearchForm;