import React from 'react';
import { EntityForm } from '../../widgets/SearchForm';
import { SelectItem } from '../../widgets/SelectItem';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import DocumentUpload from '../../widgets/DocumentUpload';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

const WSPATRApprovalBulkForm = React.forwardRef((props, ref) =>{
    const classes = useStyles();

    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }

    return (
        <EntityForm ref={ref} viewId="WSPATRApproval" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="WSPATRApprovalForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <CustomDatePicker
                                id="BoardApprovalDate"
                                label="Board Approval Date"
                                className='w-100'
                                required
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <SelectItem
                                dataUrl="api/sims/WSPATRApproval/GetLookupListWSPATRApprovalStatus"
                                id="BoardApprovalStatusID"
                                label="Board Approval Status"
                                className={classes.MuiSelectSelectMenu}
                                form={form}
                                required
                            />
                        </div>
                        <div className="col form-col">
                            &nbsp;
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    );
})

export default WSPATRApprovalBulkForm;