import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import SponsorshipSetupSearchForm from '../program/SponsorshipSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'ProgrammeType', numeric: false, disablePadding: true, label: 'Programme Type' },
    { id: 'SponsorshipType', numeric: false, disablePadding: true, label: 'Sponsorship Type' },
    { id: 'StartDate', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'EndDate', numeric: false, disablePadding: true, label: 'End Date' },
    { id: 'Client', numeric: false, disablePadding: true, label: 'Client' },
    { id: 'ProjectNumber', numeric: false, disablePadding: true, label: 'Project Number' }
];

const utils = new ViewUtils();

class LearnerSponsorship extends Component {
    constructor(props) {
        super(props);
        
        this.searchParameters = !utils.isNull(this.props.location.state.learnerID) ? [
            { Name: "Client", Value: "" },
            { Name: "ProgrammeID", Value: this.props.programmeId },
            { Name: "TrancheSetupSponsorshipTypeID", Value: { Id: 1, Description: "Bursary" } },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]
            :
            [
                { Name: "Client", Value: "" },
                { Name: "TrancheSetupSponsorshipTypeID", Value: { Id: 1, Description: "Bursary" } },
                { Name: "LearnerID", Value: this.props.location.state.learnerID }
            ];

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                Client: "",
                TrancheSetupSponsorshipTypeID: { Id: 1, Description: "Bursary" },
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                Client: "",
                TrancheSetupSponsorshipTypeID: { Id: 1, Description: "Bursary" },
                LearnerID: this.props.location.state.learnerID
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }


        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                model: model,
                keyUpSwitch: false,
                toolbarConfig: {
                    items: [
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                            ]
                        }
                        ,
                        {
                            type: "devider"
                        }
                        ,
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'project', label: 'GO TO PROJECT', tooltip: 'go to project', visible: this.props.onMenuSpecificPermissionItem('/GrantSetupSearch').View }
                            ]
                        }
                    ]
                }
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };

        //TODO: Add url for unit standard removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "project") {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: {
                    TrancheID: this.state.selectedLearnerProgramme[0].TrancheSetupID,
                    hideFields: false
                }
            })
        }

    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerProgramme: selection })
        } else {
            this.setState({ selectedLearnerProgramme: selection })
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Sponsorship > ${this.props.location.state.learnerNames}`}
                </p>
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/learnersponsorship/GetLearnerSponsorshipSearch" : this.props.dataUrl}
                    deleteUrl={"api/sims/learnersponsorship/unlinkLearnerSponsorship"} useDeletePost={true}
                    entityViewPath='/AddLearnerSponsorship'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="Client" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Learner Sponsorship"}
                    promptContent={"Are you sure you want to remove the learner Sponsorship record?"}
                >
                    <SponsorshipSetupSearchForm
                        data={this.state.model}
                        model={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LearnerSponsorship));

