import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardLayout from './widgets/Card';

const useStyles = makeStyles(theme => ({
    container: {
        height: 'auto',
        // overflow: ' auto !important',
        width: '100%',
        margin: "0px"
    }
}));

export function ViewContainer(props) {
    const classes = useStyles();

    return (
        <CardLayout title={props.title} mode={props.mode}>
            <div className={classes.container} style={{ paddingTop: props.topPadding !== null ? props.topPadding : "8px"}}>
                {props.children}
            </div>
        </CardLayout>
    );
}
