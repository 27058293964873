import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Organisation.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/Table';
import UnionCreateDialog from "./UnionCreateDialog";
import UnionEditDialog from "./UnionEditDialog";


const headCells= [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'JobTitle', numeric: false, disablePadding: true, label: 'Job Title' },
    { id: 'Designation', numeric: false, disablePadding: true, label: 'Training Committee Designation(Employer/Union Representative)' },
]
class TrainingCommitteeSearchRecord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            model: {  
            },
            addDialogOpen: false,
            editDialogOpen: false,
        
            toolbarConfig: {
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            {id: 'close', label: 'Close', tooltip:'Close'},
                            { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                            { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon' }
                        ]
                    }
                ]
            },
         
        }

    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });
        } else if (buttonId === "close") {
            this.close();
        }
        else if (buttonId === "edit") {
            this.setState({ editDialogOpen: true });
        }
    }

    close() {
        this.props.history.push({
            pathname: "/skillsOrganisation",
            // state: { selection: this.props.location.state.organisationID }
        })
    }

    render() {
        return (
            <ViewContainer 
            title={"Organisation > Training Committee >" + (this.props.location.state.organisationName !== null ? this.props.location.state.organisationName : "> Add")}>
                 <UnionCreateDialog open={this.state.addDialogOpen} addTrainingCommittee={true} />
                <UnionEditDialog open={this.state.editDialogOpen} addTrainingCommittee={true}  />

                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        title=""
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.state.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => this.setState({ selection: selection })}
                        dataUrl="api/indicium/provider/searchContacts" searchParameters={[{ Name: "OrganisationID", Value: this.props.location.state.organisationID }]} />
                </div>
        </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(TrainingCommitteeSearchRecord)
);
